import React from "react";

import { useTheme } from "@emotion/react";
import { Heading, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { Modal } from "components/lib/molecules";
import { useToast } from "index";
import { DeleteJobModalProps } from "./DeleteJobModal.types";
import { useBuildBundleDelete } from "components/pages/AdminPage/hooks/useBuildBundleDelete.hook";

export const DeleteJobModal: React.FC<DeleteJobModalProps> = ({
  onClose,
  job,
  refetchJobs,
}) => {
  const theme = useTheme();
  const { addToast, removeToast } = useToast();
  const { mutateAsync: deleteBuildBundle } = useBuildBundleDelete();

  /**
   * Helper function to delete a specific build bundle 
   */
  const handleDeleteJob = async () => {
    const toastId = addToast({
      variant: "info",
      description: "Deleting Build Bundle...",
    });
    try {
      if (!job?.id) throw Error();
      await deleteBuildBundle(job.id);
      await refetchJobs();
      removeToast(toastId);
      addToast({
        variant: "success",
        description: "Build Bundle deleted successfully",
      });
      onClose();
    } catch (err: any) {
      removeToast(toastId);
      addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to delete build bundle. Please try again later",
      });
    }
  };

  return (
    <Modal
      isOpen={Boolean(job)}
      continueLabel="Yes, delete it"
      onContinue={handleDeleteJob}
      closeLabel="Cancel"
      onClose={onClose}
    >
      <Stack
        direction="vertical"
        align="center"
        gap={2}
        styles={{ padding: theme.space[2], marginBottom: theme.space[3] }}
      >
        <Heading variant={1}>Are you sure?</Heading>
        <Text>
          You will not be able to recover this build bundle after deleting it
        </Text>
      </Stack>
    </Modal>
  );
};
