import { useTheme } from "@emotion/react";
import { useHistory } from "react-router";

import { Button } from "components/lib/atoms/Button/Button";
import { Card } from "components/lib/atoms/Card/Card";
import { Container } from "components/lib/atoms/Container/Container";
import { Heading } from "components/lib/atoms/Heading/Heading";
import { Text } from "components/lib/atoms/Text/Text";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { PATH } from "components/shared/constants/paths.constants";

import { ReactComponent as Illustration } from "./svgs/completed.svg";

export const CompletedPage = () => {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Card styles={{ padding: "32px", width: "80%" }} fullWidth={true}>
      <Stack
        direction="vertical"
        align="center"
        justify="center"
        fullWidth={true}
      >
        <Container styles={{ marginBottom: "24px" }}>
          <Illustration />
        </Container>

        <Heading
          variant={2}
          styles={{
            fontSize: theme.fontSizes[6],
            marginBottom: theme.space[2],
          }}
        >
          You're awesome! You have completed the job
        </Heading>
        <Text styles={{ marginBottom: theme.space[5] }}>
          Thanks so much for your help! Keep an eye out for your results and
          pay.
        </Text>
        <Stack direction="horizontal" gap={3}>
          <Button
            variant="secondary"
            onClick={() => {
              history.push(PATH.completedJobs);
            }}
          >
            View completed jobs
          </Button>
          <Button
            onClick={() => {
              history.push(PATH.browseJobs);
            }}
          >
            Do another job
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
