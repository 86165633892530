import React from "react";
import { useTheme } from "@emotion/react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Stack } from "components/lib/layouts";
import { Card, Heading } from "components/lib/atoms";
import { Modal } from "components/lib/molecules";
import { Link } from "react-router-dom";

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const theme = useTheme();
  return (
    <Modal
      isOpen={true}
      hideCloseButton={true}
      hideCloseIcon={true}
      disableContinueButton={false}
    >
      <Card
        styles={{ maxWidth: "800px", padding: "65px 50px" }}
        fullWidth={true}
      >
        <Stack
          justify="center"
          direction="vertical"
          gap={4}
          fullWidth={true}
          align="center"
          styles={{ maxWidth: "800px" }}
        >
          <Heading variant={1} color={theme.colors.purple.dark}>
            Oops! Something went wrong
          </Heading>
          <p style={{ textAlign: "center" }}>
            Try to refresh this page or return to the{" "}
            <Link to="/" onClick={resetErrorBoundary}>
              homepage
            </Link>
            .
            <br />
            <br />
            Please contact us at{" "}
            <a
              href="mailto:create@learncoach.com"
              style={{ textDecoration: "underline" }}
            >
              create@learncoach.com
            </a>{" "}
            if the problem persists.
          </p>
        </Stack>
      </Card>
    </Modal>
  );
};

export const GlobalErrorBoundary: React.FC = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
};
