import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { useToast } from "index";

import { Button, Heading, Text } from "components/lib/atoms";
import { InputField, Modal } from "components/lib/molecules";
import { validationEmail } from "components/utils/validation";
import { PATH } from "components/shared/constants/paths.constants";
import { Stack } from "components/lib/layouts";
import { useAuth } from "components/utils/auth";

import { useLogin } from "../hooks/useLogin";
import {
  AuthCard,
  AuthHeader,
  AuthLink,
} from "../CommonElements/CommonElements";

export const LoginForm = () => {
  const {
    getValues,
    register,
    handleSubmit,
    onLogin,
    errors,
    isSubmitting,
    watch,
    isUserNotConfirmed,
  } = useLogin();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const { push } = useHistory();
  const toast = useToast();
  const { resendConfirmationLink } = useAuth();

  const [email, password] = watch(["email", "password"]);

  useEffect(() => {
    if (!isOpen && isUserNotConfirmed) setIsOpen(true);
  }, [isUserNotConfirmed]);

  return (
    <AuthCard>
      <form onSubmit={handleSubmit(onLogin)}>
        <Stack direction="vertical" gap={4} fullWidth>
          <AuthHeader>Sign In</AuthHeader>

          <Stack direction="vertical" gap={1}>
            <Text>Email</Text>
            <InputField
              validationMessage={errors.email?.message}
              placeholder="Enter email address"
              {...register("email", {
                required: true,
                pattern: validationEmail,
              })}
            />
          </Stack>

          <Stack direction="vertical" gap={1}>
            <Text>Password</Text>
            <InputField
              validationMessage={errors.password?.message}
              type="password"
              placeholder="Enter password"
              {...register("password", {
                required: true,
              })}
            />
          </Stack>

          <Button
            type="submit"
            disabled={
              Object.keys(errors).length > 0 ||
              isSubmitting ||
              !email ||
              !password
            }
          >
            Login
          </Button>

          <Text align="center">
            <AuthLink to={PATH.forgotPassword}>I forgot my password</AuthLink>
          </Text>
        </Stack>
      </form>
      <Modal
        isOpen={isOpen}
        continueLabel="Resend"
        onContinue={() => {
          setIsOpen(false);
          // Resend Verification Link
          resendConfirmationLink(getValues().email)
            .then(() => {
              // Send Toast and Redirect to the Email Verification Component
              toast.addToast({
                description: "Verification link successfuly sent!",
                variant: "success",
              });
              push(`${PATH.login}/verify?email=${getValues().email}`);
            })
            .catch((err: Error) => {
              toast.addToast({
                description:
                  err?.message ||
                  "Something went wrong with sending the verification link",
                variant: "error",
              });
            });
        }}
        closeLabel="No"
        onClose={() => {
          setIsOpen(false);
        }}
        styles={{ width: "600px" }}
      >
        <Stack
          fullWidth={false}
          direction="vertical"
          align="center"
          gap={2}
          styles={{
            padding: theme.space[2],
            marginBottom: theme.space[1],
            textAlign: "justify",
          }}
        >
          <Heading variant={1}>Resend Verification Link?</Heading>
          <Text variant={3} color={theme.colors.grey.text}>
            Your account seems to be unverified. If you have not received the
            link or it has expired, we can resend a verification link for you.
          </Text>
        </Stack>
      </Modal>
    </AuthCard>
  );
};
