import { useContext } from "react";

import { ToastContext } from "index";
import { Stack } from "components/lib/layouts";
import { useCreateStripeLink } from "components/shared/hooks/useCreateStripeLink.hook";
import { Button, Container, Heading, Text } from "components/lib/atoms";

import { AccountPageSection } from "../../AccountPage.types";
import { AccountDetailsFormSection } from "../../common";
import { LinkIcon } from "./svgs";

export const PaymentsSection: React.FC<AccountPageSection> = ({
  title,
  user,
  refetchUser,
}) => {
  const { mutateAsync: generateStripeLink, isLoading } = useCreateStripeLink();
  const toast = useContext(ToastContext);
  const redirectToStripe = async () => {
    toast.addToast({
      heading: "Opening stripe dashboard",
      variant: "info",
    });
    try {
      const { url } = await generateStripeLink();
      window.open(url, "_blank");
    } catch (error) {
      toast.addToast({
        heading: "Something failed, Try again later.",
        variant: "error",
      });
    }
  };

  const callToActionText = user?.stripeAccountVerified
    ? "Edit account"
    : "Setup an account";
  return (
    <AccountDetailsFormSection
      title={title}
      actionButton={
        <Button
          onClick={redirectToStripe}
          styles={{ width: "fit-content" }}
          disabled={isLoading}
        >
          <Stack direction="horizontal" gap={2}>
            <LinkIcon />
            <Container>{callToActionText}</Container>
          </Stack>
        </Button>
      }
    >
      <Text styles={{ marginBottom: 0 }}>
        {user?.stripeAccountVerified ? (
          "Your account is connected to Stripe."
        ) : (
          <>
            We use Stripe to make sure you get paid on time and to keep your
            personal bank details secure. Click <b>“{callToActionText}”</b>{" "}
            button below to set up your payments on Stripe.
          </>
        )}
      </Text>
    </AccountDetailsFormSection>
  );
};
