export type FileExtension =
  | "png"
  | "jpg"
  | "jpeg"
  | "mp4"
  | "wav"
  | "zip"
  | "doc"
  | "docx"
  | "ppt"
  | "pptx"
  | "odp"
  | "zip(compressed)";

export type MimeType =
  | "image/png"
  | "image/jpg"
  | "image/jpeg"
  | "video/mp4"
  | "audio/wav"
  | "application/zip"
  | "application/x-zip-compressed"
  | "application/msword"
  | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  | "application/vnd.ms-powerpoint"
  | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  | "application/vnd.oasis.opendocument.presentation";

export const fileExtensionByMimeTypeLookup: {
  [key: string]: FileExtension;
} = {
  "image/png": "png",
  "image/jpg": "jpg",
  "image/jpeg": "jpeg",
  "video/mp4": "mp4",
  "audio/wav": "wav",
  "application/zip": "zip",
  "application/x-zip-compressed": "zip",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/vnd.ms-powerpoint": "ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
  "application/vnd.oasis.opendocument.presentation" : "odp",
};
