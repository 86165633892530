import { useContext, useState, useMemo, useCallback } from "react";
import { useHistory, useParams, Prompt } from "react-router";
import isEqual from "react-fast-compare";
import { useTheme } from "@emotion/react";
import ReactPlayer from "react-player";

import { ToastContext } from "index";
import {
  Button,
  Divider,
  Heading,
  Text,
  Select,
  ValidationMessage,
} from "components/lib/atoms";
import {
  AttachmentResponseDto,
  RetrieveSubmissionByIdJobResponseDto,
} from "components/utils/openapi";
import { UploadImage, Modal } from "components/lib/molecules";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { RichEditor } from "components/shared/RichEditor/RichEditor";
import { RichPreview } from "components/shared/RichPreview/RichPreview";
import { PATH } from "components/shared/constants/paths.constants";
import { ValidationMessageRequired } from "components/utils/validation";
import { useQuery } from "components/utils/routing";

import { useUpdateSubmissionContent } from "../../hooks/useUpdateSubmissionContent.hook";
import { useUploadAttachment } from "../../hooks/useUploadAttachment.hook";
import { ReactComponent as TickIcon } from "../../svgs/tick.svg";
import {
  FeedbackForm,
  QuestionForm,
  QuestionFormValidation,
  UpdatedQuestionForm,
} from "./QuestEditSection.types";
import { InputSubmissionLookup } from "../../JobEditPage";

export const QuestionEditSection = ({
  jobID,
  submission,
  createNewQuestion,
  refetch,
  completedQuestions,
  submissionContentLookup,
}: {
  refetch: () => Promise<any>;
  jobID: string;
  submission: RetrieveSubmissionByIdJobResponseDto;
  createNewQuestion: () => Promise<any>;
  completedQuestions: number;
  submissionContentLookup?: InputSubmissionLookup;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const toast = useContext(ToastContext);
  const history = useHistory();
  const { questionID } = useParams<{ questionID: string }>();
  const { uploadAttachment, deleteAttachment } = useUploadAttachment();
  const { mutateAsync: updateSubmission } = useUpdateSubmissionContent();
  const query = useQuery();
  const isNew = query.get("new") === "true";
  const isReviewMode = query.get("mode") === "review";
  const previewMode =
    query.get("previewMode") || ("disabled" as "enabled" | "disabled");

  const feedbackOptions = [
    { value: "Spelling mistake", label: "Spelling mistake" },
    { value: "Logic Error", label: "Logic Error" },
    { value: "Formatting mistake", label: "Formatting mistake" },
    { value: "Other", label: "Other" },
  ];

  const intialQuestionFormValidation: QuestionFormValidation = {
    feedbackValidationMessage: "",
    feedbackTypeValidationMessage: "",
    questionValidationMessage: "",
    correctAnswerValidationMessage: "",
    incorrectAnswerOneValidationMessage: "",
    incorrectAnswerTwoValidationMessage: "",
    incorrectAnswerThreeValidationMessage: "",
  };
  const [questionFormValidation, setQuestionFormValidation] =
    useState<QuestionFormValidation>(intialQuestionFormValidation);

  const getSelectDefaultValues = () => {
    return isReviewMode
      ? feedbackOptions.filter((option) => {
          return feedbackForm.errorTypes?.find((errorType: string) => {
            return option.value === errorType;
          });
        })
      : [];
  };

  const areAnswersDifferent = (
    orginalAnswer: string,
    udpatedAnswer: string
  ) => {
    if (udpatedAnswer && orginalAnswer) {
      return orginalAnswer !== udpatedAnswer;
    }
    return false;
  };

  const isUpdatedReviewContent = () => {
    const currentSubmission = currentSubmissionContent?.content;
    // Use existing comparison (isSaveReviewContent) if there are no updated questions/answers
    if (
      currentSubmission &&
      !currentSubmission.updatedCorrectAnswer &&
      !currentSubmission.updatedIncorrectAnswerOne &&
      !currentSubmission.updatedIncorrectAnswerTwo &&
      !currentSubmission.updatedIncorrectAnswerThree &&
      !currentSubmission.updatedQuestion
    ) {
      return !isSameReviewContent;
    }

    // Compare each field with the current review submission for changes
    if (currentSubmission && questionForm) {
      return (
        areAnswersDifferent(
          questionForm.correctAnswer,
          currentSubmission.updatedCorrectAnswer
        ) ||
        areAnswersDifferent(
          questionForm.incorrectAnswerOne,
          currentSubmission.updatedIncorrectAnswerOne
        ) ||
        areAnswersDifferent(
          questionForm.incorrectAnswerTwo,
          currentSubmission.updatedIncorrectAnswerTwo
        ) ||
        areAnswersDifferent(
          questionForm.incorrectAnswerThree,
          currentSubmission.updatedIncorrectAnswerThree
        ) ||
        areAnswersDifferent(
          questionForm.question,
          currentSubmission.updatedQuestion
        )
      );
    }
    return false;
  };

  const overrideInitialSubmissionContent = () => {
    if (currentSubmissionContent?.content?.feedback) {
      return {
        question: currentSubmissionContent.content.updatedQuestion,
        correctAnswer: currentSubmissionContent.content.updatedCorrectAnswer,
        incorrectAnswerOne:
          currentSubmissionContent.content.updatedIncorrectAnswerOne,
        incorrectAnswerTwo:
          currentSubmissionContent.content.updatedIncorrectAnswerTwo,
        incorrectAnswerThree:
          currentSubmissionContent.content.updatedIncorrectAnswerThree,
      };
    }
    return {};
  };

  // New Questions will have param '?new=true' included, need to strip them out
  const sanitizedQuestionID = useCallback(() => {
    return questionID.indexOf("?") > 0
      ? questionID.substring(0, questionID.indexOf("?"))
      : questionID;
  }, [questionID]);

  const currentSubmissionContent = submission.submissionContents.find(
    (content) => content && content.id === sanitizedQuestionID()
  );

  // Get Submission Contents (Get from Initial Submission Content if Review, Directly if not)
  const getSubmissionForDisplay = useCallback(() => {
    if (isReviewMode && submissionContentLookup) {
      const initialSubmissionId =
        currentSubmissionContent?.content?.initialSubmissionContentId;
      return submissionContentLookup[initialSubmissionId];
    }
    return currentSubmissionContent;
  }, [isReviewMode, submissionContentLookup, currentSubmissionContent]);

  const savedContent: QuestionForm = useMemo(() => {
    return {
      question: "",
      correctAnswer: "",
      incorrectAnswerOne: "",
      incorrectAnswerTwo: "",
      incorrectAnswerThree: "",
      ...(getSubmissionForDisplay()?.content || {}),
      ...overrideInitialSubmissionContent(),
    };
  }, [getSubmissionForDisplay]);
  const [questionForm, setQuestionForm] = useState<QuestionForm>(savedContent);

  const saveFeedback: FeedbackForm = useMemo(() => {
    return {
      initialSubmissionContentId: getSubmissionForDisplay()?.id!,
      approved: false,
      errorTypes: [],
      feedback: "",
      imageModified: false,
      ...(currentSubmissionContent?.content || {}),
    };
  }, [getSubmissionForDisplay, currentSubmissionContent?.content]);
  const [feedbackForm, setFeedbackForm] = useState<FeedbackForm>(saveFeedback);

  const getSubmissionAttachments = () => {
    if (currentSubmissionContent?.content?.imageModified) {
      return currentSubmissionContent?.attachments.length > 0
        ? currentSubmissionContent?.attachments || []
        : [];
    }
    return getSubmissionForDisplay()?.attachments || [];
  };

  const [attachedImage, setAttachedImage] = useState<AttachmentResponseDto[]>(
    getSubmissionAttachments()
  );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const isSameContent =
    isEqual(savedContent, questionForm) && !feedbackForm.imageModified;

  const isSameReviewContent: boolean = useMemo(() => {
    return (
      isEqual(getSubmissionForDisplay()?.content, questionForm) &&
      !feedbackForm.imageModified
    );
  }, [
    getSubmissionForDisplay()?.content,
    questionForm,
    feedbackForm.imageModified,
  ]);

  const containsEmptyFeedback = () =>
    feedbackForm.errorTypes?.length === 0 ||
    feedbackForm.feedback?.length === 0;

  const isEditTitle = !isReviewMode && (isReviewMode === false ? !isNew : true);

  const saveQuestion = async () => {
    if (!isSameContent) {
      try {
        setIsLoading(true);
        const toastId = toast.addToast({
          variant: "info",
          description: "Saving...",
        });
        await updateSubmission({
          body: {
            ...questionForm,
          },
          submissionContentId: sanitizedQuestionID(),
          submissionId: jobID,
        });
        await refetch();
        toast.removeToast(toastId);
        toast.addToast({
          variant: "success",
          description: "Saved the question successfully",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const setQuestionStatus = async (approve: boolean) => {
    var updatedQuestionForm: UpdatedQuestionForm = {
      initialSubmissionContentId: feedbackForm.initialSubmissionContentId,
      approved: approve,
    };
    if (feedbackForm.feedback) {
      updatedQuestionForm = {
        ...feedbackForm,
        updatedQuestion: questionForm.question,
        updatedCorrectAnswer: questionForm.correctAnswer,
        updatedIncorrectAnswerOne: questionForm.incorrectAnswerOne,
        updatedIncorrectAnswerTwo: questionForm.incorrectAnswerTwo,
        updatedIncorrectAnswerThree: questionForm.incorrectAnswerThree,
        approved: approve,
      };
    }

    const toastId = toast.addToast({
      variant: "info",
      description: "Saving...",
    });
    await updateSubmission({
      body: {
        ...updatedQuestionForm,
      },
      submissionContentId: sanitizedQuestionID(),
      submissionId: jobID,
    });
    await refetch();
    toast.removeToast(toastId);
    toast.addToast({
      variant: "success",
      description: `Question has been ${approve ? "approved" : "unapproved"}`,
    });
    if (approve) {
      history.push({
        pathname: `${PATH.activeJobs}/${jobID}/resource-job`,
        search: "?mode=review",
      });
    }
  };

  const saveQuestionAndPreview = async () => {
    var hasNoValidationErrors = true;
    if (feedbackForm.feedback?.length === 0) {
      questionFormValidation.feedbackValidationMessage =
        ValidationMessageRequired;
      hasNoValidationErrors = false;
    }
    if (feedbackForm.errorTypes?.length === 0) {
      questionFormValidation.feedbackTypeValidationMessage =
        ValidationMessageRequired;
      hasNoValidationErrors = false;
    }
    if (questionForm.question.length === 0) {
      questionFormValidation.questionValidationMessage =
        ValidationMessageRequired;
      hasNoValidationErrors = false;
    }
    if (questionForm.correctAnswer.length === 0) {
      questionFormValidation.correctAnswerValidationMessage =
        ValidationMessageRequired;
      hasNoValidationErrors = false;
    }
    if (questionForm.incorrectAnswerOne.length === 0) {
      questionFormValidation.incorrectAnswerOneValidationMessage =
        ValidationMessageRequired;
      hasNoValidationErrors = false;
    }
    if (questionForm.incorrectAnswerTwo.length === 0) {
      questionFormValidation.incorrectAnswerTwoValidationMessage =
        ValidationMessageRequired;
      hasNoValidationErrors = false;
    }
    if (questionForm.incorrectAnswerThree.length === 0) {
      questionFormValidation.incorrectAnswerThreeValidationMessage =
        ValidationMessageRequired;
      hasNoValidationErrors = false;
    }

    if (hasNoValidationErrors) {
      const updatedQuestionForm: UpdatedQuestionForm = {
        ...feedbackForm,
        approved: false,
        updatedQuestion: questionForm.question,
        updatedCorrectAnswer: questionForm.correctAnswer,
        updatedIncorrectAnswerOne: questionForm.incorrectAnswerOne,
        updatedIncorrectAnswerTwo: questionForm.incorrectAnswerTwo,
        updatedIncorrectAnswerThree: questionForm.incorrectAnswerThree,
      };
      if (!isSameContent || (isReviewMode && !isSameReviewContent)) {
        const toastId = toast.addToast({
          variant: "info",
          description: "Saving...",
        });
        await updateSubmission({
          body: {
            ...updatedQuestionForm,
          },
          submissionContentId: sanitizedQuestionID(),
          submissionId: jobID,
        });
        await refetch();
        toast.removeToast(toastId);
        toast.addToast({
          variant: "success",
          description: "Saved the question successfully",
        });
        history.push({
          search: "?mode=review&previewMode=enabled",
        });
      }
    } else {
      setQuestionFormValidation({ ...questionFormValidation });
    }
  };

  const removeImage = (attachment: AttachmentResponseDto): void => {
    if (isReviewMode) {
      const attachments = currentSubmissionContent?.attachments || [];
      if (attachments.length === 0) {
        // no new image used, removing reference of image on page
        setAttachedImage([]);
      } else {
        for (var i = 0; i < attachments.length; i++) {
          if (attachment.id === attachments[i].id) {
            deleteAttachment(jobID, sanitizedQuestionID(), attachment.id).then(
              () => {
                currentSubmissionContent?.attachments.pop();
                setAttachedImage([]);
              }
            );
            break;
          }
        }
      }
      setFeedbackForm({
        ...feedbackForm,
        imageModified: true,
      });
    } else {
      deleteAttachment(jobID, sanitizedQuestionID(), attachment.id).then(() => {
        setAttachedImage([]);
      });
    }
  };

  const uploadImage = (files: File[]) => {
    uploadAttachment(files[0], jobID, sanitizedQuestionID()).then(
      (response: AttachmentResponseDto) => {
        // save image url/id and set state
        if (response) {
          const image = {
            id: response.id,
            url: response.url,
            name: response.name,
            mimeType: response.mimeType,
          };
          if (isReviewMode) {
            currentSubmissionContent?.attachments.push(image);
            setFeedbackForm({
              ...feedbackForm,
              imageModified: true,
            });
          }
          setAttachedImage([image]);
        }
      }
    );
  };

  const handleFeedbackTypeChange = (feedbackTypes: any) => {
    const errorTypes: string[] = [];
    feedbackTypes.forEach((feedbackType: any) =>
      errorTypes.push(feedbackType.value)
    );
    setFeedbackForm({ ...feedbackForm, errorTypes });
    setQuestionFormValidation({
      ...questionFormValidation,
      feedbackTypeValidationMessage: "",
    });
  };

  const clearAllFormData = () => {
    try {
      // To Trigger each RichEditor Components' onChange Values
      questionForm.question = "";
      questionForm.correctAnswer = "";
      questionForm.incorrectAnswerOne = "";
      questionForm.incorrectAnswerTwo = "";
      questionForm.incorrectAnswerThree = "";
    } finally {
      // Clear out all Values
      setQuestionForm({ ...questionForm });
    }
  };

  const clearAttachments = (referenceOnly: boolean = false) => {
    if (attachedImage.length > 0) {
      if (referenceOnly) {
        // we only want to remove the referenced image not remove the actual image from the db/server
        setAttachedImage([]);
      } else {
        removeImage(attachedImage[0]);
      }
    }
  };

  const resetAllFormData = async () => {
    const submission = getSubmissionForDisplay();
    const content = submission?.content;
    try {
      // To Trigger each RichEditor Components' onChange Values
      questionForm.question = content.question;
      questionForm.correctAnswer = content.correctAnswer;
      questionForm.incorrectAnswerOne = content.correctAnswer;
      questionForm.incorrectAnswerTwo = content.incorrectAnswerTwo;
      questionForm.incorrectAnswerThree = content.incorrectAnswerThree;
      feedbackForm.errorTypes = [];
      feedbackForm.approved = false;
      feedbackForm.feedback = "";
      feedbackForm.imageModified = false;
    } finally {
      const updatedQuestionForm: UpdatedQuestionForm = {
        initialSubmissionContentId: feedbackForm.initialSubmissionContentId,
        approved: false,
      };
      setQuestionForm({ ...questionForm });
      setFeedbackForm({
        ...feedbackForm,
      });
      await updateSubmission({
        body: {
          ...updatedQuestionForm,
        },
        submissionContentId: sanitizedQuestionID(),
        submissionId: jobID,
      });
      if (attachedImage.length > 0) {
        clearAttachments();
      } else {
        setAttachedImage(getSubmissionAttachments());
      }
      await refetch();
      setIsConfirmationModalOpen(false);
      history.push({
        search: "?mode=review&previewMode=enabled",
      });
    }
  };

  // Edit Question components

  const mcqVideoSection = (): JSX.Element => {
    return (
      <Stack
        direction="vertical"
        gap={1}
        styles={{ marginTop: theme.space[6] }}
      >
        <Heading variant={2} weight={500}>
          Video to write MCQs about
        </Heading>
        <ReactPlayer
          url={submission?.activityBuild?.videoLink}
          width="100%"
          height="100%"
          config={{
            vimeo: {
              playerOptions: {
                title: submission?.activityBuild?.name,
                responsive: true,
                autoplay: false,
                fullscreen: true,
                pip: true,
                controls: true,
              },
            },
          }}
        />
      </Stack>
    );
  };

  const onWriteNewQuestionClick = async () => {
    try {
      await saveQuestion();
      setIsLoading(true);
      clearAllFormData();
      clearAttachments(true);
      await createNewQuestion();
    } finally {
      setIsLoading(false);
    }
  };

  const onReviewQuestionsClick = async () => {
    await saveQuestion();
    history.push(`${PATH.activeJobs}/${jobID}/resource-job`);
  };

  const mcqButtonSet = (): JSX.Element => {
    return (
      <Stack justify="space-between" gap={2}>
        <Button
          variant="warningtext"
          size="medium"
          onClick={() => {
            clearAllFormData();
            clearAttachments();
          }}
        >
          Clear All
        </Button>
        <Button
          variant="secondary"
          size="medium"
          onClick={() => {
            saveQuestion();
            history.push({
              search: "?previewMode=enabled",
            });
          }}
        >
          Preview
        </Button>
        <Button
          variant="secondary"
          size="medium"
          onClick={() => {
            history.push({
              pathname: `${PATH.activeJobs}/${jobID}/resource-job`,
            });
          }}
        >
          Return to questions
        </Button>
      </Stack>
    );
  };

  const mcqFooterButtonSet = (): JSX.Element => {
    return (
      <Stack
        direction="horizontal"
        gap={1}
        styles={{
          padding: theme.space[2],
          justifyContent: "flex-end",
          position: "fixed",
          bottom: 0,
          left: 0,
          rigth: 0,
          width: "100%",
          border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
          background: theme.colors.white.default,
        }}
      >
        {submission?.submissionContents?.length !==
        submission?.job?.jobType?.contentNumber ? (
          <Button
            variant="secondary"
            onClick={() => onWriteNewQuestionClick()}
            disabled={isLoading}
          >
            Write another question
          </Button>
        ) : (
          <Button variant="secondary" onClick={() => onReviewQuestionsClick()}>
            Review questions
          </Button>
        )}
        <Button
          disabled={isSameContent}
          styles={{ minWidth: "120px", marginLeft: theme.space[3] }}
          onClick={saveQuestion}
        >
          Save
        </Button>
      </Stack>
    );
  };

  const mcqQuestionPreviewForm = (): JSX.Element => {
    if (
      isReviewMode &&
      ((questionForm.question === "" && savedContent.question !== "") ||
        !isEqual(savedContent, questionForm))
    ) {
      setQuestionForm(savedContent);
      setAttachedImage(getSubmissionAttachments());
    }
    const answers = [
      {
        label: "Correct answer",
        answer: questionForm.correctAnswer,
      },
      {
        label: "Incorrect answer 1",
        answer: questionForm.incorrectAnswerOne,
      },
      {
        label: "Incorrect answer 2",
        answer: questionForm.incorrectAnswerTwo,
      },
      {
        label: "Incorrect answer 3",
        answer: questionForm.incorrectAnswerThree,
      },
    ];

    return (
      <Stack
        direction="vertical"
        gap={1}
        styles={{ marginTop: theme.space[5] }}
      >
        <Stack direction="vertical">
          <Text weight={500}>Question</Text>
          <RichPreview
            styles={{
              paddingTop: theme.space[3],
            }}
          >
            {questionForm.question}
          </RichPreview>
        </Stack>
        {attachedImage && attachedImage.length > 0 && (
          <img
            src={attachedImage[0].url}
            alt="Attached file"
            style={{ width: "max-content" }}
          />
        )}

        <Divider styles={{ margin: `${theme.space[6]} 0` }} />

        {answers.map(({ answer, label }) => {
          return (
            <Stack
              key={label}
              direction="vertical"
              styles={{ marginTop: theme.space[0] }}
            >
              <Text weight={500}>{label}</Text>
              <RichPreview
                styles={{
                  paddingTop: theme.space[3],
                  marginBottom: theme.space[4],
                }}
              >
                {answer}
              </RichPreview>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const mcqQuestionForm = (): JSX.Element => {
    return (
      <Stack
        direction="vertical"
        gap={3}
        styles={{ marginTop: theme.space[6] }}
      >
        <Stack direction="vertical" gap={1}>
          <Text>{isEditTitle ? "Edit question" : "Question"}</Text>
          <RichEditor
            placeholder="Write your question"
            data={questionForm.question}
            onChange={(question) => {
              setQuestionForm({ ...questionForm, question });
              setQuestionFormValidation({
                ...questionFormValidation,
                questionValidationMessage: "",
              });
            }}
          />
          <ValidationMessage
            styles={{ marginTop: theme.space[2] }}
            text={questionFormValidation.questionValidationMessage}
          />
        </Stack>

        <Stack direction="vertical" gap={1}>
          {attachedImage && attachedImage.length > 0 && (
            <Stack direction="horizontal" gap={4}>
              <img
                src={attachedImage[0].url}
                width={200}
                height={200}
                alt="Attached file"
              />
              <Stack direction="vertical" gap={1} justify="center">
                <Button variant="warning" onClick={() => clearAttachments()}>
                  Remove
                </Button>
              </Stack>
            </Stack>
          )}
          {attachedImage && attachedImage.length === 0 && (
            <UploadImage
              maxFiles={1}
              accept="image/jpeg, image/png"
              width={600}
              height={600}
              maxSize={1024 * 200}
              uploadImage={uploadImage}
            />
          )}
          <Divider styles={{ marginTop: theme.space[6] }} />
        </Stack>
        <Stack
          direction="vertical"
          gap={1}
          styles={{ marginTop: theme.space[6] }}
        >
          <Text>{isEditTitle ? "Edit correct answer" : "Correct answer"}</Text>
          <RichEditor
            placeholder="Write your answer"
            data={questionForm.correctAnswer}
            onChange={(correctAnswer) => {
              setQuestionForm({ ...questionForm, correctAnswer });
              setQuestionFormValidation({
                ...questionFormValidation,
                correctAnswerValidationMessage: "",
              });
            }}
          />
          <ValidationMessage
            styles={{ marginTop: theme.space[2] }}
            text={questionFormValidation.correctAnswerValidationMessage}
          />
        </Stack>
        <Stack
          direction="vertical"
          gap={1}
          styles={{ marginTop: theme.space[4] }}
        >
          <Text>
            {isEditTitle ? "Edit incorrect answer 1" : "Incorrect answer 1 "}
          </Text>
          <RichEditor
            placeholder="Write your answer"
            data={questionForm.incorrectAnswerOne}
            onChange={(incorrectAnswerOne) => {
              setQuestionForm({ ...questionForm, incorrectAnswerOne });
              setQuestionFormValidation({
                ...questionFormValidation,
                incorrectAnswerOneValidationMessage: "",
              });
            }}
          />
          <ValidationMessage
            styles={{ marginTop: theme.space[2] }}
            text={questionFormValidation.incorrectAnswerOneValidationMessage}
          />
        </Stack>
        <Stack
          direction="vertical"
          gap={1}
          styles={{ marginTop: theme.space[4] }}
        >
          <Text>
            {isEditTitle ? "Edit incorrect answer 2" : "Incorrect answer 2 "}
          </Text>
          <RichEditor
            placeholder="Write your answer"
            data={questionForm.incorrectAnswerTwo}
            onChange={(incorrectAnswerTwo) => {
              setQuestionForm({ ...questionForm, incorrectAnswerTwo });
              setQuestionFormValidation({
                ...questionFormValidation,
                incorrectAnswerTwoValidationMessage: "",
              });
            }}
          />
          <ValidationMessage
            styles={{ marginTop: theme.space[2] }}
            text={questionFormValidation.incorrectAnswerTwoValidationMessage}
          />
        </Stack>
        <Stack
          direction="vertical"
          gap={1}
          styles={{ marginTop: theme.space[4] }}
        >
          <Text>
            {isEditTitle ? "Edit incorrect answer 3" : "Incorrect answer 3 "}
          </Text>
          <RichEditor
            placeholder="Write your answer"
            data={questionForm.incorrectAnswerThree}
            onChange={(incorrectAnswerThree) => {
              setQuestionForm({ ...questionForm, incorrectAnswerThree });
              setQuestionFormValidation({
                ...questionFormValidation,
                incorrectAnswerThreeValidationMessage: "",
              });
            }}
          />
          <ValidationMessage
            styles={{ marginTop: theme.space[2] }}
            text={questionFormValidation.incorrectAnswerThreeValidationMessage}
          />
        </Stack>
      </Stack>
    );
  };

  // Review Job components
  const reviewFeedbackSection = (): JSX.Element => {
    return (
      <Stack
        direction="vertical"
        gap={2}
        styles={{ marginTop: theme.space[6] }}
      >
        <Stack direction="vertical" gap={1}>
          <Text styles={{ fontWeight: theme.fontWeights.medium }}>
            What fixes did you make to this question?
          </Text>
          <Text>This feedback will be sent to the original writer</Text>
        </Stack>
        <Select
          styles={{ width: "auto" }}
          options={feedbackOptions}
          placeholder="Select error type"
          validationMessage={
            questionFormValidation.feedbackTypeValidationMessage
          }
          isMulti={true}
          onChange={(e: any) => {
            handleFeedbackTypeChange(e);
          }}
          defaultValue={getSelectDefaultValues()}
        />
        <RichEditor
          placeholder="Write your feedback"
          data={feedbackForm.feedback!}
          onChange={(feedback) => {
            setFeedbackForm({ ...feedbackForm, feedback });
            setQuestionFormValidation({
              ...questionFormValidation,
              feedbackValidationMessage: "",
            });
          }}
        />
        <ValidationMessage
          styles={{ marginTop: theme.space[2] }}
          text={questionFormValidation.feedbackValidationMessage}
        />
      </Stack>
    );
  };

  const reviewButtonSet = (): JSX.Element => {
    return (
      <Stack justify="space-between" gap={2}>
        <Button
          variant="warningtext"
          onClick={() => setIsConfirmationModalOpen(true)}
        >
          Reset All
        </Button>
        <Button
          variant="secondary"
          styles={{ minWidth: "120px" }}
          onClick={() => {
            history.push({
              search: "?mode=review&previewMode=enabled",
            });
          }}
        >
          Return to question
        </Button>
      </Stack>
    );
  };

  const reviewFooterButtonSet = (): JSX.Element => {
    return (
      <Stack
        direction="horizontal"
        gap={1}
        styles={{
          padding: theme.space[2],
          justifyContent: "flex-end",
          position: "fixed",
          bottom: 0,
          left: 0,
          rigth: 0,
          width: "100%",
          border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
          background: theme.colors.white.default,
        }}
      >
        <Button
          disabled={isSameReviewContent || containsEmptyFeedback()}
          styles={{ minWidth: "120px" }}
          onClick={() => {
            saveQuestionAndPreview();
          }}
        >
          Save & preview
        </Button>
      </Stack>
    );
  };

  const approveFooterButtonSet = (): JSX.Element => {
    return (
      <Stack
        direction="horizontal"
        gap={1}
        styles={{
          padding: theme.space[2],
          justifyContent: "flex-end",
          position: "fixed",
          bottom: 0,
          left: 0,
          rigth: 0,
          width: "100%",
          border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
          background: theme.colors.white.default,
        }}
      >
        {currentSubmissionContent?.content.approved !== true ? (
          <>
            <Button
              variant="secondary"
              styles={{ minWidth: "120px" }}
              onClick={() => {
                history.push({
                  search: "?mode=review",
                });
              }}
            >
              Fix question
            </Button>
            <Button
              styles={{ minWidth: "120px" }}
              onClick={() => {
                setQuestionStatus(true);
                history.push({
                  pathname: `${PATH.activeJobs}/${jobID}/resource-job`,
                });
              }}
            >
              Approve question
            </Button>
          </>
        ) : (
          <Button
            styles={{ minWidth: "120px" }}
            onClick={() => {
              setQuestionStatus(false);
              history.push({
                search: "?mode=review",
              });
            }}
          >
            Unapprove question
          </Button>
        )}
      </Stack>
    );
  };

  const approvedInfoText = (): JSX.Element => {
    if (currentSubmissionContent?.content.approved !== true) {
      return <></>;
    }
    return (
      <Stack
        styles={{
          background: theme.colors.mint.surface,
          padding: `${theme.space[3]} ${theme.space[2]}`,
          borderRadius: theme.radii[3],
        }}
      >
        <TickIcon height={theme.fontSizes[4]} width={theme.fontSizes[4]} />
        <Text
          color={theme.colors.mint.dark}
          styles={{ marginLeft: theme.space[2] }}
        >
          You have already approved this question
        </Text>
      </Stack>
    );
  };

  return (
    <>
      <Prompt
        when={
          (!isReviewMode && previewMode !== "enabled" && !isSameContent) ||
          (isReviewMode &&
            previewMode !== "enabled" &&
            isUpdatedReviewContent())
        }
        message={(location) => {
          const alertText =
            "You have unsaved changes that will be lost if you decide to continue. Are you sure you want to leave this page?";
          if (isReviewMode) {
            return alertText;
          }
          return location.search.includes("previewMode=enabled") ||
            location.search.includes("new=true")
            ? true
            : alertText;
        }}
      />
      <Stack
        direction="vertical"
        fullWidth={true}
        gap={2}
        styles={isLoading ? { pointerEvents: "none" } : {}}
      >
        {previewMode === "disabled" ? (
          <Stack
            direction="vertical"
            fullWidth={true}
            gap={3}
            styles={isLoading ? { opacity: 0.6, pointerEvents: "none" } : {}}
          >
            <Stack justify="space-between">
              <Stack direction="vertical">
                <Heading variant={2} weight={500}>
                  {isNew
                    ? "Write a new question"
                    : isReviewMode
                    ? "Fix question"
                    : "Edit the question"}{" "}
                </Heading>
                {!isReviewMode && (
                  <Text
                    color={theme.colors.grey.text}
                    styles={{ paddingTop: "4px" }}
                  >
                    {completedQuestions} of{" "}
                    {submission?.job?.jobType?.contentNumber} questions are
                    completed
                  </Text>
                )}
              </Stack>
              {isReviewMode ? reviewButtonSet() : mcqButtonSet()}
            </Stack>
            {questionForm && mcqQuestionForm()}
            <Divider styles={{ marginTop: theme.space[6] }} />
            {isReviewMode ? reviewFeedbackSection() : mcqVideoSection()}
          </Stack>
        ) : (
          <Stack direction="vertical" gap={4}>
            {isReviewMode && approvedInfoText()}
            <Stack justify="space-between">
              <Heading variant={2} weight={500}>
                {isReviewMode ? "Question to review" : "Preview Question"}
              </Heading>
              <Button
                variant="secondary"
                size="medium"
                onClick={() => {
                  history.push({
                    pathname: `${PATH.activeJobs}/${jobID}/resource-job`,
                  });
                }}
              >
                Return to Questions
              </Button>
            </Stack>
            {questionForm && mcqQuestionPreviewForm()}
          </Stack>
        )}
        {isReviewMode
          ? previewMode === "disabled"
            ? reviewFooterButtonSet()
            : approveFooterButtonSet()
          : mcqFooterButtonSet()}
        <ResetConfirmationModal
          isOpen={isConfirmationModalOpen}
          onConfirm={resetAllFormData}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      </Stack>
    </>
  );
};

interface ResetConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ResetConfirmationModal: React.FC<ResetConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const theme = useTheme();
  return (
    <Modal
      isOpen={isOpen}
      continueLabel="Reset All"
      onContinue={onConfirm}
      closeLabel="Cancel"
      onClose={onClose}
    >
      <Stack
        direction="vertical"
        align="center"
        gap={2}
        // Move this style out into the styles file
        styles={{ padding: theme.space[2], marginBottom: theme.space[3] }}
      >
        <Heading variant={1}>Reset All</Heading>
        <Text>
          Reset all will remove all changes you have made on this question
        </Text>
      </Stack>
    </Modal>
  );
};
