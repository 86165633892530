/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import { styles } from "./Radio.styles";
import { getTextStyles } from "../Text/Text.styles";

interface RadioProps {
  checked: boolean;
  value: string;
  name: string;
  onChange: (e: React.FormEvent<HTMLInputElement>, value: string) => void;
  children: React.ReactNode;
}

export const Radio = React.forwardRef(
  (
    { name, checked, value, onChange, children }: RadioProps,
    ref?: React.Ref<HTMLInputElement>
  ): JSX.Element => {
    const theme = useTheme();
    const id = `${name}-${value}`;
    return (
      <p css={styles}>
        <input
          ref={ref}
          type="radio"
          id={id}
          name={name}
          value={value}
          onChange={(e) => {
            onChange(e, value);
          }}
          checked={checked}
        />
        <label htmlFor={id} css={getTextStyles(theme, {})}>
          {children}
        </label>
      </p>
    );
  }
);
