import moment, { DurationInputArg1, DurationInputArg2, Moment } from "moment";

type OptionalDateArithmeticQuery = {
  type: "add" | "subtract";
  amount: DurationInputArg1;
  unit: DurationInputArg2;
  utc?: boolean;
};

/**
 * Helper function that handles moment date addition or subtraction queries
 * @param momentDate moment date to be manipulated
 * @param query addition or subtraction of the date based on the amount and units
 * @param convertToUtc converts the local date to UTC Date
 * @returns an adjusted moment date
 */
const momentDateQueryHandler = (
  momentDate: Moment,
  query: OptionalDateArithmeticQuery,
  convertToUtc: boolean = false
): Moment => {
  if (momentDate && query) {
    momentDate.utc(convertToUtc);
    switch (query?.type) {
      case "add":
        momentDate.add(query?.amount, query?.unit);
        break;
      case "subtract":
        momentDate.subtract(query?.amount, query?.unit);
        break;
    }
  }
  return momentDate;
};

/**
 * Helper function to format Date into a viable string value into specific formats
 * @param date to format into date input string
 * @param query additional queries (ex: adding or reducing the date day / week)
 * @param format format of the date
 * @param convertToUtc converts the local date to UTC Date
 * @returns a formatted version of the date
 */
const getFormattedDateString = (
  date?: Date,
  format = "DD/MM/YY",
  query?: OptionalDateArithmeticQuery,
  convertToUtc: boolean = false
): string => {
  let momentDate = moment(date).utc(convertToUtc);
  if (query) momentDate = momentDateQueryHandler(momentDate, query);
  return momentDate.format(format);
};

/**
 * Helper function to get a string format of two largest avaiable section of time, starting from days
 * @param timeInSeconds to convert into throughput time
 * @returns formatted date string with the largest and 2nd largest avaiable time string
 */
const getFormattedThroughputTime = (timeInSeconds: number): string => {
  let timeString = "";
  const days = Math.floor(timeInSeconds / 86400);
  timeInSeconds -= days * 86400;
  if (days > 0) timeString = `${days}d`;

  const hours = Math.floor(timeInSeconds / 3600) % 24;
  timeInSeconds -= hours * 3600;
  if (hours > 0) {
    const hourString = `${hours}h`;
    if (timeString) return timeString + " " + hourString;
    timeString += hourString;
  }

  const minutes = Math.floor(timeInSeconds / 60) % 60;
  timeInSeconds -= minutes * 60;
  if (minutes > 0) {
    const minuteString = `${minutes}m`;
    if (timeString) return timeString + " " + minuteString;
    timeString += minuteString;
  }

  const seconds = Math.floor(timeInSeconds) % 60;
  if (seconds > 0) {
    const secondString = `${seconds}s`;
    if (timeString) return timeString + " " + secondString;
    return secondString;
  }

  return timeString;
};

export {
  momentDateQueryHandler,
  getFormattedDateString,
  getFormattedThroughputTime,
};
