import { useMutation, useQuery } from "react-query";
import { ReportingApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

type JobTypesReportingMutationQueries = {
  creationTimeFrom?: Date;
  creationTimeTo?: Date;
  buildBundleIds?: string[];
};

const useJobTypesReporting = (
  creationTimeFrom?: Date,
  creationTimeTo?: Date,
  buildBundleIds?: string[]
) => {
  const { getToken } = useAuth();
  return useQuery(["getJobTypesReporting"], async () =>
    getJobTypesReporting(
      await getToken(),
      creationTimeFrom,
      creationTimeTo,
      buildBundleIds
    )
  );
};

const useJobTypesReportingMutation = () => {
  const { getToken } = useAuth();
  return useMutation(
    async ({
      creationTimeFrom,
      creationTimeTo,
      buildBundleIds,
    }: JobTypesReportingMutationQueries) =>
      getJobTypesReporting(
        await getToken(),
        creationTimeFrom,
        creationTimeTo,
        buildBundleIds
      )
  );
};

const getJobTypesReporting = async (
  token: string | null,
  creationTimeFrom?: Date,
  creationTimeTo?: Date,
  buildBundleIds?: string[]
) => {
  const { getConfiguration } = AppConfig();
  const api = new ReportingApi(getConfiguration(token));
  const buildBundleIdsData =
    buildBundleIds && buildBundleIds?.length > 0
      ? buildBundleIds?.join(",")
      : undefined;
  return api.jobTypesControllerRetrieveJobTypesStatistics(
    creationTimeFrom,
    creationTimeTo,
    buildBundleIdsData,
    getDefaultOptions(token)
  );
};

export { useJobTypesReporting, useJobTypesReportingMutation };
