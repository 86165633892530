export const WIZARD_STEPS = [
  {
    label: "Personal details",
    id: "personal-details",
  },
  { label: "Subjects excellence endorsement", id: "subjects-excellence-endorsement" },
  { label: "Standards excellence endorsement", id: "standards-excellence-endorsement" },
  { label: "Other eligibility", id: "other-eligiblity" },
  { label: "Setup your billing account", id: "set-billing-account" },
];
