import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { VerticalDividerProps } from "./VerticalDivider.types";

import { getStyles } from "../../utils";

export const getVerticalDividerStyles = (
  theme: Theme,
  { height,minHeight, styles, variant }: Partial<VerticalDividerProps>
) => {
  const base: CSSObject = {
    margin: 0,
    width:"1px",
    height,
    border: 0,
    background: theme.colors.grey.border,
    minHeight
  };

  return getStyles({
    base,
    styles,
    variant
  });
};
