import React from "react";
import { JobCardProps } from "./JobCard.types";
import * as styles from "./JobCard.styles";
import { Card, Container, Heading, Text } from "components/lib/atoms";
import { useTheme } from "@emotion/react";
import { Stack } from "components/lib/layouts";

export const JobCard: React.FC<JobCardProps> = ({
  title,
  description,
  price,
  datePosted,
  deadLine,
  level,
  subject,
  standard,
}) => {
  const theme = useTheme();

  return (
    <Card styles={styles.jobCard}>
      <Heading variant={3}>{title}</Heading>
      <Text variant={2}>{description}</Text>
      <Text
        variant={7}
        styles={styles.price}
        color={theme.colors.purple.default}
        weight={500}
      >
        {price}
      </Text>

      <Container styles={styles.details}>
        <Container styles={styles.date}>
          <Text variant={2} color={theme.colors.grey.text}>
            Job deadline
          </Text>
          <Text variant={2}>{deadLine}</Text>
        </Container>
        <Container styles={styles.date}>
          <Text variant={2} color={theme.colors.grey.text}>
            Date posted
          </Text>
          <Text variant={2}>{datePosted}</Text>
        </Container>
        <Container styles={styles.earnings}>
          <Text
            variant={2}
            styles={styles.maxEarningText}
            color={theme.colors.grey.text}
          >
            Maximum earning per job
          </Text>
        </Container>
      </Container>

      <Stack direction="horizontal" gap={2} styles={styles.standards}>
        <Text variant={2}>{level}</Text>
        <Container styles={styles.dot} />
        <Text variant={2}>{subject}</Text>
        <Container styles={styles.dot} />
        <Text variant={2}>{standard}</Text>
      </Stack>
    </Card>
  );
};
