export const ACCOUNT_SECTIONS = [
  {
    label: "Profile Details",
    id: "profile",
  },
  { label: "Subjects excellence endorsement", id: "subjects" },
  { label: "Standard excellence endorsement", id: "standards" },
  { label: "Other eligibility", id: "other-eligiblity" },
  { label: "Payment Details", id: "payments" },
  { label: "Total money earned", id: "payments-summary" },
];
