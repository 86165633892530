import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";

import {
  Table,
  TableData,
  TableHeading,
  TableRow,
  TableBody,
  TableHead,
} from "components/lib/atoms/Table/Table";
import { Stack } from "components/lib/layouts";
import { Spinner, Heading, Card, Button, Text } from "components/lib/atoms";
import { PATH } from "components/shared/constants/paths.constants";
import { sortOptionsAlphabetically } from "components/utils/helpers";
import { useJobTypes } from "../../../shared/hooks/useJobTypes.hook";

export const JobTemplates = () => {
  const theme = useTheme();
  const { push } = useHistory();
  const { data, isLoading } = useJobTypes();

  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={4} fullWidth={true}>
        <Stack direction="vertical" gap={1}>
          <Heading variant={1}>Job type templates</Heading>
        </Stack>

        {isLoading ? (
          <Spinner />
        ) : (
          <Stack direction="vertical" gap={2}>
            <Table styles={{ width: "100%" }}>
              <TableHead>
                <TableRow isHeading={true}>
                  <TableHeading>Template Name</TableHeading>
                  <TableHeading />
                  <TableHeading />
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.sort(sortOptionsAlphabetically)?.map((jobType) => {
                  return (
                    <TableRow key={jobType?.id}>
                      <TableData>{jobType?.name}</TableData>
                      <TableData
                        styles={{
                          width: "100px",
                        }}
                      >
                        <Button
                          variant="link"
                          onClick={() => {
                            push(PATH.getAdminJobTemplatesPreview(jobType?.id));
                          }}
                        >
                          Preview
                        </Button>
                      </TableData>

                      <TableData
                        styles={{
                          width: "100px",
                        }}
                      >
                        <Button
                          variant="link"
                          onClick={() => {
                            push(PATH.getAdminJobTemplatesEdit(jobType?.id));
                          }}
                        >
                          Edit
                        </Button>
                      </TableData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {data?.length === 0 && (
              <Text styles={{ padding: theme.space[2] }}>
                No templates found.
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
