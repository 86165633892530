import { useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Table,
  TableData,
  TableHeading,
  TableRow,
  TableBody,
  TableHead,
} from "components/lib/atoms/Table/Table";
import { Stack } from "components/lib/layouts";
import { getCurriculumName } from "components/utils/helpers";
import { Spinner, Heading, Card, Button, Text } from "components/lib/atoms";
import { GetBuildBundlesResponseDto } from "components/utils/openapi";
import { getFormattedDateString } from "components/utils/dateUtils";
import { useCurriculum } from "../../../shared/hooks/useCurriculum";

import {
  BuildBundlesStatus,
  useBuildBundleList,
} from "../hooks/useBuildBundleList.hook";
import { byBundleDate } from "../ManageBuildBundles/utils";
import { DeleteJobModal } from "../ArchivedJobs/components";

export const CancelledJobs = () => {
  const theme = useTheme();

  const { data, isLoading, refetch } = useBuildBundleList(
    undefined,
    undefined,
    [BuildBundlesStatus.CANCELLED]
  );
  const { data: curriculumData } = useCurriculum();

  const [
    jobToBeDeleted,
    setJobToBeDeleted,
  ] = useState<GetBuildBundlesResponseDto | null>(null);

  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={4} fullWidth={true}>
        <Stack direction="horizontal" fullWidth={true}>
          <Stack direction="vertical" gap={1}>
            <Heading variant={1}>Cancelled jobs</Heading>
          </Stack>
        </Stack>

        {isLoading ? (
          <Spinner />
        ) : (
          <Stack direction="vertical" gap={2}>
            <Table styles={{ width: "100%" }}>
              <TableHead>
                <TableRow isHeading={true}>
                  <TableHeading>Build bundle names</TableHeading>
                  <TableHeading>Date added</TableHeading>
                  <TableHeading>Jobs completed</TableHeading>
                  <TableHeading styles={{ width: "350px" }} />
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.sort(byBundleDate).map((buildBundle) => {
                  const name = getCurriculumName(
                    buildBundle.nceaCode,
                    curriculumData
                  );
                  const activityBuildsFinished = buildBundle.activityBuilds.filter(
                    (activityBuild) => activityBuild?.completed
                  ).length;

                  return (
                    <TableRow key={buildBundle.id}>
                      <TableData>{name}</TableData>
                      <TableData>
                        {getFormattedDateString(
                          new Date(buildBundle?.creationTime)
                        )}
                      </TableData>
                      <TableData>
                        {activityBuildsFinished} of{" "}
                        {buildBundle?.activityBuilds?.length}
                      </TableData>

                      <TableData>
                        <Button
                          variant="warningtext"
                          onClick={() => {
                            setJobToBeDeleted(buildBundle);
                          }}
                        >
                          Delete
                        </Button>
                      </TableData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {data?.length === 0 && (
              <Text styles={{ padding: theme.space[2] }}>
                No Cancelled build bundles found.
              </Text>
            )}

            <DeleteJobModal
              onClose={() => {
                setJobToBeDeleted(null);
              }}
              job={jobToBeDeleted}
              refetchJobs={refetch}
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
