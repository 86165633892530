/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { TextProps } from "./Text.types";
import { getTextStyles } from "./Text.styles";

export const Text = ({ children, ...props }: TextProps) => {
  const theme = useTheme();

  return <div css={getTextStyles(theme, props)}>{children}</div>;
};
