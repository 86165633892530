import { useQuery } from "react-query";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

export const useJobTypeStatistics = () => {
  const { getToken } = useAuth();
  return useQuery(["getJobTypeStatistics"], async () =>
    getJobTypeStatistics(await getToken())
  );
};

const getJobTypeStatistics = async (token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.jobTypesControllerGetJobTypesStatistics(getDefaultOptions(token));
};
