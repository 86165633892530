import { VerticalDivider } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { theme } from "components/lib/utils";
import { VideoElementType } from "../../TrainingPage.types";
import { ReactComponent as Checkbox } from "../svgs/checkbox.svg";
import { ReactComponent as UnCheckbox } from "../svgs/unchecked.svg";
import TraningVideoCard from "../TraningVideoCard/TraningVideoCard";

export default function TraningStepper() {
  //testing data, will be removed once api integrated
  const data = [
    {
      title: "Teaching Brief Creation + Review Training",
      subTitle: "Video | 2:35 mins",
      isCompeleted: true,
    },
    {
      title: "Story-telling PowerPoint Creation + Review Training",
      subTitle: "Video | 2:35 mins",
      isCompeleted: true,
    },
    {
      title: "Final PowerPoint Creation + Review Training",
      subTitle: "Video | 2:35 mins",
      isCompeleted: true,
    },
    {
      title: "Script Creation + Review Training",
      subTitle: "Video | 2:35 mins",
      isCompeleted: false,
    },
  ];
  return (
    <Stack fullWidth inline={false}>
      <Stack
        direction="vertical"
        styles={{
          paddingTop: theme.space[1],
        }}
      >
        {data.map((ele, index) => {
          return (
            <Stack
              direction="vertical"
              align="center"
              styles={{
                paddingRight: theme.space[4],
                paddingTop: theme.space[1],
              }}
            >
              {ele.isCompeleted ? (
                <Checkbox
                  style={{
                    paddingBottom: theme.space[1],
                  }}
                />
              ) : (
                <UnCheckbox
                  style={{
                    paddingBottom: theme.space[1],
                  }}
                />
              )}
              {index !== data.length - 1 && <VerticalDivider minHeight={30} />}
            </Stack>
          );
        })}
      </Stack>
      <Stack
        direction="vertical"
        styles={{
          flex: 1,
        }}
      >
        {data.map((ele: VideoElementType) => {
          return <TraningVideoCard cardElement={ele} />;
        })}
      </Stack>
    </Stack>
  );
}
