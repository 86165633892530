/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import ReactSelect from "react-select";

import { getStyles } from "../../utils";

import { SelectProps } from "./Select.types";
import { getReactSelectStyles } from "./Select.styles";
import { ValidationMessage } from "../ValidationMessage/ValidationMessage";

export const Select = React.forwardRef(
  (
    {
      styles = {},
      stylesReactSelect = {},
      disabled = false,
      placeholder = "",
      options = [],
      onChange,
      validationMessage = "",
      fullWidth = false,
      isMulti,
      defaultValue,
      value,
    }: SelectProps,
    ref?: React.Ref<any>
  ) => {
    const theme = useTheme();
    const validationBorderStyle =
      validationMessage.length !== 0
        ? `${theme.lineThickness[0]} solid ${theme.colors.red.border}`
        : "";
    const validationStyle = { border: validationBorderStyle };
    const selectStyle = getReactSelectStyles(theme, {
      ...stylesReactSelect,
      ...validationStyle,
    });

    return (
      <div
        css={getStyles({
          base: {
            ...(fullWidth ? { width: "100%" } : {}),
          },
          styles: styles,
        })}
      >
        <ReactSelect
          ref={ref}
          isDisabled={disabled}
          isSearchable={false}
          styles={selectStyle}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          isMulti={isMulti}
          defaultValue={defaultValue}
          value={value}
        />
        {validationMessage.length !== 0 && (
          <ValidationMessage
            styles={{ marginTop: "10px" }}
            text={validationMessage}
          />
        )}
      </div>
    );
  }
);
