import { SVGProps } from "svgs/svg.types";

export const ChevronDownIcon: React.FC<SVGProps> = ({
  fillColour,
  svgWidth,
  svgHeight,
}) => {
  return (
    <svg
      className="svg-icon"
      width={`${svgWidth || 18}`}
      height={`${svgHeight || 18}`}
      viewBox={`0 0 ${svgWidth || 18} ${svgHeight || 18}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6L9 12L15 6"
        stroke={fillColour || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
