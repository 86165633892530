//@ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CSSObject, useTheme } from "@emotion/react";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Stack } from "components/lib/layouts/Stack/Stack";

type Variant = "science" | "text";

const SCIENCE_ITEMS = ["MathType", "ChemType"];

const TEXT_ITEMS = ["bulletedList", "numberedList"];

// available items

// "heading",
// "MathType",
// "ChemType",
// "|",
// "bold",
// "italic",
// "link",
// "bulletedList",
// "numberedList",
// "imageUpload",
// "mediaEmbed",
// "insertTable",
// "blockQuote",
// "undo",
// "redo",

export const RichEditor = ({
  placeholder,
  data,
  variant = "science",
  onChange,
  styles,
}: {
  placeholder?: string;
  data: string;
  variant?: Variant;
  onChange: (value: string) => void;
  styles?: CSSObject;
}) => {
  const theme = useTheme();
  const items = variant === "text" ? TEXT_ITEMS : SCIENCE_ITEMS;
  return (
    <Stack
      direction="vertical"
      styles={{
        flex: 1,
        fontWeight: theme.fontWeights.light,
        lineHeight: "150%",
        ...styles,
      }}
    >
      <CKEditor
        editor={Editor}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          onChange(data);
        }}
        config={{
          placeholder: placeholder,
          toolbar: {
            items,
          },
        }}
        data={data}
      />
    </Stack>
  );
};
