import { CSSObject } from "@emotion/serialize";

export const styles: Record<string, CSSObject> = {
  ToastStyles: {
    minWidth: "400px",
    padding: "8px",
  },
  ToastsContainer: {
    left: "50%",
    bottom: "20px",
    transform: "translateX(-50%)",
    position: "fixed",
  },
  CloseButton: {
    border: 0,
    height: "fit-content",
    width: "fit-content",
    background: "transparent",
    padding: "8px",
    marginLeft: "auto",
    ":hover": {
      background: "transparent",
    },
  },
};
