import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils";

export const sideMenu = (): CSSObject => {
  return { marginLeft: theme.space[1], minWidth: "250px" };
};

export const selectedSideMenuItem = (): CSSObject => {
  return {
    textAlign: "left",
    padding: `${theme.space[3]} ${theme.space[4]}`,
    marginBottom: theme.space[1],
    background: theme.colors.grey.darkSurface,
    color: theme.colors.purple.default,
    fontWeight: theme.fontWeights.medium,

    ":hover": {
      background: theme.colors.grey.darkSurface,
      color: theme.colors.purple.default,
    },
  };
};

export const sideMenuItem = (): CSSObject => {
  return {
    textAlign: "left",
    padding: `${theme.space[3]} ${theme.space[4]}`,
    marginBottom: theme.space[1],
    color: theme.colors.purple.base,

    ":hover": {
      background: theme.colors.grey.darkSurface,
      color: theme.colors.purple.default,
    },
  };
};
