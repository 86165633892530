/** @jsxImportSource @emotion/react */
import React from "react";

import { Stack } from "components/lib/layouts";

import { FormSection } from "../ProfileDetails.types";
import { DetailInputField } from "./DetailInputField";

export const UserNames: React.FC<FormSection> = ({
  register,
  formState: { errors },
  watch,
}) => {
  const [firstName, lastName] = watch(["firstName", "lastName"]);
  return (
    <Stack direction="vertical" gap={3}>
      <Stack gap={4}>
        <DetailInputField
          title="First Name"
          isMandatory
          autoFocus
          defaultValue={firstName}
          validationMessage={errors?.firstName?.message}
          {...register("firstName", {
            required: {
              message: "Required",
              value: true,
            },
          })}
          errors={errors}
        />
        <DetailInputField
          title="Last Name"
          isMandatory
          defaultValue={lastName}
          {...register("lastName", {
            required: {
              message: "Required",
              value: true,
            },
          })}
          errors={errors}
        />
      </Stack>
    </Stack>
  );
};
