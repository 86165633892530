import React, { FormEvent } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";
import { Stack } from "components/lib/layouts/";
import {
  Checkbox,
  Radio,
  Heading,
  Text,
  TextArea,
  ValidationMessage,
} from "components/lib/atoms";
import { FormSection } from "../../sections/InputsSection/ResourceJobSection.types";

export const FeedbackFields: React.FC<FormSection> = ({
  formState,
  control,
  watch,
}) => {
  const theme = useTheme();
  const [isFixable, feedback, errorTypes] = watch([
    "isFixable",
    "feedback",
    "errorTypes",
  ]);

  const FEEDBACK_ERRORS = [
    {
      value: "logic",
      label: "Logic error",
    },
    {
      value: "spelling",
      label: "Spelling mistake",
    },
    {
      value: "other",
      label: "Other",
    },
    {
      value: "formatting",
      label: "Formatting mistake",
    },
  ];

  const IS_FIXED = [
    {
      value: "true",
      label: "I fixed this work",
    },
    {
      value: "false",
      label: "This work is not fixable",
    },
  ];

  const isSelected = (key: string) => {
    return Boolean(errorTypes?.find((s) => s === key));
  };

  return (
    <Stack fullWidth={true} direction="vertical" gap={3}>
      <Stack gap={0}>
        <Heading variant={5}>How did you fix this work?</Heading>
        <Text color={theme.colors.red.default}>&nbsp;(Required)</Text>
      </Stack>
      <Controller
        name="isFixable"
        control={control}
        defaultValue={isFixable}
        render={({ field }) => (
          <Stack direction="vertical">
            {IS_FIXED.map(({ value, label }, index) => {
              return (
                <Radio
                  key={index}
                  value={value}
                  checked={value === field.value?.toString()}
                  onChange={(e) => {
                    field.onChange(
                      e.currentTarget.value === "true" ? true : false
                    );
                  }}
                  name="isFixable"
                >
                  {label}
                </Radio>
              );
            })}
          </Stack>
        )}
      />

      <Stack direction="vertical" gap={1}>
        <Stack gap={0}>
          <Heading variant={5}>What errors did you find in this work?</Heading>
          <Text color={theme.colors.red.default}>&nbsp;(Required)</Text>
        </Stack>
        <Text>This feedback will be sent to original writer</Text>
      </Stack>
      <Controller
        name="errorTypes"
        control={control}
        defaultValue={errorTypes}
        rules={{
          required: {
            message: "Error Types cannot be Empty",
            value: true,
          },
        }}
        render={({ field }) => (
          <Stack direction="vertical">
            <ValidationMessage
              styles={{ marginTop: "5px", marginBottom: "5px" }}
              text={formState?.errors?.errorTypes?.message}
            />
            <Stack direction="horizontal" gap={0}>
              {FEEDBACK_ERRORS.map(({ value, label }) => {
                const key = value?.toString();
                const isChecked = isSelected(key);
                const handleChange = (
                  _event: FormEvent<HTMLInputElement>,
                  selectedValueKey: string
                ) => {
                  const subjectsAfterSelection = isChecked
                    ? errorTypes?.filter((s) => s !== selectedValueKey)
                    : errorTypes
                    ? [...errorTypes, selectedValueKey]
                    : [];
                  field.onChange(subjectsAfterSelection);
                };
                return (
                  <Stack key={key} styles={{ width: "50%", height: "40px" }}>
                    <Checkbox
                      value={value}
                      name={label}
                      checked={isChecked}
                      onChange={handleChange}
                    >
                      {label}
                    </Checkbox>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        )}
      />
      <Controller
        name="feedback"
        control={control}
        defaultValue={feedback}
        rules={{
          required: {
            message: "Feedback cannot be empty.",
            value: true,
          },
        }}
        render={({ field }) => (
          <Stack direction="vertical">
            <ValidationMessage
              styles={{ marginTop: "5px", marginBottom: "5px" }}
              text={formState?.errors?.feedback?.message}
            />
            <TextArea
              placeholder={"Input your feedback here..."}
              rows={10}
              onChange={field.onChange}
            />
          </Stack>
        )}
      />
    </Stack>
  );
};
