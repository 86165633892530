import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "components/utils/auth";
import { FormValue } from "./ResetPassword.types";

export function useResetPassword() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValue>({
    mode: "onChange",
  });
  const { forgotPassword } = useAuth();

  const [formStatus, setFormStatus] = useState<"default" | "verification">(
    "default"
  );

  async function handleResetPassword(values: FormValue) {
    return forgotPassword(values.email)
      .then(() => {
        setFormStatus("verification");
      })
      .catch(() => {
        setError("email", {
          type: "server",
          message: "Something went wrong with resetting your password",
        });
      });
  }

  return {
    formStatus,
    register,
    handleSubmit,
    handleResetPassword,
    errors,
    isSubmitting,
  };
}
