import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

export enum SortDirection {
  ASCENDING = "ASC",
  DESCENDING = "DESC",
}

enum SortType {
  CREATED_AT = "createdAt",
  EARNINGS = "earnings",
}

const SORT_ORDERS = [
  {
    value: SortDirection.ASCENDING,
    label: "Oldest job",
    type: SortType.CREATED_AT,
  },
  {
    value: SortDirection.DESCENDING,
    label: "Newest job",
    type: SortType.CREATED_AT,
  },
  {
    value: SortDirection.DESCENDING,
    label: "Highest earning",
    type: SortType.EARNINGS,
  },
  {
    value: SortDirection.ASCENDING,
    label: "Lowest earning",
    type: SortType.EARNINGS,
  },
];

interface SelectType {
  value: string;
  label: string;
  type: string;
}

export type FormValue = {
  jobType: string[];
  selectedLevel: SelectType | null;
  selectedSubject: SelectType | null;
  selectedStandard: SelectType | null;
  sortOrder: SelectType | null;
  pageNumber: number;
};

const DEFAULT_VALUES: FormValue = {
  jobType: [],
  selectedLevel: null,
  selectedStandard: null,
  selectedSubject: null,
  sortOrder: SORT_ORDERS[0],
  pageNumber: 0,
};

interface FilterProps extends FormValue {
  sortOrderOptions: SelectType[];
  filters: typeof DEFAULT_VALUES;
  handleJobTypeChange: (type: string) => void;
  handleLevelChange: (level: SelectType) => void;
  handleSubjectChange: (subject: SelectType) => void;
  handleStandardChange: (standard: SelectType) => void;
  clearFilters: () => void;
  handleSortOrderChange: (sortOrder: SelectType) => void;
  handlePageNumberChange: (pageNumber: number) => void;
  jobTypeCodes: string | null;
  levelId: string | null;
  subjectId: string | null;
  nceaCode: string | null;
  changeLevel: (level: SelectType) => void;
  changeSubject: (subject: SelectType) => void;
  changeStandard: (standard: SelectType) => void;
}

export interface FilterComponentProps {
  methods: FilterProps;
}
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useFilter = (): FilterProps => {
  const { watch, setValue, reset } = useForm<FormValue>({
    defaultValues: DEFAULT_VALUES,
    mode: "onChange",
  });
  let query = useQuery();
  const history = useHistory();
  const jobTypeCodes = query.get("jobTypeCodes");
  const levelId = query.get("levelId");
  const subjectId = query.get("subjectId");
  const nceaCode = query.get("nceaCode");

  const values = watch();
  const {
    jobType = [],
    selectedSubject,
    selectedStandard,
    selectedLevel,
    sortOrder,
    pageNumber,
  } = useMemo(() => values, [values]);

  const filters = {
    jobType,
    sortOrder,
    selectedLevel,
    selectedSubject,
    selectedStandard,
    pageNumber,
  };

  function handleJobTypeChange(pJobType: string) {
    handlePageNumberChange(0);
    let jobTypeArray = [];
    if (jobType.includes(pJobType)) {
      jobTypeArray = jobType.filter((value) => value !== pJobType);
    } else {
      jobTypeArray = [...jobType, pJobType];
    }
    if (jobTypeArray.length) {
      query.set("jobTypeCodes", jobTypeArray.join(","));
      handledQueryChanges();
    } else {
      setValue("jobType", []);
      query.delete("jobTypeCodes");
      handledQueryChanges();
    }
  }

  function handleLevelChange(level: SelectType | null) {
    setValue("selectedLevel", level);
    setValue("selectedStandard", null);
    setValue("selectedSubject", null);
  }

  function changeLevel(level: SelectType | null) {
    if (level?.value && level?.value != levelId) {
      query.set("levelId", level.value);
      query.delete("subjectId");
      query.delete("nceaCode");
      handledQueryChanges();
    }
  }

  function handleSubjectChange(subject: SelectType) {
    setValue("selectedStandard", null);
    setValue("selectedSubject", subject);
  }

  function changeSubject(subject: SelectType | null) {
    if (subject?.value && subject?.value != subjectId) {
      query.set("subjectId", subject.value);
      query.delete("nceaCode");
      handledQueryChanges();
    }
  }
  function changeStandard(standard: SelectType | null) {
    if (standard?.value) {
      query.set("nceaCode", standard.value);
      handledQueryChanges();
    }
  }

  function clearFilters() {
    history.replace({
      pathname: "/browse-jobs",
    });
    reset(DEFAULT_VALUES, { keepDefaultValues: true });
  }
  const handlePageNumberChange = (pageNumber: number) =>
    setValue("pageNumber", pageNumber);

  function handledQueryChanges() {
    history.replace({
      pathname: "/browse-jobs",
      search: query.toString(),
    });
  }

  useEffect(() => {
    if (jobTypeCodes) {
      setValue("jobType", jobTypeCodes.split(","));
    }
  }, [jobTypeCodes]);

  return {
    sortOrderOptions: SORT_ORDERS,
    filters,
    jobType,
    handleJobTypeChange,
    selectedLevel,
    handleLevelChange,
    selectedSubject,
    handleSubjectChange,
    selectedStandard,
    handleStandardChange: (standard: SelectType) =>
      setValue("selectedStandard", standard),
    clearFilters,
    sortOrder,
    handleSortOrderChange: (sortOrder: SelectType) =>
      setValue("sortOrder", sortOrder),
    pageNumber,
    handlePageNumberChange,
    jobTypeCodes,
    levelId,
    subjectId,
    nceaCode,
    changeLevel,
    changeSubject,
    changeStandard,
  };
};
