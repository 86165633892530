import { useTheme } from "@emotion/react";
import { InputField } from "components/lib/molecules/InputField/InputField";
import { Divider } from "components/lib/atoms/Divider/Divider";
import { Card, Button, Heading, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { ValidationMessageRequired } from "components/utils/validation";

import { usePublishToLive } from "../../hooks/usePublishToLive.hook";
import { AdminDetailsProps } from "../CreateNewJob.types";

export const AdminDetails: React.FC<AdminDetailsProps> = ({
  onNext,
  numberOfActivityBuilds,
}) => {
  const theme = useTheme();

  const resources = Array.from(Array(numberOfActivityBuilds).keys()).map(
    (_value, index) => {
      return {
        title: `Job ${index + 1}`,
        name: "",
        fileName: "",
      };
    }
  );

  const defaultFields = {
    resources: resources,
  };

  const { errors, fields, handleSubmit, register } = usePublishToLive({
    defaultFields,
  });

  const CreateDetailItems = () => {
    return (
      <>
        {fields.map((item: any, index: any) => {
          return (
            <Stack key={item.id} gap={3} fullWidth={true} direction="vertical">
              <Text color={theme.colors.purple.dark}>{item.title}</Text>
              <Stack gap={2} fullWidth={true} justify="left" align="center">
                <Text
                  color={theme.colors.purple.dark}
                  styles={{ width: "225px" }}
                >
                  Job name
                </Text>
                <InputField
                  styles={{ width: "100%", maxWidth: "450px" }}
                  placeholder="Specify here"
                  validationMessage={errors?.resources?.[index]?.name?.message}
                  {...register(`resources.${index}.name` as const, {
                    required: ValidationMessageRequired,
                  })}
                />
              </Stack>
              <Stack gap={2} fullWidth={true} justify="left" align="center">
                <Text
                  color={theme.colors.purple.dark}
                  styles={{ width: "225px" }}
                >
                  Resource file to use
                </Text>
                <InputField
                  styles={{ width: "100%", maxWidth: "450px" }}
                  placeholder="Specify here"
                  validationMessage={
                    errors?.resources?.[index]?.fileName?.message
                  }
                  {...register(
                    `resources.${index}.fileName` as const,
                    {
                      required: ValidationMessageRequired,
                    }
                  )}
                />
              </Stack>
              <br />
              <br />
              <Divider />
              <br />
            </Stack>
          );
        })}
      </>
    );
  };

  return (
    <Stack
      direction="vertical"
      gap={4}
      fullWidth={true}
      align="left"
      styles={{ maxWidth: "800px" }}
    >
      <Heading variant={1} color={theme.colors.purple.dark}>
        Details
      </Heading>
      <Card styles={{ maxWidth: "800px", padding: "50px" }} fullWidth={true}>
        <form
          onSubmit={handleSubmit((data) => {
            onNext(data.resources);
          })}
        >
          <Stack
            direction="vertical"
            gap={4}
            fullWidth={true}
            styles={{ flex: 1 }}
          >
            {CreateDetailItems()}
            <Stack gap={2} fullWidth={true} justify="left" align="center">
              <Button type="submit" disabled={Object.keys(errors).length > 0}>
                Publish to live
              </Button>
            </Stack>
          </Stack>
        </form>
      </Card>
    </Stack>
  );
};
