import { Theme } from "@emotion/react";
import { CSSProperties } from "react";
import { CSSObject } from "@emotion/serialize";

export const getAuthCardStyles = (_theme: Theme): CSSObject => {
  return { maxWidth: "500px" };
};

export const getLinkStyles = (_theme: Theme): CSSProperties => {
  return {
    display: "contents",
    textDecoration: "underline",
  };
};

export const getLinkWrapperStyles = (_theme: Theme): CSSProperties => {
  return {
    display: "contents",
    textDecoration: "none",
  };
};
