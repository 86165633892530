import { useTheme } from "@emotion/react";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { fileExtensionByMimeTypeLookup } from "components/shared/constants/mimetypes";
import { Button, Table, Text } from "components/lib/atoms";
import {
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/lib/atoms/Table/Table";
import {
  AttachmentResponseDto,
  RetrieveSubmissionByIdJobResponseDto,
} from "components/utils/openapi";

type InputResources = {
  buildBundleAttachments: AttachmentResponseDto[];
  jobTypeAttachments: AttachmentResponseDto[];
  parentJobTypeAttachments: AttachmentResponseDto[];
  parentSubContentAttachments: AttachmentResponseDto[];
  grandParentJobTypeAttachments: AttachmentResponseDto[];
  grandParentSubContentAttachments: AttachmentResponseDto[];
};

/**
 * Helper function to generate the Attachment rows on a table
 * @param attachments to be displayed as rows
 */
const buildAttachmentRows = (attachments?: AttachmentResponseDto[]) => {
  return attachments?.map((attachment: AttachmentResponseDto) => {
    return (
      <TableRow key={attachment?.id}>
        <TableData>{attachment?.name}</TableData>
        <TableData>
          <a target="_blank" href={attachment?.url} rel="noreferrer">
            <Button type="button" variant="secondary">
              Download
              {attachment?.mimeType &&
              fileExtensionByMimeTypeLookup[attachment.mimeType]
                ? ` .${fileExtensionByMimeTypeLookup[attachment.mimeType]}`
                : ""}
            </Button>
          </a>
        </TableData>
      </TableRow>
    );
  });
};

export const ResourceFilePanel = ({
  submission,
  inputResources,
}: {
  submission: RetrieveSubmissionByIdJobResponseDto;
  inputResources: InputResources;
}) => {
  const theme = useTheme();
  return (
    <Stack fullWidth={true} direction="vertical" gap={4}>
      <Text>
        Resource File to use:{" "}
        <span
          style={{
            color: submission?.activityBuild?.specifiedFileName
              ? theme.colors.mint.dark
              : theme.colors.grey.text,
          }}
        >
          {submission?.activityBuild?.specifiedFileName ??
            "No Specified Resource"}
        </span>
      </Text>
      <Table>
        <TableHead>
        <TableRow isHeading={true}>
          <TableHeading>File Name</TableHeading>
          <TableHeading>Files</TableHeading>
        </TableRow>
        </TableHead>
        <TableBody>
          {/* Build Bundle / Global Level Attachments */}
          {inputResources?.buildBundleAttachments &&
            buildAttachmentRows(inputResources?.buildBundleAttachments)}

          {/* Job Type Level Attachments*/}
          {inputResources?.jobTypeAttachments &&
            buildAttachmentRows(inputResources?.jobTypeAttachments)}

          {/* Parent Job Type Level Attachments*/}
          {inputResources?.parentJobTypeAttachments &&
            buildAttachmentRows(inputResources?.parentJobTypeAttachments)}

          {/* Parent Submission Content Type Level Attachments - Only Display as Resource on Creation */}
          {!submission?.job?.jobType?.isReview &&
            inputResources?.parentSubContentAttachments &&
            buildAttachmentRows(inputResources?.parentSubContentAttachments)}

          {/* Grandparent Job Type Level Attachments*/}
          {inputResources?.grandParentJobTypeAttachments &&
            buildAttachmentRows(inputResources?.grandParentJobTypeAttachments)}

          {/* Grandparent Submission Content Type Level Attachments*/}
          {inputResources?.grandParentSubContentAttachments &&
            buildAttachmentRows(
              inputResources?.grandParentSubContentAttachments
            )}
        </TableBody>
      </Table>
    </Stack>
  );
};
