import { ToastContext } from "index";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

import {
  Table,
  TableData,
  TableHeading,
  TableRow,
  TableBody,
  TableHead,
} from "components/lib/atoms/Table/Table";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { getCurriculumName } from "components/utils/helpers";
import { Spinner, Heading, Card, Button, Text } from "components/lib/atoms";
import { PATH } from "components/shared/constants/paths.constants";
import { useCurriculum } from "../../../shared/hooks/useCurriculum";
import { GetBuildBundlesResponseDto } from "components/utils/openapi";
import { CancelBundleModal } from "./components/CancelJobModal/CancelBundleModal";

import { useBuildBundleArchive } from "../hooks/useBuildBundleArchive.hook";
import { useBuildBundleDownload } from "../hooks/useBuildBundleDownload.hook";
import {
  BuildBundlesStatus,
  useBuildBundleList,
} from "../hooks/useBuildBundleList.hook";
import { byBundleDate } from "./utils";

export const ManageBuildBundles = () => {
  const theme = useTheme();
  const history = useHistory();
  const toast = useContext(ToastContext);
  const { data, isLoading, refetch } = useBuildBundleList(
    undefined,
    undefined,
    [BuildBundlesStatus.ACTIVE, BuildBundlesStatus.COMPLETED]
  );
  const { mutateAsync: archiveBuildBundle } = useBuildBundleArchive();
  const { mutateAsync: downloadBuildBundle } = useBuildBundleDownload();
  const { data: curriculumData } = useCurriculum();
  const [
    buildBundleToBeDeleted,
    setBuildBundleToBeDeleted,
  ] = useState<GetBuildBundlesResponseDto | null>(null);

  /**
   * Helper function to archiving a specific build bundle by ID
   * @param buildBundleId Specific Build bundle to Archive
   */
  const archiveBuildBundleById = async (buildBundleId: string) => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Archiving Build Bundle...",
    });
    try {
      await archiveBuildBundle(buildBundleId);
      await refetch();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Build Bundle archived successfully",
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to archive build bundle. Please try again later",
      });
    }
  };

  /**
   * Helper function in downloading a specific build bundle by ID
   * @param buildBundleId Specific Build bundle to download
   */
  const downloadBuildBundleById = async (buildBundleId: string, raw = false) => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Downloading Build Bundle...",
    });
    try {
      const response = raw 
        ? await downloadBuildBundle({buildBundleId, buildBundleType: "raw"}) 
        : await downloadBuildBundle({buildBundleId, buildBundleType: "finished"});
      if (response && response?.blob) {
        const blob = await response.blob();

        // Update the download link state
        const downloadLink = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        document.body.appendChild(link); // for Firefox
        link.setAttribute("href", downloadLink);
        link.setAttribute("download", `buildbundles_${raw ? 'raw_' : ''}${buildBundleId}.zip`);
        link.click();

        // Clear the Object URL from memory
        setTimeout(() => {
          window.URL.revokeObjectURL(downloadLink);
        }, 3000);

        // Success Toast
        toast.removeToast(toastId);
        toast.addToast({
          variant: "success",
          description: "Build Bundle downloaded successfully",
        });
      }
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to download build bundle. Please try again later",
      });
    }
  };

  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={4} fullWidth={true}>
        <Stack
          direction="horizontal"
          justify="space-between"
          align="center"
          fullWidth={true}
        >
          <Stack direction="vertical" gap={1}>
            <Heading variant={1}>Jobs</Heading>
          </Stack>
          <Button
            onClick={() => {
              history.push(PATH.adminCreate);
            }}
          >
            Create Jobs
          </Button>
        </Stack>

        {isLoading ? (
          <Spinner />
        ) : (
          <Stack direction="vertical" gap={2}>
            <Table styles={{ width: "100%" }}>
              <TableHead>
                <TableRow isHeading={true}>
                  <TableHeading>Bundle Name</TableHeading>
                  <TableHeading>Job Completed</TableHeading>
                  <TableHeading>Raw Files</TableHeading>
                  <TableHeading>Finished Files</TableHeading>
                  <TableHeading />
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.sort(byBundleDate).map((buildBundle) => {
                    const name = getCurriculumName(
                      buildBundle.nceaCode,
                      curriculumData
                    );
                    const activityBuildsFinished = buildBundle.activityBuilds.filter(
                      (activityBuild) => activityBuild?.completed
                    ).length;

                    return (
                      <TableRow key={buildBundle.id}>
                        <TableData>{name}</TableData>
                        <TableData>
                          {activityBuildsFinished} of{" "}
                          {buildBundle?.activityBuilds?.length}
                        </TableData>
                        <TableData>
                        <Button
                            variant="secondary"
                            disabled={
                              !buildBundle?.activityBuilds?.some(
                                (activityBuild) => activityBuild?.completed
                              )
                            }
                            onClick={() => {
                              downloadBuildBundleById(buildBundle.id, true);
                            }}
                          >
                            Download zip file
                          </Button>
                        </TableData>
                        <TableData>
                          <Button
                            variant="secondary"
                            disabled={
                              !buildBundle?.activityBuilds?.some(
                                (activityBuild) => activityBuild?.completed
                              )
                            }
                            onClick={() => {
                              downloadBuildBundleById(buildBundle.id);
                            }}
                          >
                            Download zip file
                          </Button>
                        </TableData>
                        <TableData>
                          {activityBuildsFinished <
                          buildBundle?.activityBuilds?.length ? (
                            <Button
                              variant="link"
                              onClick={() => {
                                setBuildBundleToBeDeleted(buildBundle);
                              }}
                            >
                              Cancel
                            </Button>
                          ) : (
                            <Button
                              variant="link"
                              onClick={() => {
                                archiveBuildBundleById(buildBundle.id);
                              }}
                            >
                              Archive
                            </Button>
                          )}
                        </TableData>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {data?.length === 0 && (
              <Text styles={{ padding: theme.space[2] }}>
                No Build Bundles Found. Go to{" "}
                <Link to={PATH.adminCreate}> create jobs </Link> to get started.{" "}
              </Text>
            )}
          </Stack>
        )}
        <CancelBundleModal
          onClose={() => {
            setBuildBundleToBeDeleted(null);
          }}
          buildBundle={buildBundleToBeDeleted}
          refetchBuildBundle={refetch}
        />
      </Stack>
    </Card>
  );
};
