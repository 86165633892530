import { CSSProperties } from "react";
import { CSSObject } from "@emotion/react";
import { theme } from "components/lib/utils";

export const divider: CSSObject = { marginTop: theme.space[4] };

export const jobDescription: CSSProperties = { lineHeight: theme.fontSizes[5] };

export const jobPriceDescription: CSSObject = { marginTop: "4px" };

export const jobPrice: CSSObject = {
  lineHeight: theme.fontSizes[6],
};

export const standards: CSSObject = {
  display: "flex",
  alignItems: "center",
};

export const dot: CSSObject = {
  height: "6px",
  width: "6px",
  borderRadius: "100%",
  background: theme.colors.grey.border,
};
