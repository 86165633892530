import { CSSObject } from "@emotion/react";
import { theme } from "components/lib/utils";
import { CSSProperties } from "react";

export const divider: CSSObject = { marginTop: theme.space[4] };
export const jobDescription: CSSProperties = { lineHeight: theme.fontSizes[5] };
export const jobPriceDescription: CSSObject = { marginTop: "4px" };
export const jobPrice: CSSObject = {
  lineHeight: theme.fontSizes[6],
};
