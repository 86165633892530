import { AttachmentResponseDto } from "../openapi/api";
import { ConfigurationParameters } from "../openapi/configuration";
import { uploadFile } from "./uploadFile";

export enum FileUploadType {
  JOB_TYPE = "JOB_TYPE",
  BUILD_BUNDLE = "BUILD_BUNDLE",
}

export const FileUploadApi = (configuration: ConfigurationParameters) => {
  const uploadAttachment = async (
    file: any,
    id: string,
    type: FileUploadType,
    options?: any
  ): Promise<AttachmentResponseDto> => {
    let url = `${configuration?.basePath}`;
    switch (type) {
      case FileUploadType.JOB_TYPE:
        url = `${url}/job-management/job-types/${id}/attachments`;
        break;
      case FileUploadType.BUILD_BUNDLE:
        url = `${url}/job-management/build-bundles/${id}/attachments`;
        break;
    }
    return uploadFile(url, file, options);
  };

  const updateAttachment = async (
    file: any,
    id: string,
    attachmentId: string,
    type: FileUploadType,
    options?: any
  ): Promise<AttachmentResponseDto> => {
    let url = `${configuration?.basePath}`;
    switch (type) {
      case FileUploadType.JOB_TYPE:
        url = `${url}/job-management/job-types/${id}/attachments/${attachmentId}`;
        break;
      case FileUploadType.BUILD_BUNDLE:
        url = `${url}/job-management/build-bundles/${id}/attachments/${attachmentId}`;
        break;
    }
    options.method = "PUT";
    return uploadFile(url, file, options);
  };

  return {
    uploadAttachment,
    updateAttachment,
  };
};
