import { useQuery } from "react-query";
import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export type ActivitybuildJobsSearchQueries = {
  page?: number;
  limit?: number;
  sort?: string;
  sortDirection?: string;
  jobTypeCode?: string[];
};

export const useActivityBuildJob = (id: string) => {
  const { getToken } = useAuth();
  return useQuery(["getActivityBuildJob"], async () =>
    getActivityBuildJob(id, await getToken())
  );
};

const getActivityBuildJob = async (id: string, token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.activityBuildJobsControllerRetrieveAbjById(
    id,
    getDefaultOptions(token)
  );
};
