/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";

import { ContainerProps } from "./Container.types";
import { getContainerStyles } from "./Container.styles";

export const Container = React.forwardRef(
  (
    { fullWidth, styles, ...props }: ContainerProps,
    ref?: React.Ref<any>
  ): JSX.Element => {
    const theme = useTheme();

    return (
      <div
        css={getContainerStyles(theme, { fullWidth, styles })}
        ref={ref}
        {...props}
      />
    );
  }
);
