import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";

export const getReactSelectStyles = (theme: Theme, styles?: CSSObject) => {
  return {
    container: (provided: any, state: any) => ({
      ...provided,
      alignItems: "flex-start",
      border: `${theme.lineThickness[0]} solid ${theme.colors.grey.input}`,
      borderRadius: theme.radii[2],
      boxSizing: "border-box",
      color: theme.colors.grey.input,
      fontFamily: theme.fonts.primary,
      fontWeight: theme.fontWeights.regular,
      lineHeight: "130%",
      ...styles,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: `${theme.space[2]} ${theme.space[3]}`,
    }),
    option: (styles: any, { isFocused, isSelected }: any) => ({
      ...styles,
      color: isFocused
        ? theme.colors.white.default
        : isSelected
        ? theme.colors.purple.default
        : theme.colors.grey.default,
      backgroundColor: isFocused
        ? theme.colors.purple.default
        : theme.colors.white.default,
    }),
  };
};
