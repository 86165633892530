import React, { useContext } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./components/lib/utils/theme";
import { AuthProvider } from "components/utils/auth";
import {
  createToastContext,
  ToastManager,
} from "components/lib/molecules/Toast/ToastProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const ToastContext = createToastContext();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const useToast = () => {
  return useContext(ToastContext);
};

Sentry.init({
  dsn: String(process.env.REACT_APP_SENTRY_DSN),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.3,
  environment: String(process.env.REACT_APP_NODE_ENV),
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ToastManager context={ToastContext}>
          <AuthProvider>
            <Router>
              <App />
            </Router>
          </AuthProvider>
        </ToastManager>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
