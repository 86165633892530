import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import moment from "moment";
import pluralize from "pluralize";

import {
  Table,
  TableData,
  TableHeading,
  TableRow,
  TableBody,
} from "components/lib/atoms/Table/Table";
import { Card, Heading, Spinner, Text, Button } from "components/lib/atoms";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { PATH } from "components/shared/constants/paths.constants";
import { bySubmissionLatestCreatedAt } from "components/utils/helpers";
import { RetrieveSubmissionsJobResponseDto } from "components/utils/openapi";
import { JobTypeMCQEnum } from "components/shared/constants/jobtypes";

import { useActiveJobsList } from "../../hooks/useActiveJobsList.hook";

export const ActiveJobsPanel: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const { data, isLoading } = useActiveJobsList();

  const calculateRemainingTime = (timestamp: string): string => {
    const now = moment();
    const dueDate = moment(timestamp);
    const duration = moment.duration(dueDate.diff(now));
    const durationInMinutes = Math.floor(duration.asMinutes());
    const durationInHours = Math.floor(duration.asHours());

    if (durationInHours > 1) {
      return `${pluralize("hour", durationInHours, true)} left`;
    } else if (durationInMinutes > 1) {
      return `${pluralize("minute", durationInMinutes, true)} left`;
    } else {
      return `Less than a minute left`;
    }
  };

  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={4} fullWidth={true}>
        <Heading variant={1}>Active Jobs</Heading>
        {isLoading ? (
          <Spinner />
        ) : (
          <Stack fullWidth={true} direction="vertical" gap={2}>
            <Table styles={{ width: "100%" }}>
              <TableRow isHeading={true}>
                <TableHeading>Job Name</TableHeading>
                <TableHeading>Job Type</TableHeading>
                <TableHeading>Job Deadline</TableHeading>
                <TableHeading>Max Earnings</TableHeading>
                <TableHeading />
              </TableRow>
              <TableBody>
                {data &&
                  data
                    ?.sort(bySubmissionLatestCreatedAt)
                    ?.map((submission: RetrieveSubmissionsJobResponseDto) => {
                      const jobTypeCode = submission?.job?.jobType?.code;
                      return (
                        <TableRow key={submission?.id}>
                          <TableData>
                            {submission?.activityBuild?.name}
                          </TableData>
                          <TableData>
                            {submission?.job?.jobType?.description}
                          </TableData>
                          <TableData>
                            {calculateRemainingTime(
                              submission?.expiryTime?.toString()
                            )}
                          </TableData>
                          <TableData>{`$${(
                            submission?.job?.payoutRate *
                            submission?.job?.jobType?.contentNumber
                          ).toFixed(2)}`}</TableData>
                          <TableData>
                            <Button
                              variant="secondary"
                              onClick={() => {
                                history.push(
                                  `${PATH.getActiveJob(
                                    submission?.id
                                  )}/resource-job`
                                );
                              }}
                            >
                              Continue Job
                            </Button>
                          </TableData>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
            {data?.length === 0 && (
              <Text styles={{ padding: theme.space[2] }}>
                You do not have any active jobs. Go to{" "}
                <Link to={PATH.browseJobs}> browse jobs </Link> to get started.{" "}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
