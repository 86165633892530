import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import ReactPaginate from "react-paginate";

import { Text, Select, Container, Spinner } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { ActivityBuildJobsResponseDto } from "components/utils/openapi";

import { Job } from "./Job";
import { FilterComponentProps } from "../hooks/useFilters.hook";
import * as styles from "../BrowseJobsPage.styles";
import { useActivityBuildJobs } from "../hooks/useActivityBuildJobs.hook";

export const Jobs: React.FC<FilterComponentProps> = ({ methods }) => {
  const theme = useTheme();

  const {
    sortOrder,
    sortOrderOptions,
    handleSortOrderChange,
    handlePageNumberChange,
    filters,
    pageNumber,
  } = methods;

  const { data, isLoading, refetch, isFetching } = useActivityBuildJobs({
    page: filters?.pageNumber + 1,
    limit: 20,
    sort: filters?.sortOrder?.type,
    sortDirection: filters?.sortOrder?.value,
    jobTypeCode: filters?.jobType,
    levelId: filters?.selectedLevel?.value,
    subjectId: filters?.selectedSubject?.value,
    nceaCode: filters?.selectedStandard?.value,
  });

  useEffect(() => {
    refetch();
  }, [methods, refetch]);

  return (
    <Stack direction="vertical" gap={3} wrap="nowrap" styles={styles.jobs}>
      <Stack
        direction="horizontal"
        styles={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text variant={2} color={theme.colors.grey.text}>
          {data?.meta?.totalItems || 0} jobs found
        </Text>
        <Container
          styles={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            variant={2}
            color={theme.colors.grey.text}
            styles={{
              marginRight: theme.space[1],
            }}
          >
            Sort by
          </Text>
          <Select
            styles={styles.sortOrder}
            options={sortOrderOptions}
            value={sortOrder}
            onChange={handleSortOrderChange}
          />
        </Container>
      </Stack>

      <Container styles={styles.jobsContent}>
        {isLoading || isFetching ? (
          <Spinner />
        ) : (
          <>
            {data?.activityBuildJobs?.map(
              (activityBuildJob: ActivityBuildJobsResponseDto) => {
                return (
                  <Job
                    key={activityBuildJob?.id}
                    activityBuildJob={activityBuildJob}
                  />
                );
              }
            )}
          </>
        )}
      </Container>

      {!((isLoading || isFetching) && !data?.activityBuildJobs) ? (
        <ReactPaginate
          previousLabel={"‹"}
          nextLabel={"›"}
          breakLabel={"..."}
          breakClassName={"break"}
          pageCount={data?.meta?.totalPages ?? 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={({ selected }) => handlePageNumberChange(selected)}
          containerClassName={"pagination"}
          activeClassName={"active"}
          forcePage={pageNumber}
        />
      ) : null}
    </Stack>
  );
};
