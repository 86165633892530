import React from "react";
import { useTheme } from "@emotion/react";

import { Heading } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";

import { Filter, Jobs } from "./components";
import { useFilter } from "./hooks/useFilters.hook";

export const BrowseJobsPage = () => {
  const theme = useTheme();
  const methods = useFilter();

  return (
    <Stack direction="vertical" fullWidth={true} gap={3}>
      <Heading variant={1} color={theme.colors.purple.dark}>
        Browse Jobs
      </Heading>

      <Stack direction="horizontal" gap={4}>
        <Filter methods={methods} />
        <Jobs methods={methods} />
      </Stack>
    </Stack>
  );
};
