import React from "react";
import { useTheme } from "@emotion/react";
import { Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";

export const FormFieldTitle: React.FC = ({ children }) => {
  const { colors } = useTheme();

  return (
    <Stack direction="horizontal">
      <Text styles={{ marginRight: "4px" }}>{children}</Text>
      <Text styles={{ color: colors.red.default }}>*</Text>
    </Stack>
  );
};
