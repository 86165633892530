/** @jsxImportSource @emotion/react */
import { Theme } from "@emotion/react";
import React from "react";
import { Container } from "../Container/Container";
import { getTextStyles } from "../Text/Text.styles";
import { styles } from "./Checkbox.styles";
import { CheckboxProps } from "./Checkbox.types";

export type { CheckboxProps };

export const Checkbox = React.forwardRef(
  (
    {
      id,
      name,
      checked,
      value,
      onChange,
      styles: propStyles,
      children,
    }: CheckboxProps,
    ref?: React.Ref<HTMLInputElement>
  ): JSX.Element => {
    const inputId = id ? id : `${name}-${value}`;
    return (
      <Container css={styles} styles={propStyles}>
        <input
          type="checkbox"
          id={inputId}
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => {
            onChange(e, value);
          }}
          ref={ref}
        />
        <label
          htmlFor={inputId}
          css={(theme: Theme) => getTextStyles(theme, {})}
        >
          {children}
        </label>
      </Container>
    );
  }
);
