import { css } from "@emotion/react";

export const BaseStyles = css`
  body {
    margin: 0;
    font-family: "Graphit", sans-serif;
    font-weight: 300;
    color: #2a184b;
  }

  a {
    text-decoration: underline;
    color: #13a698;
  }

  @font-face {
    font-family: "Graphit";
    src: local("Graphit"), url(fonts/graphitlight.otf) format("opentype");
    font-weight: 200;
  }

  @font-face {
    font-family: "Graphit";
    src: local("Graphit"), url(fonts/graphitregular.otf) format("opentype");
    font-weight: 300;
  }

  @font-face {
    font-family: "Graphit";
    src: local("Graphit"), url(fonts/graphitmedium.otf) format("opentype");
    font-weight: 500;
  }

  @font-face {
    font-family: "Graphit";
    src: local("Graphit"), url(fonts/graphitbold.otf) format("opentype");
    font-weight: 700;
  }

  li {
    font-family: "Graphit";
    font-weight: 200;
    font-size: "12px";
    color: #2a184b;
  }

  .ck-editor__editable_inline {
    min-height: 100px;
  }

  .ck-tooltip__text {
    left: -15px !important;
  }

  .ck-tooltip__text::after {
    display: none;
  }
`;
