/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import { getInputStyles } from "./Input.styles";
import { InputProps } from "./Input.types";

export const Input = React.forwardRef(
  (
    { placeholder = "", disabled = false, ...props }: InputProps,
    ref?: React.Ref<HTMLInputElement>
  ): JSX.Element => {
    const theme = useTheme();

    return (
      <input
        ref={ref}
        css={getInputStyles(theme, props)}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
    );
  }
);
