import React, { useEffect, useMemo } from "react";
import { useTheme } from "@emotion/react";
import { Stack } from "components/lib/layouts";
import {
  Checkbox,
  CheckboxProps,
  Heading,
  Text,
  Divider,
  Select,
  Button,
} from "components/lib/atoms";
import { CrossIcon } from "components/lib/icons";
import { useCurriculum } from "components/shared/hooks/useCurriculum";
import { useJobTypes } from "components/shared/hooks/useJobTypes.hook";
import { sortOptionsAlphabetically } from "components/utils/helpers";
import { FilterComponentProps } from "../hooks/useFilters.hook";
import * as styles from "../BrowseJobsPage.styles";

const JobTypeCheckbox: React.FC<CheckboxProps> = ({
  value,
  name,
  checked,
  onChange,
  children,
}) => {
  return (
    <Checkbox
      value={value}
      name={name}
      checked={checked}
      onChange={onChange}
      styles={styles.filterCheckbox}
    >
      <Text variant={2} styles={styles.filterCheckboxText}>
        {children}
      </Text>
    </Checkbox>
  );
};

const StandardType: React.FC<FilterComponentProps> = ({ methods }) => {
  const { data } = useCurriculum();

  const {
    selectedLevel,
    selectedStandard,
    selectedSubject,
    handleLevelChange,
    handleStandardChange,
    handleSubjectChange,
    levelId,
    subjectId,
    nceaCode,
    changeLevel,
    changeSubject,
    changeStandard,
  } = methods;

  const levels = useMemo(
    () =>
      data?.map(({ id, name }) => {
        return {
          label: `Level ${name}`,
          value: id,
        };
      }),
    [data]
  );

  const availableSubjects = data?.find(({ id, name }) => {
    return id === selectedLevel?.value;
  })?.subjects;

  const subjects = useMemo(
    () =>
      availableSubjects?.map(({ id, name }) => {
        return {
          label: name,
          value: id,
        };
      }),
    [availableSubjects]
  );

  const standards = useMemo(
    () =>
      availableSubjects
        ?.find(({ id }) => {
          return id === selectedSubject?.value;
        })
        ?.standards.map(({ name, nceaCode }) => {
          return {
            label: name,
            value: nceaCode,
          };
        }),
    [availableSubjects, selectedSubject]
  );

  const standardTypes = {
    level: {
      options: levels,
      placeHolder: "level",
    },
    subject: {
      options: subjects,
      placeHolder: "subject",
    },
    standard: {
      options: standards,
      placeHolder: "standard",
    },
  };

  useEffect(() => {
    if (levels && levelId) {
      const newLevel = levels.find((ele) => ele.value == levelId);
      newLevel && handleLevelChange({ ...newLevel, type: "" });
    }
  }, [levelId, levels]);
  useEffect(() => {
    if (subjects && subjectId) {
      const newSubject = subjects.find((ele) => ele.value == subjectId);
      newSubject && handleSubjectChange({ ...newSubject, type: "" });
    }
  }, [subjectId, subjects]);
  useEffect(() => {
    if (standards && nceaCode) {
      const newLevel = standards.find((ele) => ele.value == nceaCode);
      newLevel && handleStandardChange({ ...newLevel, type: "" });
    }
  }, [standards, nceaCode]);

  return (
    <>
      <Select
        options={standardTypes.level.options}
        styles={styles.standardSelect}
        placeholder={`Select ${standardTypes.level.placeHolder}`}
        value={selectedLevel}
        onChange={changeLevel}
      />
      <Select
        options={standardTypes.subject.options}
        styles={styles.standardSelect}
        placeholder={`Select ${standardTypes.subject.placeHolder}`}
        value={selectedSubject}
        onChange={changeSubject}
        disabled={
          standardTypes.subject.options?.length === 0 ||
          standardTypes.subject.options === undefined
        }
      />
      <Select
        options={standardTypes.standard.options}
        styles={styles.standardSelect}
        placeholder={`Select ${standardTypes.standard.placeHolder}`}
        key={standardTypes.standard.placeHolder}
        value={selectedStandard}
        onChange={changeStandard}
        disabled={
          standardTypes.standard.options?.length === 0 ||
          standardTypes.standard.options === undefined
        }
      />
    </>
  );
};

const Clear: React.FC<FilterComponentProps> = ({ methods }) => {
  const { clearFilters } = methods;

  return (
    <Button
      variant="secondary"
      size="medium"
      styles={styles.clearButton}
      onClick={() => clearFilters()}
    >
      <div style={styles.clearButtonContent}>
        <CrossIcon style={styles.clearButtonIcon} />

        <span style={styles.clearButtonText}>Clear all filters</span>
      </div>
    </Button>
  );
};

export const Filter: React.FC<FilterComponentProps> = ({ methods }) => {
  const theme = useTheme();
  const { data: jobTypes } = useJobTypes();
  const { handleJobTypeChange, jobType } = methods;

  return (
    <Stack direction="vertical" gap={3} styles={styles.filter}>
      <Heading variant={3} color={theme.colors.purple.dark}>
        Filter by
      </Heading>

      <Text variant={3} styles={styles.jobTypeTitle}>
        Job type
      </Text>

      {jobTypes?.sort(sortOptionsAlphabetically)?.map((jobTypeData) => {
        return (
          <JobTypeCheckbox
            key={jobTypeData?.code}
            value={jobTypeData?.code}
            name="jobType"
            checked={jobType?.includes(jobTypeData?.code)}
            onChange={() => {
              handleJobTypeChange(jobTypeData?.code);
            }}
          >
            {jobTypeData?.description}
          </JobTypeCheckbox>
        );
      })}

      <Divider styles={styles.filterDivider} />

      <Text variant={3} styles={styles.standardTypeTitle}>
        Standard type
      </Text>

      <StandardType methods={methods} />

      <Divider styles={styles.filterDivider} />

      <Clear methods={methods} />
    </Stack>
  );
};
