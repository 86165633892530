import { useTheme } from "@emotion/react";
import { Text, Heading } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";

export const ContactSection = () => {
  const theme = useTheme();
  return (
    <Stack direction="vertical" gap={3}>
      <Heading variant={2}>Contact Us</Heading>
      <Text weight={theme.fontWeights["light"]}>
        If you need help, please email us at{" "}
        <a
          href="mailto:create@learncoach.com"
          style={{ textDecoration: "underline" }}
        >
          create@learncoach.com
        </a>{" "}
      </Text>
      <Text weight={theme.fontWeights["light"]}>
        We'll do our best to get back to you ASAP!
      </Text>
    </Stack>
  );
};
