import { useParams } from "react-router-dom";

import {
  Button,
  Container,
  Heading,
  Spinner,
  Text,
} from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import {
  TableBody,
  TableData,
  TableHeading,
  TableRow,
  Table,
} from "components/lib/atoms/Table/Table";
import { AppLink, RichPreview } from "components/shared";
import { PATH } from "components/shared/constants/paths.constants";
import { JobTypeMCQEnum } from "components/shared/constants/jobtypes";
import { LeftChevronIcon } from "components/lib/icons";

import { useCompletedSubmission } from "./hooks/useCompletedSubmission";

enum SubmissionStatus {
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
}

export const FeedbackPage = () => {
  const { completedSubmissionId } =
    useParams<{
      completedSubmissionId: string;
    }>();
  const { data, isLoading } = useCompletedSubmission(completedSubmissionId);
  const isMcqJobType =
    data?.jobTypeCode &&
    Object.keys(JobTypeMCQEnum).includes(data?.jobTypeCode);

  /**
   * Helper function to get the submission status string
   */
  const getInputJobSubmissionStatusString = (): string | undefined => {
    const status = data?.status?.toUpperCase();
    switch (status) {
      case SubmissionStatus.REJECTED:
        return "Rejected";
      case SubmissionStatus.COMPLETED:
        const acceptedContents =
          data?.additionalContent?.acceptedContents[0] ?? null;
        const rejectedContents =
          data?.additionalContent?.rejectedContents[0] ?? null;
        if (
          (acceptedContents?.isFixable === true &&
            acceptedContents?.errorTypes?.length > 0) ||
          (rejectedContents?.isFixable &&
            rejectedContents?.errorTypes?.length > 0)
        ) {
          return "Accepted, minor errors";
        }
        return "Accepted, no errors";
      default:
        return "Unknown";
    }
  };

  return (
    <Stack direction="vertical" gap={4} fullWidth={true}>
      <Heading variant={1}>Feedback</Heading>
      {isLoading && !data ? (
        <Spinner />
      ) : (
        <Stack direction="vertical" gap={5} fullWidth={true}>
          <Heading variant={2}>{data?.activityBuildName}</Heading>
          <Stack direction="horizontal">
            {!isMcqJobType && (
              <Stack direction="vertical" gap={1} styles={{ width: "350px" }}>
                <Heading variant={2}>
                  {getInputJobSubmissionStatusString()}
                </Heading>
                <Text>Submission Status</Text>
              </Stack>
            )}
            <Stack direction="vertical" gap={1} styles={{ width: "200px" }}>
              <Heading variant={2}>
                {data?.amountEarned || data?.amountEarned === 0
                  ? `$${data.amountEarned?.toFixed(2)}`
                  : `N/A`}
              </Heading>
              <Text>Total amount earned</Text>
            </Stack>
          </Stack>
          <Stack direction="vertical" gap={4} fullWidth={true}>
            <Heading variant={2}>
              {isMcqJobType ? "Questions rejected" : "Feedback"}
            </Heading>
            <Stack fullWidth={true} direction="vertical" gap={2}>
              <Table styles={{ width: "100%" }}>
                <colgroup>
                  {isMcqJobType && <col span={1} width="5%" />}
                  <col span={1} width="20%" />
                  <col span={1} />
                </colgroup>
                <TableRow isHeading={true}>
                  {isMcqJobType && <TableHeading />}
                  <TableHeading>Errors</TableHeading>
                  <TableHeading>Feedback</TableHeading>
                </TableRow>
                <TableBody>
                  {data?.additionalContent?.rejectedContents?.map(
                    (rejectedContent: any, index: number) => {
                      return (
                        <TableRow>
                          {isMcqJobType && <TableData>{index + 1}</TableData>}
                          <TableData>
                            {rejectedContent?.errorTypes?.join(", ")}
                          </TableData>
                          <TableData>
                            <RichPreview>
                              {rejectedContent?.feedback}
                            </RichPreview>
                          </TableData>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Stack>
          </Stack>
          <Stack direction="vertical" gap={4}>
            <Text>
              If you need this feedback explained, please email
              <a href="mailto:create@learncoach.com">
                {" "}
                create@learncoach.com{" "}
              </a>{" "}
            </Text>
            <a href="mailto:create@learncoach.com">
              <Button variant="secondary">Email Us</Button>
            </a>{" "}
            <AppLink path={PATH.completedJobs}>
              <Stack gap={2} align="center">
                <Container>
                  <LeftChevronIcon />
                </Container>
                <Container>Back to completed jobs</Container>
              </Stack>
            </AppLink>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
