import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import moment from "moment";

import { useToast } from "index";
import { Stack } from "components/lib/layouts";
import {
  Table,
  Heading,
  Container,
  Text,
  Button,
  Spinner,
} from "components/lib/atoms";
import { InputField } from "components/lib/molecules";
import {
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/lib/atoms/Table/Table";
import {
  GetBuildBundlesResponseDto,
  JobTypesResponseDto,
} from "components/utils/openapi";
import {
  getFormattedDateString,
  getFormattedThroughputTime,
  momentDateQueryHandler,
} from "components/utils/dateUtils";
import { sortOptionsAlphabetically } from "components/utils/helpers";

import { useJobTypesReportingMutation } from "../../hooks/useJobTypesReporting.hook";
import {
  BuildBundlesStatus,
  useBuildBundleListMutation,
} from "../../hooks/useBuildBundleList.hook";
import {
  getActiveJobLinkSearch,
  getActiveJobLinkSearchButton,
} from "../AdminDashboard.styles";
import { BundleCreationDates } from "./BundleCreationDates";

export const ThroughputStats = () => {
  const theme = useTheme();
  const toast = useToast();

  // Handle From and To Dates
  const currentDate = new Date();
  const currentDateString = getFormattedDateString(
    currentDate,
    "YYYY-MM-DD",
    undefined,
    true
  );
  const defaultFromDate = momentDateQueryHandler(
    moment(new Date()).startOf("day"),
    {
      type: "subtract",
      unit: "weeks",
      amount: 2,
    },
    true
  ).toDate();

  const [fromDate, setFromDate] = useState<Date | undefined>(defaultFromDate);
  const [toDate, setToDate] = useState<Date>();

  // Get the Related Build Bundle and Job Type Data
  const [buildBundleData, setBuildBundleData] =
    useState<GetBuildBundlesResponseDto[]>();
  const [jobTypeReportingData, setJobTypeReportingData] =
    useState<JobTypesResponseDto[]>();
  const { mutateAsync: getBuildBundleList, isLoading: isBuildBundleLoading } =
    useBuildBundleListMutation();
  const { mutateAsync: getJobTypesReporting, isLoading: isJobTypeLoading } =
    useJobTypesReportingMutation();

  /**
   * Get Throughput Data of Build Bundles and its Reporting Data
   */
  const getThroughputData = async (
    creationTimeFrom?: Date,
    creationTimeTo?: Date
  ) => {
    try {
      // Setup the Mutation Variables for the Build Bundle Mutation Query
      const buildBundleListVariables = {
        creationTimeFrom,
        creationTimeTo,
        statuses: [
          BuildBundlesStatus.ACTIVE,
          BuildBundlesStatus.ARCHIVED,
          BuildBundlesStatus.COMPLETED,
        ],
      };

      // Fetch the Build Bundle List
      await getBuildBundleList(buildBundleListVariables, {
        onSuccess: async (buildBundleData) => {
          // Update Build Bundle Data
          setBuildBundleData(buildBundleData);
          // Setup the Mutation Variables for the Job Types Reporting Mutation Query
          const jobTypesReportingVariables = {
            creationTimeFrom,
            creationTimeTo,
            buildBundleIds:
              buildBundleData && buildBundleData?.length >= 0
                ? buildBundleData?.map((buildBundle) => buildBundle?.id)
                : undefined,
          };
          // Fetch the Job Types Reporting
          const jobTypeReportingData = await getJobTypesReporting(
            jobTypesReportingVariables
          );
          // Update Job Type Data
          setJobTypeReportingData(jobTypeReportingData);
        },
        onError: () => {
          throw new Error(
            "Something went wrong with retrieving data. Please try again later."
          );
        },
      });
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  useEffect(() => {
    getThroughputData(
      momentDateQueryHandler(
        moment(new Date()),
        {
          type: "subtract",
          unit: "weeks",
          amount: 2,
        },
        true
      ).toDate(),
      undefined
    );
  }, []);

  const handleSearchJobType = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const fromDateString = getFormattedDateString(fromDate);
    const toDateString = getFormattedDateString(toDate);
    const toastId = toast.addToast({
      variant: "info",
      description: `Searching Build Bundles between ${fromDateString} and ${toDateString}`,
    });
    try {
      await getThroughputData(fromDate, toDate);
      toast.removeToast(toastId);
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          `Failed to search between ${fromDateString} and ${toDateString}. Please try again later`,
      });
    }
  };

  return (
    <>
      <Stack direction="vertical" gap={2}>
        <Stack direction="vertical" gap={1}>
          <Heading variant={2}>Throughput stats</Heading>
          <form onSubmit={handleSearchJobType}>
            <Stack direction="horizontal" gap={1} align="center">
              <Heading variant={5}>Bundle creation date between</Heading>
              <InputField
                type="date"
                style={getActiveJobLinkSearch()}
                disabled={isBuildBundleLoading || isJobTypeLoading}
                defaultValue={getFormattedDateString(
                  defaultFromDate,
                  "YYYY-MM-DD"
                )}
                max={getFormattedDateString(toDate, "YYYY-MM-DD")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFromDate(e?.target?.valueAsDate ?? undefined);
                }}
              />
              <Container>
                <Heading variant={5}>and</Heading>
              </Container>
              <InputField
                type="date"
                style={getActiveJobLinkSearch()}
                disabled={isBuildBundleLoading || isJobTypeLoading}
                defaultValue={currentDateString}
                max={currentDateString}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setToDate(e?.target?.valueAsDate ?? undefined);
                }}
              />
              <Button
                variant="primary"
                style={getActiveJobLinkSearchButton()}
                disabled={isBuildBundleLoading || isJobTypeLoading}
                type="submit"
              >
                Search
              </Button>
            </Stack>
          </form>
        </Stack>
        <Table styles={{ width: "100%" }}>
          <TableHead>
            <TableRow isHeading={true}>
              <TableHeading>Job Type</TableHeading>
              <TableHeading>Live to Pickup (Median Time)</TableHeading>
              <TableHeading>Pickup to Submitted (Median Time)</TableHeading>
              <TableHeading>Live to Submitted (Median Time)</TableHeading>
              <TableHeading>% reclaimed</TableHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            {isJobTypeLoading || isBuildBundleLoading ? (
              <TableRow isHeading={true}>
                <TableData>
                  <Spinner />
                </TableData>
              </TableRow>
            ) : (
              jobTypeReportingData
                ?.sort(sortOptionsAlphabetically)
                ?.map((jobType: JobTypesResponseDto) => {
                  return (
                    <TableRow key={jobType?.code}>
                      <TableData>{jobType?.name}</TableData>
                      <TableData>
                        {getFormattedThroughputTime(
                          jobType?.medianLiveToLatestPickUp
                        )}
                      </TableData>
                      <TableData>
                        {getFormattedThroughputTime(
                          jobType?.medianLatestPickUpToSubmitted
                        )}
                      </TableData>
                      <TableData>
                        {getFormattedThroughputTime(
                          jobType?.medianLiveToSubmitted
                        )}
                      </TableData>
                      <TableData>
                        {(jobType?.percentageJobsReclaimed ||
                          jobType?.percentageJobsReclaimed === 0) &&
                          `${jobType?.percentageJobsReclaimed}%`}
                      </TableData>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>

        {((!isJobTypeLoading && jobTypeReportingData?.length === 0) ||
          !jobTypeReportingData) && (
          <Text styles={{ padding: theme.space[2] }}>
            No throughput stats found. Please use the date range search to find
            build bundles.
          </Text>
        )}
      </Stack>
      <BundleCreationDates
        isLoading={isBuildBundleLoading}
        buildBundleData={buildBundleData}
      />
    </>
  );
};
