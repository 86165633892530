import { Spinner } from "components/lib/atoms";
import { PATH } from "components/shared/constants/paths.constants";
import { useUser } from "components/shared/hooks/useUser.hook";
import { useAuth } from "components/utils/auth";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ children, ...rest }: any) => {
  const { isAuthenticated } = useAuth();
  const { data: user, isLoading } = useUser();
  return (
    <Route
      {...rest}
      render={() => {
        if (isAuthenticated) {
          if (isLoading) return <Spinner />;
          if (user?.isOnboarded) {
            return children;
          } else {
            return <Redirect to={PATH.onboardingWizard} />;
          }
        } else {
          return <Redirect to={PATH.login} />;
        }
      }}
    />
  );
};
