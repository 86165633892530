import { CSSObject } from "@emotion/serialize";
import { Theme } from "@emotion/react";
import { TextProps } from "./Text.types";
import { getStyles } from "../../utils";

export const getTextStyles = (
  theme: Theme,
  { styles, color, weight, variant, align, fullWidth }: Partial<TextProps>
) => {
  const base: CSSObject = {
    fontFamily: theme.fonts.primary,
    color: color || theme.colors.purple.dark,
    fontWeight: weight || theme.fontWeights.regular,
    ...(align ? { textAlign: align } : {}),
    ...(fullWidth ? { width: "100%" } : {}),
  };

  const variants = theme.fontSizes.reduce((agg, fontSize, index) => {
    agg[index] = { fontSize };
    return agg;
  }, {} as Record<string, CSSObject>);

  return getStyles({ base, variants, styles, variant });
};
