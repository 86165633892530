import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useToast } from "index";

import { useAuth } from "components/utils/auth";
import { PATH } from "components/shared/constants/paths.constants";

import { FormValue } from "./ChangePassword.types";
import { ACCOUNT_SECTIONS } from "../constants";

export function useChangePassword() {
  const {
    getValues,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValue>({
    mode: "onChange",
  });
  const { changePassword } = useAuth();
  const { push } = useHistory();
  const toast = useToast();

  async function handleChangePassword(values: FormValue) {
    // Check if the confirm new password matches with the new password
    if (values?.newPassword !== values?.confirmNewPassword) {
      setError("confirmNewPassword", {
        type: "validate",
        message: "Confirm password does not match new password",
      });
      return;
    }

    // Change the password through Cognito
    await changePassword(values?.password, values?.newPassword)
      .then((res: "SUCCESS" | undefined) => {
        toast.addToast({
          description: "Password changed successfully",
          variant: "success",
        });
        push(PATH.getAccountSection(ACCOUNT_SECTIONS[0].id));
        return res;
      })
      .catch((error: Error) => {
        toast.addToast({
          description: error.message,
          variant: "error",
        });
        return error;
      });
  }

  return {
    errors,
    getValues,
    handleChangePassword,
    handleSubmit,
    isSubmitting,
    register,
  };
}
