import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { useHistory } from "react-router";

import { ToastContext } from "index";
import { Button, Divider, Heading, Text } from "components/lib/atoms/";
import { Stack } from "components/lib/layouts";

import { JobCardProps } from "../JobCard/JobCard.types";
import { RichPreview } from "../RichPreview/RichPreview";
import { useAccceptJob } from "./hooks/useAcceptJob.hook";
import * as styles from "./JobDetails.styles";
import { PATH } from "../constants/paths.constants";

export const JobDetails = ({
  job,
  disableAccept = false,
  title = "",
}: {
  job: JobCardProps;
  disableAccept?: boolean;
  title?: string;
}) => {
  const theme = useTheme();
  const toast = useContext(ToastContext);
  const history = useHistory();
  const { mutateAsync: acceptJob, isLoading } = useAccceptJob();

  const acceptJobClick = async () => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Accepting job",
    });
    try {
      const acceptedJob = await acceptJob(job.id);
      toast.removeToast(toastId);
      const { id: submissionId } = acceptedJob;
      history.push(`${PATH.activeJobs}/${submissionId}/instructions`);
    } catch (error) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description: "Failed to accept job. Try again later",
      });
    }
  };
  return (
    <Stack
      direction="vertical"
      gap={4}
      fullWidth={true}
      styles={{ marginTop: theme.space[4] }}
    >
      <Stack direction="vertical" gap={4}>
        <Stack justify="space-between" fullWidth={true} align="center">
          <Stack direction="vertical" styles={{ maxWidth: "590px" }}>
            <Heading
              color={theme.colors.purple.dark}
              weight={500}
              variant={3}
              styles={{ marginBottom: "4px" }}
            >
              {title}
            </Heading>
            <Text color={theme.colors.purple.dark}>
              {job.jobType.description}
            </Text>
          </Stack>
          <Stack
            direction="vertical"
            styles={{
              textAlign: "right",
              marginTop: "auto",
            }}
          >
            <Text
              variant={6}
              color={theme.colors.purple.default}
              weight={500}
              styles={styles.jobPrice}
            >{`$${(job.payoutRate * job.jobType?.contentNumber).toFixed(
              2
            )}`}</Text>
            <Text
              color={theme.colors.grey.text}
              styles={styles.jobPriceDescription}
            >
              Maximum earnings per job
            </Text>
          </Stack>
        </Stack>
        {!disableAccept && (
          <Button
            styles={{ width: "144px" }}
            onClick={acceptJobClick}
            disabled={job.activityBuilds === 0 || isLoading}
          >
            Accept Job
          </Button>
        )}
      </Stack>
      <Divider styles={styles.divider} />
      <Stack
        gap={4}
        fullWidth={true}
        justify="space-between"
        styles={{ maxWidth: "600px" }}
      >
        <Stack direction="vertical" gap={1}>
          <Text color={theme.colors.grey.text}>Time to complete job</Text>
          <Text
            color={theme.colors.purple.dark}
          >{`${job.jobDuration} hours`}</Text>
        </Stack>
        <Stack direction="vertical" gap={1}>
          <Text color={theme.colors.grey.text}>Jobs available</Text>
          <Text color={theme.colors.purple.dark}>
            {job.activityBuilds === 0 ? "Coming soon" : job.activityBuilds}
          </Text>
        </Stack>

        <Stack direction="vertical" gap={1}>
          <Text color={theme.colors.grey.text}>Pay per question</Text>
          <Text color={theme.colors.purple.dark}>${job.payoutRate}</Text>
        </Stack>
      </Stack>
      <Divider styles={styles.divider} />
      <RichPreview>{job?.jobType?.details}</RichPreview>
    </Stack>
  );
};
