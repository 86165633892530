import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { DividerProps } from "./Divider.types";

import { getStyles } from "../../utils";

export const getDividerStyles = (
  theme: Theme,
  { width, styles, variant }: Partial<DividerProps>
) => {
  const base: CSSObject = {
    margin: 0,
    width,
    height: "1px",
    border: 0,
    background: theme.colors.grey.border,
  };

  return getStyles({
    base,
    styles,
    variant
  });
};
