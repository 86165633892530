import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils";
import { CSSProperties } from "react";

export const templateName: CSSObject = {
  marginTop: theme.space[1],
};

export const richTextTitle: CSSObject = {
  marginTop: theme.space[5],
};

export const richTextPreview: CSSObject = {
  marginTop: 0,
};

export const richTextEditor: CSSObject = {
  marginTop: theme.space[1],
};

export const actionButton: CSSObject = {
  width: "200px",
  marginBottom: theme.space[6],
};

export const successNotification: CSSObject = {
  background: theme.colors.mint.surface,
  borderRadius: "4px",
  padding: `${theme.space[3]} ${theme.space[4]}`,
  display: "flex",
  flexDirection: "row",
  marginBottom: theme.space[4],
};

export const successNotificationText: CSSObject = {
  paddingLeft: theme.space[3],
  justifyContent: "center",
};
