export const uploadFile = (url: string, file: Blob, options?: any) => {
  const formData = new FormData();
  formData.append("file", file);
  return fetch(url, {
    method: options.method ?? "POST",
    headers: options.headers,
    body: formData,
  }).then((response: Response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      throw response;
    }
  });
};
