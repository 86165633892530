import { CSSObject } from "@emotion/react";
import { theme } from "components/lib/utils";

export const jobCard: CSSObject = {
  padding: theme.space[4],
  marginBottom: theme.space[2],
};

export const price: CSSObject = {
  display: "flex",
  justifyContent: "flex-end",
};

export const details: CSSObject = {
  display: "flex",
  marginBottom: theme.space[4],
};

export const date: CSSObject = {
  flexBasis: "100px",
  marginRight: theme.space[11],
};

export const earnings: CSSObject = {
  marginLeft: "auto",
};

export const maxEarningText: CSSObject = {
  width: "120px",
  textAlign: "right",
};

export const standards: CSSObject = {
  display: "flex",
  alignItems: "center",
};

export const dot: CSSObject = {
  height: "6px",
  width: "6px",
  borderRadius: "100%",
  background: theme.colors.grey.border,
};
