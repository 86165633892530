import { useTheme } from "@emotion/react";
import { Button } from "components/lib/atoms/Button/Button";
import { Heading } from "components/lib/atoms/Heading/Heading";
import {
  Table,
  TableBody,
  TableData,
  TableHeading,
  TableRow,
} from "components/lib/atoms/Table/Table";
import { Text } from "components/lib/atoms/Text/Text";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { Modal } from "components/lib/molecules/Modal/Modal";
import { RichPreview } from "components/shared/RichPreview/RichPreview";
import {
  RetrieveSubmissionByIdJobResponseDto,
  RetrieveSubmissionContentResponseDto,
} from "components/utils/openapi";
import { ToastContext } from "index";
import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSubmissionSubmit } from "../../hooks/useSumbissionSubmit.hook";
import { isQuestionComplete } from "../../utils";
import { ReactComponent as TickIcon } from "../../svgs/tick.svg";
import { ReactComponent as TickLineIcon } from "../../svgs/tickline.svg";
import { InputSubmissionLookup, JobType } from "../../JobEditPage";
import { PATH } from "components/shared/constants/paths.constants";

export const QuestionsSection = ({
  jobID,
  submission,
  jobType,
  createNewQuestion,
  deleteQuestion,
  completedQuestions,
  submissionContentLookup,
}: {
  createNewQuestion: () => Promise<any>;
  deleteQuestion: (questionId: string) => Promise<any>;
  jobType: JobType;
  jobID: string;
  submission: RetrieveSubmissionByIdJobResponseDto;
  completedQuestions: number;
  submissionContentLookup?: InputSubmissionLookup;
}) => {
  const history = useHistory();
  const toast = useContext(ToastContext);
  const theme = useTheme();
  const isMCQJobType = jobType === "mcq";
  const isReviewJobType = jobType === "review";
  const isAllQuestionsCompleted = isMCQJobType
    ? submission?.job?.jobType?.contentNumber === completedQuestions
    : submission?.submissionContents?.every(
        (question) => question?.content?.approved
      );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState({
    open: false,
    question: "",
  });

  const { mutateAsync: submit } = useSubmissionSubmit();

  const onSubmit = async () => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Submitting..",
    });
    await submit({ submissionId: jobID });
    toast.removeToast(toastId);
    toast.addToast({
      variant: "success",
      description: "Submitted",
    });
    history.push({
      pathname: PATH.completed,
    });
  };

  const totalQuestionsCreated = submission.submissionContents.length;
  const getInitialQuestionById = (initialSubmissionId: string): string => {
    return submissionContentLookup &&
      submissionContentLookup[initialSubmissionId]
      ? submissionContentLookup[initialSubmissionId]?.content?.question
      : "N/A";
  };

  return (
    <Stack fullWidth={true} gap={3} direction="vertical">
      <Stack
        direction="horizontal"
        justify="space-between"
        align="center"
        fullWidth={true}
      >
        <Stack direction="vertical" gap={1}>
          <Heading variant={2}>
            {isMCQJobType ? "Your Questions" : "Review Questions"}
          </Heading>
          {!isAllQuestionsCompleted ? (
            <Text>
              {completedQuestions} of {submission?.job?.jobType?.contentNumber}{" "}
              questions are {isMCQJobType ? "completed" : "reviewed"}
            </Text>
          ) : (
            <Stack direction="horizontal" gap={1} align="center">
              <TickIcon height="16px" width="16px" />
              <Text color={theme.colors.mint.dark}>
                All questions are {isMCQJobType ? "completed" : "reviewed"}
              </Text>
            </Stack>
          )}
        </Stack>
        {isMCQJobType && (
          <Button
            size="medium"
            onClick={createNewQuestion}
            disabled={
              totalQuestionsCreated >= submission?.job?.jobType?.contentNumber
            }
          >
            + Write new question
          </Button>
        )}
      </Stack>

      <Table styles={{ marginTop: theme.space[6] }}>
        <TableBody>
          <TableRow isHeading={true}>
            <TableHeading>Questions</TableHeading>
            <TableHeading>Status</TableHeading>
            <TableHeading />
            <TableHeading />
            <TableHeading />
          </TableRow>
          {submission.submissionContents.length === 0 && (
            <Text styles={{ padding: theme.space[3] }}>
              You have not written any questions. Click 'write new question' to
              start.
            </Text>
          )}
          {submission.submissionContents.map(
            (question: RetrieveSubmissionContentResponseDto) => (
              <TableRow key={question?.id}>
                <TableData styles={{ verticalAlign: "top" }}>
                  {isMCQJobType && (
                    <RichPreview>
                      {question.content["question"] || "NA"}
                    </RichPreview>
                  )}
                  {isReviewJobType && (
                    <RichPreview>
                      {question.content.updatedQuestion
                        ? question.content.updatedQuestion
                        : getInitialQuestionById(
                            question.content.initialSubmissionContentId
                          )}
                    </RichPreview>
                  )}
                </TableData>
                {isMCQJobType && (
                  <>
                    <TableData styles={{ verticalAlign: "top" }}>
                      <Text color={theme.colors.grey.text}>
                        {isQuestionComplete(question)
                          ? "Complete"
                          : "Incomplete"}
                      </Text>
                    </TableData>
                    <TableData styles={{ verticalAlign: "top" }}>
                      <Button
                        variant="textdark"
                        styles={{
                          textDecoration: "underline",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: theme.colors.purple.default,
                          }}
                          to={`resource-job/${question.id}`}
                        >
                          Edit
                        </Link>
                      </Button>
                    </TableData>

                    <TableData styles={{ verticalAlign: "top" }}>
                      <Button
                        variant="textdark"
                        styles={{
                          textDecoration: "underline",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: theme.colors.purple.default,
                          }}
                          to={`resource-job/${question.id}?previewMode=enabled`}
                        >
                          Preview
                        </Link>
                      </Button>
                    </TableData>
                    <TableData styles={{ verticalAlign: "top" }}>
                      <Button
                        variant="textdark"
                        styles={{
                          textDecoration: "underline",
                          color: theme.colors.red.default,
                        }}
                        onClick={() =>
                          setIsConfirmationModalOpen({
                            open: true,
                            question: question.id,
                          })
                        }
                      >
                        Delete
                      </Button>
                    </TableData>
                  </>
                )}

                {isReviewJobType && (
                  <>
                    <TableData>
                      {question.content["approved"] === true ? (
                        <Stack
                          direction="horizontal"
                          gap={1}
                          align="center"
                          wrap="nowrap"
                        >
                          <TickLineIcon />
                          <Text color={theme.colors.mint.dark}>Approved</Text>
                        </Stack>
                      ) : (
                        <Text
                          color={theme.colors.grey.text}
                          styles={{ minWidth: "125px" }}
                        >
                          Ready for review
                        </Text>
                      )}
                    </TableData>

                    <TableData>
                      <Button
                        variant="textdark"
                        styles={{
                          textDecoration: "underline",
                          minWidth: "120px;",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: theme.colors.purple.default,
                          }}
                          to={`resource-job/${question.id}?previewMode=enabled&mode=review`}
                        >
                          Review question
                        </Link>
                      </Button>
                    </TableData>
                  </>
                )}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      <Stack
        styles={{
          padding: theme.space[2],
          justifyContent: "flex-end",
          position: "fixed",
          bottom: 0,
          left: 0,
          rigth: 0,
          width: "100%",
          border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
          background: theme.colors.white.default,
        }}
      >
        <Button disabled={!isAllQuestionsCompleted} onClick={onSubmit}>
          Submit all questions
        </Button>
      </Stack>
      <Modal
        isOpen={isConfirmationModalOpen.open}
        continueLabel="Yes, delete it"
        onContinue={() => {
          deleteQuestion(isConfirmationModalOpen.question);
        }}
        closeLabel="Cancel"
        onClose={() => {
          setIsConfirmationModalOpen({
            open: false,
            question: "",
          });
        }}
      >
        <Stack
          direction="vertical"
          align="center"
          gap={2}
          styles={{ padding: theme.space[2], marginBottom: theme.space[3] }}
        >
          <Heading variant={1}>Are you sure?</Heading>
          <Text>You will not be able to recover this question</Text>
        </Stack>
      </Modal>
    </Stack>
  );
};
