/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { ParagraphProps } from "./Paragraph.types";
import { getParagraphStyles } from "./Paragraph.styles";

export const Paragraph = ({ children, styles, fullWidth }: ParagraphProps) => {
  const theme = useTheme();
  return (
    <p css={getParagraphStyles(theme, { styles, fullWidth })}>{children}</p>
  );
};
