import { useQuery } from "react-query";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

export const useActivityTemplate = () => {
  const { getToken } = useAuth();
  return useQuery(["getActivityTemplates"], async () =>
    getActivityTemplates(await getToken())
  );
};

const getActivityTemplates = async (token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.activityTemplatesControllerFindAll(getDefaultOptions(token));
};