import { JobList } from "components/pages/AdminPage/CreateNewJob/CreateNewJob.types";
import {
  CompletedSubmissionsDto,
  CurriculumGetDto,
  JobTypeDto,
  RetrieveSubmissionsJobResponseDto,
} from "./openapi";

export const getCurriculumName = (
  nceaCode: string,
  curriculumData: CurriculumGetDto[] | undefined
): string => {
  const standard = getStandardByCode(nceaCode, curriculumData);
  return standard?.standardName || "";
};

export const getStandardByCode = (
  nceaCode: string,
  curriculumData?: CurriculumGetDto[]
) => {
  if (curriculumData) {
    for (let curriculum of curriculumData) {
      for (let subject of curriculum.subjects) {
        for (let standard of subject.standards) {
          if (standard.nceaCode === nceaCode) {
            return {
              standardName: standard.name,
              subjectName: subject.name,
              levelName: `Level ${curriculum.name}`,
            };
          }
        }
      }
    }
  }
  return null;
};

export const getAllNceaCodes = (
  curriculumData: CurriculumGetDto[] | undefined
): string[] => {
  let nceaCodes: string[] = [];
  if (curriculumData) {
    for (let curriculum of curriculumData) {
      for (let subject of curriculum.subjects) {
        for (let standard of subject.standards) {
          if (standard?.nceaCode)
            nceaCodes = nceaCodes.concat(standard.nceaCode);
        }
      }
    }
  }
  return nceaCodes;
};

type Subject = CurriculumGetDto["subjects"][0];
type Standard = Subject["standards"][0];

export function sortSubjects(a: Subject, b: Subject) {
  return a.name.localeCompare(b.name);
}

export function sortStandards(a: Standard, b: Standard) {
  return a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });
}

export const currencyFormatter = new Intl.NumberFormat("en-NZ", {
  style: "currency",
  currency: "NZD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

type SubmissionTypes =
  | CompletedSubmissionsDto
  | RetrieveSubmissionsJobResponseDto;

/**
 * Generic Submission Sort by createdAt value
 * @param submissionA current submission
 * @param submissionB next submission
 */
export const bySubmissionLatestCreatedAt = (
  submissionA: SubmissionTypes,
  submissionB: SubmissionTypes
) => {
  return (
    new Date(submissionB.createdAt).getTime() -
    new Date(submissionA.createdAt).getTime()
  );
};

export const sortOptionsAlphabetically = (
  a: { name: string },
  b: { name: string }
) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

/**
 * Helper function to group jobs based on job group
 * @param jobs JobsList
 * @returns grouped jobs list
 */
export const getGroupedJobs = (jobs: JobList[]) => {
  return jobs.reduce((acc: any, cur: any) => {
    if (!(cur.groupName in acc)) acc[cur.groupName] = [];
    acc[cur.groupName].push(cur);
    acc[cur.groupName].sort((item: JobTypeDto) => item.isReview ? 1 : -1);
    return acc;
  }, {});
}
