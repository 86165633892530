/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { DividerProps } from "./Divider.types";
import { getDividerStyles } from "./Divider.styles";

export const Divider = ({
  variant = "solid",
  ...props
}: DividerProps): JSX.Element => {
  const theme = useTheme();

  return <hr css={getDividerStyles(theme, props)} {...props} />;
};
