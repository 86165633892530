import { ReactComponent as PlayIcon } from "../svgs/play.svg";
import { Stack } from "components/lib/layouts";
import { Divider, Text } from "components/lib/atoms";
import { theme } from "components/lib/utils";
import { VideoElementType } from "../../TrainingPage.types";

interface TraningVideoCardProps {
  cardElement: VideoElementType;
}

export default function TraningVideoCard({
  cardElement,
}: TraningVideoCardProps) {
  const { title, subTitle } = cardElement;
  return (
    <Stack
      direction="vertical"
      fullWidth
      styles={{
        minHeight: 60,
      }}
    >
      <Stack
        styles={{
          padding: ` ${theme.space[2]} ${theme.space[3]} `,
        }}
        align="center"
      >
        <PlayIcon />
        <Stack
          direction="vertical"
          styles={{
            paddingLeft: theme.space[3],
          }}
        >
          <Text variant={2}>{title} </Text>
          <Text variant={1} color={theme.colors.grey.text}>
            {subTitle}
          </Text>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
}
