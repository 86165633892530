import React from "react";

import { Heading } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { theme } from "components/lib/utils";

import { styles } from "../../styles";

interface AccountDetailsFormSectionProps {
  title: string;
  actionButton: React.ReactNode;
  backButton?: React.ReactNode;
}

export const AccountDetailsFormSection: React.FC<AccountDetailsFormSectionProps> = ({
  title,
  actionButton,
  children,
}) => {
  return (
    <Stack direction="vertical" gap={4} fullWidth>
      <Heading variant={2} styles={{ marginBottom: theme.space[3] }}>
        {title}
      </Heading>
      {children}

      <Stack styles={styles.NextButton} fullWidth gap={2}>
        {actionButton}
      </Stack>
    </Stack>
  );
};
