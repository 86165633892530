/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { ButtonProps } from "./Button.types";
import { getButtonStyles } from "./Button.styles";

export const Button = ({
  children,
  variant = "primary",
  size,
  styles,
  fullWidth,
  ...restProps
}: ButtonProps) => {
  const theme = useTheme();

  return (
    <button
      css={getButtonStyles(theme, { styles, size, variant, fullWidth })}
      {...restProps}
    >
      {children}
    </button>
  );
};
