import { useQuery } from "react-query";
import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export const useSubmissionById = (id: string, queryKey?: string) => {
  const { getToken } = useAuth();
  return useQuery(
    [queryKey ? queryKey : "getSubmissionById"],
    async () => getSubmissionById(id, await getToken()),
    {
      retry: 2,
      // The query will not execute until the userId exists
      enabled: !!id,
    }
  );
};

const getSubmissionById = async (id: string, token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.submissionControllerFindSubmissionById(
    id,
    getDefaultOptions(token)
  );
};

export const useSubmitReviewJob = async () => {
  const { getToken } = useAuth();
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(await getToken()));

  const getSubmissionById = async (id: string) => {
    return api.submissionControllerFindSubmissionById(
      id,
      getDefaultOptions(await getToken())
    );
  };

  return {
    getSubmissionById,
  };
};
