/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import { getTextAreaStyles } from "./TextArea.styles";
import { TextProps } from "./TextArea.types";

export const TextArea = React.forwardRef(
  (
    { placeholder = "", disabled = false, ...props }: TextProps,
    ref?: React.Ref<HTMLTextAreaElement>
  ): JSX.Element => {
    const theme = useTheme();

    return (
      <textarea
        ref={ref}
        css={getTextAreaStyles(theme, props)}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
    );
  }
);
