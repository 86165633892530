/** @jsxImportSource @emotion/react */
import { useRef, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { getStyles } from "./Modal.styles";
import { ModalPropsType } from "./Modal.types";
import { noop } from "components/lib/utils";
import { keyCodes } from "./constants";
import { Card } from "components/lib/atoms/Card/Card";
import { CSSObject } from "@emotion/serialize";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { Heading } from "components/lib/atoms/Heading/Heading";
import { Button } from "components/lib/atoms/Button/Button";
import { Portal } from "components/lib/layouts/Portal/Portal";
import { CancelIcon } from "./svgs";

export const Modal = ({
  heading,
  isOpen,
  onClose,
  onContinue,
  continueLabel = "Continue",
  closeLabel = "Cancel",
  children,
  continueButtonVariant = "primary",
  hideCloseButton = false,
  hideCloseIcon = false,
  disableOverlay = false,
  disableEscape = false,
  disableContinueButton = false,
  styles: stylesProp = {},
}: ModalPropsType) => {
  const modalRef: any = useRef(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const overlayStyles: CSSObject = {
    ...styles.Overlay,
    ...(isOpen ? styles.ShowOverlay : styles.HideOverlay),
    ...(isOpen && onClose && !disableOverlay
      ? { cursor: "pointer" }
      : { cursor: "unset" }),
  };

  const modalContainerStyles: CSSObject = {
    ...styles.ModalContainer,
    ...stylesProp,
    ...(isOpen ? styles.ShowModal : styles.HideModal),
  };

  useEffect(() => {
    let focusRestoreElement: any;
    let focusableModalElements: any = [];
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target;
      if (event && event.keyCode === keyCodes.ESCAPE && !disableEscape) {
        focusRestoreElement && focusRestoreElement.focus();
        onClose && onClose();
      } else if (
        event.keyCode === keyCodes.TAB &&
        focusableModalElements.length > 0
      ) {
        const firstFocusableElement = focusableModalElements[0];
        const lastFocusableElement = [...focusableModalElements].pop();
        if (!modalRef.current.contains(target)) {
          firstFocusableElement.focus();
          event.preventDefault();
        } else if (target === firstFocusableElement && event.shiftKey) {
          lastFocusableElement.focus();
          event.preventDefault();
        } else if (target === lastFocusableElement && !event.shiftKey) {
          firstFocusableElement.focus();
          event.preventDefault();
        }
      }
    };

    if (isOpen) {
      focusRestoreElement = document.activeElement;
      focusableModalElements = modalRef.current.querySelectorAll(
        'a[href], button, textarea, input, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose, disableEscape]);

  return (
    <Portal>
      <div css={overlayStyles} onClick={disableOverlay ? noop : onClose}></div>
      <Card
        role="dialog"
        aria-modal={isOpen}
        aria-hidden={isOpen}
        styles={modalContainerStyles}
        ref={modalRef}
      >
        {!hideCloseIcon && (
          <Button
            variant="textlight"
            styles={{
              position: "absolute",
              right: theme.space[4],
              top: theme.space[4],
            }}
            onClick={onClose}
          >
            <CancelIcon />
          </Button>
        )}
        <Stack
          direction="vertical"
          gap={2}
          styles={{ height: "100%" }}
          fullWidth={true}
        >
          {heading && <Heading variant={1}>{heading}</Heading>}
          {children}
          {onContinue && (
            <Stack direction="horizontal" gap={3} justify="center">
              {!hideCloseButton && (
                <Button variant="cancel" onClick={onClose}>
                  {closeLabel}
                </Button>
              )}
              <Button
                disabled={disableContinueButton}
                onClick={onContinue}
                variant={continueButtonVariant}
              >
                {continueLabel}
              </Button>
            </Stack>
          )}
        </Stack>
      </Card>
    </Portal>
  );
};
