import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { HeadingProps } from "./Heading.types";

import { getStyles } from "../../utils";

export const getHeadingStyles = (
  theme: Theme,
  { color, weight = 500, textAlign, styles, variant }: Partial<HeadingProps>
) => {
  const base: CSSObject = {
    margin: 0,
    fontFamily: theme.fonts.primary,
    color: color || theme.colors.purple.dark,
    fontWeight: weight || theme.fontWeights.regular,
    ...(textAlign ? { textAlign } : {}),
  };

  const variants = theme.fontSizes.reduce((agg, fontSize, index) => {
    agg[theme.fontSizes.length - 1 - index] = { fontSize };
    return agg;
  }, {} as Record<string, CSSObject>);

  return getStyles({
    base,
    variants,
    styles,
    variant,
  });
};
