import { GetBuildBundlesResponseDto } from "components/utils/openapi";

export const byBundleDate = (
  bundle1: GetBuildBundlesResponseDto,
  bundle2: GetBuildBundlesResponseDto
) => {
  return (
    new Date(bundle2.creationTime).getTime() -
    new Date(bundle1.creationTime).getTime()
  );
};
