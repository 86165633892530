import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { ContainerProps } from "./Container.types";

import { getStyles } from "../../utils";

export const getContainerStyles = (
  _theme: Theme,
  { fullWidth, styles }: Partial<ContainerProps>
) => {
  const base: CSSObject = {
    boxSizing: "border-box",
    ...(fullWidth ? { width: "100%" } : {}),
  };

  return getStyles({
    base,
    styles,
  });
};
