import { useQuery } from "react-query";
import { CurriculumApi } from "components/utils/openapi";
import { AppConfig } from "core/AppConfig";

const getCurriculum = async () => {
  const { getConfiguration } = AppConfig();
  const api = new CurriculumApi(getConfiguration());
  return api.curriculumControllerFindAll();
};

export const useCurriculum = () => {
  return useQuery(["getCurriculum"], () => getCurriculum());
};
