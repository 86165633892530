/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";

import { getCardStyles } from "./Card.styles";
import { CardProps } from "./Card.types";

export const Card = React.forwardRef(
  (
    { styles, fullWidth , ...props}: CardProps,
    ref?: React.Ref<HTMLDivElement>
  ): JSX.Element => {
    const theme = useTheme();

    return <div css={getCardStyles(theme, { styles, fullWidth })} ref={ref} {...props}/>;
  }
);
