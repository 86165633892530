/** @jsxImportSource @emotion/react */
import { Stack } from "components/lib/layouts";
import { Button } from "components/lib/atoms";

import { SideMenuItemProps, SideMenuProps } from "./SideMenu.types";
import * as styles from "./SideMenu.styles";

export const SideMenu = ({ children }: SideMenuProps) => {
  return (
    <Stack direction="vertical" styles={styles.sideMenu()}>
      {children}
    </Stack>
  );
};

export const SideMenuItem = ({
  children,
  isSelected,
  onClick,
}: SideMenuItemProps) => {
  return (
    <Button
      variant="textdark"
      styles={
        isSelected ? styles.selectedSideMenuItem() : styles.sideMenuItem()
      }
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
