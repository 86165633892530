/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import { useHistory, useParams } from "react-router-dom";

import { Card, Heading, Spinner } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { PATH } from "components/shared/constants/paths.constants";
import { useUser } from "components/shared/hooks/useUser.hook";
import {
  OtherEligibility,
  PaymentsSection,
  ProfileDetailsSection,
  StandardsEndorsement,
  SubjectsEndorsement,
} from "components/shared/AccountDetails/sections";
import { AccountDetails } from "components/shared/AccountDetails/sections/ProfileDetailsSection/components/AccountDetails";
import { SideMenu, SideMenuItem } from "components/lib/molecules";

import { AccountParams } from "../../shared/AccountDetails/AccountPage.types";
import { ACCOUNT_SECTIONS } from "./constants";

function getAccountSectionId(index: number) {
  return ACCOUNT_SECTIONS[index].id;
}

function getAccountSectionLabel(index: number) {
  return ACCOUNT_SECTIONS[index].label;
}

export const AccountPage: React.FC = () => {
  const { sectionID }: AccountParams = useParams();
  const { data, isLoading, refetch } = useUser();
  const { push } = useHistory();
  const theme = useTheme();

  const getSection = () => {
    const basicAccountSectionProps = {
      user: data,
      refetchUser: refetch,
    };

    switch (sectionID) {
      // Profile
      case getAccountSectionId(0):
        return (
          <ProfileDetailsSection
            title={getAccountSectionLabel(0)}
            {...basicAccountSectionProps}
          >
            <AccountDetails />
          </ProfileDetailsSection>
        );

      // Subjects
      case getAccountSectionId(1):
        return (
          <SubjectsEndorsement
            title={getAccountSectionLabel(1)}
            {...basicAccountSectionProps}
          />
        );

      // Standards
      case getAccountSectionId(2):
        return (
          <StandardsEndorsement
            title={getAccountSectionLabel(2)}
            {...basicAccountSectionProps}
          />
        );

      // Other Eligibility
      case getAccountSectionId(3):
        return (
          <OtherEligibility
            title={getAccountSectionLabel(3)}
            {...basicAccountSectionProps}
          />
        );

      // Payments
      case getAccountSectionId(4):
        return (
          <PaymentsSection
            title={getAccountSectionLabel(4)}
            {...basicAccountSectionProps}
          />
        );

      // Payment Summary
      case getAccountSectionId(5):
        break;

      // Default to Profile
      default:
        return (
          <ProfileDetailsSection
            title={getAccountSectionLabel(0)}
            {...basicAccountSectionProps}
          >
            <AccountDetails />
          </ProfileDetailsSection>
        );
    }
  };

  return (
    <Stack direction="horizontal" gap={4} fullWidth={true}>
      <Stack direction="vertical" gap={4}>
        <Heading variant={1}>My account</Heading>
        <SideMenu>
          {ACCOUNT_SECTIONS.map(({ id, label }) => {
            const isSelectedSection = sectionID === id;

            return (
              <SideMenuItem
                key={id}
                isSelected={isSelectedSection}
                onClick={() => {
                  push(PATH.getAccountSection(id));
                }}
              >
                {label}
              </SideMenuItem>
            );
          })}
        </SideMenu>
      </Stack>
      <Card styles={{ flex: 1, padding: theme.space[5] }}>
        {isLoading ? <Spinner /> : getSection()}
      </Card>
    </Stack>
  );
};
