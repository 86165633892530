import {
  Configuration,
  ConfigurationParameters,
} from "components/utils/openapi";

export const AppConfig = () => {
  return {
    /**
     * Get configuration for fetch endpoint calls
     * @param token
     * @returns ConfigurationParameters
     */
    getConfiguration(token?: any): ConfigurationParameters {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return {
        basePath: String(process.env.REACT_APP_API_BASE_PATH),
        accessToken: token,
        username: undefined,
        apiKey: token,
        password: undefined,
        baseOptions: token ? options : undefined,
      } as Configuration;
    },
  };
};


export const getBaseOptions = (token: string | null | undefined) => {
  return {
    headers: {
      ...(token !== null ? { Authorization: `Bearer ${token}` } : {}),
      Accept: `application/json, text/plain, */*`,
    }
  }
};

export const getDefaultOptions = (token: string | null | undefined) => {
  const options = getBaseOptions(token);
  const defaultHeaders = {
    ...options.headers,
    "Content-Type": "application/json"
  }

  return {
    headers: defaultHeaders,
  };
};

export const getImageOptions = (token: string | null | undefined) => {
  return getBaseOptions(token);
};


export const getFileOptions = (token: string | null | undefined) => {
  return getBaseOptions(token);
};