/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import { VerticalDividerProps } from "./VerticalDivider.types";
import { getVerticalDividerStyles } from "./VerticalDivider.styles";

export const VerticalDivider = ({
  variant = "solid",
  ...props
}: VerticalDividerProps): JSX.Element => {
  const theme = useTheme();

  return <div css={getVerticalDividerStyles(theme, props)} {...props} />;
};
