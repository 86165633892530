import { useTheme } from "@emotion/react";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useToast } from "index";

import { Stack } from "components/lib/layouts/Stack/Stack";
import { Button, Divider, Heading, Text } from "components/lib/atoms";
import { fileExtensionByMimeTypeLookup } from "components/shared/constants/mimetypes";
import { PATH } from "components/shared/constants/paths.constants";
import {
  AttachmentResponseDto,
  RetrieveSubmissionByIdJobResponseDto,
  RetrieveSubmissionContentResponseDto,
} from "components/utils/openapi";
import { useQuery } from "components/utils/routing";

import { useSubmissionSubmit } from "../../hooks/useSumbissionSubmit.hook";
import { useUpdateSubmissionContent } from "../../hooks/useUpdateSubmissionContent.hook";
import { UploadFilePanel } from "../../components/UploadFile/UploadFile";
import { ResourceFilePanel } from "../../components/ResourceFile/ResourceFile";
import { FeedbackFields } from "../../components/FeedbackFields/FeedbackFields";
import { JobType } from "../../JobEditPage";
import { InputFormType } from "./ResourceJobSection.types";
import { useUploadAttachment } from "../../hooks/useUploadAttachment.hook";

type InputResources = {
  buildBundleAttachments: AttachmentResponseDto[];
  jobTypeAttachments: AttachmentResponseDto[];
  parentJobTypeAttachments: AttachmentResponseDto[];
  parentSubContentAttachments: AttachmentResponseDto[];
  grandParentJobTypeAttachments: AttachmentResponseDto[];
  grandParentSubContentAttachments: AttachmentResponseDto[];
};

export const InputsSection = ({
  submission,
  jobType,
  refetch,
}: {
  submission: RetrieveSubmissionByIdJobResponseDto;
  jobType: JobType;
  refetch: () => Promise<any>;
}) => {
  const theme = useTheme();
  const toast = useToast();
  const history = useHistory();
  const query = useQuery();
  const isReview = submission?.job?.jobType?.isReview;
  const isFixMode = query.get("mode") === "fix";
  const isFixReviewMode = isReview && isFixMode;
  // Used for Cleaning up when submitting a review job on a non-fix mode
  const { deleteAttachment } = useUploadAttachment();

  // Submission Handlers
  const { mutateAsync: updateSubmission, isLoading: isUpdateLoading } =
    useUpdateSubmissionContent();
  const { mutateAsync: submitSubmission, isLoading: isSubmitLoading } =
    useSubmissionSubmit();

  // Since Input Resources only has 1 Submission Content, only use that specific one
  const [currentSubmissionContent, setCurrentSubmissionContent] =
    useState<RetrieveSubmissionContentResponseDto>(
      submission?.submissionContents[0]
    );

  // Feedback Form
  const {
    register,
    formState,
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
  } = useForm<InputFormType>({
    mode: "onChange",
    defaultValues: {
      initialSubmissionContentId:
        currentSubmissionContent?.content?.initialSubmissionContentId,
      approved: currentSubmissionContent?.content?.approved,
      isFixable: true,
      errorTypes: [],
      feedback: "",
    },
  });
  const isFixable = watch("isFixable");

  const [downloadedFiles, setDownloadedFiles] = useState({});
  const inputResources: InputResources = useMemo(() => {
    let initialInputResources: InputResources = {
      buildBundleAttachments: submission?.buildBundle?.attachments ?? [],
      jobTypeAttachments: submission?.job?.jobType?.attachments ?? [],
      parentJobTypeAttachments: [],
      parentSubContentAttachments: [],
      grandParentJobTypeAttachments: [],
      grandParentSubContentAttachments: [],
    };

    submission?.inputSubmissions?.forEach((inputSubmission) => {
      if (isFixReviewMode) {
        // Get the Initial Parent Job Type Attachments
        initialInputResources.parentJobTypeAttachments =
          initialInputResources?.parentJobTypeAttachments?.concat(
            inputSubmission?.jobType?.attachments
          );
      }

      // Get the Initial Parent Submission Content Attachments
      inputSubmission?.submissionContents?.forEach((inputSubmissionContent) => {
        initialInputResources.parentSubContentAttachments =
          initialInputResources?.parentSubContentAttachments?.concat(
            inputSubmissionContent?.attachments
          );
      });
    });

    // Grandparent Data is only used on Job Types that are in Review
    if (isFixReviewMode) {
      // Getting Grandparent Submission Data
      submission?.inputSubmissions?.forEach((inputSubmission) => {
        inputSubmission?.inputSubmissions?.forEach(
          (grandParentInputSubmission) => {
            // Get the Initial Grandparent Job Type Attachments
            initialInputResources.grandParentJobTypeAttachments =
              initialInputResources?.grandParentJobTypeAttachments?.concat(
                grandParentInputSubmission?.jobType?.attachments
              );

            // Get the Initial Grandparent Submission Content Attachments
            grandParentInputSubmission?.submissionContents?.forEach(
              (initGPSubmmissionContent) => {
                initialInputResources.grandParentSubContentAttachments =
                  initialInputResources?.grandParentSubContentAttachments?.concat(
                    initGPSubmmissionContent?.attachments
                  );
              }
            );
          }
        );
      });
    }

    return initialInputResources;
  }, [submission, isFixReviewMode]);

  const handleDownloadedFile = (fileId: string) => {
    setDownloadedFiles(prev => {
      return {
        ...prev,
        [fileId]: true
      }
    })
  }

  const allFilesDownloaded = Object.keys(downloadedFiles).length === inputResources?.parentSubContentAttachments.length;

  /**
   * Handles Submission of the job (Only has one submission content)
   */
  const handleSaveAndSubmitJob = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // Disable button to prevent double clicks
    if (e.currentTarget) e.currentTarget.disabled = true;

    const toastId = toast.addToast({
      variant: "info",
      description: "Submitting Job...",
    });

    try {
      // Attachment Validation
      if (
        !submission?.job?.jobType?.isReview &&
        currentSubmissionContent?.attachments?.length === 0
      ) {
        throw new Error("Please Upload an Attachment");
      }

      // Submission Validation
      if (!submission?.id || !currentSubmissionContent?.id) {
        throw new Error("Submission or Contents not Found");
      }

      // Remove Attachments when submitted a review input job on a non-fix mode
      if (
        isReview &&
        !isFixMode &&
        currentSubmissionContent?.attachments?.length > 0
      ) {
        currentSubmissionContent?.attachments?.forEach(async (attachment) => {
          await deleteAttachment(
            submission?.id,
            currentSubmissionContent?.id,
            attachment?.id
          );
        });
      }

      // Update Submission Contents if in job type is review (Not required on Input Creation Jobs)
      if (isReview) {
        await updateSubmission({
          body: { ...getValues(), approved: true },
          submissionContentId: currentSubmissionContent?.id,
          submissionId: submission?.id,
        });
      }

      // Submit Job after Uploading the Attachment (Attachment is Required for Submission)
      await submitSubmission({ submissionId: submission?.id }).then(() => {
        history.push({
          pathname: PATH.completed,
        });
      });
    } catch (err: any) {
      // Enable button for resubmit (if in case toastProvider doesn't re-render button)
      if (e.currentTarget) e.currentTarget.disabled = false;

      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message || "Failed to submit Job. Please try again later",
      });
    }
  };

  let submitButtonDisabled = false;

  if (isUpdateLoading ||isSubmitLoading ||submission?.job?.jobType?.isReview) {
    if (isReview && !isFixMode) {
      submitButtonDisabled = !allFilesDownloaded;
    } else {
      submitButtonDisabled = isFixable && currentSubmissionContent?.attachments?.length === 0;
    }
  } else {
    submitButtonDisabled = currentSubmissionContent?.attachments?.length === 0
  }

  if (toast.isToasting) {
    submitButtonDisabled = true;
  }

  return (
    <form
      id={"profileDetailsForm"}
      onSubmit={handleSubmit(handleSaveAndSubmitJob)}
    >
      <Stack fullWidth={true} gap={6} direction="vertical">
        {isReview && (
          // Files to Review Section
          <Stack direction="vertical" gap={3}>
            <Heading variant={2}>
              {submission?.job?.jobType.description ?? "Your Job"}
            </Heading>
            <Text>Files to review - you must download these to approve the job!</Text>
            {inputResources?.parentSubContentAttachments?.map(
              (inputResource: AttachmentResponseDto) => {
                return (
                  <a
                    key={inputResource?.id}
                    target="_blank"
                    href={inputResource?.url}
                    rel="noreferrer"
                    onAuxClick={() => handleDownloadedFile(inputResource?.id)}
                    onContextMenu={() => handleDownloadedFile(inputResource?.id)}
                  >
                    <Button type="button" variant="secondary" onClick={() => handleDownloadedFile(inputResource?.id)}>
                      Download
                      {inputResource?.mimeType &&
                      fileExtensionByMimeTypeLookup[inputResource?.mimeType]
                        ? ` .${
                            fileExtensionByMimeTypeLookup[
                              inputResource?.mimeType
                            ]
                          }`
                        : ""}
                    </Button>
                  </a>
                );
              }
            )}
          </Stack>
        )}
        {/* Input Resources Section */}
        <Stack direction="horizontal" fullWidth={true}>
          <Stack fullWidth={true} direction="vertical" gap={2}>
            <Heading variant={2}>
              {submission?.job?.jobType?.isReview
                ? "Resource to fix"
                : submission?.job?.jobType.description ?? "Your Job"}
            </Heading>
            {inputResources && (
              <ResourceFilePanel
                submission={submission}
                inputResources={inputResources}
              />
            )}
          </Stack>
        </Stack>
        {(!isReview || isFixReviewMode) && (
          <>
            {/* Upload Resource Section */}
            <Stack fullWidth={true} direction="vertical" gap={4}>
              <Heading variant={2}>Your file</Heading>
              {currentSubmissionContent && (
                <UploadFilePanel
                  submission={submission}
                  currentSubmissionContent={currentSubmissionContent}
                  isOptional={submission?.job?.jobType?.isReview}
                  refetch={refetch}
                  setCurrentSubmissionContent={setCurrentSubmissionContent}
                />
              )}
            </Stack>
          </>
        )}
        {/* Feedback Fields Form Section */}
        {isReview && isFixReviewMode && (
          <>
            <Divider
              styles={{
                marginTop: theme.space[5],
                marginBottom: theme.space[2],
              }}
            />
            <FeedbackFields
              control={control}
              watch={watch}
              formState={formState}
            />
          </>
        )}

        <Stack
          styles={{
            padding: theme.space[2],
            justifyContent: "flex-end",
            position: "fixed",
            bottom: 0,
            left: 0,
            rigth: 0,
            width: "100%",
            border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
            background: theme.colors.white.default,
          }}
          gap={3}
        >
          {/* Buttons Section - Fix Job Button */}
          {isReview && !isFixReviewMode ? (
            <Button
              type="button"
              variant="secondary"
              disabled={isFixReviewMode}
              onClick={() => {
                history.push({
                  search: "?mode=fix",
                });
              }}
            >
              Fix job
            </Button>
          ) : (
            isReview && (
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  reset();
                  history.push({
                    search: "",
                  });
                }}
              >
                Back to review
              </Button>
            )
          )}
          {/* Buttons Section - Approve / Submit Job */}
          <Button
            type="submit"
            disabled={submitButtonDisabled}
          >
            {isReview && !isFixReviewMode
              ? "Approve Job"
              : "Save and Submit job"}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
