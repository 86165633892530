import { CSSObject, Theme } from "@emotion/react";
import { getStyles, theme } from "components/lib/utils";
import { RichPreviewProps } from "./RichPreview.types";

export const getContainer = (
  _theme: Theme,
  { styles }: RichPreviewProps
): CSSObject => {
  const base = {
    flex: 1,
    fontWeight: theme.fontWeights.light,
    lineHeight: "150%",
    ">p": {
      margin: 0,
    },
  };

  return getStyles({
    base,
    styles,
  });
};
