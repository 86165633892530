import { useParams } from "react-router-dom";

import { PATH } from "components/shared/constants/paths.constants";
import { Card } from "components/lib/atoms/Card/Card";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { AppLink } from "components/shared/AppLink/AppLink";
import { Container } from "components/lib/atoms/Container/Container";
import { Spinner } from "components/lib/atoms/Spinner/Spinner";
import { JobDetailsNew } from "components/shared";

import { useActivityBuildJob } from "./hooks/useActivityBuildJob.hook";
import { ReactComponent as LeftChevronIcon } from "./svgs/chevronleft.svg";
import { jobCard } from "./JobPage.styles";

export const JobPage = () => {
  const { activityBuildJobId } = useParams<{ activityBuildJobId: string }>();
  const { data, isLoading } = useActivityBuildJob(activityBuildJobId);

  return (
    <Stack direction="vertical" gap={4} fullWidth={true}>
      {isLoading && !data ? (
        <Spinner />
      ) : (
        <>
          {data && (
            <Card fullWidth={true} styles={jobCard}>
              <JobDetailsNew activityBuildJob={data} />
            </Card>
          )}
          <AppLink path={PATH.browseJobs}>
            <Stack gap={2} align="center">
              <Container>
                <LeftChevronIcon />
              </Container>
              <Container>Browse Jobs</Container>
            </Stack>
          </AppLink>
        </>
      )}
    </Stack>
  );
};
