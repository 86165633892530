import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { ParagraphProps } from "./Paragraph.types";

import { getStyles } from "../../utils";

export const getParagraphStyles = (
  theme: Theme,
  { styles, fullWidth }: Partial<ParagraphProps>
) => {
  const base: CSSObject = {
    fontFamily: theme.fonts.primary,
    lineHeight: theme.fontSizes[5],
    width: fullWidth ? "100%" : "auto",
    fontWeight: theme.fontWeights.light,
    letterSpacing: "1px",
  };

  return getStyles({ base, styles });
};
