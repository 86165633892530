/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";

import {
  getTableStyles,
  getTableRowStyles,
  getTableDataStyles,
  getTableHeadingStyles,
} from "./Table.styles";

import { TableProps, TableRowProps } from "./Table.types";

export const Table: React.FC<TableProps> = ({ children, ...props }) => {
  const tableStyles = getTableStyles(props);

  return <table css={tableStyles}>{children}</table>;
};

export const TableRow: React.FC<TableRowProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const tableRowStyles = getTableRowStyles(theme, props);

  return <tr css={tableRowStyles}>{children}</tr>;
};

export const TableData: React.FC<TableProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const tableDataStyles = getTableDataStyles(theme, props);

  return <td css={tableDataStyles}>{children}</td>;
};

export const TableHeading: React.FC<TableProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return <th css={getTableHeadingStyles(theme, props)}> {children}</th>;
};

export const TableBody: React.FC = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export const TableHead: React.FC<TableProps> = ({ children, ...props }) => {
  return <thead>{children}</thead>;
};

