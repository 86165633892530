import { CSSObject } from "@emotion/serialize";
import { keyframes, Theme } from "@emotion/react";
import { SpinnerProps } from "./Spinner.types";
import { getStyles } from "../../utils";

const spin = keyframes`
  to { -webkit-transform: rotate(360deg); }
`;

export const getSpinnerWrapperStyles = (
  theme: Theme,
  { styles }: SpinnerProps
) => {
  const base: CSSObject = {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    border: `${theme.lineThickness[2]} solid ${theme.colors.purple.light}`,
    display: "inline-block",
    borderTopColor: theme.colors.purple.default,
    animation: `${spin} 400ms ease-in-out infinite`,
    margin: "auto",
  };

  return getStyles({ base, styles });
};

export const getSpinnerContainerStyles = (_theme: Theme) => {
  const base: CSSObject = {
    padding: "12px",
  };

  return getStyles({ base });
};
