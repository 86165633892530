import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { CardProps } from "./Card.types";
import { getStyles } from "../../utils";

export const getCardStyles = (
  theme: Theme,
  { styles, fullWidth }: Partial<CardProps>
) => {
  const base: CSSObject = {
    height: "fit-content",
    padding: `${theme.space[5]} ${theme.space[5]}`,
    borderRadius: theme.radii[3],
    outline: 0,
    textAlign: "unset",
    boxSizing: "border-box",
    background: theme.colors.white.default,
    border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
    ":focus": {
      outline: "none",
      border: 0,
    },
    ...(fullWidth ? { width: "100%" } : {}),
  };

  return getStyles({
    base,
    styles,
  });
};
