import { useContext } from "react";
import { useForm } from "react-hook-form";

import { ToastContext } from "index";

import { Button } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { useUpdateUserDetails } from "components/shared/hooks/useUpdateUserDetails.hook";
import { AccountDetailsFormSection } from "../../common";

import { AccountPageSection } from "../../AccountPage.types";
import { CurrentOccupation } from "./components/CurrentOccupation";
import { UserNames } from "./components/UserNames";
import { FormType } from "./ProfileDetails.types";
import { LocationAndContactInformation } from "./components/LocationAndContactInformation";

export const ProfileDetailsSection: React.FC<AccountPageSection> = ({
  title,
  user,
  refetchUser,
  onNext,
  onBack,
  children,
}) => {
  const toast = useContext(ToastContext);
  const {
    register,
    formState,
    handleSubmit,
    control,
    watch,
  } = useForm<FormType>({
    defaultValues: {
      firstName: user?.details?.firstName,
      lastName: user?.details?.lastName,
      occupation: user?.details?.occupation,
      city: user?.details?.city,
      postCode: user?.details?.postCode,
      mobileNumber: user?.details?.mobileNumber,
    },
  });
  const { mutateAsync: updateUserDetails } = useUpdateUserDetails();

  async function handlePersonalDetailsSubmit(values: FormType) {
    // Save details and on success call next
    const toastId = toast.addToast({
      variant: "info",
      description: "Saving user details...",
    });
    try {
      await updateUserDetails({
        ...values,
        mobileNumber: values?.mobileNumber?.trim(),
      });
      await refetchUser();
      if (onNext) onNext();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "User details saved successfully",
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message || "Failed to save user details. Please try again later",
      });
    }
  }

  return (
    <form
      id={"profileDetailsForm"}
      onSubmit={handleSubmit(handlePersonalDetailsSubmit)}
    >
      <AccountDetailsFormSection
        title={title}
        backButton={onBack && <Button onClick={onBack}>Back</Button>}
        actionButton={
          <Button type="submit">{onNext ? "Next" : "Update"}</Button>
        }
      >
        <Stack direction="vertical" gap={6}>
          <Stack direction="vertical" gap={2}>
            <UserNames
              control={control}
              formState={formState}
              register={register}
              watch={watch}
            />
            <CurrentOccupation
              control={control}
              formState={formState}
              register={register}
              watch={watch}
            />
            <LocationAndContactInformation
              control={control}
              formState={formState}
              register={register}
              watch={watch}
            />
          </Stack>
          {children}
        </Stack>
      </AccountDetailsFormSection>
    </form>
  );
};
