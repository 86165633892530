/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * LearnCoach Services
 * These services encapsulate all resources needed for Learn Coach. More details [here](https://twd-learncoach.atlassian.net/wiki/spaces/LEARNCOACH/pages/2785480/Services)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";
url.URLSearchParams = URLSearchParams; // https://github.com/swagger-api/swagger-codegen/issues/6403
const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration = {};

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ActivityBuildDto
 */
export interface ActivityBuildDto {
    /**
     * Id of the created Activity Build
     * @type {string}
     * @memberof ActivityBuildDto
     */
    id: string;
    /**
     * Name of the created Activity Build
     * @type {string}
     * @memberof ActivityBuildDto
     */
    name: string;
    /**
     * Video URL
     * @type {string}
     * @memberof ActivityBuildDto
     */
    videoLink: string;
}
/**
 * 
 * @export
 * @interface ActivityBuildJobBuildBundleResponse
 */
export interface ActivityBuildJobBuildBundleResponse {
    /**
     * Id of the Build Bundle
     * @type {string}
     * @memberof ActivityBuildJobBuildBundleResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityBuildJobBuildBundleResponse
     */
    subjectId: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityBuildJobBuildBundleResponse
     */
    levelId: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityBuildJobBuildBundleResponse
     */
    nceaCode: string;
}
/**
 * 
 * @export
 * @interface ActivityBuildJobJobResponseDto
 */
export interface ActivityBuildJobJobResponseDto {
    /**
     * Id of the Job
     * @type {string}
     * @memberof ActivityBuildJobJobResponseDto
     */
    id: string;
    /**
     * Timestamp at which this Job was created
     * @type {Date}
     * @memberof ActivityBuildJobJobResponseDto
     */
    createdAt: Date;
    /**
     * The Job Type Object
     * @type {JobTypesDto}
     * @memberof ActivityBuildJobJobResponseDto
     */
    jobType: JobTypesDto;
    /**
     * Payout Rate
     * @type {number}
     * @memberof ActivityBuildJobJobResponseDto
     */
    payoutRate: number;
    /**
     * Job duration in hours
     * @type {number}
     * @memberof ActivityBuildJobJobResponseDto
     */
    jobDuration: number;
}
/**
 * 
 * @export
 * @interface ActivityBuildJobsResponseDto
 */
export interface ActivityBuildJobsResponseDto {
    /**
     * Id of the Activity Build Job
     * @type {string}
     * @memberof ActivityBuildJobsResponseDto
     */
    id: string;
    /**
     * Timestamp at which this Activity Build Job was created
     * @type {Date}
     * @memberof ActivityBuildJobsResponseDto
     */
    createdAt: Date;
    /**
     * The job for the Activity Build Job.
     * @type {ActivityBuildJobJobResponseDto}
     * @memberof ActivityBuildJobsResponseDto
     */
    job: ActivityBuildJobJobResponseDto;
    /**
     * The activity build for the Activity Build Job.
     * @type {ActivityBuildDto}
     * @memberof ActivityBuildJobsResponseDto
     */
    activityBuild: ActivityBuildDto;
    /**
     * The activity build for the Activity Build Job.
     * @type {ActivityBuildJobBuildBundleResponse}
     * @memberof ActivityBuildJobsResponseDto
     */
    buildBundle: ActivityBuildJobBuildBundleResponse;
}
/**
 * 
 * @export
 * @interface ActivityTemplateGetDto
 */
export interface ActivityTemplateGetDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityTemplateGetDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTemplateGetDto
     */
    name: string;
    /**
     * 
     * @type {Array<JobTypeDto>}
     * @memberof ActivityTemplateGetDto
     */
    jobTypes: Array<JobTypeDto>;
}
/**
 * 
 * @export
 * @interface AttachmentResponseDto
 */
export interface AttachmentResponseDto {
    /**
     * Id of the Attachment
     * @type {string}
     * @memberof AttachmentResponseDto
     */
    id: string;
    /**
     * Presigned URL for your object attachment
     * @type {string}
     * @memberof AttachmentResponseDto
     */
    url: string;
    /**
     * Mime Type of the Attachment
     * @type {string}
     * @memberof AttachmentResponseDto
     */
    mimeType: string;
    /**
     * Name of the Attachment
     * @type {string}
     * @memberof AttachmentResponseDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AttachmentsAttachmentIdBody
 */
export interface AttachmentsAttachmentIdBody {
    /**
     * 
     * @type {Blob}
     * @memberof AttachmentsAttachmentIdBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface AttachmentsAttachmentIdBody1
 */
export interface AttachmentsAttachmentIdBody1 {
    /**
     * 
     * @type {Blob}
     * @memberof AttachmentsAttachmentIdBody1
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface BuildBundleActivityBuildsResponseDto
 */
export interface BuildBundleActivityBuildsResponseDto {
    /**
     * Id of the created Activity Build
     * @type {string}
     * @memberof BuildBundleActivityBuildsResponseDto
     */
    id: string;
    /**
     * Activity Build is published or not
     * @type {boolean}
     * @memberof BuildBundleActivityBuildsResponseDto
     */
    published: boolean;
    /**
     * Activity Build is completed or not
     * @type {boolean}
     * @memberof BuildBundleActivityBuildsResponseDto
     */
    completed: boolean;
}
/**
 * 
 * @export
 * @interface BuildBundleJobResponseDto
 */
export interface BuildBundleJobResponseDto {
    /**
     * Id of the Build Bundle
     * @type {string}
     * @memberof BuildBundleJobResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BuildBundleJobResponseDto
     */
    nceaCode: string;
    /**
     * Attachments of the Build Bundle
     * @type {Array<AttachmentResponseDto>}
     * @memberof BuildBundleJobResponseDto
     */
    attachments: Array<AttachmentResponseDto>;
}
/**
 * 
 * @export
 * @interface BuildBundleJobsResponseDto
 */
export interface BuildBundleJobsResponseDto {
    /**
     * Id of the Job
     * @type {string}
     * @memberof BuildBundleJobsResponseDto
     */
    id: string;
    /**
     * Id of the Job type
     * @type {string}
     * @memberof BuildBundleJobsResponseDto
     */
    jobTypeId: string;
    /**
     * Payout Rate
     * @type {number}
     * @memberof BuildBundleJobsResponseDto
     */
    payoutRate: number;
    /**
     * Duration of Job in hours
     * @type {number}
     * @memberof BuildBundleJobsResponseDto
     */
    jobDuration: number;
}
/**
 * 
 * @export
 * @interface BuildBundleWithNoAttachments
 */
export interface BuildBundleWithNoAttachments {
    /**
     * Id of the Build Bundle
     * @type {string}
     * @memberof BuildBundleWithNoAttachments
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BuildBundleWithNoAttachments
     */
    nceaCode: string;
}
/**
 * 
 * @export
 * @interface CompletedSubmissionsDto
 */
export interface CompletedSubmissionsDto {
    /**
     * Id of the completed submission entity.
     * @type {string}
     * @memberof CompletedSubmissionsDto
     */
    id: string;
    /**
     * User Id that initiated the submission.
     * @type {string}
     * @memberof CompletedSubmissionsDto
     */
    userId: string;
    /**
     * Id of the Submission
     * @type {string}
     * @memberof CompletedSubmissionsDto
     */
    submissionId: string;
    /**
     * The name of the activity build.
     * @type {string}
     * @memberof CompletedSubmissionsDto
     */
    activityBuildName: string;
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof CompletedSubmissionsDto
     */
    jobTypeCode: string;
    /**
     * Name of Job type
     * @type {string}
     * @memberof CompletedSubmissionsDto
     */
    jobTypeName: string;
    /**
     * Is the Completed Submission job type a review
     * @type {boolean}
     * @memberof CompletedSubmissionsDto
     */
    jobTypeIsReview: boolean;
    /**
     * Timestamp at which this Completed Submission was created
     * @type {Date}
     * @memberof CompletedSubmissionsDto
     */
    createdAt: Date;
    /**
     * Number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof CompletedSubmissionsDto
     */
    contentNumber: number;
    /**
     * The accepted number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof CompletedSubmissionsDto
     */
    acceptedContentNumber: number;
    /**
     * Payout Rate from the Job
     * @type {number}
     * @memberof CompletedSubmissionsDto
     */
    payoutRate: number;
    /**
     * Total amount earned from the completed submission
     * @type {number}
     * @memberof CompletedSubmissionsDto
     */
    amountEarned: number;
    /**
     * Content in JSON format.
     * @type {any}
     * @memberof CompletedSubmissionsDto
     */
    additionalContent: any;
    /**
     * The status of the submission.
     * @type {string}
     * @memberof CompletedSubmissionsDto
     */
    status: string;
}
/**
 * 
 * @export
 * @interface CreateBuildBundlesDto
 */
export interface CreateBuildBundlesDto {
    /**
     * Id of the created Build Bundle
     * @type {string}
     * @memberof CreateBuildBundlesDto
     */
    id: string;
    /**
     * Build Bundle completion status
     * @type {boolean}
     * @memberof CreateBuildBundlesDto
     */
    completed: boolean;
    /**
     * Build Bundle archive status
     * @type {boolean}
     * @memberof CreateBuildBundlesDto
     */
    archived: boolean;
    /**
     * Build Bundle cancel status
     * @type {boolean}
     * @memberof CreateBuildBundlesDto
     */
    cancelled: boolean;
    /**
     * Timestamp at which this Build Bundle was created
     * @type {Date}
     * @memberof CreateBuildBundlesDto
     */
    creationTime: Date;
    /**
     * Id of Activity Template
     * @type {string}
     * @memberof CreateBuildBundlesDto
     */
    activityTemplateId: string;
    /**
     * NCEA Code of standard
     * @type {string}
     * @memberof CreateBuildBundlesDto
     */
    nceaCode: string;
    /**
     * ID of the Curriculum Level Row from the Curriculum DB
     * @type {string}
     * @memberof CreateBuildBundlesDto
     */
    levelId: string;
    /**
     * ID of the Curriculum Subject Row from the Curriculum DB
     * @type {string}
     * @memberof CreateBuildBundlesDto
     */
    subjectId: string;
    /**
     * 
     * @type {Array<CreateJobDto>}
     * @memberof CreateBuildBundlesDto
     */
    jobs: Array<CreateJobDto>;
    /**
     * Number of Activity Builds
     * @type {number}
     * @memberof CreateBuildBundlesDto
     */
    numberOfActivityBuilds: number;
}
/**
 * 
 * @export
 * @interface CreateBuildBundlesResponseDto
 */
export interface CreateBuildBundlesResponseDto {
    /**
     * Id of the created Build Bundle
     * @type {string}
     * @memberof CreateBuildBundlesResponseDto
     */
    id: string;
    /**
     * Build Bundle completion status
     * @type {boolean}
     * @memberof CreateBuildBundlesResponseDto
     */
    completed: boolean;
    /**
     * Build Bundle archive status
     * @type {boolean}
     * @memberof CreateBuildBundlesResponseDto
     */
    archived: boolean;
    /**
     * Build Bundle cancel status
     * @type {boolean}
     * @memberof CreateBuildBundlesResponseDto
     */
    cancelled: boolean;
    /**
     * Timestamp at which this Build Bundle was created
     * @type {Date}
     * @memberof CreateBuildBundlesResponseDto
     */
    creationTime: Date;
    /**
     * Id of Activity Template
     * @type {string}
     * @memberof CreateBuildBundlesResponseDto
     */
    activityTemplateId: string;
    /**
     * NCEA Code of standard
     * @type {string}
     * @memberof CreateBuildBundlesResponseDto
     */
    nceaCode: string;
    /**
     * ID of the Curriculum Level Row from the Curriculum DB
     * @type {string}
     * @memberof CreateBuildBundlesResponseDto
     */
    levelId: string;
    /**
     * ID of the Curriculum Subject Row from the Curriculum DB
     * @type {string}
     * @memberof CreateBuildBundlesResponseDto
     */
    subjectId: string;
    /**
     * 
     * @type {Array<BuildBundleJobsResponseDto>}
     * @memberof CreateBuildBundlesResponseDto
     */
    jobs: Array<BuildBundleJobsResponseDto>;
    /**
     * 
     * @type {Array<BuildBundleActivityBuildsResponseDto>}
     * @memberof CreateBuildBundlesResponseDto
     */
    activityBuilds: Array<BuildBundleActivityBuildsResponseDto>;
    /**
     * Attachments of the Build Bundle
     * @type {Array<AttachmentResponseDto>}
     * @memberof CreateBuildBundlesResponseDto
     */
    attachments: Array<AttachmentResponseDto>;
}
/**
 * 
 * @export
 * @interface CreateJobDto
 */
export interface CreateJobDto {
    /**
     * Id of the Job type
     * @type {string}
     * @memberof CreateJobDto
     */
    jobTypeId: string;
    /**
     * Payout Rate
     * @type {number}
     * @memberof CreateJobDto
     */
    payoutRate: number;
    /**
     * Duration of the Job in hours
     * @type {number}
     * @memberof CreateJobDto
     */
    jobDuration: number;
}
/**
 * 
 * @export
 * @interface CreateSubmissionContentResponseDto
 */
export interface CreateSubmissionContentResponseDto {
    /**
     * Submission-Content Id
     * @type {string}
     * @memberof CreateSubmissionContentResponseDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateSubmissionRequestDto
 */
export interface CreateSubmissionRequestDto {
    /**
     * Id of the Job
     * @type {string}
     * @memberof CreateSubmissionRequestDto
     */
    jobId?: string;
    /**
     * Id of the Activity Build Job
     * @type {string}
     * @memberof CreateSubmissionRequestDto
     */
    activityBuildJobId?: string;
}
/**
 * 
 * @export
 * @interface CreateSubmissionResponseDto
 */
export interface CreateSubmissionResponseDto {
    /**
     * Id of the Submission
     * @type {string}
     * @memberof CreateSubmissionResponseDto
     */
    id: string;
    /**
     * Activity Build object of the Submission
     * @type {UpdateActivityBuildDto}
     * @memberof CreateSubmissionResponseDto
     */
    activityBuild: UpdateActivityBuildDto;
    /**
     * Timestamp at which this Submission was created
     * @type {Date}
     * @memberof CreateSubmissionResponseDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Submission expires
     * @type {Date}
     * @memberof CreateSubmissionResponseDto
     */
    expiryTime: Date;
    /**
     * Input Submissions from the Parent Activity Build Job
     * @type {Array<InputSubmissionResponseDto>}
     * @memberof CreateSubmissionResponseDto
     */
    inputSubmissions?: Array<InputSubmissionResponseDto>;
    /**
     * Job object of the Submission
     * @type {JobWithNoJobTypeAttachments}
     * @memberof CreateSubmissionResponseDto
     */
    job: JobWithNoJobTypeAttachments;
    /**
     * Build Bundle object of the Submission
     * @type {BuildBundleWithNoAttachments}
     * @memberof CreateSubmissionResponseDto
     */
    buildBundle: BuildBundleWithNoAttachments;
}
/**
 * 
 * @export
 * @interface CurriculumGetDto
 */
export interface CurriculumGetDto {
    /**
     * 
     * @type {string}
     * @memberof CurriculumGetDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumGetDto
     */
    name: string;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof CurriculumGetDto
     */
    subjects: Array<Subject>;
}
/**
 * 
 * @export
 * @interface GetBuildBundlesResponseDto
 */
export interface GetBuildBundlesResponseDto {
    /**
     * Id of the created Build Bundle
     * @type {string}
     * @memberof GetBuildBundlesResponseDto
     */
    id: string;
    /**
     * Build Bundle completion status
     * @type {boolean}
     * @memberof GetBuildBundlesResponseDto
     */
    completed: boolean;
    /**
     * Build Bundle archive status
     * @type {boolean}
     * @memberof GetBuildBundlesResponseDto
     */
    archived: boolean;
    /**
     * Build Bundle cancel status
     * @type {boolean}
     * @memberof GetBuildBundlesResponseDto
     */
    cancelled: boolean;
    /**
     * Timestamp at which this Build Bundle was created
     * @type {Date}
     * @memberof GetBuildBundlesResponseDto
     */
    creationTime: Date;
    /**
     * Id of Activity Template
     * @type {string}
     * @memberof GetBuildBundlesResponseDto
     */
    activityTemplateId: string;
    /**
     * NCEA Code of standard
     * @type {string}
     * @memberof GetBuildBundlesResponseDto
     */
    nceaCode: string;
    /**
     * ID of the Curriculum Level Row from the Curriculum DB
     * @type {string}
     * @memberof GetBuildBundlesResponseDto
     */
    levelId: string;
    /**
     * ID of the Curriculum Subject Row from the Curriculum DB
     * @type {string}
     * @memberof GetBuildBundlesResponseDto
     */
    subjectId: string;
    /**
     * 
     * @type {Array<BuildBundleJobsResponseDto>}
     * @memberof GetBuildBundlesResponseDto
     */
    jobs: Array<BuildBundleJobsResponseDto>;
    /**
     * 
     * @type {Array<BuildBundleActivityBuildsResponseDto>}
     * @memberof GetBuildBundlesResponseDto
     */
    activityBuilds: Array<BuildBundleActivityBuildsResponseDto>;
}
/**
 * 
 * @export
 * @interface GetByIdJobResponseDto
 */
export interface GetByIdJobResponseDto {
    /**
     * Id of job
     * @type {string}
     * @memberof GetByIdJobResponseDto
     */
    id: string;
    /**
     * 
     * @type {JobTypeDto}
     * @memberof GetByIdJobResponseDto
     */
    jobType: JobTypeDto;
    /**
     * Payout Rate from Build Bundles
     * @type {number}
     * @memberof GetByIdJobResponseDto
     */
    payoutRate: number;
    /**
     * Ncea code from build bundles
     * @type {string}
     * @memberof GetByIdJobResponseDto
     */
    nceaCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetByIdJobResponseDto
     */
    completed: boolean;
    /**
     * Job duration from build bundles
     * @type {number}
     * @memberof GetByIdJobResponseDto
     */
    jobDuration: number;
    /**
     * Number of activity builds with no submission id
     * @type {number}
     * @memberof GetByIdJobResponseDto
     */
    activityBuilds: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetByIdJobResponseDto
     */
    accepted: boolean;
    /**
     * Id of active submission
     * @type {string}
     * @memberof GetByIdJobResponseDto
     */
    activeSubmissionId: string;
}
/**
 * 
 * @export
 * @interface GetStripeLinkResponseDto
 */
export interface GetStripeLinkResponseDto {
    /**
     * Timestamp at which this URL was created
     * @type {Date}
     * @memberof GetStripeLinkResponseDto
     */
    creationTime: Date;
    /**
     * Login Link URL for Stripe's Connect Express
     * @type {string}
     * @memberof GetStripeLinkResponseDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface IdAttachmentsBody
 */
export interface IdAttachmentsBody {
    /**
     * 
     * @type {Blob}
     * @memberof IdAttachmentsBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface IdAttachmentsBody1
 */
export interface IdAttachmentsBody1 {
    /**
     * 
     * @type {Blob}
     * @memberof IdAttachmentsBody1
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface InputSubmissionJobTypeResponseDto
 */
export interface InputSubmissionJobTypeResponseDto {
    /**
     * Id of the Job Type
     * @type {string}
     * @memberof InputSubmissionJobTypeResponseDto
     */
    id: string;
    /**
     * Name of the Job Type
     * @type {string}
     * @memberof InputSubmissionJobTypeResponseDto
     */
    name: string;
    /**
     * Group Name or Code of the Job Type
     * @type {string}
     * @memberof InputSubmissionJobTypeResponseDto
     */
    groupName: string;
    /**
     * Is the Job Type a Review Type
     * @type {boolean}
     * @memberof InputSubmissionJobTypeResponseDto
     */
    isReview: boolean;
    /**
     * Attachments of the Job Type
     * @type {Array<AttachmentResponseDto>}
     * @memberof InputSubmissionJobTypeResponseDto
     */
    attachments: Array<AttachmentResponseDto>;
}
/**
 * 
 * @export
 * @interface InputSubmissionResponseDto
 */
export interface InputSubmissionResponseDto {
    /**
     * Id of the Submission
     * @type {string}
     * @memberof InputSubmissionResponseDto
     */
    id: string;
    /**
     * The Job Type Object
     * @type {InputSubmissionJobTypeResponseDto}
     * @memberof InputSubmissionResponseDto
     */
    jobType: InputSubmissionJobTypeResponseDto;
    /**
     * Id of the related input Activity Build Job
     * @type {string}
     * @memberof InputSubmissionResponseDto
     */
    activityBuildJobId: string;
    /**
     * The submission contents.
     * @type {Array<RetrieveSubmissionContentResponseDto>}
     * @memberof InputSubmissionResponseDto
     */
    submissionContents: Array<RetrieveSubmissionContentResponseDto>;
    /**
     * Grandparent of the Submission / Parent of the Submission
     * @type {Array<InputSubmissionResponseDto>}
     * @memberof InputSubmissionResponseDto
     */
    inputSubmissions?: Array<InputSubmissionResponseDto>;
    /**
     * Timestamp at which this Submission was created
     * @type {Date}
     * @memberof InputSubmissionResponseDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Submission expires
     * @type {Date}
     * @memberof InputSubmissionResponseDto
     */
    expiryTime: Date;
}
/**
 * 
 * @export
 * @interface JobTypeDto
 */
export interface JobTypeDto {
    /**
     * Id of the Job type
     * @type {string}
     * @memberof JobTypeDto
     */
    id: string;
    /**
     * Name of the Job type
     * @type {string}
     * @memberof JobTypeDto
     */
    name: string;
    /**
     * Description of the Job type
     * @type {string}
     * @memberof JobTypeDto
     */
    description: string;
    /**
     * Details of the Job type
     * @type {string}
     * @memberof JobTypeDto
     */
    details: string;
    /**
     * Instructions of the Job type
     * @type {string}
     * @memberof JobTypeDto
     */
    instructions: string;
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof JobTypeDto
     */
    code: string;
    /**
     * Number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof JobTypeDto
     */
    contentNumber: number;
    /**
     * Group Name or Code of the Job Type
     * @type {string}
     * @memberof JobTypeDto
     */
    groupName: string;
    /**
     * Job Type details
     * @type {Array<string>}
     * @memberof JobTypeDto
     */
    allowedMimeTypes: Array<string>;
    /**
     * Is the Job Type a Review Type
     * @type {boolean}
     * @memberof JobTypeDto
     */
    isReview: boolean;
}
/**
 * 
 * @export
 * @interface JobTypeJobResponse
 */
export interface JobTypeJobResponse {
    /**
     * Id of the Job Type
     * @type {string}
     * @memberof JobTypeJobResponse
     */
    id: string;
    /**
     * Timestamp at which this Job Type was created
     * @type {Date}
     * @memberof JobTypeJobResponse
     */
    createdAt: Date;
    /**
     * Timestamp at which this Job Type was updated
     * @type {Date}
     * @memberof JobTypeJobResponse
     */
    updatedAt: Date;
    /**
     * Name of the Job Type
     * @type {string}
     * @memberof JobTypeJobResponse
     */
    name: string;
    /**
     * Description of the Job type
     * @type {string}
     * @memberof JobTypeJobResponse
     */
    description: string;
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof JobTypeJobResponse
     */
    code: string;
    /**
     * Number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof JobTypeJobResponse
     */
    contentNumber: number;
    /**
     * Job Type instructions
     * @type {string}
     * @memberof JobTypeJobResponse
     */
    instructions: string;
    /**
     * Job Type details
     * @type {string}
     * @memberof JobTypeJobResponse
     */
    details: string;
    /**
     * Group Name or Code of the Job Type
     * @type {string}
     * @memberof JobTypeJobResponse
     */
    groupName: string;
    /**
     * Job Type details
     * @type {Array<string>}
     * @memberof JobTypeJobResponse
     */
    allowedMimeTypes: Array<string>;
    /**
     * Is the Job Type a Review Type
     * @type {boolean}
     * @memberof JobTypeJobResponse
     */
    isReview: boolean;
    /**
     * Attachments of the Job Type
     * @type {Array<AttachmentResponseDto>}
     * @memberof JobTypeJobResponse
     */
    attachments: Array<AttachmentResponseDto>;
}
/**
 * 
 * @export
 * @interface JobTypesAnalyticsDto
 */
export interface JobTypesAnalyticsDto {
    /**
     * Id of the Job Type
     * @type {string}
     * @memberof JobTypesAnalyticsDto
     */
    id: string;
    /**
     * Timestamp at which this Job Type was created
     * @type {Date}
     * @memberof JobTypesAnalyticsDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Job Type was updated
     * @type {Date}
     * @memberof JobTypesAnalyticsDto
     */
    updatedAt: Date;
    /**
     * Name of the Job Type
     * @type {string}
     * @memberof JobTypesAnalyticsDto
     */
    name: string;
    /**
     * Description of the Job type
     * @type {string}
     * @memberof JobTypesAnalyticsDto
     */
    description: string;
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof JobTypesAnalyticsDto
     */
    code: string;
    /**
     * Number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof JobTypesAnalyticsDto
     */
    contentNumber: number;
    /**
     * Job Type instructions
     * @type {string}
     * @memberof JobTypesAnalyticsDto
     */
    instructions: string;
    /**
     * Job Type details
     * @type {string}
     * @memberof JobTypesAnalyticsDto
     */
    details: string;
    /**
     * Group Name or Code of the Job Type
     * @type {string}
     * @memberof JobTypesAnalyticsDto
     */
    groupName: string;
    /**
     * Job Type details
     * @type {Array<string>}
     * @memberof JobTypesAnalyticsDto
     */
    allowedMimeTypes: Array<string>;
    /**
     * Is the Job Type a Review Type
     * @type {boolean}
     * @memberof JobTypesAnalyticsDto
     */
    isReview: boolean;
    /**
     * Number of unclaimed activity build jobs. The activity build job is not complete and submission id is null.
     * @type {number}
     * @memberof JobTypesAnalyticsDto
     */
    unclaimedActivityBuildJobs: number;
    /**
     * Number of in progress activity build jobs. The activity build job is not complete and submission id is not null.
     * @type {number}
     * @memberof JobTypesAnalyticsDto
     */
    inProgressActivityBuildJobs: number;
}
/**
 * 
 * @export
 * @interface JobTypesDto
 */
export interface JobTypesDto {
    /**
     * Id of the Job Type
     * @type {string}
     * @memberof JobTypesDto
     */
    id: string;
    /**
     * Timestamp at which this Job Type was created
     * @type {Date}
     * @memberof JobTypesDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Job Type was updated
     * @type {Date}
     * @memberof JobTypesDto
     */
    updatedAt: Date;
    /**
     * Name of the Job Type
     * @type {string}
     * @memberof JobTypesDto
     */
    name: string;
    /**
     * Description of the Job type
     * @type {string}
     * @memberof JobTypesDto
     */
    description: string;
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof JobTypesDto
     */
    code: string;
    /**
     * Number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof JobTypesDto
     */
    contentNumber: number;
    /**
     * Job Type instructions
     * @type {string}
     * @memberof JobTypesDto
     */
    instructions: string;
    /**
     * Job Type details
     * @type {string}
     * @memberof JobTypesDto
     */
    details: string;
    /**
     * Group Name or Code of the Job Type
     * @type {string}
     * @memberof JobTypesDto
     */
    groupName: string;
    /**
     * Job Type details
     * @type {Array<string>}
     * @memberof JobTypesDto
     */
    allowedMimeTypes: Array<string>;
    /**
     * Is the Job Type a Review Type
     * @type {boolean}
     * @memberof JobTypesDto
     */
    isReview: boolean;
}
/**
 * 
 * @export
 * @interface JobTypesResponseDto
 */
export interface JobTypesResponseDto {
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof JobTypesResponseDto
     */
    code: string;
    /**
     * Name of Job Type
     * @type {string}
     * @memberof JobTypesResponseDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof JobTypesResponseDto
     */
    medianLiveToLatestPickUp: number;
    /**
     * 
     * @type {number}
     * @memberof JobTypesResponseDto
     */
    medianLatestPickUpToSubmitted: number;
    /**
     * 
     * @type {number}
     * @memberof JobTypesResponseDto
     */
    medianLiveToSubmitted: number;
    /**
     * 
     * @type {number}
     * @memberof JobTypesResponseDto
     */
    percentageJobsReclaimed: number;
}
/**
 * 
 * @export
 * @interface JobTypesWithAttachmentsDto
 */
export interface JobTypesWithAttachmentsDto {
    /**
     * Id of the Job Type
     * @type {string}
     * @memberof JobTypesWithAttachmentsDto
     */
    id: string;
    /**
     * Timestamp at which this Job Type was created
     * @type {Date}
     * @memberof JobTypesWithAttachmentsDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Job Type was updated
     * @type {Date}
     * @memberof JobTypesWithAttachmentsDto
     */
    updatedAt: Date;
    /**
     * Name of the Job Type
     * @type {string}
     * @memberof JobTypesWithAttachmentsDto
     */
    name: string;
    /**
     * Description of the Job type
     * @type {string}
     * @memberof JobTypesWithAttachmentsDto
     */
    description: string;
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof JobTypesWithAttachmentsDto
     */
    code: string;
    /**
     * Number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof JobTypesWithAttachmentsDto
     */
    contentNumber: number;
    /**
     * Job Type instructions
     * @type {string}
     * @memberof JobTypesWithAttachmentsDto
     */
    instructions: string;
    /**
     * Job Type details
     * @type {string}
     * @memberof JobTypesWithAttachmentsDto
     */
    details: string;
    /**
     * Group Name or Code of the Job Type
     * @type {string}
     * @memberof JobTypesWithAttachmentsDto
     */
    groupName: string;
    /**
     * Job Type details
     * @type {Array<string>}
     * @memberof JobTypesWithAttachmentsDto
     */
    allowedMimeTypes: Array<string>;
    /**
     * Is the Job Type a Review Type
     * @type {boolean}
     * @memberof JobTypesWithAttachmentsDto
     */
    isReview: boolean;
    /**
     * Attachment of Job Type
     * @type {Array<AttachmentResponseDto>}
     * @memberof JobTypesWithAttachmentsDto
     */
    attachments: Array<AttachmentResponseDto>;
}
/**
 * 
 * @export
 * @interface JobWithNoJobTypeAttachments
 */
export interface JobWithNoJobTypeAttachments {
    /**
     * Id of the Job
     * @type {string}
     * @memberof JobWithNoJobTypeAttachments
     */
    id: string;
    /**
     * Payout Rate
     * @type {number}
     * @memberof JobWithNoJobTypeAttachments
     */
    payoutRate: number;
    /**
     * Job duration in hours
     * @type {number}
     * @memberof JobWithNoJobTypeAttachments
     */
    jobDuration: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobWithNoJobTypeAttachments
     */
    completed: boolean;
    /**
     * 
     * @type {number}
     * @memberof JobWithNoJobTypeAttachments
     */
    activityBuilds: number;
    /**
     * The Job Type Object
     * @type {OmitTypeClass}
     * @memberof JobWithNoJobTypeAttachments
     */
    jobType: OmitTypeClass;
}
/**
 * 
 * @export
 * @interface OmitTypeClass
 */
export interface OmitTypeClass {
    /**
     * Id of the Job Type
     * @type {string}
     * @memberof OmitTypeClass
     */
    id: string;
    /**
     * Timestamp at which this Job Type was created
     * @type {Date}
     * @memberof OmitTypeClass
     */
    createdAt: Date;
    /**
     * Timestamp at which this Job Type was updated
     * @type {Date}
     * @memberof OmitTypeClass
     */
    updatedAt: Date;
    /**
     * Name of the Job Type
     * @type {string}
     * @memberof OmitTypeClass
     */
    name: string;
    /**
     * Description of the Job type
     * @type {string}
     * @memberof OmitTypeClass
     */
    description: string;
    /**
     * Unique code for the Job type
     * @type {string}
     * @memberof OmitTypeClass
     */
    code: string;
    /**
     * Number of content items expected to be completed for the Job type
     * @type {number}
     * @memberof OmitTypeClass
     */
    contentNumber: number;
    /**
     * Job Type instructions
     * @type {string}
     * @memberof OmitTypeClass
     */
    instructions: string;
    /**
     * Job Type details
     * @type {string}
     * @memberof OmitTypeClass
     */
    details: string;
    /**
     * Group Name or Code of the Job Type
     * @type {string}
     * @memberof OmitTypeClass
     */
    groupName: string;
    /**
     * Job Type details
     * @type {Array<string>}
     * @memberof OmitTypeClass
     */
    allowedMimeTypes: Array<string>;
    /**
     * Is the Job Type a Review Type
     * @type {boolean}
     * @memberof OmitTypeClass
     */
    isReview: boolean;
}
/**
 * 
 * @export
 * @interface RetrieveSubmissionByIdJobResponseDto
 */
export interface RetrieveSubmissionByIdJobResponseDto {
    /**
     * Id of the Submission
     * @type {string}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    id: string;
    /**
     * Activity Build object of the Submission
     * @type {UpdateActivityBuildDto}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    activityBuild: UpdateActivityBuildDto;
    /**
     * Timestamp at which this Submission was created
     * @type {Date}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Submission expires
     * @type {Date}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    expiryTime: Date;
    /**
     * The submission contents.
     * @type {Array<RetrieveSubmissionContentResponseDto>}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    submissionContents: Array<RetrieveSubmissionContentResponseDto>;
    /**
     * Input Submissions from the Parent Activity Build Job
     * @type {Array<InputSubmissionResponseDto>}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    inputSubmissions?: Array<InputSubmissionResponseDto>;
    /**
     * Job object of the Submission
     * @type {SubmissionJobResponseDto}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    job: SubmissionJobResponseDto;
    /**
     * Build Bundle object of the Submission
     * @type {BuildBundleJobResponseDto}
     * @memberof RetrieveSubmissionByIdJobResponseDto
     */
    buildBundle: BuildBundleJobResponseDto;
}
/**
 * 
 * @export
 * @interface RetrieveSubmissionContentResponseDto
 */
export interface RetrieveSubmissionContentResponseDto {
    /**
     * Id of the Submission Content
     * @type {string}
     * @memberof RetrieveSubmissionContentResponseDto
     */
    id: string;
    /**
     * Content in JSON format.
     * @type {any}
     * @memberof RetrieveSubmissionContentResponseDto
     */
    content: any;
    /**
     * Attachments of the Submission Content
     * @type {Array<AttachmentResponseDto>}
     * @memberof RetrieveSubmissionContentResponseDto
     */
    attachments: Array<AttachmentResponseDto>;
}
/**
 * 
 * @export
 * @interface RetrieveSubmissionsJobResponseDto
 */
export interface RetrieveSubmissionsJobResponseDto {
    /**
     * Id of the Submission
     * @type {string}
     * @memberof RetrieveSubmissionsJobResponseDto
     */
    id: string;
    /**
     * Activity Build object of the Submission
     * @type {UpdateActivityBuildDto}
     * @memberof RetrieveSubmissionsJobResponseDto
     */
    activityBuild: UpdateActivityBuildDto;
    /**
     * Timestamp at which this Submission was created
     * @type {Date}
     * @memberof RetrieveSubmissionsJobResponseDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Submission expires
     * @type {Date}
     * @memberof RetrieveSubmissionsJobResponseDto
     */
    expiryTime: Date;
    /**
     * Job object of the Submission
     * @type {JobWithNoJobTypeAttachments}
     * @memberof RetrieveSubmissionsJobResponseDto
     */
    job: JobWithNoJobTypeAttachments;
    /**
     * Build Bundle object of the Submission
     * @type {BuildBundleWithNoAttachments}
     * @memberof RetrieveSubmissionsJobResponseDto
     */
    buildBundle: BuildBundleWithNoAttachments;
}
/**
 * 
 * @export
 * @interface SearchActivityBuildJobResponseDto
 */
export interface SearchActivityBuildJobResponseDto {
    /**
     * The metadata object.
     * @type {SearchMetaData}
     * @memberof SearchActivityBuildJobResponseDto
     */
    meta: SearchMetaData;
    /**
     * The list of Activity Build Jobs in the page.
     * @type {Array<ActivityBuildJobsResponseDto>}
     * @memberof SearchActivityBuildJobResponseDto
     */
    activityBuildJobs: Array<ActivityBuildJobsResponseDto>;
}
/**
 * 
 * @export
 * @interface SearchMetaData
 */
export interface SearchMetaData {
    /**
     * Total items.
     * @type {number}
     * @memberof SearchMetaData
     */
    totalItems: number;
    /**
     * Items count.
     * @type {number}
     * @memberof SearchMetaData
     */
    itemCount: number;
    /**
     * Items count per page.
     * @type {number}
     * @memberof SearchMetaData
     */
    itemsPerPage: number;
    /**
     * Total number of pages.
     * @type {number}
     * @memberof SearchMetaData
     */
    totalPages: number;
    /**
     * Current page number.
     * @type {number}
     * @memberof SearchMetaData
     */
    currentPage: number;
}
/**
 * 
 * @export
 * @interface Standard
 */
export interface Standard {
    /**
     * 
     * @type {string}
     * @memberof Standard
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Standard
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Standard
     */
    nceaCode: string;
}
/**
 * 
 * @export
 * @interface Subject
 */
export interface Subject {
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    name: string;
    /**
     * 
     * @type {Array<Standard>}
     * @memberof Subject
     */
    standards: Array<Standard>;
}
/**
 * 
 * @export
 * @interface SubmissionContentIdAttachmentsBody
 */
export interface SubmissionContentIdAttachmentsBody {
    /**
     * 
     * @type {Blob}
     * @memberof SubmissionContentIdAttachmentsBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface SubmissionContentJsonDto
 */
export interface SubmissionContentJsonDto {
}
/**
 * 
 * @export
 * @interface SubmissionJobResponseDto
 */
export interface SubmissionJobResponseDto {
    /**
     * Id of the Job
     * @type {string}
     * @memberof SubmissionJobResponseDto
     */
    id: string;
    /**
     * Payout Rate
     * @type {number}
     * @memberof SubmissionJobResponseDto
     */
    payoutRate: number;
    /**
     * Job duration in hours
     * @type {number}
     * @memberof SubmissionJobResponseDto
     */
    jobDuration: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubmissionJobResponseDto
     */
    completed: boolean;
    /**
     * The Job Type Object
     * @type {JobTypeJobResponse}
     * @memberof SubmissionJobResponseDto
     */
    jobType: JobTypeJobResponse;
    /**
     * 
     * @type {number}
     * @memberof SubmissionJobResponseDto
     */
    activityBuilds: number;
}
/**
 * 
 * @export
 * @interface SubmitSubmissionContentResponseDto
 */
export interface SubmitSubmissionContentResponseDto {
    /**
     * Id of the Submission Content
     * @type {string}
     * @memberof SubmitSubmissionContentResponseDto
     */
    id: string;
    /**
     * Content in JSON format.
     * @type {any}
     * @memberof SubmitSubmissionContentResponseDto
     */
    content: any;
    /**
     * Attachments of the Submission Content
     * @type {Array<AttachmentResponseDto>}
     * @memberof SubmitSubmissionContentResponseDto
     */
    attachments: Array<AttachmentResponseDto>;
}
/**
 * 
 * @export
 * @interface SubmitSubmissionResponseDto
 */
export interface SubmitSubmissionResponseDto {
    /**
     * Id of the Submission
     * @type {string}
     * @memberof SubmitSubmissionResponseDto
     */
    id: string;
    /**
     * Activity Build object of the Submission
     * @type {UpdateActivityBuildDto}
     * @memberof SubmitSubmissionResponseDto
     */
    activityBuild: UpdateActivityBuildDto;
    /**
     * Timestamp at which this Submission was created
     * @type {Date}
     * @memberof SubmitSubmissionResponseDto
     */
    createdAt: Date;
    /**
     * Timestamp at which this Submission expires
     * @type {Date}
     * @memberof SubmitSubmissionResponseDto
     */
    expiryTime: Date;
    /**
     * Timestamp at which this Submission is submitted
     * @type {Date}
     * @memberof SubmitSubmissionResponseDto
     */
    submittedTime: Date;
    /**
     * The submission contents.
     * @type {Array<SubmitSubmissionContentResponseDto>}
     * @memberof SubmitSubmissionResponseDto
     */
    submissionContents: Array<SubmitSubmissionContentResponseDto>;
    /**
     * Input Submissions from the Parent Activity Build Job
     * @type {Array<InputSubmissionResponseDto>}
     * @memberof SubmitSubmissionResponseDto
     */
    inputSubmissions?: Array<InputSubmissionResponseDto>;
    /**
     * Job object of the Submission
     * @type {SubmissionJobResponseDto}
     * @memberof SubmitSubmissionResponseDto
     */
    job: SubmissionJobResponseDto;
    /**
     * Build Bundle object of the Submission
     * @type {BuildBundleJobResponseDto}
     * @memberof SubmitSubmissionResponseDto
     */
    buildBundle: BuildBundleJobResponseDto;
}
/**
 * 
 * @export
 * @interface UpdateActivityBuildDto
 */
export interface UpdateActivityBuildDto {
    /**
     * Id of the created Activity Build
     * @type {string}
     * @memberof UpdateActivityBuildDto
     */
    id: string;
    /**
     * Activity Build is published or not
     * @type {boolean}
     * @memberof UpdateActivityBuildDto
     */
    published: boolean;
    /**
     * Name of the created Activity Build
     * @type {string}
     * @memberof UpdateActivityBuildDto
     */
    name: string;
    /**
     * Video URL
     * @type {string}
     * @memberof UpdateActivityBuildDto
     */
    videoLink?: string;
    /**
     * Resource file to use
     * @type {string}
     * @memberof UpdateActivityBuildDto
     */
    specifiedFileName: string;
}
/**
 * 
 * @export
 * @interface UpdateActivityBuildResponseDto
 */
export interface UpdateActivityBuildResponseDto {
    /**
     * Id of the created Activity Build
     * @type {string}
     * @memberof UpdateActivityBuildResponseDto
     */
    id: string;
    /**
     * Activity Build is published or not
     * @type {boolean}
     * @memberof UpdateActivityBuildResponseDto
     */
    published: boolean;
    /**
     * Name of the created Activity Build
     * @type {string}
     * @memberof UpdateActivityBuildResponseDto
     */
    name: string;
    /**
     * Video URL
     * @type {string}
     * @memberof UpdateActivityBuildResponseDto
     */
    videoLink?: string;
    /**
     * Resource file to use
     * @type {string}
     * @memberof UpdateActivityBuildResponseDto
     */
    specifiedFileName: string;
}
/**
 * 
 * @export
 * @interface UserInformationDetailsDto
 */
export interface UserInformationDetailsDto {
    /**
     * First Name of the User
     * @type {string}
     * @memberof UserInformationDetailsDto
     */
    firstName: string;
    /**
     * Last Name of the User
     * @type {string}
     * @memberof UserInformationDetailsDto
     */
    lastName: string;
    /**
     * Current occupation of the user
     * @type {string}
     * @memberof UserInformationDetailsDto
     */
    occupation: string;
    /**
     * Current city of the user
     * @type {string}
     * @memberof UserInformationDetailsDto
     */
    city: string;
    /**
     * Current post code of the user
     * @type {string}
     * @memberof UserInformationDetailsDto
     */
    postCode: string;
    /**
     * Mobile number of the user
     * @type {string}
     * @memberof UserInformationDetailsDto
     */
    mobileNumber: string;
}
/**
 * 
 * @export
 * @interface UserInformationEndorsementsDto
 */
export interface UserInformationEndorsementsDto {
    /**
     * Other eligible endorsements of the user
     * @type {string}
     * @memberof UserInformationEndorsementsDto
     */
    otherEligibility: string;
    /**
     * Eligible NCEA Standard Endorsements of the User
     * @type {Array<string>}
     * @memberof UserInformationEndorsementsDto
     */
    nceaCodes: Array<string>;
    /**
     * Eligible Subject Endorsements of the User
     * @type {Array<string>}
     * @memberof UserInformationEndorsementsDto
     */
    subjectIds: Array<string>;
}
/**
 * 
 * @export
 * @interface UserInformationResponseDto
 */
export interface UserInformationResponseDto {
    /**
     * 
     * @type {UserInformationDetailsDto}
     * @memberof UserInformationResponseDto
     */
    details: UserInformationDetailsDto;
    /**
     * 
     * @type {UserInformationEndorsementsDto}
     * @memberof UserInformationResponseDto
     */
    endorsements: UserInformationEndorsementsDto;
    /**
     * Has the user provided their details
     * @type {boolean}
     * @memberof UserInformationResponseDto
     */
    detailsProvided: boolean;
    /**
     * Has the user verified their stripe account
     * @type {boolean}
     * @memberof UserInformationResponseDto
     */
    stripeAccountVerified: boolean;
    /**
     * Has the user finished their onboarding
     * @type {boolean}
     * @memberof UserInformationResponseDto
     */
    isOnboarded: boolean;
}
/**
 * CurriculumApi - fetch parameter creator
 * @export
 */
export const CurriculumApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck(options: any = {}): FetchArgs {
            const localVarPath = `/curriculum/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        curriculumControllerFindAll(options: any = {}): FetchArgs {
            const localVarPath = `/curriculum/levels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurriculumApi - functional programming interface
 * @export
 */
export const CurriculumApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CurriculumApiFetchParamCreator(configuration).appControllerGetHealthCheck(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        curriculumControllerFindAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CurriculumGetDto>> {
            const localVarFetchArgs = CurriculumApiFetchParamCreator(configuration).curriculumControllerFindAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CurriculumApi - factory interface
 * @export
 */
export const CurriculumApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck(options?: any) {
            return CurriculumApiFp(configuration).appControllerGetHealthCheck(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        curriculumControllerFindAll(options?: any) {
            return CurriculumApiFp(configuration).curriculumControllerFindAll(options)(fetch, basePath);
        },
    };
};

/**
 * CurriculumApi - object-oriented interface
 * @export
 * @class CurriculumApi
 * @extends {BaseAPI}
 */
export class CurriculumApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurriculumApi
     */
    public appControllerGetHealthCheck(options?: any) {
        return CurriculumApiFp(this.configuration).appControllerGetHealthCheck(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurriculumApi
     */
    public curriculumControllerFindAll(options?: any) {
        return CurriculumApiFp(this.configuration).curriculumControllerFindAll(options)(this.fetch, this.basePath);
    }

}
/**
 * IdentityApi - fetch parameter creator
 * @export
 */
export const IdentityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck2(options: any = {}): FetchArgs {
            const localVarPath = `/identity/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindUserInformation(options: any = {}): FetchArgs {
            const localVarPath = `/identity/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetStripeLink(options: any = {}): FetchArgs {
            const localVarPath = `/identity/users/me/account/stripe-link`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUserDetails(body: any, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling usersControllerUpdateUserDetails.');
            }
            const localVarPath = `/identity/users/me/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUserEndorsements(body: any, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling usersControllerUpdateUserEndorsements.');
            }
            const localVarPath = `/identity/users/me/endorsements`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityApi - functional programming interface
 * @export
 */
export const IdentityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck2(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IdentityApiFetchParamCreator(configuration).appControllerGetHealthCheck2(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindUserInformation(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInformationResponseDto> {
            const localVarFetchArgs = IdentityApiFetchParamCreator(configuration).usersControllerFindUserInformation(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetStripeLink(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetStripeLinkResponseDto> {
            const localVarFetchArgs = IdentityApiFetchParamCreator(configuration).usersControllerGetStripeLink(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUserDetails(body: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInformationDetailsDto> {
            const localVarFetchArgs = IdentityApiFetchParamCreator(configuration).usersControllerUpdateUserDetails(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUserEndorsements(body: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInformationEndorsementsDto> {
            const localVarFetchArgs = IdentityApiFetchParamCreator(configuration).usersControllerUpdateUserEndorsements(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IdentityApi - factory interface
 * @export
 */
export const IdentityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck2(options?: any) {
            return IdentityApiFp(configuration).appControllerGetHealthCheck2(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindUserInformation(options?: any) {
            return IdentityApiFp(configuration).usersControllerFindUserInformation(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetStripeLink(options?: any) {
            return IdentityApiFp(configuration).usersControllerGetStripeLink(options)(fetch, basePath);
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUserDetails(body: any, options?: any) {
            return IdentityApiFp(configuration).usersControllerUpdateUserDetails(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUserEndorsements(body: any, options?: any) {
            return IdentityApiFp(configuration).usersControllerUpdateUserEndorsements(body, options)(fetch, basePath);
        },
    };
};

/**
 * IdentityApi - object-oriented interface
 * @export
 * @class IdentityApi
 * @extends {BaseAPI}
 */
export class IdentityApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public appControllerGetHealthCheck2(options?: any) {
        return IdentityApiFp(this.configuration).appControllerGetHealthCheck2(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public usersControllerFindUserInformation(options?: any) {
        return IdentityApiFp(this.configuration).usersControllerFindUserInformation(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public usersControllerGetStripeLink(options?: any) {
        return IdentityApiFp(this.configuration).usersControllerGetStripeLink(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public usersControllerUpdateUserDetails(body: any, options?: any) {
        return IdentityApiFp(this.configuration).usersControllerUpdateUserDetails(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public usersControllerUpdateUserEndorsements(body: any, options?: any) {
        return IdentityApiFp(this.configuration).usersControllerUpdateUserEndorsements(body, options)(this.fetch, this.basePath);
    }

}
/**
 * JobManagementApi - fetch parameter creator
 * @export
 */
export const JobManagementApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id The Activity Build Jobs id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityBuildJobsControllerRetrieveAbjById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activityBuildJobsControllerRetrieveAbjById.');
            }
            const localVarPath = `/job-management/activity-build-jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] The page number
         * @param {number} [limit] Number of items in a page.
         * @param {string} [sort] Fields to be used in the query results sorting.
         * @param {string} [sortDirection] Sort direction.
         * @param {string} [jobTypeCodes] List of job type codes.
         * @param {string} [levelId] ID of the Curriculum Level Row from the Curriculum DB
         * @param {string} [subjectId] ID of the Curriculum Subject Row from the Curriculum DB
         * @param {string} [nceaCode] ID of the NCEA Code from the Curriculum DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityBuildJobsControllerSearch(page?: number, limit?: number, sort?: string, sortDirection?: string, jobTypeCodes?: string, levelId?: string, subjectId?: string, nceaCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/job-management/activity-build-jobs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (jobTypeCodes !== undefined) {
                localVarQueryParameter['jobTypeCodes'] = jobTypeCodes;
            }

            if (levelId !== undefined) {
                localVarQueryParameter['levelId'] = levelId;
            }

            if (subjectId !== undefined) {
                localVarQueryParameter['subjectId'] = subjectId;
            }

            if (nceaCode !== undefined) {
                localVarQueryParameter['nceaCode'] = nceaCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityTemplatesControllerFindAll(options: any = {}): FetchArgs {
            const localVarPath = `/job-management/activity-templates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck1(options: any = {}): FetchArgs {
            const localVarPath = `/job-management/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerArchiveBuildBundle(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling buildBundlesControllerArchiveBuildBundle.');
            }
            const localVarPath = `/job-management/build-bundles/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerCancel(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling buildBundlesControllerCancel.');
            }
            const localVarPath = `/job-management/build-bundles/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBuildBundlesDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerCreate(body: CreateBuildBundlesDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling buildBundlesControllerCreate.');
            }
            const localVarPath = `/job-management/build-bundles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateBuildBundlesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerDelete(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling buildBundlesControllerDelete.');
            }
            const localVarPath = `/job-management/build-bundles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {string} type The type of build bundle file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerDownload(id: string, type: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling buildBundlesControllerDownload.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling buildBundlesControllerDownload.');
            }
            const localVarPath = `/job-management/build-bundles/{id}/download/{type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Date} [creationTimeFrom] Start of range of build bundle creation times
         * @param {Date} [creationTimeTo] End of range of build bundle creation times
         * @param {string} [statuses] List of build bundle statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerGetAvailableBuildBundles(creationTimeFrom?: Date, creationTimeTo?: Date, statuses?: string, options: any = {}): FetchArgs {
            const localVarPath = `/job-management/build-bundles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            if (creationTimeFrom !== undefined) {
                localVarQueryParameter['creationTimeFrom'] = (creationTimeFrom as any).toISOString();
            }

            if (creationTimeTo !== undefined) {
                localVarQueryParameter['creationTimeTo'] = (creationTimeTo as any).toISOString();
            }

            if (statuses !== undefined) {
                localVarQueryParameter['statuses'] = statuses;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUnarchiveBuildBundle(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling buildBundlesControllerUnarchiveBuildBundle.');
            }
            const localVarPath = `/job-management/build-bundles/{id}/unarchive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateActivityBuildDto>} body 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUpdate(body: Array<UpdateActivityBuildDto>, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling buildBundlesControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling buildBundlesControllerUpdate.');
            }
            const localVarPath = `/job-management/build-bundles/{id}/activity-builds`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;UpdateActivityBuildDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUploadBuildBundleAttachment(file: Blob, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling buildBundlesControllerUploadBuildBundleAttachment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling buildBundlesControllerUploadBuildBundleAttachment.');
            }
            const localVarPath = `/job-management/build-bundles/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication bearer required

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobTypeId The job types id.
         * @param {string} attachmentId The id of attachment to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerDeleteJobTypesAttachment(jobTypeId: string, attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'jobTypeId' is not null or undefined
            if (jobTypeId === null || jobTypeId === undefined) {
                throw new RequiredError('jobTypeId','Required parameter jobTypeId was null or undefined when calling jobTypesControllerDeleteJobTypesAttachment.');
            }
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling jobTypesControllerDeleteJobTypesAttachment.');
            }
            const localVarPath = `/job-management/job-types/{job_type_id}/attachments/{attachment_id}`
                .replace(`{${"job_type_id"}}`, encodeURIComponent(String(jobTypeId)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerGetJobTypesStatistics(options: any = {}): FetchArgs {
            const localVarPath = `/job-management/job-types/statistics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveAllJobTypes(options: any = {}): FetchArgs {
            const localVarPath = `/job-management/job-types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The job types id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveJobTypesById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling jobTypesControllerRetrieveJobTypesById.');
            }
            const localVarPath = `/job-management/job-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JobTypesDto} body 
         * @param {string} id The job types id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUpdateJobTypes(body: JobTypesDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling jobTypesControllerUpdateJobTypes.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling jobTypesControllerUpdateJobTypes.');
            }
            const localVarPath = `/job-management/job-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JobTypesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} jobTypeId The job type id.
         * @param {string} attachmentId The job types attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUpdateJobTypesAttachment(file: Blob, jobTypeId: string, attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling jobTypesControllerUpdateJobTypesAttachment.');
            }
            // verify required parameter 'jobTypeId' is not null or undefined
            if (jobTypeId === null || jobTypeId === undefined) {
                throw new RequiredError('jobTypeId','Required parameter jobTypeId was null or undefined when calling jobTypesControllerUpdateJobTypesAttachment.');
            }
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling jobTypesControllerUpdateJobTypesAttachment.');
            }
            const localVarPath = `/job-management/job-types/{job_type_id}/attachments/{attachment_id}`
                .replace(`{${"job_type_id"}}`, encodeURIComponent(String(jobTypeId)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication bearer required

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} id The job type id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUploadJobTypeAttachment(file: Blob, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling jobTypesControllerUploadJobTypeAttachment.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling jobTypesControllerUploadJobTypeAttachment.');
            }
            const localVarPath = `/job-management/job-types/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication bearer required

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The Job id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerFindById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling jobsControllerFindById.');
            }
            const localVarPath = `/job-management/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetAvailableJobs(options: any = {}): FetchArgs {
            const localVarPath = `/job-management/jobs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSubmissionRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerCreate(body: CreateSubmissionRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling submissionControllerCreate.');
            }
            const localVarPath = `/job-management/submissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateSubmissionRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerCreateSubmissionContent(submissionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerCreateSubmissionContent.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}/submission-contents`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {string} attachmentId The submission content attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerDeleteAttachment(submissionId: string, submissionContentId: string, attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerDeleteAttachment.');
            }
            // verify required parameter 'submissionContentId' is not null or undefined
            if (submissionContentId === null || submissionContentId === undefined) {
                throw new RequiredError('submissionContentId','Required parameter submissionContentId was null or undefined when calling submissionControllerDeleteAttachment.');
            }
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling submissionControllerDeleteAttachment.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}/submission-contents/{submission_content_id}/attachments/{attachment_id}`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)))
                .replace(`{${"submission_content_id"}}`, encodeURIComponent(String(submissionContentId)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerDeleteSubmissionContent(submissionId: string, submissionContentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerDeleteSubmissionContent.');
            }
            // verify required parameter 'submissionContentId' is not null or undefined
            if (submissionContentId === null || submissionContentId === undefined) {
                throw new RequiredError('submissionContentId','Required parameter submissionContentId was null or undefined when calling submissionControllerDeleteSubmissionContent.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}/submission-contents/{submission_content_id}`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)))
                .replace(`{${"submission_content_id"}}`, encodeURIComponent(String(submissionContentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerFindSubmissionById(submissionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerFindSubmissionById.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] Submission Status
         * @param {Date} [fromUpdatedAt] Timestamp where the search query for updated at should start
         * @param {Date} [toUpdatedAt] Timestamp where the search query for updated at should end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerFindSubmissions(status?: string, fromUpdatedAt?: Date, toUpdatedAt?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/job-management/submissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (fromUpdatedAt !== undefined) {
                localVarQueryParameter['fromUpdatedAt'] = (fromUpdatedAt as any).toISOString();
            }

            if (toUpdatedAt !== undefined) {
                localVarQueryParameter['toUpdatedAt'] = (toUpdatedAt as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerSubmit(submissionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerSubmit.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}/submit`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {string} attachmentId The submission content attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUpdateAttachment(file: Blob, submissionId: string, submissionContentId: string, attachmentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling submissionControllerUpdateAttachment.');
            }
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerUpdateAttachment.');
            }
            // verify required parameter 'submissionContentId' is not null or undefined
            if (submissionContentId === null || submissionContentId === undefined) {
                throw new RequiredError('submissionContentId','Required parameter submissionContentId was null or undefined when calling submissionControllerUpdateAttachment.');
            }
            // verify required parameter 'attachmentId' is not null or undefined
            if (attachmentId === null || attachmentId === undefined) {
                throw new RequiredError('attachmentId','Required parameter attachmentId was null or undefined when calling submissionControllerUpdateAttachment.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}/submission-contents/{submission_content_id}/attachments/{attachment_id}`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)))
                .replace(`{${"submission_content_id"}}`, encodeURIComponent(String(submissionContentId)))
                .replace(`{${"attachment_id"}}`, encodeURIComponent(String(attachmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication bearer required

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} body 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUpdateContent(body: any, submissionId: string, submissionContentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling submissionControllerUpdateContent.');
            }
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerUpdateContent.');
            }
            // verify required parameter 'submissionContentId' is not null or undefined
            if (submissionContentId === null || submissionContentId === undefined) {
                throw new RequiredError('submissionContentId','Required parameter submissionContentId was null or undefined when calling submissionControllerUpdateContent.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}/submission-contents/{submission_content_id}/content`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)))
                .replace(`{${"submission_content_id"}}`, encodeURIComponent(String(submissionContentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUploadAttachment(file: Blob, submissionId: string, submissionContentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling submissionControllerUploadAttachment.');
            }
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling submissionControllerUploadAttachment.');
            }
            // verify required parameter 'submissionContentId' is not null or undefined
            if (submissionContentId === null || submissionContentId === undefined) {
                throw new RequiredError('submissionContentId','Required parameter submissionContentId was null or undefined when calling submissionControllerUploadAttachment.');
            }
            const localVarPath = `/job-management/submissions/{submission_id}/submission-contents/{submission_content_id}/attachments`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)))
                .replace(`{${"submission_content_id"}}`, encodeURIComponent(String(submissionContentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication bearer required

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobManagementApi - functional programming interface
 * @export
 */
export const JobManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id The Activity Build Jobs id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityBuildJobsControllerRetrieveAbjById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ActivityBuildJobsResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).activityBuildJobsControllerRetrieveAbjById(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [page] The page number
         * @param {number} [limit] Number of items in a page.
         * @param {string} [sort] Fields to be used in the query results sorting.
         * @param {string} [sortDirection] Sort direction.
         * @param {string} [jobTypeCodes] List of job type codes.
         * @param {string} [levelId] ID of the Curriculum Level Row from the Curriculum DB
         * @param {string} [subjectId] ID of the Curriculum Subject Row from the Curriculum DB
         * @param {string} [nceaCode] ID of the NCEA Code from the Curriculum DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityBuildJobsControllerSearch(page?: number, limit?: number, sort?: string, sortDirection?: string, jobTypeCodes?: string, levelId?: string, subjectId?: string, nceaCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchActivityBuildJobResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).activityBuildJobsControllerSearch(page, limit, sort, sortDirection, jobTypeCodes, levelId, subjectId, nceaCode, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityTemplatesControllerFindAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ActivityTemplateGetDto>> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).activityTemplatesControllerFindAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).appControllerGetHealthCheck1(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerArchiveBuildBundle(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetBuildBundlesResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerArchiveBuildBundle(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerCancel(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetBuildBundlesResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerCancel(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateBuildBundlesDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerCreate(body: CreateBuildBundlesDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateBuildBundlesResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerCreate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerDelete(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerDelete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {string} type The type of build bundle file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerDownload(id: string, type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerDownload(id, type, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Date} [creationTimeFrom] Start of range of build bundle creation times
         * @param {Date} [creationTimeTo] End of range of build bundle creation times
         * @param {string} [statuses] List of build bundle statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerGetAvailableBuildBundles(creationTimeFrom?: Date, creationTimeTo?: Date, statuses?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GetBuildBundlesResponseDto>> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerGetAvailableBuildBundles(creationTimeFrom, creationTimeTo, statuses, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUnarchiveBuildBundle(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetBuildBundlesResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerUnarchiveBuildBundle(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<UpdateActivityBuildDto>} body 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUpdate(body: Array<UpdateActivityBuildDto>, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UpdateActivityBuildResponseDto>> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerUpdate(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUploadBuildBundleAttachment(file: Blob, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).buildBundlesControllerUploadBuildBundleAttachment(file, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} jobTypeId The job types id.
         * @param {string} attachmentId The id of attachment to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerDeleteJobTypesAttachment(jobTypeId: string, attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobTypesControllerDeleteJobTypesAttachment(jobTypeId, attachmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerGetJobTypesStatistics(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypesAnalyticsDto>> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobTypesControllerGetJobTypesStatistics(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveAllJobTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypesDto>> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobTypesControllerRetrieveAllJobTypes(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The job types id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveJobTypesById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypesWithAttachmentsDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobTypesControllerRetrieveJobTypesById(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JobTypesDto} body 
         * @param {string} id The job types id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUpdateJobTypes(body: JobTypesDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobTypesDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobTypesControllerUpdateJobTypes(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} jobTypeId The job type id.
         * @param {string} attachmentId The job types attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUpdateJobTypesAttachment(file: Blob, jobTypeId: string, attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobTypesControllerUpdateJobTypesAttachment(file, jobTypeId, attachmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} id The job type id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUploadJobTypeAttachment(file: Blob, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobTypesControllerUploadJobTypeAttachment(file, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The Job id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerFindById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetByIdJobResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobsControllerFindById(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetAvailableJobs(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GetByIdJobResponseDto>> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).jobsControllerGetAvailableJobs(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateSubmissionRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerCreate(body: CreateSubmissionRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateSubmissionResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerCreate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerCreateSubmissionContent(submissionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateSubmissionContentResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerCreateSubmissionContent(submissionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {string} attachmentId The submission content attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerDeleteAttachment(submissionId: string, submissionContentId: string, attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerDeleteAttachment(submissionId, submissionContentId, attachmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerDeleteSubmissionContent(submissionId: string, submissionContentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerDeleteSubmissionContent(submissionId, submissionContentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerFindSubmissionById(submissionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RetrieveSubmissionByIdJobResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerFindSubmissionById(submissionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [status] Submission Status
         * @param {Date} [fromUpdatedAt] Timestamp where the search query for updated at should start
         * @param {Date} [toUpdatedAt] Timestamp where the search query for updated at should end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerFindSubmissions(status?: string, fromUpdatedAt?: Date, toUpdatedAt?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RetrieveSubmissionsJobResponseDto>> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerFindSubmissions(status, fromUpdatedAt, toUpdatedAt, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerSubmit(submissionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmitSubmissionResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerSubmit(submissionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {string} attachmentId The submission content attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUpdateAttachment(file: Blob, submissionId: string, submissionContentId: string, attachmentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerUpdateAttachment(file, submissionId, submissionContentId, attachmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {any} body 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUpdateContent(body: any, submissionId: string, submissionContentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionContentJsonDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerUpdateContent(body, submissionId, submissionContentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUploadAttachment(file: Blob, submissionId: string, submissionContentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AttachmentResponseDto> {
            const localVarFetchArgs = JobManagementApiFetchParamCreator(configuration).submissionControllerUploadAttachment(file, submissionId, submissionContentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobManagementApi - factory interface
 * @export
 */
export const JobManagementApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id The Activity Build Jobs id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityBuildJobsControllerRetrieveAbjById(id: string, options?: any) {
            return JobManagementApiFp(configuration).activityBuildJobsControllerRetrieveAbjById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [page] The page number
         * @param {number} [limit] Number of items in a page.
         * @param {string} [sort] Fields to be used in the query results sorting.
         * @param {string} [sortDirection] Sort direction.
         * @param {string} [jobTypeCodes] List of job type codes.
         * @param {string} [levelId] ID of the Curriculum Level Row from the Curriculum DB
         * @param {string} [subjectId] ID of the Curriculum Subject Row from the Curriculum DB
         * @param {string} [nceaCode] ID of the NCEA Code from the Curriculum DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityBuildJobsControllerSearch(page?: number, limit?: number, sort?: string, sortDirection?: string, jobTypeCodes?: string, levelId?: string, subjectId?: string, nceaCode?: string, options?: any) {
            return JobManagementApiFp(configuration).activityBuildJobsControllerSearch(page, limit, sort, sortDirection, jobTypeCodes, levelId, subjectId, nceaCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityTemplatesControllerFindAll(options?: any) {
            return JobManagementApiFp(configuration).activityTemplatesControllerFindAll(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck1(options?: any) {
            return JobManagementApiFp(configuration).appControllerGetHealthCheck1(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerArchiveBuildBundle(id: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerArchiveBuildBundle(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerCancel(id: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerCancel(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateBuildBundlesDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerCreate(body: CreateBuildBundlesDto, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerDelete(id: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerDelete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {string} type The type of build bundle file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerDownload(id: string, type: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerDownload(id, type, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Date} [creationTimeFrom] Start of range of build bundle creation times
         * @param {Date} [creationTimeTo] End of range of build bundle creation times
         * @param {string} [statuses] List of build bundle statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerGetAvailableBuildBundles(creationTimeFrom?: Date, creationTimeTo?: Date, statuses?: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerGetAvailableBuildBundles(creationTimeFrom, creationTimeTo, statuses, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUnarchiveBuildBundle(id: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerUnarchiveBuildBundle(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<UpdateActivityBuildDto>} body 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUpdate(body: Array<UpdateActivityBuildDto>, id: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerUpdate(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} id The build bundles id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildBundlesControllerUploadBuildBundleAttachment(file: Blob, id: string, options?: any) {
            return JobManagementApiFp(configuration).buildBundlesControllerUploadBuildBundleAttachment(file, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} jobTypeId The job types id.
         * @param {string} attachmentId The id of attachment to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerDeleteJobTypesAttachment(jobTypeId: string, attachmentId: string, options?: any) {
            return JobManagementApiFp(configuration).jobTypesControllerDeleteJobTypesAttachment(jobTypeId, attachmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerGetJobTypesStatistics(options?: any) {
            return JobManagementApiFp(configuration).jobTypesControllerGetJobTypesStatistics(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveAllJobTypes(options?: any) {
            return JobManagementApiFp(configuration).jobTypesControllerRetrieveAllJobTypes(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The job types id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveJobTypesById(id: string, options?: any) {
            return JobManagementApiFp(configuration).jobTypesControllerRetrieveJobTypesById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JobTypesDto} body 
         * @param {string} id The job types id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUpdateJobTypes(body: JobTypesDto, id: string, options?: any) {
            return JobManagementApiFp(configuration).jobTypesControllerUpdateJobTypes(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} jobTypeId The job type id.
         * @param {string} attachmentId The job types attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUpdateJobTypesAttachment(file: Blob, jobTypeId: string, attachmentId: string, options?: any) {
            return JobManagementApiFp(configuration).jobTypesControllerUpdateJobTypesAttachment(file, jobTypeId, attachmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} id The job type id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerUploadJobTypeAttachment(file: Blob, id: string, options?: any) {
            return JobManagementApiFp(configuration).jobTypesControllerUploadJobTypeAttachment(file, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The Job id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerFindById(id: string, options?: any) {
            return JobManagementApiFp(configuration).jobsControllerFindById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetAvailableJobs(options?: any) {
            return JobManagementApiFp(configuration).jobsControllerGetAvailableJobs(options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateSubmissionRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerCreate(body: CreateSubmissionRequestDto, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerCreateSubmissionContent(submissionId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerCreateSubmissionContent(submissionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {string} attachmentId The submission content attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerDeleteAttachment(submissionId: string, submissionContentId: string, attachmentId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerDeleteAttachment(submissionId, submissionContentId, attachmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerDeleteSubmissionContent(submissionId: string, submissionContentId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerDeleteSubmissionContent(submissionId, submissionContentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerFindSubmissionById(submissionId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerFindSubmissionById(submissionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [status] Submission Status
         * @param {Date} [fromUpdatedAt] Timestamp where the search query for updated at should start
         * @param {Date} [toUpdatedAt] Timestamp where the search query for updated at should end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerFindSubmissions(status?: string, fromUpdatedAt?: Date, toUpdatedAt?: Date, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerFindSubmissions(status, fromUpdatedAt, toUpdatedAt, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} submissionId The submission id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerSubmit(submissionId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerSubmit(submissionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {string} attachmentId The submission content attachment id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUpdateAttachment(file: Blob, submissionId: string, submissionContentId: string, attachmentId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerUpdateAttachment(file, submissionId, submissionContentId, attachmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {any} body 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUpdateContent(body: any, submissionId: string, submissionContentId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerUpdateContent(body, submissionId, submissionContentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Blob} file 
         * @param {string} submissionId The submission id.
         * @param {string} submissionContentId The submission content id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionControllerUploadAttachment(file: Blob, submissionId: string, submissionContentId: string, options?: any) {
            return JobManagementApiFp(configuration).submissionControllerUploadAttachment(file, submissionId, submissionContentId, options)(fetch, basePath);
        },
    };
};

/**
 * JobManagementApi - object-oriented interface
 * @export
 * @class JobManagementApi
 * @extends {BaseAPI}
 */
export class JobManagementApi extends BaseAPI {
    /**
     * 
     * @param {string} id The Activity Build Jobs id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public activityBuildJobsControllerRetrieveAbjById(id: string, options?: any) {
        return JobManagementApiFp(this.configuration).activityBuildJobsControllerRetrieveAbjById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [page] The page number
     * @param {number} [limit] Number of items in a page.
     * @param {string} [sort] Fields to be used in the query results sorting.
     * @param {string} [sortDirection] Sort direction.
     * @param {string} [jobTypeCodes] List of job type codes.
     * @param {string} [levelId] ID of the Curriculum Level Row from the Curriculum DB
     * @param {string} [subjectId] ID of the Curriculum Subject Row from the Curriculum DB
     * @param {string} [nceaCode] ID of the NCEA Code from the Curriculum DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public activityBuildJobsControllerSearch(page?: number, limit?: number, sort?: string, sortDirection?: string, jobTypeCodes?: string, levelId?: string, subjectId?: string, nceaCode?: string, options?: any) {
        return JobManagementApiFp(this.configuration).activityBuildJobsControllerSearch(page, limit, sort, sortDirection, jobTypeCodes, levelId, subjectId, nceaCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public activityTemplatesControllerFindAll(options?: any) {
        return JobManagementApiFp(this.configuration).activityTemplatesControllerFindAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public appControllerGetHealthCheck1(options?: any) {
        return JobManagementApiFp(this.configuration).appControllerGetHealthCheck1(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The build bundles id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerArchiveBuildBundle(id: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerArchiveBuildBundle(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The build bundles id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerCancel(id: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerCancel(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateBuildBundlesDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerCreate(body: CreateBuildBundlesDto, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The build bundles id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerDelete(id: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerDelete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The build bundles id.
     * @param {string} type The type of build bundle file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerDownload(id: string, type: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerDownload(id, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Date} [creationTimeFrom] Start of range of build bundle creation times
     * @param {Date} [creationTimeTo] End of range of build bundle creation times
     * @param {string} [statuses] List of build bundle statuses.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerGetAvailableBuildBundles(creationTimeFrom?: Date, creationTimeTo?: Date, statuses?: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerGetAvailableBuildBundles(creationTimeFrom, creationTimeTo, statuses, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The build bundles id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerUnarchiveBuildBundle(id: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerUnarchiveBuildBundle(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<UpdateActivityBuildDto>} body 
     * @param {string} id The build bundles id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerUpdate(body: Array<UpdateActivityBuildDto>, id: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerUpdate(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Blob} file 
     * @param {string} id The build bundles id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public buildBundlesControllerUploadBuildBundleAttachment(file: Blob, id: string, options?: any) {
        return JobManagementApiFp(this.configuration).buildBundlesControllerUploadBuildBundleAttachment(file, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} jobTypeId The job types id.
     * @param {string} attachmentId The id of attachment to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobTypesControllerDeleteJobTypesAttachment(jobTypeId: string, attachmentId: string, options?: any) {
        return JobManagementApiFp(this.configuration).jobTypesControllerDeleteJobTypesAttachment(jobTypeId, attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobTypesControllerGetJobTypesStatistics(options?: any) {
        return JobManagementApiFp(this.configuration).jobTypesControllerGetJobTypesStatistics(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobTypesControllerRetrieveAllJobTypes(options?: any) {
        return JobManagementApiFp(this.configuration).jobTypesControllerRetrieveAllJobTypes(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The job types id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobTypesControllerRetrieveJobTypesById(id: string, options?: any) {
        return JobManagementApiFp(this.configuration).jobTypesControllerRetrieveJobTypesById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JobTypesDto} body 
     * @param {string} id The job types id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobTypesControllerUpdateJobTypes(body: JobTypesDto, id: string, options?: any) {
        return JobManagementApiFp(this.configuration).jobTypesControllerUpdateJobTypes(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Blob} file 
     * @param {string} jobTypeId The job type id.
     * @param {string} attachmentId The job types attachment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobTypesControllerUpdateJobTypesAttachment(file: Blob, jobTypeId: string, attachmentId: string, options?: any) {
        return JobManagementApiFp(this.configuration).jobTypesControllerUpdateJobTypesAttachment(file, jobTypeId, attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Blob} file 
     * @param {string} id The job type id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobTypesControllerUploadJobTypeAttachment(file: Blob, id: string, options?: any) {
        return JobManagementApiFp(this.configuration).jobTypesControllerUploadJobTypeAttachment(file, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The Job id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobsControllerFindById(id: string, options?: any) {
        return JobManagementApiFp(this.configuration).jobsControllerFindById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public jobsControllerGetAvailableJobs(options?: any) {
        return JobManagementApiFp(this.configuration).jobsControllerGetAvailableJobs(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateSubmissionRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerCreate(body: CreateSubmissionRequestDto, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} submissionId The submission id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerCreateSubmissionContent(submissionId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerCreateSubmissionContent(submissionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} submissionId The submission id.
     * @param {string} submissionContentId The submission content id.
     * @param {string} attachmentId The submission content attachment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerDeleteAttachment(submissionId: string, submissionContentId: string, attachmentId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerDeleteAttachment(submissionId, submissionContentId, attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} submissionId The submission id.
     * @param {string} submissionContentId The submission content id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerDeleteSubmissionContent(submissionId: string, submissionContentId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerDeleteSubmissionContent(submissionId, submissionContentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} submissionId The submission id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerFindSubmissionById(submissionId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerFindSubmissionById(submissionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [status] Submission Status
     * @param {Date} [fromUpdatedAt] Timestamp where the search query for updated at should start
     * @param {Date} [toUpdatedAt] Timestamp where the search query for updated at should end
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerFindSubmissions(status?: string, fromUpdatedAt?: Date, toUpdatedAt?: Date, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerFindSubmissions(status, fromUpdatedAt, toUpdatedAt, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} submissionId The submission id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerSubmit(submissionId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerSubmit(submissionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Blob} file 
     * @param {string} submissionId The submission id.
     * @param {string} submissionContentId The submission content id.
     * @param {string} attachmentId The submission content attachment id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerUpdateAttachment(file: Blob, submissionId: string, submissionContentId: string, attachmentId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerUpdateAttachment(file, submissionId, submissionContentId, attachmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {any} body 
     * @param {string} submissionId The submission id.
     * @param {string} submissionContentId The submission content id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerUpdateContent(body: any, submissionId: string, submissionContentId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerUpdateContent(body, submissionId, submissionContentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Blob} file 
     * @param {string} submissionId The submission id.
     * @param {string} submissionContentId The submission content id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobManagementApi
     */
    public submissionControllerUploadAttachment(file: Blob, submissionId: string, submissionContentId: string, options?: any) {
        return JobManagementApiFp(this.configuration).submissionControllerUploadAttachment(file, submissionId, submissionContentId, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportingApi - fetch parameter creator
 * @export
 */
export const ReportingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck3(options: any = {}): FetchArgs {
            const localVarPath = `/reporting/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedSubmissionsControllerFindCompletedSubmissionById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling completedSubmissionsControllerFindCompletedSubmissionById.');
            }
            const localVarPath = `/reporting/completed-submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] Get all the Completed Submissions of a specific User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedSubmissionsControllerFindCompletedSubmissions(userId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/reporting/completed-submissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompletedSubmissionsDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerCreate(body: CompletedSubmissionsDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling internalCompletedSubmissionsControllerCreate.');
            }
            const localVarPath = `/reporting/internal/completed-submissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompletedSubmissionsDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerFindCompletedSubmissionById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling internalCompletedSubmissionsControllerFindCompletedSubmissionById.');
            }
            const localVarPath = `/reporting/internal/completed-submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerFindCompletedSubmissions(submissionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'submissionId' is not null or undefined
            if (submissionId === null || submissionId === undefined) {
                throw new RequiredError('submissionId','Required parameter submissionId was null or undefined when calling internalCompletedSubmissionsControllerFindCompletedSubmissions.');
            }
            const localVarPath = `/reporting/internal/completed-submissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            if (submissionId !== undefined) {
                localVarQueryParameter['submissionId'] = submissionId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompletedSubmissionsDto} body 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerUpdate(body: CompletedSubmissionsDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling internalCompletedSubmissionsControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling internalCompletedSubmissionsControllerUpdate.');
            }
            const localVarPath = `/reporting/internal/completed-submissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompletedSubmissionsDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Date} [buildBundleCreationTimeFrom] Start of range of build bundle creation times to generate statistics for
         * @param {Date} [buildBundleCreationTimeTo] End of range of build bundle creation times to generate statistics for
         * @param {string} [buildBundleIds] List of build bundles ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveJobTypesStatistics(buildBundleCreationTimeFrom?: Date, buildBundleCreationTimeTo?: Date, buildBundleIds?: string, options: any = {}): FetchArgs {
            const localVarPath = `/reporting/job-types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required

            if (buildBundleCreationTimeFrom !== undefined) {
                localVarQueryParameter['buildBundleCreationTimeFrom'] = (buildBundleCreationTimeFrom as any).toISOString();
            }

            if (buildBundleCreationTimeTo !== undefined) {
                localVarQueryParameter['buildBundleCreationTimeTo'] = (buildBundleCreationTimeTo as any).toISOString();
            }

            if (buildBundleIds !== undefined) {
                localVarQueryParameter['buildBundleIds'] = buildBundleIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingApi - functional programming interface
 * @export
 */
export const ReportingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck3(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).appControllerGetHealthCheck3(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedSubmissionsControllerFindCompletedSubmissionById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompletedSubmissionsDto> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).completedSubmissionsControllerFindCompletedSubmissionById(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [userId] Get all the Completed Submissions of a specific User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedSubmissionsControllerFindCompletedSubmissions(userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CompletedSubmissionsDto>> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).completedSubmissionsControllerFindCompletedSubmissions(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CompletedSubmissionsDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerCreate(body: CompletedSubmissionsDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompletedSubmissionsDto> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).internalCompletedSubmissionsControllerCreate(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerFindCompletedSubmissionById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompletedSubmissionsDto> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).internalCompletedSubmissionsControllerFindCompletedSubmissionById(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerFindCompletedSubmissions(submissionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CompletedSubmissionsDto>> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).internalCompletedSubmissionsControllerFindCompletedSubmissions(submissionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CompletedSubmissionsDto} body 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerUpdate(body: CompletedSubmissionsDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CompletedSubmissionsDto> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).internalCompletedSubmissionsControllerUpdate(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Date} [buildBundleCreationTimeFrom] Start of range of build bundle creation times to generate statistics for
         * @param {Date} [buildBundleCreationTimeTo] End of range of build bundle creation times to generate statistics for
         * @param {string} [buildBundleIds] List of build bundles ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveJobTypesStatistics(buildBundleCreationTimeFrom?: Date, buildBundleCreationTimeTo?: Date, buildBundleIds?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<JobTypesResponseDto>> {
            const localVarFetchArgs = ReportingApiFetchParamCreator(configuration).jobTypesControllerRetrieveJobTypesStatistics(buildBundleCreationTimeFrom, buildBundleCreationTimeTo, buildBundleIds, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportingApi - factory interface
 * @export
 */
export const ReportingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealthCheck3(options?: any) {
            return ReportingApiFp(configuration).appControllerGetHealthCheck3(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedSubmissionsControllerFindCompletedSubmissionById(id: string, options?: any) {
            return ReportingApiFp(configuration).completedSubmissionsControllerFindCompletedSubmissionById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [userId] Get all the Completed Submissions of a specific User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedSubmissionsControllerFindCompletedSubmissions(userId?: string, options?: any) {
            return ReportingApiFp(configuration).completedSubmissionsControllerFindCompletedSubmissions(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CompletedSubmissionsDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerCreate(body: CompletedSubmissionsDto, options?: any) {
            return ReportingApiFp(configuration).internalCompletedSubmissionsControllerCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerFindCompletedSubmissionById(id: string, options?: any) {
            return ReportingApiFp(configuration).internalCompletedSubmissionsControllerFindCompletedSubmissionById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerFindCompletedSubmissions(submissionId: string, options?: any) {
            return ReportingApiFp(configuration).internalCompletedSubmissionsControllerFindCompletedSubmissions(submissionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CompletedSubmissionsDto} body 
         * @param {string} id The completed submissions id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCompletedSubmissionsControllerUpdate(body: CompletedSubmissionsDto, id: string, options?: any) {
            return ReportingApiFp(configuration).internalCompletedSubmissionsControllerUpdate(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Date} [buildBundleCreationTimeFrom] Start of range of build bundle creation times to generate statistics for
         * @param {Date} [buildBundleCreationTimeTo] End of range of build bundle creation times to generate statistics for
         * @param {string} [buildBundleIds] List of build bundles ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobTypesControllerRetrieveJobTypesStatistics(buildBundleCreationTimeFrom?: Date, buildBundleCreationTimeTo?: Date, buildBundleIds?: string, options?: any) {
            return ReportingApiFp(configuration).jobTypesControllerRetrieveJobTypesStatistics(buildBundleCreationTimeFrom, buildBundleCreationTimeTo, buildBundleIds, options)(fetch, basePath);
        },
    };
};

/**
 * ReportingApi - object-oriented interface
 * @export
 * @class ReportingApi
 * @extends {BaseAPI}
 */
export class ReportingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public appControllerGetHealthCheck3(options?: any) {
        return ReportingApiFp(this.configuration).appControllerGetHealthCheck3(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The completed submissions id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public completedSubmissionsControllerFindCompletedSubmissionById(id: string, options?: any) {
        return ReportingApiFp(this.configuration).completedSubmissionsControllerFindCompletedSubmissionById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [userId] Get all the Completed Submissions of a specific User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public completedSubmissionsControllerFindCompletedSubmissions(userId?: string, options?: any) {
        return ReportingApiFp(this.configuration).completedSubmissionsControllerFindCompletedSubmissions(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CompletedSubmissionsDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public internalCompletedSubmissionsControllerCreate(body: CompletedSubmissionsDto, options?: any) {
        return ReportingApiFp(this.configuration).internalCompletedSubmissionsControllerCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id The completed submissions id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public internalCompletedSubmissionsControllerFindCompletedSubmissionById(id: string, options?: any) {
        return ReportingApiFp(this.configuration).internalCompletedSubmissionsControllerFindCompletedSubmissionById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} submissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public internalCompletedSubmissionsControllerFindCompletedSubmissions(submissionId: string, options?: any) {
        return ReportingApiFp(this.configuration).internalCompletedSubmissionsControllerFindCompletedSubmissions(submissionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CompletedSubmissionsDto} body 
     * @param {string} id The completed submissions id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public internalCompletedSubmissionsControllerUpdate(body: CompletedSubmissionsDto, id: string, options?: any) {
        return ReportingApiFp(this.configuration).internalCompletedSubmissionsControllerUpdate(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Date} [buildBundleCreationTimeFrom] Start of range of build bundle creation times to generate statistics for
     * @param {Date} [buildBundleCreationTimeTo] End of range of build bundle creation times to generate statistics for
     * @param {string} [buildBundleIds] List of build bundles ids.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public jobTypesControllerRetrieveJobTypesStatistics(buildBundleCreationTimeFrom?: Date, buildBundleCreationTimeTo?: Date, buildBundleIds?: string, options?: any) {
        return ReportingApiFp(this.configuration).jobTypesControllerRetrieveJobTypesStatistics(buildBundleCreationTimeFrom, buildBundleCreationTimeTo, buildBundleIds, options)(this.fetch, this.basePath);
    }

}
