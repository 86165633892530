import { AttachmentResponseDto } from "../openapi/api";
import { ConfigurationParameters } from "../openapi/configuration";
import { uploadFile } from "./uploadFile";

export const ImageUploadApi = (configuration: ConfigurationParameters) => {
  const submissionControllerUploadAttachment = async (
    file: File,
    submissionId: string,
    submissionContentId: string,
    options?: any
  ): Promise<AttachmentResponseDto> => {
    const url = `${configuration?.basePath}/job-management/submissions/${submissionId}/submission-contents/${submissionContentId}/attachments`;
    return uploadFile(url, file, options);
  };

  const submissionControllerUpdateAttachment = async (
    file: File,
    submissionId: string,
    submissionContentId: string,
    attachmentId: string,
    options?: any
  ): Promise<AttachmentResponseDto> => {
    const url = `${configuration?.basePath}/job-management/submissions/${submissionId}/submission-contents/${submissionContentId}/attachments/${attachmentId}`;
    options.method = "PUT";
    return uploadFile(url, file, options);
  };

  return {
    submissionControllerUploadAttachment,
    submissionControllerUpdateAttachment,
  };
};
