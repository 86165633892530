import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { CreateBuildBundlesDto } from "components/utils/openapi";
import { ValidationMessageRequired } from "components/utils/validation";

import { JobList } from "../CreateNewJob/CreateNewJob.types";
import { useToast } from "index";
import { getGroupedJobs } from "components/utils/helpers";

interface JobDurationList {
  jobDuration: number;
}

interface CreateJobFormData {
  activityTemplate: { label: string; value: string };
  activityBuilds: number;
  jobs: JobList[];
  jobDurations: JobDurationList[];
  level: { label: string; value: string };
  subject?: { label: string; value: string };
  standard?: { label: string; value: number };
}

export const useCreateJob = ({
  defaultFields,
  handleNext,
}: {
  defaultFields: any;
  handleNext: (
    buildBundle: CreateBuildBundlesDto,
    buildBundleFile?: File
  ) => void;
}) => {
  // Can Be multiple, but for now just one file can be uploaded
  const [selectedFiles, setSelectedFiles] = useState<File[]>();
  const toast = useToast();

  const {
    register,
    reset,
    handleSubmit,
    setError,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateJobFormData>({
    mode: "onChange",
    defaultValues: defaultFields,
  });

  const { fields } = useFieldArray({
    control,
    name: "jobs",
  });

  const _ = useFieldArray({
    control,
    name: "jobDurations",
  })

  useEffect(() => {
    watch();
  }, []);

  const onCreateJob = async ({
    activityTemplate,
    activityBuilds,
    jobs,
    jobDurations,
    level,
    subject,
    standard,
  }: CreateJobFormData) => {
    let passedValidation = true;

    // fill jobDuration in jobs
    const groupedJobs = getGroupedJobs(jobs);
    let jobIdx = 0;
    Object.keys(groupedJobs).forEach((groupKey: string, groupIdx: number) => {
      groupedJobs[groupKey].forEach((job: any, idx: number) => {
        jobs[jobIdx].jobDuration = jobDurations[groupIdx].jobDuration;
        jobIdx++;
      });
    });
    //

    if (!activityTemplate) {
      passedValidation = false;
      setError("activityTemplate", {
        type: "manual",
        message: ValidationMessageRequired,
      });
    }
    if (!level) {
      passedValidation = false;
      setError("level", { type: "manual", message: ValidationMessageRequired });
    }
    if (!subject) {
      passedValidation = false;
      setError("subject", {
        type: "manual",
        message: ValidationMessageRequired,
      });
    }
    if (!standard) {
      passedValidation = false;
      setError("standard", {
        type: "manual",
        message: ValidationMessageRequired,
      });
    }
    if (!selectedFiles || selectedFiles.length === 0) {
      passedValidation = false;
      toast.addToast({
        description: "Please upload a resource .zip file",
        variant: "error",
      });
    }

    if (passedValidation) {
      const jobList = jobs.map(
        (job: { id: string; name: string; rate: string; jobDuration: number }) => {
          return { jobTypeId: job.id, payoutRate: Number(job.rate), jobDuration: job.jobDuration };
        }
      );

      const createBuildBundlesDto: CreateBuildBundlesDto = {
        activityTemplateId: activityTemplate.value,
        // @ts-ignore
        nceaCode: standard?.value,
        subjectId: subject?.value ?? "",
        levelId: level?.value ?? "",
        jobs: jobList,
        numberOfActivityBuilds: activityBuilds,
      };

      handleNext(
        createBuildBundlesDto,
        selectedFiles && selectedFiles?.length > 0
          ? selectedFiles[0]
          : undefined
      );
    }
  };

  return {
    fields,
    control,
    errors,
    register,
    reset,
    handleSubmit,
    onCreateJob,
    getValues,
    setValue,
    setSelectedFiles,
    watch,
  };
};
