const BASE_LOGIN = "/login";
const ACTIVE_JOBS = "/active-jobs";
const ONBOARDING_WIZARD = "/onboarding-wizard/:step?";
const ACCOUNT = "/account/:sectionID?";
const BASE_ADMIN = "/admin";
const BASE_TRAINING = "/training";
const ADMIN_JOBS = `${BASE_ADMIN}/jobs`;

export const PATH = {
  login: BASE_LOGIN,
  forgotPassword: `${BASE_LOGIN}/reset-password`,
  updatePassword: `${BASE_LOGIN}/update-password`,
  changePassword: `/change-password`,
  register: `${BASE_LOGIN}/register`,
  verify: `${BASE_LOGIN}/verify`,
  activeJobs: ACTIVE_JOBS,  
  activeJob: `${ACTIVE_JOBS}/:jobID`,
  getActiveJob: (activeSubmissionId: string) => 
    `active-jobs/${activeSubmissionId}`,
  job: "/job/:activityBuildJobId",
  getJob: (activityBuildJobId: string) => `job/${activityBuildJobId}`,
  browseJobs: "/browse-jobs",
  completedJobs: "/completed-jobs",
  completed: "/completed",
  feedback: "/feedback/:completedSubmissionId",
  getFeedback: (completedSubmissionId: string) =>
    `feedback/${completedSubmissionId}`,
  onboardingWizard: ONBOARDING_WIZARD,
  getOnboardingWizard: (step: string) =>
    ONBOARDING_WIZARD.replace(":step?", step),
  sandBoxEditor: "/sandbox-editor",
  earnings: "/earnings",
  account: ACCOUNT,
  getAccountSection: (section: string) =>
    ACCOUNT.replace(":sectionID?", section),
  admin: BASE_ADMIN,
  adminJobs: ADMIN_JOBS,
  adminArchivedJobs: `${ADMIN_JOBS}/archived`,
  adminCancelledJobs: `${ADMIN_JOBS}/cancelled`,
  adminCreate: `${ADMIN_JOBS}/create-job`,
  adminJobTemplates: `${BASE_ADMIN}/templates`,
  adminJobTemplatesPreview: `${BASE_ADMIN}/templates/:templateId/preview`,
  adminJobTemplatesEdit: `${BASE_ADMIN}/templates/:templateId/edit`,
  getAdminJobTemplatesPreview: (templateId: string) =>
    `${BASE_ADMIN}/templates/${templateId}/preview`,
  getAdminJobTemplatesEdit: (templateId: string) =>
    `${BASE_ADMIN}/templates/${templateId}/edit`,
  training: BASE_TRAINING,
};
