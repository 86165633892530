import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import ReactPaginate from "react-paginate";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/lib/atoms/Table/Table";
import { Stack } from "components/lib/layouts";
import { Spinner, Text } from "components/lib/atoms";

import { TableWithPaginationPropsType } from "./TableWithPagination.types";
import "./TableWithPagination.css";

export const TableWithPagination = ({
  isLoading,
  tableHeadings,
  emptyMessage,
  children,
  rowsPerPage = 12,
  onClick,
  colgroup,
}: TableWithPaginationPropsType): JSX.Element => {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState<React.ReactNodeArray>();
  const [allData, setAllData] = useState<React.ReactNodeArray>();
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    setAllData(children);
    setCurrentPage(children.slice(0, rowsPerPage));
    setPageCount(Math.ceil(children.length / rowsPerPage));
  }, [children, rowsPerPage]);

  const handlePageClick = (data: any) => {
    const selected = data.selected * rowsPerPage;
    setCurrentPage(allData?.slice(selected, selected + rowsPerPage));
    onClick && onClick();
  };

  const generateEmptyRowsForMax = (amount: number) => {
    let emptyRows = [];
    for (let index = 0; index < amount; index++) {
      emptyRows.push(
        <TableRow key={index}>
          <TableData>&nbsp;</TableData>
        </TableRow>
      );
    }
    return emptyRows;
  };

  return (
    <Stack direction="vertical" gap={3} wrap="nowrap" fullWidth={true}>
      <Table>
        {colgroup}
        <TableHead>
          <TableRow isHeading={true}>
            {tableHeadings &&
              tableHeadings?.map((heading: string) => {
                return <TableHeading key={heading}>{heading}</TableHeading>;
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableData>
                <Spinner />
              </TableData>
            </TableRow>
          ) : (
            <>
              {currentPage}
              {currentPage &&
                children?.length > rowsPerPage &&
                currentPage.length < rowsPerPage &&
                generateEmptyRowsForMax(rowsPerPage - currentPage?.length)}
            </>
          )}
        </TableBody>
      </Table>
      {children.length > rowsPerPage && (
        <ReactPaginate
          previousLabel={"‹"}
          nextLabel={"›"}
          breakLabel={"..."}
          breakClassName={"break"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      )}
      {children?.length === 0 && (
        <Text styles={{ padding: theme.space[2] }}>{emptyMessage}</Text>
      )}
    </Stack>
  );
};
