import { CSSObject } from "@emotion/serialize";

export const theme = {
  space: [
    "0px",
    "8px",
    "12px",
    "16px",
    "24px",
    "32px",
    "40px",
    "48px",
    "56px",
    "64px",
    "72px",
    "80px",
    "160px",
  ],
  fonts: {
    primary: `"Graphit", sans-serif`,
  },
  fontWeights: { light: 200, regular: 400, medium: 500, bold: 700 },
  fontSizes: [
    "0px",
    "12px",
    "14px",
    "16px",
    "20px",
    "22px",
    "26px",
    "32px",
    "40px",
  ],
  breakpoints: ["576px", "768px", "992px"],
  lineThickness: ["1px", "2px", "4px"],
  radii: ["0", "2px", "3px", "4px", "6px", "50%", "999999px"],
  colors: {
    black: {
      default: "#000000",
    },
    gradient: {
      brand: "linear-gradient(90deg, #FF4200 .01%, #E50695 99.99%)",
    },
    pink: {
      default: "#FF206E",
      light: "#FFC0D6",
      translucent: "rgba(255, 32, 110, 0.1)",
    },
    grey: {
      default: "#555555",
      dark: "#222222",
      light: "#F6F5F9",
      text: "#827C8E",
      input: "#A9A2B6",
      border: "#D6D6D6",
      selected: "#F3F4F5",
      surface: "#FAF9FB",
      darkSurface: "#E6E4E8",
    },
    red: {
      default: "#D0021B",
      border: "#D0021B",
      surface: "#FFF6F6",
    },
    white: {
      default: "#FFFFFF",
    },
    blue: {
      default: "#4E6FFF",
      light: " #ACDBFF",
      dark: "#1A567E",
      surface: "#D9EDF7",
    },
    mint: {
      default: "#2CD5C4",
      dark: "#13A698",
      surface: "#8CE2D0",
    },
    green: {
      default: "#32A653",
      surface: "#ECF7E7",
    },
    yellow: {
      default: "#FFD95C",
      dark: "#86681D",
      surface: "#FEF7E3",
    },
    alert: "#EFF8FF",
    purple: {
      base: "#280071",
      default: "#6638B6",
      dark: "#2A184B",
      light: "#F3F2FC",
      border: "#381771",
    },
  },
  shadows: {
    0: "none",
    1: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    2: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    3: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 0px 6px -2px rgba(0, 0, 0, 0.1)",
    4: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  },
  zIndices: {
    marianatrench: -999999,
    default: 1,
    sidebar: 3000,
    navbar: 4000,
    tooltip: 5000,
    dropdown: 6000,
    overlay: 80000,
    modal: 90000,
    toast: 10000,
  },
};

export const getStyles = ({
  variant,
  variants,
  styles = {},
  base,
}: {
  base: CSSObject;
  variant?: string | number;
  variants?: Record<string | number, CSSObject>;
  styles?: CSSObject;
}) => {
  const variantStyles = variant && variants ? variants[variant] : {};
  return { ...base, ...variantStyles, ...styles };
};
