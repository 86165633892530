import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useMutation } from "react-query";

export const useBuildBundleUnarchive = () => {
  const { getToken } = useAuth();
  return useMutation(async (buildBundleId: string) => {
    const { getConfiguration } = AppConfig();
    const token = await getToken();
    const api = new JobManagementApi(getConfiguration(token));
    return api.buildBundlesControllerUnarchiveBuildBundle(
      buildBundleId,
      getDefaultOptions(token)
    );
  });
};
