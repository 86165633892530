import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils/theme";

export const styles: Record<string, CSSObject> = {
  SectionTextButton: {
    textAlign: "left",
    padding: `${theme.space[4]} ${theme.space[4]}`,
    color: theme.colors.purple.default,
    ":hover": {
      color: theme.colors.purple.dark,
    },
  },
  NextButton: {
    paddingTop: theme.space[3],
  },
};
