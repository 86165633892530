import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { AppContainerStyles } from "./components/shared/styles/layout.styles";

export const getLayoutStyles = (theme: Theme): CSSObject => {
  return { height: "100vh", background: theme.colors.grey.surface };
};

export const getMainContentStyles = (_theme: Theme): CSSObject => {
  return {
    height: "calc(100vh - 120px )",
    overflowY: "auto",
    overflowX: "unset",
    flex: 1,
  };
};

export const getContainerStyles = (
  theme: Theme,
  isAdmin: boolean
): CSSObject => {
  return {
    ...AppContainerStyles,
    flex: 1,
    paddingRight: theme.space[3],
    ...(isAdmin ? { maxWidth: "100%" } : { paddingLeft: theme.space[3] }),
  };
};

export const getContentStyles = (theme: Theme, isAdmin: boolean): CSSObject => {
  return {
    ...AppContainerStyles,
    marginBottom: theme.space[4],
    ...(isAdmin ? { maxWidth: "100%" } : { paddingTop: theme.space[6] }),
  };
};
