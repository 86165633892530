import { Stack } from "components/lib/layouts/Stack/Stack";
import { ActiveJobsPanel } from "./components/ActiveJobsPanel/ActiveJobsPanel";
import { ExpiredJobsPanel } from "./components/ExpiredJobsPanel/ExpiredJobsPanel";

export const ActiveJobsPage: React.FC = () => {
  return (
    <Stack direction="vertical" gap={4} fullWidth={true}>
      <ActiveJobsPanel />
      <ExpiredJobsPanel />
    </Stack>
  );
};
