import { useQuery } from "react-query";
import { useAuth } from "components/utils/auth";
import { IdentityApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export const useUser = () => {
  const { getToken } = useAuth();
  return useQuery(["getUser"], async () => getUser(await getToken()));
};

const getUser = async (token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new IdentityApi(getConfiguration(token));
  return api.usersControllerFindUserInformation(getDefaultOptions(token));
};
