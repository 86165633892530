import { useTheme } from "@emotion/react";
// @ts-ignore
import ReactHtmlParser, {
  htmlparser2,
  // @ts-ignore
} from "react-html-parser";
// @ts-ignore
import MathJax from "react-mathjax-preview";
import { Stack } from "components/lib/layouts";
import { RichPreviewProps } from "./RichPreview.types";
import * as styles from "./RichPreview.styles";

function transform(node: any) {
  if (node.type === "tag" && node.name === "math") {
    const innerHTML = htmlparser2.DomUtils.getInnerHTML(node);
    const math = `<math>${innerHTML} </math>`;
    return (
      <span style={{ display: "inline-flex" }}>
        <MathJax math={math} />
      </span>
    );
  }
}

export const RichPreview: React.FC<RichPreviewProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="vertical"
      styles={styles.getContainer(theme, { ...props })}
    >
      {ReactHtmlParser(children, {
        transform,
      })}
    </Stack>
  );
};
