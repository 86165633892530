import { Card, Divider, Heading, Paragraph } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { theme } from "components/lib/utils";
import TraningStepper from "./components/TraningStepper/TraningStepper";

export function TraningsPage() {
  return (
    <Stack direction="vertical" gap={4} fullWidth>
      <Heading variant={1} weight={500}>
        Trainings
      </Heading>
      <Card>
        <Heading variant={3}>Introduction text</Heading>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
          suscipit finibus tortor, non interdum massa pharetra sed. Sed suscipit
          placerat pharetra. Orci varius natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus. Vestibulum sollicitudin est
          eu tellus elementum, id tristique erat facilisis. Duis sed commodo
          augue. Vestibulum aliquet non turpis et aliquet.
        </Paragraph>
      </Card>
      <Card>
        <Heading variant={2} styles={{ paddingBottom: theme.space[3] }}>
          List of trainings
        </Heading>
        <Card>
          <Heading variant={3} styles={{ paddingBottom: theme.space[1] }}>
            Job type
          </Heading>
          <Divider />
          <TraningStepper />
        </Card>
      </Card>
    </Stack>
  );
}
