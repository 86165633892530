import { useMutation } from "react-query";
import { useAuth } from "components/utils/auth";
import {
  IdentityApi,
  UserInformationEndorsementsDto,
} from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export const useUpdateUserEndorsements = () => {
  const { getToken } = useAuth();
  return useMutation(async (endorsements: Partial<UserInformationEndorsementsDto>) => {
    const { getConfiguration } = AppConfig();
    const token = await getToken();
    const api = new IdentityApi(getConfiguration(token));
    return api.usersControllerUpdateUserEndorsements(
      endorsements,
      getDefaultOptions(token)
    );
  });
};
