import { useQuery } from "react-query";
import { ReportingApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

export const useCompletedSubmissionsList = () => {
  const { getToken, getSession } = useAuth();
  return useQuery(["getCompletedSubmissionsList"], async () => {
    const token = await getToken();
    const userId = await getSession();
    return getCompletedSubmissionsList(userId?.idToken?.payload?.sub, token);
  });
};

const getCompletedSubmissionsList = async (userId?: string, token?: string) => {
  const { getConfiguration } = AppConfig();
  const api = new ReportingApi(getConfiguration(token));
  return api.completedSubmissionsControllerFindCompletedSubmissions(
    userId,
    getDefaultOptions(token)
  );
};
