import React from "react";
import { Button, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { InputField } from "components/lib/molecules";
import { PATH } from "components/shared/constants/paths.constants";

import {
  AuthCard,
  AuthHeader,
  AuthLink,
} from "../CommonElements/CommonElements";
import { useResetPassword } from "./useResetPassword.hook";
import { VerifyEmailMessage } from "./components/VerifyEmailMessage";
import { FormFieldTitle } from "../CommonElements/FormFieldTitle";
import { validationEmail } from "components/utils/validation";

export const ResetPassword = () => {
  const {
    formStatus,
    isSubmitting,
    register,
    handleSubmit,
    handleResetPassword,
    errors,
  } = useResetPassword();

  const isSubmitDisabled = Object.keys(errors).length > 0;

  if (formStatus === "verification") {
    return <VerifyEmailMessage />;
  }

  return (
    <AuthCard>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Stack direction="vertical" gap={4} fullWidth>
          <AuthHeader>Reset Password</AuthHeader>

          <Text>Enter your email address and click Send Email.</Text>

          <Stack direction="vertical" gap={1}>
            <FormFieldTitle>Email address</FormFieldTitle>
            <InputField
              validationMessage={errors.email?.message}
              placeholder="Enter email address"
              {...register("email", {
                required: true,
                pattern: validationEmail,
              })}
            />
          </Stack>

          <Button type="submit" disabled={isSubmitDisabled}>
            Send email
          </Button>

          <Text align="center">
            <AuthLink to={PATH.login}>Cancel</AuthLink>
          </Text>
        </Stack>
      </form>
    </AuthCard>
  );
};
