export const ValidationMessageInvalidNumber = "The number is invalid";
export const ValidationMessageInvalidMobileNumber =
  "The mobile number is invalid";
export const ValidationMessageInvalidPostCode = "The post code is invalid";
export const ValidationMessageRequired = "This field is required";
export const ValidationMessageInvalidLink = "The link is invalid";
export const ValidationMessageInvalidVimeoLink =
  "The link needs to be an embedded Vimeo link";
export const ValidationMessageInvalidEmail = "The email address is invalid";
export const ValidationMessageInvalidPassword =
  "Your password does not meet the requirements";
export const ValidationMessageNumberLimit = "Number must be between 0 - 35";

export const validationValidVimeoLink = {
  value: /^https:\/\/player.vimeo.com\/video\/(\d*$)/g,
  message: ValidationMessageInvalidVimeoLink,
};

export const validationNumberToTwoDecimals = {
  value: /^\d+(\.\d{1,2})?$/i,
  message: ValidationMessageInvalidNumber,
};

export const validationNumberLimit = {
  value: /(^\d$)|(^[12][0-9]$)|(^3[0-5]$)/,
  message: ValidationMessageNumberLimit,
};

export const validationWholeNumber = {
  value: /^[1-9]\d*$/i,
  message: ValidationMessageInvalidNumber,
};

export const validationMobileNumber = {
  value: /^[0-9]+( [0-9]+)*$/,
  message: ValidationMessageInvalidMobileNumber,
};

export const validationPostCode = {
  value: /^\d{4}$/,
  message: ValidationMessageInvalidPostCode,
};

export const validationEmail = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: ValidationMessageInvalidEmail,
};

export const validationPassword = {
  /* Password Regext
    ^ - beginning of the string
    (?=.*\d) - There must be at least one digit
    (?=.*[a-zA-Z]) - There must be at least one ASCII letter (Capital or Not)
    .{8,} - There must be eight or more ASCII alphanumeric chars
    % - end of the string
  */
  value: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/i,
  message: ValidationMessageInvalidPassword,
};
