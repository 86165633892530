import { useTheme } from "@emotion/react";
import React from "react";
import { Text } from "../../lib/atoms/Text/Text";
import { Stack } from "../../lib/layouts/Stack/Stack";
import { AppContainerStyles } from "../styles/layout.styles";

export const Footer = () => {
  const theme = useTheme();
  return (
    <Stack
      direction="horizontal"
      justify="center"
      align="center"
      fullWidth={true}
      styles={{
        marginTop: "auto",
        height: "fit-content",
        background: theme.colors.grey.darkSurface,
      }}
    >
      <Stack
        direction="horizontal"
        justify="space-between"
        align="center"
        fullWidth={true}
        styles={{
          ...AppContainerStyles,
          height: "60px",
          padding: `0 ${theme.space[3]}`,
        }}
      >
        <Text>Copyright 2021 LearnCoach</Text>
        <Text>
          <a
            rel="noopener noreferrer"
            href="https://my.learncoach.com/docs/LearnCoach-Terms-and-Conditions-v03-02-2021.pdf"
            target="_blank"
            style={{ textDecoration: "none", color: theme.colors.purple.dark }}
          >
            Terms of Service
          </a>{" "}
          | Privacy policy
        </Text>
      </Stack>
    </Stack>
  );
};
