import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";

import { UpdateActivityBuildDto } from "components/utils/openapi";
import { PATH } from "components/shared/constants/paths.constants";
import { Button } from "components/lib/atoms/Button/Button";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { RichPreview } from "components/shared";
import { Heading } from "components/lib/atoms";

import { JobType } from "../../JobEditPage";

export const InstructionsSection = ({
  instructions,
  activityBuild,
  submissionId,
  createNewQuestion,
  jobType,
  isReview,
}: {
  instructions?: string;
  submissionId: string;
  activityBuild: UpdateActivityBuildDto;
  jobType: JobType;
  createNewQuestion: () => Promise<any>;
  isReview: boolean | undefined;
}) => {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Stack direction="vertical" gap={3} fullWidth={true}>
      <Heading variant={2}>Instructions</Heading>
      <Stack direction="horizontal" gap={3} fullWidth={true}>
        {instructions && <RichPreview>{instructions}</RichPreview>}
      </Stack>
      <ReactPlayer
        url={activityBuild?.videoLink}
        width="100%"
        height="100%"
        config={{
          vimeo: {
            playerOptions: {
              title: activityBuild?.name,
              responsive: true,
              autoplay: false,
              fullscreen: true,
              pip: true,
              controls: true,
            },
          },
        }}
      />
      <Stack
        direction="horizontal"
        gap={1}
        styles={{
          padding: theme.space[2],
          justifyContent: "flex-end",
          position: "fixed",
          bottom: 0,
          left: 0,
          rigth: 0,
          width: "100%",
          border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
          background: theme.colors.white.default,
        }}
      >
        {jobType === "mcq" && (
          <Button styles={{ minWidth: "120px" }} onClick={createNewQuestion}>
            Write a question
          </Button>
        )}
        {isReview && (
          <Button
            styles={{ minWidth: "120px" }}
            onClick={() => {
              history.push({
                pathname: `${PATH.activeJobs}/${submissionId}/resource-job`,
              });
            }}
          >
            Review Job
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
