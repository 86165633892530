import { useTheme } from "@emotion/react";
import pluralize from "pluralize";
import { formatBytes } from "components/lib/utils";
import { getStyles } from "../../utils/theme";
import { useDropzone } from "react-dropzone";
import { UploadImageProps } from "./UploadImage.types";
import { getUploadImageStyles } from "./UploadImage.styles";
import { Button } from "../../atoms/Button/Button";
import { Stack } from "../../layouts/Stack/Stack";
import { ReactComponent as UploadIcon } from "./svgs/uploadIcon.svg";
import { ValidationMessage } from "../../atoms/ValidationMessage/ValidationMessage";

const resolveImageDimensions = async (event: any): Promise<any> => {
  const files = event?.target?.files;
  const promises = [];
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    const promise = new Promise((resolve, reject) => {
      const image = new Image();
      let url: string;
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      url = URL.createObjectURL(file);
      image.src = url;
    });
    promises.push(promise);
  }
  return Promise.all(promises);
};

export const UploadImage = ({
  maxFiles = 1,
  validator,
  accept,
  width,
  height,
  maxSize,
  uploadImage,
  styles = {},
}: UploadImageProps): JSX.Element => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    getFilesFromEvent: resolveImageDimensions,
    maxFiles: maxFiles,
    accept: accept,
    validator: (file: any) => {
      if (width && width > file.width && height && height > file.height) {
        return null;
      }
      return {
        code: "dimensions-too-large",
        message: `You have selected an image with dimensions that is larger than ${width}px in width or ${height}px in height`,
      };
    },
    maxSize: maxSize,
    noClick: true,
    noDrag: true,
  });

  const theme = useTheme();
  const { base } = getUploadImageStyles(theme);
  const dropzoneStyles = getStyles({
    base: {
      ...base,
    },
    styles: styles,
  });

  const removeFile = (file: File) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
  };

  const acceptedFileItems = acceptedFiles.map((file) => (
    <Stack key={file.name} direction="horizontal" gap={3} fullWidth={false}>
      <span style={{ verticalAlign: "bottom", lineHeight: "48px" }}>
        {file.name}
      </span>
      <Button variant="warning" onClick={removeFile(file)}>
        Remove
      </Button>
    </Stack>
  ));

  const validationErrors = () => {
    if (fileRejections.length === 0) {
      return null;
    }
    let errorMessage = "";
    switch (fileRejections[0].errors[0].code) {
      case "too-many-files":
        errorMessage = `You have selected more than ${pluralize(
          "image",
          maxFiles,
          true
        )}`;
        break;
      case "file-too-large":
        errorMessage = `You have selected an image larger than ${formatBytes(
          maxSize!
        )}`;
        break;
      case "dimensions-too-large":
        errorMessage = fileRejections[0].errors[0].message;
        break;
      default:
        errorMessage =
          "The image you have selected is unable to be uploaded, please select another";
        break;
    }
    return (
      <ValidationMessage styles={{ marginTop: "10px" }} text={errorMessage} />
    );
  };

  return (
    <Stack direction="vertical" gap={3} fullWidth={false}>
      <div {...getRootProps({ styles: dropzoneStyles })}>
        <input {...getInputProps()} />
        {acceptedFiles.length === 0 && (
          <Button variant="secondary" onClick={open} size="medium">
            Choose image
          </Button>
        )}
        {acceptedFiles.length > 0 && acceptedFileItems}
      </div>
      {validationErrors()}
      <Stack direction="horizontal" gap={3}>
        <Button
          variant="primary"
          onClick={() => uploadImage(acceptedFiles)}
          style={{ display: "inline-block", fontWeight: "unset" }}
          size="medium"
        >
          <UploadIcon />
          &nbsp;&nbsp;Upload selected image
        </Button>
      </Stack>
      <Stack
        styles={{
          fontWeight: theme.fontWeights.regular,
          color: theme.colors.grey.text,
          fontSize: theme.fontSizes[2],
        }}
        direction="vertical"
        gap={1}
      >
        <div style={{ fontWeight: theme.fontWeights.medium }}>
          Image requirements
        </div>
        <div>
          Width and height: {height}px | Max{" "}
          {maxSize ? formatBytes(maxSize) : "(no size limit)"} image upload
        </div>
      </Stack>
    </Stack>
  );
};
