import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import { ToastContext } from "index";
import UserPool from "../../../utils/cognitoPool";
import { PATH } from "components/shared/constants/paths.constants";

export const useRegister = () => {
  const history = useHistory();
  const toast = useContext(ToastContext);
  const [isTermsChecked, setIsTermsChecked] = useState(true);
  const [loading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    watch,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    watch();
  }, []);

  const onSignup = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    UserPool.signUp(
      email,
      password,
      [
        new CognitoUserAttribute({
          Name: "custom:isTermsAccepted",
          Value: String(isTermsChecked),
        }),
      ],
      [],
      (err) => {
        setIsLoading(false);
        if (err) {
          var errorMessage =
            err.message || "Failed to register, try again later";
          if (err.name === "InvalidParameterException") {
            errorMessage =
              "Your password does not meet the requirements, please change your password";
          }
          toast.addToast({
            description: errorMessage,
            variant: "error",
          });
        } else {
          window.sessionStorage.setItem("pass", password);
          history.push(`${PATH.login}/verify?email=${email}`);
        }
      }
    );
  };

  return {
    isTermsChecked,
    setIsTermsChecked,
    errors,
    getValues,
    register,
    handleSubmit,
    onSignup,
    isDirty,
    isValid,
    isLoading: loading,
  };
};
