/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { Input } from "components/lib/atoms/Input/Input";
import { ValidationMessage } from "components/lib/atoms/ValidationMessage/ValidationMessage";
import React from "react";
import { getStyles } from "../../utils/theme";
import { getInputFieldStyles } from "./InputField.styles";
import { InputFieldProps } from "./InputField.types";

export const InputField = React.forwardRef(
  (
    {
      styles = {},
      placeholder = "",
      disabled = false,
      validationMessage = "",
      fullWidth = false,
      ...restProps
    }: InputFieldProps,
    ref?: React.Ref<any>
  ) => {
    const theme = useTheme();
    return (
      <div
        css={getStyles({
          base: {
            ...getInputFieldStyles(theme).base,
            ...(fullWidth ? { width: "100%" } : {}),
          },
          styles: styles,
        })}
      >
        <Input
          styles={
            validationMessage.length !== 0
              ? {
                  border: `${theme.lineThickness[0]} solid ${theme.colors.red.border}`,
                }
              : {}
          }
          placeholder={placeholder}
          disabled={disabled}
          {...restProps}
        />
        {validationMessage.length !== 0 && (
          <ValidationMessage
            styles={{ marginTop: "10px" }}
            text={validationMessage}
          />
        )}
      </div>
    );
  }
);
