import { useQuery } from "react-query";
import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export const useExpiredJobsList = (
  fromUpdatedAt?: Date,
  toUpdatedAt?: Date
) => {
  const { getToken } = useAuth();
  return useQuery(
    ["getExpiredJobsList"],
    async () =>
      await getExpiredJobsList(await getToken(), fromUpdatedAt, toUpdatedAt)
  );
};

const getExpiredJobsList = async (
  token: string | null,
  fromUpdatedAt?: Date,
  toUpdatedAt?: Date
) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.submissionControllerFindSubmissions(
    "expired",
    fromUpdatedAt,
    toUpdatedAt,
    getDefaultOptions(token)
  );
};
