import { useMutation } from "react-query";
import { useAuth } from "components/utils/auth";
import {
  IdentityApi,
  UserInformationDetailsDto,
} from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export const useUpdateUserDetails = () => {
  const { getToken } = useAuth();
  return useMutation(async (details: Partial<UserInformationDetailsDto>) => {
    const { getConfiguration } = AppConfig();
    const token = await getToken();
    const api = new IdentityApi(getConfiguration(token));
    return api.usersControllerUpdateUserDetails(
      details,
      getDefaultOptions(token)
    );
  });
};
