import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils";
import { CSSProperties } from "react";

export const filter: CSSObject = {
  flexBasis: "240px",
  background: theme.colors.white.default,
  padding: theme.space[4],
  marginBottom: theme.space[4],
};

export const filterCheckbox: CSSObject = {
  marginBottom: theme.space[1],
  marginTop: theme.space[1],
};

export const filterCheckboxText: CSSObject = {
  display: "initial",
};

export const filterDivider: CSSObject = {
  marginTop: theme.space[5],
};

export const jobTypeTitle: CSSObject = {
  marginBottom: theme.space[2],
};
export const standardTypeTitle: CSSObject = {
  ...jobTypeTitle,
  paddingTop: theme.space[4],
};

export const standardSelect: CSSObject = {
  marginTop: theme.space[1],
};

export const clearButton: CSSObject = {
  width: "60px",
  marginTop: theme.space[5],
};
export const clearButtonContent: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
};

export const clearButtonIcon: CSSProperties = {
  marginTop: "2px",
  marginRight: theme.space[1],
};

export const clearButtonText: CSSProperties = {
  fontSize: theme.fontSizes[1],
};

export const jobs: CSSObject = {
  flex: 1,
};

export const activeJobLink: CSSProperties = {
  textDecoration: "none",
};

export const sortOrder: CSSObject = {
  width: "200px",
};

export const jobsContent: CSSObject = {
  minHeight: "500px",
};
