/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { StackPropsType } from "./Stack.types";
import { getStackStyles } from "./Stack.styles";

export const Stack = ({
  id,
  fullWidth,
  justify = "unset",
  inline = true,
  align = "unset",
  direction = "horizontal",
  gap = 0,
  wrap = "wrap",
  styles,
  ...restProps
}: StackPropsType): JSX.Element => {
  const theme = useTheme();

  return (
    <div
      id={id}
      css={getStackStyles(theme, {
        fullWidth,
        justify,
        inline,
        align,
        direction,
        gap,
        wrap,
        styles,
      })}
      {...restProps}
    />
  );
};
