import { AppConfig, getFileOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";
import {
  FileUploadApi,
  FileUploadType,
} from "components/utils/openapi-custom/FileUploadApi";
import {
  AttachmentResponseDto,
  JobManagementApi,
} from "components/utils/openapi";

export type JobTypeUpload = {
  file: any;
  jobTypeId: string;
};

export const useJobTypeUploadAttachment = () => {
  const { getToken } = useAuth();
  const { getConfiguration } = AppConfig();

  const uploadResourceAttachment = async (
    file: any,
    jobTypeId: string
  ): Promise<AttachmentResponseDto> => {
    // Use File Options Instead
    const token = await getToken();
    const api = FileUploadApi(getConfiguration(token));

    // Swagger Codegen Api
    // const api = new JobManagementApi(getConfiguration(token));
    return api.uploadAttachment(
      file,
      jobTypeId,
      FileUploadType.JOB_TYPE,
      getFileOptions(token)
    );
  };

  const updateResourceAttachment = async (
    file: any,
    jobTypeId: string,
    attachmentId: string
  ) => {
    const token = await getToken();
    const api = FileUploadApi(getConfiguration(token));
    return api.updateAttachment(
      file,
      jobTypeId,
      attachmentId,
      FileUploadType.JOB_TYPE,
      getFileOptions(token)
    );
  };

  const deleteResourceAttachment = async (
    jobTypeId: string,
    attachmentId: string
  ) => {
    const token = await getToken();
    const api = new JobManagementApi(getConfiguration(token));
    return api.jobTypesControllerDeleteJobTypesAttachment(
      jobTypeId,
      attachmentId,
      getFileOptions(token)
    );
  };

  return {
    uploadResourceAttachment,
    updateResourceAttachment,
    deleteResourceAttachment,
  };
};
