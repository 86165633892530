import { CSSObject } from "@emotion/serialize";
import { Theme } from "@emotion/react";
import { TableRowProps } from "./Table.types";

import { getStyles } from "../../utils";

export const getTableStyles = ({ styles }: Partial<TableRowProps>) => {
  const base: CSSObject = {
    border: 0,
    borderCollapse: "collapse",
  };

  return getStyles({ base, styles });
};

export const getTableRowStyles = (
  theme: Theme,
  { isHeading, styles }: Partial<TableRowProps>
) => {
  const nonHeading: CSSObject = {
    borderBottom: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
  };

  const heading: CSSObject = {
    background: theme.colors.grey.surface,
    border: `${theme.lineThickness[0]} solid ${theme.colors.grey.border}`,
    borderRadius: `${theme.radii[1]} ${theme.radii[1]} 0 0`,
  };

  const base = { ...(isHeading ? heading : nonHeading) };

  return getStyles({ base, styles });
};

export const getTableDataStyles = (
  theme: Theme,
  { styles }: Partial<TableRowProps>
) => {
  const base: CSSObject = {
    textAlign: "left",
    padding: theme.space[3],
    margin: 0,
    fontFamily: theme.fonts.primary,
    color: theme.colors.purple.dark,
    fontWeight: theme.fontWeights.light,
  };

  return getStyles({ base, styles });
};

export const getTableHeadingStyles = (
  theme: Theme,
  { styles }: Partial<TableRowProps>
) => {
  const base: CSSObject = {
    textAlign: "left",
    padding: theme.space[3],
    margin: 0,
    fontFamily: theme.fonts.primary,
    color: theme.colors.purple.dark,
    fontWeight: theme.fontWeights.regular,
  };

  return getStyles({ base, styles });
};
