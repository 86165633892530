import { useTheme } from "@emotion/react";
import { Text } from "components/lib/atoms/Text/Text";
import React from "react";
import { Link } from "react-router-dom";

export const AppLink = ({
  path,
  children,
}: {
  path: string;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Link to={path} style={{ display: "block", textDecoration: "none" }}>
      <Text
        color={theme.colors.purple.base}
        styles={{
          cursor: "pointer",
          ":hover": {
            textDecoration: "underline",
            color: theme.colors.purple.default,
          },
        }}
      >
        {children}
      </Text>
    </Link>
  );
};
