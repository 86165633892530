import { useAuth } from "components/utils/auth";
import { IdentityApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useMutation } from "react-query";

export const useCreateStripeLink = () => {
  const { getToken } = useAuth();

  return useMutation(async () => {
    const { getConfiguration } = AppConfig();
    const token = await getToken();
    const api = new IdentityApi(getConfiguration(token));
    return api.usersControllerGetStripeLink(getDefaultOptions(token));
  });
};
