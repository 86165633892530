import React, { useState } from "react";

import { Button } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { InputField } from "components/lib/molecules";

import { AuthCard, AuthHeader } from "../CommonElements/CommonElements";
import { FormFieldTitle } from "../CommonElements/FormFieldTitle";
import { useUpdatePassword } from "./useUpdatePassword.hook";
import {
  PasswordRequirements,
  PasswordToggle,
} from "components/pages/LoginPage/CommonElements";
import {
  ValidationMessageRequired,
  validationPassword,
} from "components/utils/validation";
/**
 * Example url to access this page
 * localhost:3000/login/update-password?email=test@email.com&code=12345
 */

export const UpdatePassword = () => {
  const {
    getValues,
    handleSubmit,
    handleResetPassword,
    register,
    errors,
    isSubmitting,
  } = useUpdatePassword();
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const isSubmitDisabled = Object.keys(errors).length > 0 || isSubmitting;

  return (
    <AuthCard>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Stack direction="vertical" gap={4} fullWidth>
          <AuthHeader>Update your password</AuthHeader>

          <Stack direction="vertical" gap={1}>
            <Stack justify="space-between" gap={0}>
              <FormFieldTitle>New password</FormFieldTitle>
              <PasswordToggle
                isPasswordShown={isPasswordShown}
                onClick={() => setIsPasswordShown(!isPasswordShown)}
              />
            </Stack>
            <InputField
              validationMessage={errors.password?.message}
              type={isPasswordShown ? "text" : "password"}
              autoComplete="off"
              autoFocus
              placeholder="Enter new password"
              {...register("password", {
                required: ValidationMessageRequired,
                pattern: validationPassword,
              })}
            />
          </Stack>
          <PasswordRequirements password={getValues("password")} />
          <Button type="submit" disabled={isSubmitDisabled}>
            Update password
          </Button>
        </Stack>
      </form>
    </AuthCard>
  );
};
