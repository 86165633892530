import { useQuery } from "react-query";
import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export type ActivitybuildJobsSearchQueries = {
  page?: number;
  limit?: number;
  sort?: string;
  sortDirection?: string;
  jobTypeCode?: string[];
  nceaCode?: string;
  subjectId?: string;
  levelId?: string;
};

export const useActivityBuildJobs = (
  queries?: ActivitybuildJobsSearchQueries
) => {
  const { getToken } = useAuth();
  return useQuery(
    [
      "getActivityBuildJobs",
      queries?.jobTypeCode,
      queries?.levelId,
      queries?.subjectId,
      queries?.nceaCode,
    ],
    async () => getActivityBuildJobs(await getToken(), queries)
  );
};

const getActivityBuildJobs = async (
  token: string | null,
  queries?: ActivitybuildJobsSearchQueries
) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  const {
    page,
    limit,
    sort,
    sortDirection,
    jobTypeCode,
    nceaCode,
    subjectId,
    levelId,
  } = queries || {};
  return api.activityBuildJobsControllerSearch(
    page,
    limit,
    sort,
    sortDirection,
    jobTypeCode && jobTypeCode?.length > 0 ? jobTypeCode?.join(",") : undefined,
    levelId,
    subjectId,
    nceaCode,
    getDefaultOptions(token)
  );
};
