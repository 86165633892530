/** @jsxImportSource @emotion/react */
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { ToastContext } from "index";
import {
  Button,
  Text,
  Checkbox,
  Container,
  Heading,
  Spinner,
  Divider,
} from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { theme } from "components/lib/utils";
import { InputField } from "components/lib/molecules";
import {
  getAllNceaCodes,
  sortStandards,
  sortSubjects,
} from "components/utils/helpers";
import { useUpdateUserEndorsements } from "components/shared/hooks/useUpdateUserEndorsements.hook";
import { useCurriculum } from "components/shared/hooks/useCurriculum";
import { AccountDetailsFormSection } from "../../common/AccountDetailsFormSection/AccountDetailsFormSection";

import { AccountPageSection } from "../../AccountPage.types";
import { CurriculumGetDto } from "components/utils/openapi";
import * as styles from "./StandardEndorsement.styles";

type FormValue = {
  standards: string[];
  isOtherSelected: boolean;
  otherStandards: string;
};

export const StandardsEndorsement: React.FC<AccountPageSection> = ({
  title,
  user,
  refetchUser,
  onNext,
  onBack,
}) => {
  const toast = useContext(ToastContext);
  const { data, isLoading } = useCurriculum();
  const [curriculumData, setCurriculumData] = useState<CurriculumGetDto[]>();
  const nceaCodes = getAllNceaCodes(data);
  const { mutateAsync: updateUserEndorsements } = useUpdateUserEndorsements();

  const {
    watch,
    control,
    handleSubmit,
    register,
    unregister,
    formState,
    reset,
  } = useForm<FormValue>({
    defaultValues: {
      standards: [],
      otherStandards: "",
      isOtherSelected: false,
    },
  });

  useEffect(() => {
    if (!isLoading) {
      // Filter Curriculum Data based on the User's Chosen Subjects
      const filteredCurriculumData = data?.filter((level) => {
        level.subjects = level.subjects?.filter((subject) =>
          // Note: IDs of Subjects from Curriculum API are in Integer Form - Change if Needed
          user?.endorsements?.subjectIds?.includes(subject?.id?.toString())
        );
        return level?.subjects && level?.subjects?.length > 0;
      });
      setCurriculumData(filteredCurriculumData);

      // Pre-check already chosen Standards
      const standards =
        user?.endorsements?.nceaCodes?.filter((nceaCode) =>
          nceaCodes?.includes(nceaCode)
        ) ?? [];

      // Pre-fill up other Standards
      const otherStandards =
        user?.endorsements?.nceaCodes
          ?.filter((nceaCode) => !standards?.includes(nceaCode))
          ?.join(",") ?? "";

      reset({
        standards: standards,
        otherStandards: otherStandards,
        isOtherSelected: otherStandards !== "",
      });
    }
  }, [data, setCurriculumData]);

  const {
    standards: selectedStandards,
    isOtherSelected,
    otherStandards,
  } = watch();

  async function handlePersonalDetailsSubmit(values: FormValue) {
    // Save details and on success call next
    const toastId = toast.addToast({
      variant: "info",
      description: "Saving standard endorsements...",
    });
    try {
      let nceaCodes = [...values.standards];
      if (values?.isOtherSelected && values?.otherStandards)
        nceaCodes = nceaCodes.concat(
          values?.otherStandards?.split(",").map((nceaCode) => nceaCode.trim())
        );
      await updateUserEndorsements({ nceaCodes });
      await refetchUser();
      if (onNext) onNext();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Standard endorsements saved successfully",
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to save standard endorsements. Please try again later",
      });
    }
  }

  function getStandardsToBeAdded(code: string) {
    return [...selectedStandards, code];
  }

  function getFilteredStandards(code: string) {
    return selectedStandards.filter((nceaCode) => nceaCode !== code);
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <form onSubmit={handleSubmit(handlePersonalDetailsSubmit)}>
      <AccountDetailsFormSection
        title={title}
        backButton={onBack && <Button onClick={onBack}>Back</Button>}
        actionButton={
          <Button type="submit">{onNext ? "Next" : "Update"}</Button>
        }
      >
        <Text weight={theme.fontWeights.regular}>
          Standards I received an Excellence endorsement for (Optional)
        </Text>
        <Controller
          name="standards"
          control={control}
          render={({ field }) => {
            return (
              <>
                {curriculumData?.map(({ name, subjects }) => {
                  return (
                    <Stack
                      direction="vertical"
                      key={name}
                      styles={styles.standards()}
                      gap={1}
                    >
                      <Heading variant={2}>Level {name}</Heading>
                      <Stack wrap="wrap" direction="vertical">
                        {subjects?.sort(sortSubjects)?.map((subject) => {
                          return (
                            <Container
                              key={subject.name}
                              styles={styles.container()}
                            >
                              <Heading
                                variant={3}
                                styles={{ marginTop: theme.space[3] }}
                              >
                                {subject.name}
                              </Heading>
                              <Stack direction="horizontal">
                                {!subject?.standards ||
                                subject?.standards.length === 0 ? (
                                  <Text styles={{ marginTop: theme.space[2] }}>
                                    No Standards Available.
                                  </Text>
                                ) : (
                                  subject.standards
                                    ?.sort(sortStandards)
                                    ?.map((standard) => {
                                      const isChecked = field.value.includes(
                                        standard.nceaCode
                                      );

                                      function handleChange(
                                        _event: FormEvent<HTMLInputElement>,
                                        nceaCode: string
                                      ) {
                                        const standardsAfterSelection = isChecked
                                          ? getFilteredStandards(nceaCode)
                                          : getStandardsToBeAdded(nceaCode);
                                        field.onChange(standardsAfterSelection);
                                      }

                                      return (
                                        <Container
                                          styles={styles.standardCheckbox()}
                                          key={standard.nceaCode}
                                        >
                                          <Checkbox
                                            checked={isChecked}
                                            name={name}
                                            value={standard.nceaCode}
                                            onChange={handleChange}
                                          >
                                            {standard.name}
                                          </Checkbox>
                                        </Container>
                                      );
                                    })
                                )}
                              </Stack>
                            </Container>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })}
              </>
            );
          }}
        />

        <Divider
          styles={{
            marginTop: theme.space[1],
            marginBottom: theme.space[1],
          }}
        />
        <Controller
          name="isOtherSelected"
          control={control}
          render={({ field }) => {
            return (
              <Container styles={styles.standardCheckbox()}>
                <Checkbox
                  checked={field.value}
                  name="isOtherSelected"
                  value="isOtherSelected"
                  onChange={(_event) => {
                    field.onChange(_event.target.checked);
                    unregister("otherStandards");
                  }}
                >
                  Others (separate by comma)
                </Checkbox>
              </Container>
            );
          }}
        />

        {isOtherSelected ? (
          <InputField
            placeholder="Specify here"
            defaultValue={otherStandards}
            validationMessage={formState?.errors?.otherStandards?.message}
            styles={styles.otherStandards()}
            {...register("otherStandards", {
              required: {
                message: "Required",
                value: true,
              },
            })}
          />
        ) : null}
      </AccountDetailsFormSection>
    </form>
  );
};
