/** @jsxImportSource @emotion/react */
import React from "react";
import { Stack } from "components/lib/layouts";
import { DetailInputField } from "./DetailInputField";
import { AccountDetailsTitle } from "components/shared/AccountDetails/common";
import {
  validationMobileNumber,
  validationPostCode,
} from "components/utils/validation";

import { styles } from "../../../styles";
import { FormSection } from "../ProfileDetails.types";

export const LocationAndContactInformation: React.FC<FormSection> = ({
  formState: { errors },
  register,
  watch,
}) => {
  const [city, postCode, mobileNumber] = watch([
    "city",
    "postCode",
    "mobileNumber",
  ]);
  return (
    <Stack gap={4} direction="vertical" styles={styles.locationContactSection}>
      <AccountDetailsTitle>Location & contact</AccountDetailsTitle>

      <DetailInputField
        title="City"
        isMandatory
        defaultValue={city}
        {...register("city", {
          required: {
            message: "Required",
            value: true,
          },
        })}
        errors={errors}
      />

      <DetailInputField
        title="Postcode"
        isMandatory
        defaultValue={postCode}
        {...register("postCode", {
          pattern: validationPostCode,
          required: {
            message: "Required",
            value: true,
          },
        })}
        errors={errors}
      />

      <DetailInputField
        title="Mobile number"
        isMandatory
        defaultValue={mobileNumber}
        {...register("mobileNumber", {
          pattern: validationMobileNumber,
          required: {
            message: "Required",
            value: true,
          },
        })}
        errors={errors}
      />
    </Stack>
  );
};
