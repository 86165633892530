import { useMutation, useQuery } from "react-query";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

enum BuildBundlesStatus {
  ACTIVE = "active",
  COMPLETED = "completed",
  ARCHIVED = "archived",
  CANCELLED = "cancelled",
}

type BuildBundleListMutationQueries = {
  creationTimeFrom?: Date;
  creationTimeTo?: Date;
  statuses?: BuildBundlesStatus[];
};

const useBuildBundleList = (
  creationTimeFrom?: Date,
  creationTimeTo?: Date,
  statuses?: BuildBundlesStatus[]
) => {
  const { getToken } = useAuth();
  return useQuery(["getBuildBundleList", statuses], async () =>
    getBuildBundleList(
      await getToken(),
      creationTimeFrom,
      creationTimeTo,
      statuses
    )
  );
};

const useBuildBundleListMutation = () => {
  const { getToken } = useAuth();
  return useMutation(
    async ({
      creationTimeFrom,
      creationTimeTo,
      statuses,
    }: BuildBundleListMutationQueries) =>
      getBuildBundleList(
        await getToken(),
        creationTimeFrom,
        creationTimeTo,
        statuses
      )
  );
};

const getBuildBundleList = async (
  token: string | null,
  creationTimeFrom?: Date,
  creationTimeTo?: Date,
  statuses?: BuildBundlesStatus[]
) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.buildBundlesControllerGetAvailableBuildBundles(
    creationTimeFrom,
    creationTimeTo,
    statuses?.join(","),
    getDefaultOptions(token)
  );
};

export { BuildBundlesStatus, useBuildBundleList, useBuildBundleListMutation };
