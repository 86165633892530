import { CSSObject } from "@emotion/react";
import { theme } from "components/lib/utils";

export const subtitle: CSSObject = {
  marginBottom: theme.space[3],
};

export const footNote: CSSObject = {
  paddingTop: theme.space[3],
  marginBottom: 0,
};
