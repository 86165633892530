import React from "react";
import { Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { EllipseIcon, TickIcon } from "../svgs";

import * as styles from "./PasswordRequirements.styles";
import { PasswordRequirementsType } from "./PasswordRequirements.types";

export const PasswordRequirements: React.FC<PasswordRequirementsType> = ({
  password,
}: PasswordRequirementsType) => {
  const hasMinimumCharacterLength = password?.length >= 8;
  const hasAtLeastOneNumber = password !== undefined && /\d/.test(password);
  const hasAtLeastOneLetter =
    password !== undefined && /[a-zA-Z]/g.test(password);
  return (
    <Stack direction="vertical" gap={1}>
      <Text styles={styles.PasswordRequirementStyles}>
        You password must meet the following requirements:
      </Text>
      <Stack direction="vertical" gap={1}>
        <Stack gap={1} align="center">
          <DisplayIcon hasPassedCheck={hasMinimumCharacterLength} />
          <Text styles={styles.PasswordRequirementStyles}>
            Minimum of 8 characters
          </Text>
        </Stack>
        <Stack gap={1} align="center">
          <DisplayIcon hasPassedCheck={hasAtLeastOneNumber} />
          <Text styles={styles.PasswordRequirementStyles}>
            At least 1 number
          </Text>
        </Stack>
        <Stack gap={1} align="center">
          <DisplayIcon hasPassedCheck={hasAtLeastOneLetter} />
          <Text styles={styles.PasswordRequirementStyles}>
            At least 1 letter
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const DisplayIcon: React.FC<{ hasPassedCheck: boolean }> = ({
  hasPassedCheck,
}) => {
  return hasPassedCheck ? <TickIcon /> : <EllipseIcon />;
};
