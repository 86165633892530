import React from "react";
import { useTheme } from "@emotion/react";
import { Card, Heading } from "components/lib/atoms";
import { LinkProps, Link } from "react-router-dom";

import { getAuthCardStyles, getLinkStyles } from "./CommonElements.styles";

export const AuthCard: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Card styles={getAuthCardStyles(theme)} fullWidth={true}>
      {children}
    </Card>
  );
};

export const AuthLink: React.FC<LinkProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Link style={getLinkStyles(theme)} {...props}>
      {children}
    </Link>
  );
};

export const AuthHeader: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Heading variant={1} color={theme.colors.purple.dark} textAlign="center">
      {children}
    </Heading>
  );
};
