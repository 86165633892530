import React from "react";
import { useTheme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";

import { Stack } from "components/lib/layouts";
import { Container } from "../Container/Container";
import {
  getSpinnerWrapperStyles,
  getSpinnerContainerStyles,
} from "./Spinner.styles";

interface SpinnerProps {
  styles?: CSSObject;
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
  const theme = useTheme();

  return (
    <Stack
      fullWidth
      justify="center"
      align="center"
      styles={getSpinnerWrapperStyles(theme, props)}
    >
      <Container styles={getSpinnerContainerStyles(theme)} />
    </Stack>
  );
};
