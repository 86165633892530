import { useState } from "react";
import { useTheme } from "@emotion/react";

import { useToast } from "index";
import {
  Table,
  TableData,
  TableHeading,
  TableRow,
  TableBody,
  TableHead,
} from "components/lib/atoms/Table/Table";
import { Stack } from "components/lib/layouts";
import { getCurriculumName } from "components/utils/helpers";
import { Spinner, Heading, Card, Button, Text } from "components/lib/atoms";
import { GetBuildBundlesResponseDto } from "components/utils/openapi";
import { useCurriculum } from "../../../shared/hooks/useCurriculum";

import {
  BuildBundlesStatus,
  useBuildBundleList,
} from "../hooks/useBuildBundleList.hook";
import { useBuildBundleUnarchive } from "../hooks/useBuildBundleUnarchive.hook";
import { byBundleDate } from "../ManageBuildBundles/utils";
import { DeleteJobModal } from "./components";

export const ArchivedJobs = () => {
  const theme = useTheme();
  const toast = useToast();
  const { data, isLoading, refetch } = useBuildBundleList(
    undefined,
    undefined,
    [BuildBundlesStatus.ARCHIVED]
  );
  const { data: curriculumData } = useCurriculum();
  const { mutateAsync: unarchiveBuildBundle } = useBuildBundleUnarchive();

  const [
    jobToBeDeleted,
    setJobToBeDeleted,
  ] = useState<GetBuildBundlesResponseDto | null>(null);

  /**
   * Helper function to unarchiving a specific build bundle by ID
   * @param buildBundleId Specific Build Bundle to Unarchive
   */
  const unachiveBuildBundleById = async (buildBundleId: string) => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Unarchiving Build Bundle...",
    });
    try {
      await unarchiveBuildBundle(buildBundleId);
      await refetch();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Build Bundle unarchived successfully",
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to unarchive build bundle. Please try again later",
      });
    }
  };

  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={4} fullWidth={true}>
        <Stack direction="horizontal" fullWidth={true}>
          <Stack direction="vertical" gap={1}>
            <Heading variant={1}>Archive</Heading>
          </Stack>
        </Stack>

        {isLoading ? (
          <Spinner />
        ) : (
          <Stack direction="vertical" gap={2}>
            <Table styles={{ width: "100%" }}>
              <TableHead>
                <TableRow isHeading={true}>
                  <TableHeading>Build bundle names</TableHeading>
                  <TableHeading>Date added</TableHeading>
                  <TableHeading>Jobs completed</TableHeading>
                  <TableHeading />
                  <TableHeading />
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.sort(byBundleDate).map((buildBundle) => {
                  const name = getCurriculumName(
                    buildBundle.nceaCode,
                    curriculumData
                  );
                  const activityBuildsFinished = buildBundle.activityBuilds.filter(
                    (activityBuild) => activityBuild?.completed
                  ).length;

                  return (
                    <TableRow key={buildBundle.id}>
                      <TableData>{name}</TableData>
                      <TableData>11/11/2021</TableData>
                      <TableData>
                        {activityBuildsFinished} of{" "}
                        {buildBundle?.activityBuilds?.length}
                      </TableData>

                      <TableData>
                        <Button
                          variant="warningtext"
                          onClick={() => {
                            setJobToBeDeleted(buildBundle);
                          }}
                        >
                          Delete
                        </Button>
                      </TableData>
                      <TableData>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            unachiveBuildBundleById(buildBundle.id);
                          }}
                        >
                          Push back to Jobs
                        </Button>
                      </TableData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {data?.length === 0 && (
              <Text styles={{ padding: theme.space[2] }}>
                No Archived build bundles found.
              </Text>
            )}

            <DeleteJobModal
              onClose={() => {
                setJobToBeDeleted(null);
              }}
              job={jobToBeDeleted}
              refetchJobs={refetch}
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
