import { useContext } from "react";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import { useTheme } from "@emotion/react";
import pluralize from "pluralize";

import { ToastContext } from "index";
import {
  Button,
  Container,
  Divider,
  Heading,
  Text,
} from "components/lib/atoms/";
import { Stack } from "components/lib/layouts";
import { getStandardByCode } from "components/utils/helpers";
import { ActivityBuildJobsResponseDto } from "components/utils/openapi";
import { getFormattedDateString } from "components/utils/dateUtils";
import { PATH } from "../constants/paths.constants";

import { RichPreview } from "../RichPreview/RichPreview";
import { useCurriculum } from "../hooks/useCurriculum";
import { useAcceptActivityBuildJob } from "./hooks/useAcceptActivityBuildJob";
import * as styles from "./JobDetails.styles";

export const JobDetails = ({
  activityBuildJob,
  disableAccept = false,
}: {
  activityBuildJob: ActivityBuildJobsResponseDto;
  disableAccept?: boolean;
}) => {
  const { data: curriculumData } = useCurriculum();
  const standardInfo = getStandardByCode(
    activityBuildJob?.buildBundle?.nceaCode,
    curriculumData
  );
  const theme = useTheme();
  const toast = useContext(ToastContext);
  const history = useHistory();
  const { mutateAsync: acceptActivityBuildJob, isLoading } =
    useAcceptActivityBuildJob();

  const acceptJobClick = async () => {
    try {
      const acceptedActivityBuildJob = await acceptActivityBuildJob(
        activityBuildJob?.id
      );
      const { id: submissionId } = acceptedActivityBuildJob;
      history.push(`${PATH.activeJobs}/${submissionId}/instructions`);
    } catch (error) {
      toast.addToast({
        variant: "error",
        description: "Failed to accept job. Try again later",
      });
    }
  };

  return (
    <Stack
      direction="vertical"
      gap={4}
      fullWidth={true}
      styles={{ marginTop: theme.space[4] }}
    >
      <Stack direction="vertical" gap={4}>
        <Stack justify="space-between" fullWidth={true} align="center">
          <Stack direction="vertical" gap={1} styles={{ maxWidth: "590px" }}>
            <Heading
              color={theme.colors.purple.dark}
              weight={500}
              variant={1}
              styles={{ marginTop: "0px", marginBottom: "0px" }}
            >
              {activityBuildJob?.activityBuild?.name}
            </Heading>
            <Text variant={2} color={theme.colors.purple.dark}>
              {activityBuildJob?.job?.jobType?.description}
            </Text>
            <Stack direction="horizontal" gap={2} styles={styles.standards}>
              <Text variant={2}>{standardInfo?.levelName}</Text>
              <Container styles={styles.dot} />
              <Text variant={2}>{standardInfo?.subjectName}</Text>
              <Container styles={styles.dot} />
              <Text variant={2}>{standardInfo?.standardName}</Text>
            </Stack>
          </Stack>
          <Stack
            direction="vertical"
            styles={{
              textAlign: "right",
              marginTop: "auto",
            }}
          >
            <Text
              variant={6}
              color={theme.colors.purple.default}
              weight={500}
              styles={styles.jobPrice}
            >{`$${(
              activityBuildJob?.job?.payoutRate *
              activityBuildJob?.job?.jobType?.contentNumber
            ).toFixed(2)}`}</Text>
            <Text
              color={theme.colors.grey.text}
              styles={styles.jobPriceDescription}
            >
              Maximum earnings per job
            </Text>
          </Stack>
        </Stack>
        {!disableAccept && (
          <Button
            styles={{ width: "144px" }}
            disabled={isLoading}
            onClick={acceptJobClick}
          >
            Accept Job
          </Button>
        )}
      </Stack>
      <Divider styles={styles.divider} />
      <Stack
        gap={4}
        fullWidth={true}
        justify="space-between"
        styles={{ maxWidth: "600px" }}
      >
        <Stack direction="vertical" gap={1}>
          <Text color={theme.colors.grey.text}>Job duration</Text>
          <Text color={theme.colors.purple.dark}>
            {activityBuildJob?.job?.jobDuration
              ? `${pluralize("Hour", activityBuildJob?.job?.jobDuration, true)}`
              : ""}
          </Text>
        </Stack>
        <Stack direction="vertical" gap={1}>
          <Text color={theme.colors.grey.text}>Date Posted</Text>
          <Text color={theme.colors.purple.dark}>
            {getFormattedDateString(activityBuildJob?.createdAt, "DD/MM/YYYY")}
          </Text>
        </Stack>
      </Stack>
      <Divider styles={styles.divider} />
      <RichPreview>{activityBuildJob?.job?.jobType?.details}</RichPreview>
      <ReactPlayer
        url={activityBuildJob?.activityBuild?.videoLink}
        width="100%"
        height="100%"
        controls
      />
    </Stack>
  );
};
