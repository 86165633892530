import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { Resource } from "../CreateNewJob/CreateNewJob.types";

export interface FormData {
  resources: Resource[];
}

export const usePublishToLive = ({ defaultFields }: { defaultFields: any }) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: defaultFields,
  });

  const { fields, append } = useFieldArray({
    control,
    name: "resources",
  });

  useEffect(() => {
    watch();
  }, []);

  return {
    fields,
    append,
    errors,
    register,
    handleSubmit,
  };
};
