import React, { useState } from "react";
import { Button, Text, Checkbox } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { PATH } from "components/shared/constants/paths.constants";
import { useRegister } from "../hooks/useRegister";
import {
  AuthCard,
  AuthLink,
  AuthHeader,
} from "../CommonElements/CommonElements";
import { theme } from "components/lib/utils";
import {
  validationEmail,
  ValidationMessageRequired,
  validationPassword,
} from "components/utils/validation";
import { InputField } from "components/lib/molecules";
import {
  PasswordRequirements,
  PasswordToggle,
} from "components/pages/LoginPage/CommonElements";

export const RegisterForm = () => {
  const { errors, register, handleSubmit, onSignup, getValues, isLoading } =
    useRegister();
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(false);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setHasAgreedToTerms(event.target.checked);

  return (
    <AuthCard>
      <form onSubmit={handleSubmit(onSignup)}>
        <Stack direction="vertical" gap={2} fullWidth>
          <Stack direction="vertical" gap={4} fullWidth>
            <AuthHeader>Register</AuthHeader>
            <Stack direction="vertical" gap={1}>
              <Stack gap={0}>
                <Text>Email</Text>
                <Text color={theme.colors.red.default}>&nbsp;*</Text>
              </Stack>
              <InputField
                placeholder="Enter email address"
                validationMessage={errors.email?.message}
                {...register("email", {
                  required: ValidationMessageRequired,
                  pattern: validationEmail,
                })}
              />
            </Stack>
            <Stack direction="vertical" gap={1}>
              <Stack justify="space-between" gap={0}>
                <Stack gap={0}>
                  <Text>Password</Text>
                  <Text color={theme.colors.red.default}>&nbsp;*</Text>
                </Stack>
                <PasswordToggle
                  isPasswordShown={isPasswordShown}
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                />
              </Stack>
              <InputField
                type={isPasswordShown ? "text" : "password"}
                placeholder="Enter password"
                validationMessage={errors.password?.message}
                {...register("password", {
                  required: ValidationMessageRequired,
                  pattern: validationPassword,
                })}
              />
            </Stack>
          </Stack>
          <PasswordRequirements password={getValues("password")} />
        </Stack>

        <Stack
          direction="vertical"
          gap={4}
          fullWidth
          styles={{ marginTop: theme.space[2] }}
        >
          <Checkbox
            id="checkbox-terms-of-service"
            onChange={handleTermsChange}
            checked={hasAgreedToTerms}
            name="terms"
            value="terms"
          >
            I agree on&nbsp;
            <a
              rel="noopener noreferrer"
              href="https://my.learncoach.com/docs/LearnCoach-Terms-and-Conditions-v03-02-2021.pdf"
              target="_blank"
            >
              Terms of Service
            </a>
          </Checkbox>
          <Button type="submit" disabled={!hasAgreedToTerms || isLoading}>
            Register an Account
          </Button>
          <Text align="center">
            Already have an account?{" "}
            <AuthLink to={PATH.login}>Sign in</AuthLink>
          </Text>
        </Stack>
      </form>
    </AuthCard>
  );
};
