import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils";

export const container = (): CSSObject => {
  return { marginBottom: theme.space[2] };
};

export const standards = (): CSSObject => {
  return { paddingTop: theme.space[3] };
};

export const standardCheckbox = (): CSSObject => {
  return {
    minWidth: "325px",
    marginRight: theme.space[3],
    marginTop: theme.space[2],
    marginBottom: theme.space[2],
  };
};

export const otherStandards = (): CSSObject => {
  return { maxWidth: "300px", marginLeft: theme.space[4], marginTop: 0 };
};
