import { useTheme } from "@emotion/react";

import { JobTypesAnalyticsDto } from "components/utils/openapi";
import { sortOptionsAlphabetically } from "components/utils/helpers";
import { Stack } from "components/lib/layouts";
import { Table, Heading, Text, Spinner } from "components/lib/atoms";
import {
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/lib/atoms/Table/Table";
import { useJobTypeStatistics } from "../../hooks/useJobTypeStatistics.hook";

export const LiveJobs = () => {
  const theme = useTheme();
  const { data, isLoading } = useJobTypeStatistics();

  return isLoading ? (
    <Spinner />
  ) : (
    <Stack direction="vertical" gap={2}>
      <Heading variant={2}>Live jobs</Heading>
      <Table styles={{ width: "100%" }}>
        <TableHead>
          <TableRow isHeading={true}>
            <TableHeading>Job Type</TableHeading>
            <TableHeading>Number available</TableHeading>
            <TableHeading>Number in progress</TableHeading>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            Array.isArray(data) &&
            data
              ?.sort(sortOptionsAlphabetically)
              ?.map((liveJob: JobTypesAnalyticsDto) => {
                return (
                  <TableRow key={liveJob?.id}>
                    <TableData>{liveJob?.name}</TableData>
                    <TableData>{liveJob?.unclaimedActivityBuildJobs}</TableData>
                    <TableData>
                      {liveJob?.inProgressActivityBuildJobs}
                    </TableData>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>

      {data?.length === 0 && (
        <Text styles={{ padding: theme.space[2] }}>No Live Jobs Found.</Text>
      )}
    </Stack>
  );
};
