import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";

export const getUploadFileStyles = (theme: Theme) => {
  const base: CSSObject = {
    color: theme.colors.grey.text,
    alignItems: "flex-start",
    boxSizing: "border-box",
    borderRadius: theme.radii[2],
    fontFamily: theme.fonts.primary,
    justifyContent: "left",
    textAlign: "left",
    fontWeight: theme.fontWeights.regular,
    lineHeight: "130%",
    fontSize: theme.fontSizes[3],
    ":disabled": {},
    ":focus": {},
  };
  return { base };
};
