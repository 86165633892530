import { Heading } from "components/lib/atoms/Heading/Heading";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { JobDetails } from "components/shared/JobDetails/JobDetails";
import { GetByIdJobResponseDto } from "components/utils/openapi";

export const DetailsSection = ({
  job,
  title,
}: {
  title: string;
  job: GetByIdJobResponseDto;
}) => {
  return (
    <Stack direction="vertical" gap={3} fullWidth={true}>
      <Heading variant={2}>Job Details</Heading>
      <JobDetails job={job} disableAccept={true} title={title} />
    </Stack>
  );
};
