import { useTheme } from "@emotion/react";
import { useState } from "react";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { Button, Heading, Text } from "components/lib/atoms";

import { Modal, UploadFile } from "components/lib/molecules";
import { useToast } from "index";
import {
  AttachmentResponseDto,
  RetrieveSubmissionByIdJobResponseDto,
  RetrieveSubmissionContentResponseDto,
} from "components/utils/openapi";
import { useUploadAttachment } from "../../hooks/useUploadAttachment.hook";

export const UploadFilePanel = ({
  submission,
  currentSubmissionContent,
  isOptional = false,
  refetch,
  setCurrentSubmissionContent,
}: {
  submission: RetrieveSubmissionByIdJobResponseDto;
  currentSubmissionContent: RetrieveSubmissionContentResponseDto;
  isOptional?: boolean;
  refetch: () => Promise<any>;
  setCurrentSubmissionContent: any;
}) => {
  const theme = useTheme();

  // Submission Content Attachment Hooks
  const { uploadAttachment, deleteAttachment, updateAttachment } =
    useUploadAttachment();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState({
    open: false,
    submissionContentId: "",
    attachmentId: "",
  });

  const [loading, setLoading] = useState(false);

  /**
   * Helper function to upload or update submission content attachment
   * @param file to be uploaded
   * @param submissionId submission to update
   * @param submissionContentId submission content to upload the attachment to
   */
  const uploadSubmissionContentAttachment = async (
    file: File,
    submissionId: string,
    submissionContentId: string
  ): Promise<AttachmentResponseDto> => {
    if (
      currentSubmissionContent &&
      currentSubmissionContent?.attachments?.length >= 1
    ) {
      if (!currentSubmissionContent?.attachments[0]?.id) {
        throw new Error("Attachment not found.");
      }
      return await updateAttachment(
        file,
        submissionId,
        submissionContentId,
        currentSubmissionContent?.attachments[0]?.id
      );
    } else {
      return await uploadAttachment(file, submissionId, submissionContentId);
    }
  };

  /**
   * Handles the Uploading of Submission Content Attachment
   * @param files to upload
   */
  const handleUploadSubmissionContentAttachment = (files: File[]) => {
    try {
      // File Validation
      if (!files || files?.length === 0) {
        throw new Error("Please select a file to upload.");
      }

      // File Length Validation
      if (files?.length > 1) {
        throw new Error("You can only upload one file.");
      }

      // Submission Validation
      if (!submission?.id) throw new Error("Submission not Found.");
      if (!currentSubmissionContent || !currentSubmissionContent?.id) {
        throw new Error("Submission Content not Found.");
      }

      setLoading(true)

      // Upload Submission Content Attachment
      uploadSubmissionContentAttachment(
        files[0],
        submission?.id,
        currentSubmissionContent?.id
      )
        .then((response) => {
          setLoading(false)
          setCurrentSubmissionContent({
            ...currentSubmissionContent,
            attachments: [...currentSubmissionContent.attachments, response]
          });
        })
        .catch((err) => {
          throw new Error("Error uploading file");
        });
    } catch (err: any) {
      alert("Error uploading file. Please try again later");
    }
  };

  /**
   * Handles the Deletion of Submission Content Attachment
   * @param submissionContentId submission content owner
   * @param attachmentId attachment to delete
   */
  const handleDeleteSubmissionContentAttachment = async (
    submissionContentId: string,
    attachmentId: string
  ) => {
    try {
      if (!submissionContentId || !attachmentId) {
        throw new Error(
          "Submission Content or its Attachment was not found. Please try again later"
        );
      }

      // Notify Changes as well as close the Modal
      setIsConfirmationModalOpen({
        open: false,
        submissionContentId: "",
        attachmentId: "",
      });
      await deleteAttachment(submission?.id, submissionContentId, attachmentId);
      setCurrentSubmissionContent({...currentSubmissionContent, attachments: []});
    } catch (err: any) {
      alert("Error deleting file. Please try again later")
    }
  };

  let uploadButtonName = undefined;
  if (currentSubmissionContent?.attachments?.length >= 1) {
    uploadButtonName = "Replace File";
  }

  return (
    <>
      <Stack fullWidth={true} direction="vertical" gap={4}>
        {currentSubmissionContent?.attachments &&
          currentSubmissionContent?.attachments?.map(
            (attachment: AttachmentResponseDto) => {
              return (
                <Stack
                  key={attachment?.id}
                  direction="horizontal"
                  justify="left"
                  align="center"
                  gap={2}
                >
                  <Text>{attachment?.name}</Text>
                  <Button
                    type="button"
                    variant="warningtext"
                    onClick={() => {
                      setIsConfirmationModalOpen({
                        open: true,
                        submissionContentId: currentSubmissionContent?.id,
                        attachmentId: attachment?.id,
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Stack>
              );
            }
          )}
        {currentSubmissionContent?.attachments?.length === 0 &&
          <Stack fullWidth={true} direction="vertical" gap={4}>
            <Stack direction="horizontal">
              <Text>Upload your file here</Text>
              {isOptional && (
                <Text color={theme.colors.grey.default}>&nbsp;(Optional)</Text>
              )}
            </Stack>
            <UploadFile
              loading={loading}
              maxFiles={1}
              maxSize={1024 * 1024 * 200}
              uploadFile={handleUploadSubmissionContentAttachment}
              accept={submission?.job?.jobType?.allowedMimeTypes ?? undefined}
              uploadButtonName={uploadButtonName}
            />
          </Stack>
        }
      </Stack>

      <Modal
        isOpen={isConfirmationModalOpen.open}
        continueLabel="Yes, delete it"
        continueButtonVariant={"warning"}
        onContinue={async () => {
          handleDeleteSubmissionContentAttachment(
            isConfirmationModalOpen?.submissionContentId,
            isConfirmationModalOpen?.attachmentId
          );
        }}
        closeLabel="Cancel"
        onClose={() => {
          setIsConfirmationModalOpen({
            open: false,
            submissionContentId: "",
            attachmentId: "",
          });
        }}
      >
        <Stack
          direction="vertical"
          align="center"
          gap={2}
          styles={{ padding: theme.space[2], marginBottom: theme.space[3] }}
        >
          <Heading variant={1}>Are you sure?</Heading>
          <Text>You will not be able to recover this attachment</Text>
        </Stack>
      </Modal>
    </>
  );
};
