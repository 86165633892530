import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useQuery } from "components/utils/routing";
import { useAuth } from "components/utils/auth";
import { PATH } from "components/shared/constants/paths.constants";

import { useToast } from "index";
import { FormValue } from "./UpdatePassword.types";

export function useUpdatePassword() {
  const {
    getValues,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValue>({
    mode: "onChange",
  });
  const { confirmPassword } = useAuth();
  const { push } = useHistory();
  const query = useQuery();
  const toast = useToast();

  const email = query.get("email");
  const code = query.get("code");

  async function handleResetPassword(values: FormValue) {
    if (email === null || code === null) {
      setError("confirmPassword", {
        type: "pattern",
        message: "Invalid verifcation url",
      });
      return;
    }

    // This is unique to gmail "+", it gets read as " " (space) from query params eg: test+1@gmail.com
    const fixedEmail = email.replace(" ", "+");

    await confirmPassword(fixedEmail, code, values.password)
      .then((res) => {
        toast.addToast({
          description: "Password changed successfully",
          variant: "success",
        });

        push(PATH.login);

        return res;
      })
      .catch((error) => {
        toast.addToast({
          description: error.message,
          variant: "error",
        });
        return error;
      });
  }

  return {
    errors,
    getValues,
    handleResetPassword,
    handleSubmit,
    isSubmitting,
    register,
  };
}
