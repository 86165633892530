import React from "react";
import { useCreateNewJob } from "../hooks/useCreateNewJob";
import { AdminDetails } from "./AdminDetails/AdminDetails";
import { JobDetails } from "./JobDetails/JobDetails";

export const CreateNewJob = () => {
  const {
    jobBundle,
    formState,
    handleCreateNewJob,
    setJobBundle,
    setFormState,
    setBuildBundleFile,
  } = useCreateNewJob();

  if (jobBundle !== null && formState === "publish") {
    return (
      <AdminDetails
        onNext={handleCreateNewJob}
        numberOfActivityBuilds={Number(jobBundle.numberOfActivityBuilds)}
      />
    );
  }

  return (
    <JobDetails
      onNext={(buildBundle, buildBundleFile) => {
        setJobBundle(buildBundle);
        setBuildBundleFile(buildBundleFile);
        setFormState("publish");
      }}
    />
  );
};
