/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { getStyles } from "components/lib/utils";
import { Stack } from "components/lib/layouts";
import { Card, Button, Heading, Text } from "components/lib/atoms";
import { ToastPropsType } from "./Toast.types";
import { styles } from "./Toast.styles";
import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
  CrossIcon,
} from "./svgs";

const ToastIcons = {
  info: InfoIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  success: SuccessIcon,
};

const ICON_SIZE = "50px";
const CROSS_ICON_SIZE = "14px";

export const Toast = ({
  heading,
  variant = "success",
  description,
  styles: stylesProp = {},
  permanent = false,
  onCloseToast,
}: ToastPropsType) => {
  const ToastIcon = ToastIcons[variant];

  useEffect(() => {
    setTimeout(() => {
      if (onCloseToast && !permanent) {
        onCloseToast();
      }
    }, 5000);
  }, [onCloseToast, permanent]);

  return (
    <Card
      styles={getStyles({
        base: {
          ...styles.ToastStyles,
          ...stylesProp,
        },
        styles: stylesProp,
      })}
    >
      <Stack align="center" justify="space-between" fullWidth={true}>
        <Stack gap={1} justify="center">
          <ToastIcon height={ICON_SIZE} width={ICON_SIZE} />
          <Stack direction="vertical" justify="center">
            {heading && <Heading variant={4}>{heading}</Heading>}
            {description && <Text color="grey">{description}</Text>}
          </Stack>
        </Stack>
        <Stack gap={1} styles={{ marginLeft: "auto" }}>
          {onCloseToast && (
            <Button styles={styles.CloseButton}>
              <CrossIcon
                height={CROSS_ICON_SIZE}
                width={CROSS_ICON_SIZE}
                style={{
                  cursor: "pointer",
                  marginRight: 1,
                }}
                onClick={onCloseToast}
              />
            </Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
