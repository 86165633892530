import { Stack } from "components/lib/layouts";
import { Heading, Card } from "components/lib/atoms";

import { LiveJobs, ThroughputStats } from "./sections";

export const AdminDashboard = () => {
  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={5} fullWidth={true}>
        <Stack direction="horizontal" fullWidth={true}>
          <Stack direction="vertical" gap={1}>
            <Heading variant={1}>Admin Metrics</Heading>
          </Stack>
        </Stack>
        <Stack direction="vertical" gap={8}>
          <LiveJobs />
          <ThroughputStats />
        </Stack>
      </Stack>
    </Card>
  );
};
