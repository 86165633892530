import { CSSObject } from "@emotion/serialize";
import { Theme } from "@emotion/react";

import { ValidationMessageProps } from "./ValidationMessage.types";

import { getStyles } from "../../utils";

export const getValidationWrapperStyles = (
  theme: Theme,
  { styles, color, weight }: Partial<ValidationMessageProps>
) => {
  const base: CSSObject = {
    fontFamily: theme.fonts.primary,
    color: color || theme.colors.red.border,
    fontWeight: weight || theme.fontWeights.regular,
    fontSize: theme.fontSizes[3],
  };

  return getStyles({ base, styles });
};

export const getIconStyles = (
  _theme: Theme,
  { styles }: Partial<ValidationMessageProps>
) => {
  const base: CSSObject = {
    marginRight: "5px",
    verticalAlign: "middle",
  };

  return getStyles({ base, styles });
};
