import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { getStyles } from "../../utils";

import { InputProps } from "./Input.types";

export const getInputStyles = (
  theme: Theme,
  { styles }: Partial<InputProps>
) => {
  const base: CSSObject = {
    alignItems: "flex-start",
    boxSizing: "border-box",
    background: theme.colors.white.default,
    border: `${theme.lineThickness[0]} solid ${theme.colors.grey.input}`,
    borderRadius: theme.radii[2],
    fontFamily: theme.fonts.primary,
    justifyContent: "left",
    width: "100%",
    fontWeight: theme.fontWeights.regular,
    lineHeight: "130%",
    padding: `${theme.space[2]} ${theme.space[3]}`,
    fontSize: theme.fontSizes[3],
    ":disabled": {},
    ":focus": {},
    "::placeholder": { color: theme.colors.grey.input },
    "::-webkit-outer-spin-button": {
      '-webkit-appearance': 'none',
      margin: 0
    },
    "::-webkit-inner-spin-button": {
      '-webkit-appearance': 'none',
      margin: 0
    },
    "[type=number]": {
      '-moz-appearance': 'textfield'
    }
  };
  return getStyles({ base, styles });
};
