/** @jsxImportSource @emotion/react */
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { ToastContext } from "index";
import {
  Button,
  Text,
  Checkbox,
  Container,
  Heading,
} from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { theme } from "components/lib/utils";
import { sortSubjects } from "components/utils/helpers";
import { useCurriculum } from "components/shared/hooks/useCurriculum";
import { useUpdateUserEndorsements } from "components/shared/hooks/useUpdateUserEndorsements.hook";

import { AccountDetailsFormSection } from "../../common/AccountDetailsFormSection/AccountDetailsFormSection";
import { AccountPageSection } from "../../AccountPage.types";
import * as styles from "./SubjectsEndorsement.styles";

type FormValue = {
  subjects: string[];
};

type NceaCodesLookup = { [key: string]: string[] };

export const SubjectsEndorsement: React.FC<AccountPageSection> = ({
  title,
  user,
  refetchUser,
  onNext,
  onBack,
}) => {
  const toast = useContext(ToastContext);
  const { data } = useCurriculum();
  const { mutateAsync: updateUserEndorsements } = useUpdateUserEndorsements();
  const { watch, control, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      subjects: user?.endorsements?.subjectIds ?? [],
    },
  });
  const { subjects: selectedSubjects = [] } = watch();
  const [nceaCodesLookup, setNceaCodesLookup] = useState<NceaCodesLookup>({});
  const [initialSubjectIds] = useState<string[] | undefined>(
    user?.endorsements?.subjectIds
  );

  useEffect(() => {
    // Craeate NCEA Codes Lookup to be used in filtering out unticked subjects
    const lookup: NceaCodesLookup = {};
    data?.forEach((level) => {
      level?.subjects?.forEach((subject) => {
        lookup[subject?.id] = subject?.standards?.map(
          (standard) => standard?.nceaCode
        );
      });
    });
    setNceaCodesLookup(lookup);
  }, [data, setNceaCodesLookup]);

  async function handlePersonalDetailsSubmit(values: FormValue) {
    // Save details and on success call next
    const toastId = toast.addToast({
      variant: "info",
      description: "Saving subject endorsements...",
    });

    const userEndorsements = {
      subjectIds: values?.subjects,
      nceaCodes: user?.endorsements?.nceaCodes,
    };

    if (
      initialSubjectIds &&
      user?.endorsements?.nceaCodes &&
      user?.endorsements?.nceaCodes?.length > 0
    ) {
      // Get all the NCEA Codes that needs to be filtered out
      let nceaCodesToRemove: string[] = [];
      initialSubjectIds?.forEach((subject) => {
        if (
          !userEndorsements?.subjectIds?.includes(subject) &&
          nceaCodesLookup &&
          nceaCodesLookup[subject]
        ) {
          // Concatenate NCEA Codes from the Subject to the Filter Array (nceaCodesToRemove)
          nceaCodesToRemove = nceaCodesToRemove.concat(
            nceaCodesLookup[subject]
          );
        }
      });

      // Filter out the NCEA Codes that has its subject recently removed
      userEndorsements.nceaCodes = userEndorsements.nceaCodes?.filter(
        (nceaCode) => {
          return !nceaCodesToRemove?.includes(nceaCode);
        }
      );
    }

    try {
      await updateUserEndorsements(userEndorsements);
      await refetchUser();
      if (onNext) onNext();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Subject endorsements saved successfully",
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to save subject endorsements. Please try again later",
      });
    }
  }

  function isSelected(key: string) {
    return Boolean(selectedSubjects.find((s) => s === key));
  }

  function getSubjectsToBeAdded(key: string) {
    return [...selectedSubjects, key];
  }

  function getFilteredSubjects(key: string) {
    return selectedSubjects.filter((s) => s !== key);
  }

  return (
    <form onSubmit={handleSubmit(handlePersonalDetailsSubmit)}>
      <AccountDetailsFormSection
        title={title}
        backButton={onBack && <Button onClick={onBack}>Back</Button>}
        actionButton={
          <Button type="submit">{onNext ? "Next" : "Update"}</Button>
        }
      >
        <Text weight={theme.fontWeights.regular}>
          Subjects I received an Excellence endorsement for (Optional)
        </Text>

        <Controller
          name="subjects"
          control={control}
          defaultValue={[]}
          render={({ field }) => {
            return (
              <>
                {data?.map(({ name, subjects }) => {
                  return (
                    <Stack
                      direction="vertical"
                      key={name}
                      styles={styles.subjects()}
                      gap={2}
                    >
                      <Heading variant={2}>Level {name}</Heading>
                      <Stack wrap="wrap">
                        {subjects.sort(sortSubjects).map((subject) => {
                          // Note: IDs of Subjects from Curriculum API are in Integer Form - Change if Needed
                          const key = subject?.id?.toString();
                          const isChecked = isSelected(key);

                          function handleChange(
                            _event: FormEvent<HTMLInputElement>,
                            selectedValueKey: string
                          ) {
                            const subjectsAfterSelection = isChecked
                              ? getFilteredSubjects(selectedValueKey)
                              : getSubjectsToBeAdded(selectedValueKey);
                            field.onChange(subjectsAfterSelection);
                          }
                          return (
                            <Container
                              styles={styles.subjectCheckbox()}
                              key={key}
                            >
                              <Checkbox
                                checked={isChecked}
                                name={key}
                                value={key}
                                onChange={handleChange}
                              >
                                {subject.name}
                              </Checkbox>
                            </Container>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                })}
              </>
            );
          }}
        />
      </AccountDetailsFormSection>
    </form>
  );
};
