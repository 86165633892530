import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";

export const getStyles = (theme: Theme): Record<string, CSSObject> => {
  return {
    Overlay: {
      background: theme.colors.grey.default,
      position: "fixed",
      zIndex: theme.zIndices.overlay,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: "200ms opacity ease-out",
      willChange: "opacity",
    },
    ShowOverlay: {
      opacity: 0.3,
      pointerEvents: "unset",
    },
    HideOverlay: {
      opacity: 0,
      pointerEvents: "none",
    },
    ModalContainer: {
      minWidth: "500px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: theme.zIndices.modal,
      transition: "200ms opacity  ease-out, 200ms transform  ease-out",
      willChange: "opacity, transform",
      paddingTop: "30px",
      borderRadius: "14px",
    },
    ShowModal: {
      transform: "translate(-50%, -50%) scale(1)",
      opacity: 1,
    },
    HideModal: {
      opacity: 0,
      transition: "none",
      transform: "translate(-50%, -50%) scale(1.05)",
      pointerEvents: "none",
      visibility: "hidden",
    },
  };
};
