import { useQuery } from "react-query";
import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";

export const useActiveJobsList = () => {
  const { getToken } = useAuth();
  return useQuery(["getActiveJobsList"], async () =>
    getActiveJobsList(await getToken())
  );
};

const getActiveJobsList = async (token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.submissionControllerFindSubmissions(
    "active",
    undefined,
    undefined,
    getDefaultOptions(token)
  );
};
