import React from "react";
import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";

import { Button, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { PATH } from "components/shared/constants/paths.constants";

import { AuthCard, AuthHeader } from "../../CommonElements/CommonElements";
import { EmailVerifyIllustrationIcon } from "../../EmailVerification/svgs";

export const VerifyEmailMessage: React.FC = () => {
  const theme = useTheme();
  const { push } = useHistory();

  return (
    <AuthCard>
      <Stack fullWidth align="center" gap={5} direction="vertical">
        <EmailVerifyIllustrationIcon />
        <AuthHeader>Email sent!</AuthHeader>
        <Text align="left" color={theme.colors.purple.dark} fullWidth>
          We’ve sent an email to your email address. Follow the steps provided
          in the email to update your password or select Log In if you don’t
          want to change your password at this time
        </Text>

        <Button type="button" onClick={() => push(PATH.login)}>
          Sign in
        </Button>
      </Stack>
    </AuthCard>
  );
};
