import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import jwt_decode from "jwt-decode";

import { ToastContext } from "index";
import { useAuth } from "components/utils/auth";

type FormData = {
  email: string;
  password: string;
};

export const useLogin = () => {
  const toast = useContext(ToastContext);
  const {
    getValues,
    register,
    handleSubmit,
    watch,
    formState: { isDirty, isValid, errors, isSubmitting },
  } = useForm<FormData>({
    mode: "onChange",
  });
  const [loading, setIsLoading] = useState(false);
  const [isUserNotConfirmed, setIsUserNotConfirmed] = useState(false);

  useEffect(() => {
    watch();
  }, []);

  const { auth, onLoginSuccess } = useAuth();

  const onLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    setIsUserNotConfirmed(false);
    auth(email, password)
      .then((session: any) => {
        onLoginSuccess(
          jwt_decode(session.idToken.jwtToken),
          session.idToken.jwtToken
        );
      })
      .catch((err: any) => {
        setIsLoading(false);
        if (err?.name === "UserNotConfirmedException") {
          setIsUserNotConfirmed(true);
        } else {
          toast.addToast({
            description: err?.message || "Failed to login, try again later",
            variant: "error",
          });
        }
      });
  };

  return {
    getValues,
    errors,
    register,
    handleSubmit,
    onLogin,
    watch,
    isLoading: loading,
    isDirty,
    isValid,
    isSubmitting,
    isUserNotConfirmed,
  };
};
