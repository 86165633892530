import React from "react";

import { useTheme } from "@emotion/react";
import { Heading, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { Modal } from "components/lib/molecules";
import { useToast } from "index";
import { CancelBundleModalProps } from "./CancelBundleModal.types";
import { useBuildBundleCancel } from "components/pages/AdminPage/hooks/useBuildBundleCancel.hook";

export const CancelBundleModal: React.FC<CancelBundleModalProps> = ({
  onClose,
  buildBundle,
  refetchBuildBundle,
}) => {
  const toast = useToast();
  const theme = useTheme();
  const { mutateAsync: cancelBuildBundle } = useBuildBundleCancel();

  /**
   * Helper function to cancel a specific build bundle by ID
   */
  const handleCancelBuildBundle = async () => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Cancelling Build Bundle...",
    });
    try {
      if (!buildBundle?.id) throw Error();
      await cancelBuildBundle(buildBundle?.id);
      await refetchBuildBundle();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Build Bundle cancelled successfully",
      });
      onClose();
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to cancel build bundle. Please try again later",
      });
    }
  };

  return (
    <Modal
      isOpen={Boolean(buildBundle)}
      continueLabel="Yes, cancel it"
      onContinue={handleCancelBuildBundle}
      closeLabel="Cancel"
      onClose={onClose}
    >
      <Stack
        direction="vertical"
        align="center"
        gap={2}
        styles={{ padding: theme.space[2], marginBottom: theme.space[3] }}
      >
        <Heading variant={1}>Are you sure?</Heading>
        <Text>
          You will not be able to recover this build bundle after cancelling it
        </Text>
      </Stack>
    </Modal>
  );
};
