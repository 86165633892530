/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";

import {
  getValidationWrapperStyles,
  getIconStyles,
} from "./ValidationMessage.styles";
import { ValidationMessageProps } from "./ValidationMessage.types";
import { ReactComponent as DangerIcon } from "./svgs/dangerIcon.svg";

export const ValidationMessage = ({
  text,
  ...props
}: ValidationMessageProps) => {
  const theme = useTheme();

  if (text && text.length !== 0) {
    return (
      <div css={getValidationWrapperStyles(theme, props)}>
        <span css={getIconStyles(theme, props)}>
          <DangerIcon />
        </span>
        {text}
      </div>
    );
  }
  return null;
};
