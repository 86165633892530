import { useState } from "react";

import { Stack } from "components/lib/layouts";
import { RichPreview, RichEditor } from "components/shared";

export const SandboxEditorPage = () => {
  const [text, setText] = useState(`
  <p>inline text&nbsp;<math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mfrac><mn>12</mn><mn>24</mn></mfrac></msqrt></math></p><p>&nbsp;</p><p>
  `);
  
  return (
    <Stack fullWidth={true} gap={2}>
      <RichEditor data={text} onChange={setText} />
      <RichPreview>{text}</RichPreview>
    </Stack>
  );
};
