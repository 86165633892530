/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";

import { Stack } from "components/lib/layouts";
import { Text } from "components/lib/atoms";
import { InputField } from "components/lib/molecules";

import { DetailInputFieldProps } from "../ProfileDetails.types";

export const DetailInputField = React.forwardRef(
  (
    {
      title,
      isMandatory,
      autoFocus,
      validationMessage,
      errors,
      defaultValue,
      ...hookFormProps
    }: DetailInputFieldProps,
    ref?: React.Ref<HTMLInputElement>
  ): JSX.Element => {
    const name = (hookFormProps as { name: string }).name;
    const theme = useTheme();

    return (
      <Stack direction="vertical" gap={2}>
        <Stack direction="horizontal">
          <Text styles={{ marginRight: "4px" }}>{title}</Text>
          {isMandatory ? (
            <Text styles={{ color: theme.colors.red.default }}>*</Text>
          ) : null}
        </Stack>
        <InputField
          placeholder={title}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          validationMessage={errors?.[name]?.message}
          {...hookFormProps}
        />
      </Stack>
    );
  }
);
