import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useMutation } from "react-query";

export const useAccceptJob = () => {
  const { getToken } = useAuth();

  return useMutation(async (jobId: string) => {
    const { getConfiguration } = AppConfig();
    const api = new JobManagementApi(getConfiguration(await getToken()));
    return api.submissionControllerCreate(
      { jobId },
      getDefaultOptions(await getToken())
    );
  });
};
