import { useTheme } from "@emotion/react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Heading,
  Spinner,
  Table,
  Text,
} from "components/lib/atoms";
import {
  TableBody,
  TableData,
  TableHeading,
  TableRow,
} from "components/lib/atoms/Table/Table";
import { Stack } from "components/lib/layouts";
import { PATH } from "components/shared/constants/paths.constants";
import { bySubmissionLatestCreatedAt } from "components/utils/helpers";
import { CompletedSubmissionsDto } from "components/utils/openapi";
import { useCompletedSubmissionsList } from "./hooks/useCompletedSubmissionsList.hook";

export const CompletedJobsPage = () => {
  const { data, isLoading } = useCompletedSubmissionsList();
  const history = useHistory();
  const theme = useTheme();

  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={4} fullWidth={true}>
        <Heading variant={1}>Completed Jobs</Heading>
        {isLoading ? (
          <Spinner />
        ) : (
          <Stack fullWidth={true} direction="vertical" gap={2}>
            <Table styles={{ width: "100%", tableLayout: "fixed" }}>
              <colgroup>
                <col span={1} width={"20%"} />
                <col span={1} width={"25%"} />
                <col span={1} width={"15%"} />
                <col span={1} width={"15%"} />
                <col span={1} width={"25%"} />
              </colgroup>
              <TableRow isHeading={true}>
                <TableHeading>Job Name</TableHeading>
                <TableHeading>Job Type</TableHeading>
                <TableHeading>Status</TableHeading>
                <TableHeading>Total amount earned</TableHeading>
                <TableHeading />
              </TableRow>
              <TableBody>
                {data
                  ?.sort(bySubmissionLatestCreatedAt)
                  ?.map((completedSubmission: CompletedSubmissionsDto) => {
                    // Setup Variables
                    const status = completedSubmission?.status?.toUpperCase();
                    const amount =
                      completedSubmission?.amountEarned ||
                      completedSubmission?.amountEarned === 0
                        ? `$${completedSubmission?.amountEarned?.toFixed(2)}`
                        : `N/A`;
                    const hasFeedback =
                      completedSubmission?.acceptedContentNumber <=
                        completedSubmission?.contentNumber &&
                      (status === "COMPLETED" || status === "REJECTED");
                    return (
                      <TableRow key={completedSubmission?.id}>
                        <TableData>
                          {completedSubmission?.activityBuildName}
                        </TableData>
                        <TableData>
                          {completedSubmission?.jobTypeName}
                        </TableData>
                        <TableData>{completedSubmission?.status}</TableData>
                        <TableData
                          styles={{
                            fontStyle:
                              status === "IN REVIEW" ? "italic" : "normal",
                          }}
                        >
                          {status === "IN REVIEW" ? "Pending" : amount}
                        </TableData>
                        <TableData styles={{ height: theme.space[5] }}>
                          {!completedSubmission?.jobTypeIsReview && (
                            <Button
                              onClick={() => {
                                history.push(
                                  PATH.getFeedback(completedSubmission?.id)
                                );
                              }}
                              disabled={!hasFeedback}
                              variant="secondary"
                            >
                              View Feedback
                            </Button>
                          )}
                        </TableData>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {data?.length === 0 && (
              <Text styles={{ padding: theme.space[2] }}>
                You do not have any completed jobs. Go to{" "}
                <Link to={PATH.browseJobs}> browse jobs </Link> to get started.{" "}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
