import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Global, useTheme } from "@emotion/react";
import { PATH } from "components/shared/constants/paths.constants";

import { noop } from "./components/lib/utils";
import { Stack } from "./components/lib/layouts";
import { BaseStyles } from "./components/shared/styles/base.styles";
import { Navbar, Footer, GlobalErrorBoundary } from "./components/shared";
import {
  ActiveJobsPage,
  AdminPage,
  BrowseJobsPageNew,
  CompletedPage,
  JobEditPage,
  JobPageNew,
  LoginPage,
  OnboardingWizardPage,
  SandboxEditorPage,
  TraningsPage,
} from "./components/pages";

import { OnboardRoute, PrivateRoute } from "./components/routes";
import { useAuth } from "./components/utils/auth";

import { navlinks, unauthenticatedNavlinks } from "./navlinks";
import {
  getLayoutStyles,
  getMainContentStyles,
  getContainerStyles,
  getContentStyles,
} from "./App.styles";
import { AccountPage } from "components/pages/AccountPage/AccountPage";
import { CompletedJobsPage } from "components/pages/CompletedJobsPage/CompletedJobsPage";
import { FeedbackPage } from "components/pages/FeedbackPage/FeedbackPage";
import { useUser } from "components/shared/hooks/useUser.hook";
import { ChangePassword } from "components/pages/AccountPage/ChangePassword/ChangePassword";
import { UpdatePassword } from "components/pages/LoginPage/UpdatePassword/UpdatePassword";

const Layout: React.FC = ({ children }) => {
  const { isAuthenticated, user: authUser, logout } = useAuth();
  const { data: user } = useUser();
  const theme = useTheme();

  return (
    <>
      <Global styles={BaseStyles} />
      <Stack
        direction="vertical"
        fullWidth={true}
        styles={getLayoutStyles(theme)}
      >
        <Navbar
          logout={logout}
          onClick={noop}
          isAuthenticated={isAuthenticated}
          user={{
            "custom:role": authUser?.hasOwnProperty("custom:role")
              ? authUser["custom:role"]
              : null,
            firstName: user?.details?.firstName,
            lastName: user?.details?.lastName,
            email: authUser?.email,
          }}
          links={isAuthenticated ? navlinks : unauthenticatedNavlinks}
        />

        {children}
      </Stack>
    </>
  );
};

const MainContent: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const theme = useTheme();

  const isAdminPages = pathname.includes(PATH.admin);

  return (
    <Stack
      id="mainContent"
      fullWidth={true}
      justify="center"
      styles={getMainContentStyles(theme)}
    >
      <Stack
        fullWidth={true}
        justify="center"
        styles={getContainerStyles(theme, isAdminPages)}
      >
        <Stack
          styles={getContentStyles(theme, isAdminPages)}
          fullWidth={true}
          justify="center"
        >
          {children}
        </Stack>
      </Stack>
      <Footer />
    </Stack>
  );
};

const Routes: React.FC = () => {
  return (
    <Switch>
        <PrivateRoute path={PATH.training}>
        <TraningsPage />
      </PrivateRoute>
      <PrivateRoute path={PATH.activeJob}>
        <JobEditPage />
      </PrivateRoute>
      <PrivateRoute path={PATH.activeJobs}>
        <ActiveJobsPage />
      </PrivateRoute>
      <PrivateRoute path={PATH.browseJobs}>
        <BrowseJobsPageNew />
      </PrivateRoute>
      <PrivateRoute path={PATH.completedJobs}>
        <CompletedJobsPage />
      </PrivateRoute>
      <PrivateRoute path={PATH.feedback}>
        <FeedbackPage />
      </PrivateRoute>
      <PrivateRoute path={PATH.job}>
        <JobPageNew />
      </PrivateRoute>
      <PrivateRoute path={PATH.admin}>
        <AdminPage />
      </PrivateRoute>
      <Route path={PATH.login}>
        <LoginPage />
      </Route>
      <PrivateRoute path={PATH.changePassword} exact>
        <ChangePassword />
      </PrivateRoute>
      <Route path={PATH.updatePassword} exact>
        <UpdatePassword />
      </Route>
      <Route path={PATH.sandBoxEditor}>
        <SandboxEditorPage />
      </Route>
      <PrivateRoute path={PATH.completed}>
        <CompletedPage />
      </PrivateRoute>
      <PrivateRoute path={PATH.account}>
        <AccountPage />
      </PrivateRoute>
      <OnboardRoute path={PATH.onboardingWizard}>
        <OnboardingWizardPage />
      </OnboardRoute>
      <Route path="/">
        <Redirect to={PATH.login} />
      </Route>
    </Switch>
  );
};

function App() {
  return (
    <Layout>
      <MainContent>
        <GlobalErrorBoundary>
          <Routes />
        </GlobalErrorBoundary>
      </MainContent>
    </Layout>
  );
}

export default App;
