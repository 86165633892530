import { useQuery } from "react-query";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

export const useJobTypes = () => {
  const { getToken } = useAuth();
  return useQuery(["getJobTypes"], async () => getJobTypes(await getToken()));
};

const getJobTypes = async (token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.jobTypesControllerRetrieveAllJobTypes(getDefaultOptions(token));
};
