import { useAuth } from "components/utils/auth";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useMutation } from "react-query";

export const useDeleteSubmissionContent = () => {
  const { getToken } = useAuth();
  return useMutation(
    async ({
      submissionId,
      questionId,
    }: {
      submissionId: string;
      questionId: string;
    }) => {
      const { getConfiguration } = AppConfig();
      const api = new JobManagementApi(getConfiguration(await getToken()));
      return api.submissionControllerDeleteSubmissionContent(
        submissionId,
        questionId,
        getDefaultOptions(await getToken())
      );
    }
  );
};
