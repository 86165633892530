import { useQuery } from "react-query";
import { JobManagementApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

export const useJobType = (jobTypeId: string) => {
  const { getToken } = useAuth();
  return useQuery(["getJobType", jobTypeId], async () =>
    getJobType(jobTypeId, await getToken())
  );
};

const getJobType = async (jobTypeId: string, token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new JobManagementApi(getConfiguration(token));
  return api.jobTypesControllerRetrieveJobTypesById(
    jobTypeId,
    getDefaultOptions(token)
  );
};
