import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTheme } from "@emotion/react";

import { Stack } from "components/lib/layouts";
import { InputField, UploadFile } from "components/lib/molecules";
import {
  Button,
  Card,
  Divider,
  Heading,
  Select,
  Text,
} from "components/lib/atoms";
import { SelectInterface } from "components/lib/atoms/Select/Select.types";
import { getGroupedJobs, sortOptionsAlphabetically } from "components/utils/helpers";
import {
  validationNumberToTwoDecimals,
  validationWholeNumber,
  ValidationMessageRequired,
  validationNumberLimit,
  ValidationMessageNumberLimit,
} from "components/utils/validation";
import {
  ActivityTemplateGetDto,
  CurriculumGetDto,
  JobTypeDto,
  Standard,
  Subject,
} from "components/utils/openapi";
import { useCurriculum } from "components/shared/hooks/useCurriculum";

import { useCreateJob } from "../../hooks/useCreateJob.hook";
import { useActivityTemplate } from "../../hooks/useActivityTemplate.hook";
import { JobDetailsProps, JobList } from "../CreateNewJob.types";

export const JobDetails: React.FC<JobDetailsProps> = ({ onNext }) => {
  const defaultFields = {
    jobs: [],
  };

  const theme = useTheme();

  const standardRef = useRef<any>();
  const subjectRef = useRef<any>();

  const [activityTemplates, setActivityTemplates] = useState<
    ActivityTemplateGetDto[]
  >([]);
  const [levelOptions, setLevelOptions] = useState<SelectInterface[]>([]);
  const [standardOptions, setStandardOptions] = useState<SelectInterface[]>([]);
  const [subjectOptions, setSubjectOptions] = useState<SelectInterface[]>([]);
  const [activityTemplateOptions, setActivityTemplateOptions] = useState<
    SelectInterface[]
  >([]);

  const [curriculum, setCurriculum] = useState<CurriculumGetDto[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const activityTemplateHook = useActivityTemplate();
  const curriculumHook = useCurriculum();

  const {
    control,
    errors,
    fields,
    handleSubmit,
    onCreateJob,
    register,
    reset,
    getValues,
    setValue,
    setSelectedFiles,
    watch,
  } = useCreateJob({
    defaultFields,
    handleNext: onNext,
  });

  const activityBuilds = watch("activityBuilds");
  const jobs = useWatch({
    name: "jobs",
    defaultValue: fields,
    control: control,
  });

  useEffect(() => {
    if (activityTemplateHook.data) {
      const activityTemplateOptions: SelectInterface[] = [];
      activityTemplateHook.data.forEach((item: ActivityTemplateGetDto) => {
        activityTemplateOptions.push({ value: item.id, label: item.name });
      });
      setActivityTemplateOptions(activityTemplateOptions);
      setActivityTemplates(activityTemplateHook.data);
    }
  }, [activityTemplateHook.data]);

  useEffect(() => {
    if (curriculumHook.data) {
      const levelData: SelectInterface[] = [];
      const sortedCurriculumData = curriculumHook.data?.sort(
        sortOptionsAlphabetically
      );
      sortedCurriculumData?.forEach((levelItem: CurriculumGetDto) => {
        levelData.push({ value: levelItem.id, label: levelItem.name });
        levelItem.subjects = levelItem.subjects
          .sort(sortOptionsAlphabetically)
          .filter((subject: any) => {
            return subject.standards.length > 0;
          });
        levelItem.subjects.forEach((subjectItem: Subject) => {
          subjectItem.standards = subjectItem.standards?.sort(
            sortOptionsAlphabetically
          );
        });
        setLevelOptions(levelData);
        setCurriculum(sortedCurriculumData);
      });
    }
  }, [curriculumHook.data]);

  useEffect(() => {
    let totalPrice = 0;
    jobs?.forEach((job: JobList) => {
      if (
        job?.rate &&
        job?.contentNumber &&
        !isNaN(Number.parseFloat(job?.rate))
      ) {
        totalPrice += Number.parseFloat(job.rate) * job.contentNumber;
      }
    });
    totalPrice = activityBuilds ? totalPrice * activityBuilds : 0;
    setTotalPrice(Math.round((totalPrice + Number.EPSILON) * 100) / 100);
  }, [setTotalPrice, jobs, activityBuilds]);

  const handleChangeLevel = (event: any) => {
    curriculum?.forEach((levelItem: CurriculumGetDto) => {
      if (levelItem.id === event.value) {
        const subjectData: SelectInterface[] = [];
        levelItem.subjects?.forEach((subjectItem: Subject) => {
          subjectData.push({
            value: subjectItem.id,
            label: subjectItem.name,
          });
        });
        setSubjectOptions(subjectData);
        setStandardOptions([]);
        setValue("standard", undefined);
        standardRef.current.select.clearValue();
        setValue("subject", undefined);
        subjectRef.current.select.clearValue();
      }
    });
  };

  const handleSubjectChange = (event: any) => {
    curriculum?.forEach((levelItem: CurriculumGetDto) => {
      if (getValues("level.value") === levelItem.id) {
        levelItem.subjects?.forEach((subjectItem: Subject) => {
          if (subjectItem.id === event?.value) {
            const standardData: SelectInterface[] = [];
            subjectItem.standards?.forEach((standardItem: Standard) => {
              standardData.push({
                value: standardItem.nceaCode,
                label: standardItem.name,
              });
            });
            setStandardOptions(standardData);
            setValue("standard", undefined);
            standardRef.current.select.clearValue();
          }
        });
      }
    });
  };

  const handleActivityTemplateChange = (event: any) => {
    activityTemplates?.forEach((activityTemplate: ActivityTemplateGetDto) => {
      if (activityTemplate.id === event.value) {
        const jobsList: JobList[] = [];
        activityTemplate.jobTypes.forEach((jobType: JobTypeDto) => {
          jobsList.push({
            id: jobType?.id,
            name: jobType?.name,
            groupName: jobType?.groupName,
            isReview: jobType?.isReview,
            jobDuration: 0,
            rate: "",
            contentNumber: jobType.contentNumber,
          });
        });
        jobsList.sort((a, b) => {
          if (a.groupName === b.groupName)
            return a.isReview ? 1 : -1;
          return a.groupName > b.groupName ? 1 : -1;
        });
        reset(
          {
            ...getValues(),
            jobs: jobsList,
          },
          {
            keepValues: false,
            keepErrors: true,
            keepDirty: false,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
          }
        );
      }
    });
  };

  const CreateJobList = () => {
    const groupedJobs = getGroupedJobs(fields);
    let jobIdx = -1;
    return (
      <>
        {Object.keys(groupedJobs)
          ?.map((groupKey: string, groupIdx: number) => {
            return (
              <Stack
                gap={4}
                fullWidth={true}
                styles={{ flex: 1 }}
                direction="vertical"
                key={groupIdx}
              >
                <Text
                  variant={3}
                  weight={theme.fontWeights.medium}
                  color={theme.colors.purple.dark}
                >
                  {groupKey}
                </Text>
                {groupedJobs[groupKey]
                  ?.map((item: JobTypeDto) => {
                    jobIdx += 1;
                    return (
                      <Stack
                        gap={2}
                        fullWidth={true}
                        justify="left"
                        align="center"
                        key={item.id}
                      >
                        <Text
                          color={theme.colors.purple.dark}
                          styles={{ width: "225px" }}
                        >
                          {item.name}
                        </Text>
                        <InputField
                          styles={{
                            width: "170px",
                          }}
                          placeholder="$0.00"
                          type="number"
                          min={0}
                          max={35}
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          validationMessage={
                            errors?.jobs?.[jobIdx]?.rate?.message
                          }
                          {...register(`jobs.${jobIdx}.rate` as const, {
                            required: ValidationMessageNumberLimit,
                            pattern: validationNumberLimit,
                          })}
                        />
                      </Stack>
                    );
                  })}
                <Stack gap={2} fullWidth={true} justify="left" align="center">
                  <Text
                    color={theme.colors.purple.dark}
                    styles={{ width: "225px" }}
                  >
                    Job deadline
                  </Text>
                  <InputField
                    styles={{ width: "170px" }}
                    placeholder="Specify here"
                    validationMessage={errors.jobDurations?.[groupIdx]?.message}
                    {...register(
                      `jobDurations.${groupIdx}.jobDuration` as const,
                      {
                        required: ValidationMessageRequired,
                        pattern: validationNumberToTwoDecimals,
                      }
                    )}
                  />
                  <Text>hours</Text>
                </Stack>
                <Divider
                  styles={{
                    marginTop: theme.space[6],
                    marginBottom: theme.space[3],
                  }}
                />
              </Stack>
            );
          })}
      </>
    );
  };

  return (
    <Stack
      direction="vertical"
      gap={4}
      fullWidth={true}
      align="left"
      styles={{ maxWidth: "800px" }}
    >
      <Heading variant={1} color={theme.colors.purple.dark}>
        Create jobs
      </Heading>
      <Card
        styles={{ maxWidth: "800px", padding: "65px 50px" }}
        fullWidth={true}
      >
        <form onSubmit={handleSubmit(onCreateJob)}>
          <Stack
            direction="vertical"
            gap={4}
            fullWidth={true}
            styles={{ flex: 1 }}
          >
            <Stack gap={2} fullWidth={true} justify="left" align="center">
              <Text
                color={theme.colors.purple.dark}
                styles={{ width: "225px" }}
              >
                Activity Templates
              </Text>
              <Controller
                name="activityTemplate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={{ width: "275px" }}
                    options={activityTemplateOptions}
                    onChange={(e: any) => {
                      field.onChange(e); // method from hook form register
                      handleActivityTemplateChange(e);
                    }}
                    placeholder="Select Template"
                    validationMessage={errors.activityTemplate?.message}
                  />
                )}
              />
            </Stack>
            <Stack gap={2} fullWidth={true} justify="left" align="center">
              <Text
                color={theme.colors.purple.dark}
                styles={{ width: "225px" }}
              >
                Number of activity builds
              </Text>
              <InputField
                styles={{ width: "170px" }}
                type="number"
                min={1}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                placeholder="Specify here"
                validationMessage={errors.activityBuilds?.message}
                {...register("activityBuilds", {
                  required: ValidationMessageRequired,
                  pattern: validationWholeNumber,
                })}
              />
            </Stack>
            <Divider
              styles={{
                marginTop: theme.space[6],
                marginBottom: theme.space[3],
              }}
            />
            {CreateJobList()}

            <Stack gap={2} fullWidth={true} justify="left" align="center">
              <Text
                color={theme.colors.purple.dark}
                styles={{ width: "225px" }}
              >
                Level
              </Text>
              <Controller
                name="level"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={{ width: "275px" }}
                    options={levelOptions}
                    onChange={(e: any) => {
                      field.onChange(e); // method from hook form register
                      handleChangeLevel(e);
                    }}
                    placeholder="Select Level"
                    validationMessage={errors.level?.message}
                  />
                )}
              />
            </Stack>

            <Stack gap={2} fullWidth={true} justify="left" align="center">
              <Text
                color={theme.colors.purple.dark}
                styles={{ width: "225px" }}
              >
                Subject
              </Text>
              <Controller
                name="subject"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={{ width: "275px" }}
                    options={subjectOptions}
                    onChange={(e: any) => {
                      field.onChange(e); // method from hook form register
                      handleSubjectChange(e);
                    }}
                    disabled={subjectOptions.length === 0}
                    placeholder="Select Subject"
                    validationMessage={errors.subject?.message}
                    ref={subjectRef}
                  />
                )}
              />
            </Stack>

            <Stack gap={2} fullWidth={true} justify="left" align="center">
              <Text
                color={theme.colors.purple.dark}
                styles={{ width: "225px" }}
              >
                Standard
              </Text>
              <Controller
                name="standard"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={{ width: "275px" }}
                    options={standardOptions}
                    disabled={standardOptions.length === 0}
                    placeholder="Select Standard"
                    validationMessage={errors.standard?.message}
                    ref={standardRef}
                  />
                )}
              />
            </Stack>

            <Stack
              gap={2}
              direction="horizontal"
              fullWidth={true}
              justify="left"
              align="center"
            >
              <Text
                color={theme.colors.purple.dark}
                styles={{ width: "225px" }}
              >
                Resource Upload
              </Text>
              <UploadFile
                maxFiles={1}
                accept={["application/zip", "application/x-zip-compressed"]}
                maxSize={1024 * 1024 * 200}
                setFilesToUpload={setSelectedFiles}
              />
            </Stack>

            <Stack
              styles={{
                marginTop: theme.space[6],
              }}
              gap={2}
              fullWidth={true}
              justify="left"
              align="center"
            >
              <Text
                color={theme.colors.purple.dark}
                styles={{ width: "225px" }}
              >
                Total Price
              </Text>
              <Text styles={{ width: "225px" }}>{`$${totalPrice}`}</Text>
            </Stack>

            <Stack
              gap={2}
              fullWidth={true}
              justify="left"
              align="center"
              styles={{ paddingTop: "50px" }}
            >
              <Button type="submit" disabled={Object.keys(errors).length > 0}>
                Create Job
              </Button>
            </Stack>
          </Stack>
        </form>
      </Card>
    </Stack>
  );
};
