import React, { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";

import { Stack } from "../../lib/layouts";
import { AuthContext } from "components/utils/auth";
import { getDefaultPageRedirect } from "components/utils/routing";
import { PATH } from "components/shared/constants/paths.constants";

import { EmailVerification } from "./EmailVerification/EmailVerification";
import { LoginForm } from "./LoginForm/LoginForm";
import { RegisterForm } from "./RegisterForm/RegisterForm";
import { ResetPassword } from "./ResetPassword/ResetPassword";
import { UpdatePassword } from "./UpdatePassword/UpdatePassword";

export const LoginPage = () => {
  const history = useHistory();
  const { user, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      history.push(getDefaultPageRedirect(user));
    }
  }, [history, isAuthenticated]);

  return (
    <Stack justify="center" fullWidth>
      <Route path={PATH.register} exact>
        <RegisterForm />
      </Route>
      <Route path={PATH.verify} exact>
        <EmailVerification />
      </Route>
      <Route path={PATH.forgotPassword} exact>
        <ResetPassword />
      </Route>
      <Route path={PATH.updatePassword} exact>
        <UpdatePassword />
      </Route>
      <Route path={PATH.login} exact>
        <LoginForm />
      </Route>
    </Stack>
  );
};
