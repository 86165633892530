/** @jsxImportSource @emotion/react */

import { Theme, css } from "@emotion/react";

export const styles = (theme: Theme) => css`
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    display: flex;
  }

  label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid ${theme.colors.grey.text};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 5px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: ${theme.space[1]};
    margin-top: 2px;
    width: 2px;
    height: 2px;
    display: block;
  }

  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 4px;
    width: 3px;
    height: 8px;
    border: 1px solid ${theme.colors.green.default};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
