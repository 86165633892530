import React, { useContext, useState, useEffect } from "react";
import UserPool from "./cognitoPool";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router";
export interface AuthContextType {
  auth: any;
  user: any;
  isAuthenticated: boolean;
  getToken: any;
  getSession: any;
  changePassword: any;
  forgotPassword: (email: string) => Promise<unknown>;
  confirmPassword: (
    email: string,
    otp: string,
    newpass: string
  ) => Promise<unknown>;
  resendConfirmationLink: (Username: string) => Promise<unknown>;
  logout: any;
  confirmSignUp: any;
  onLoginSuccess: any;
}

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType
);

export const useAuth = () => useContext(AuthContext);

const TOKEN_KEY = "token";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState(localStorage.getItem(TOKEN_KEY));
  const [isAuthenticated, setIsAuthenticated] = useState(token ? true : false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (token) {
      setUser(jwt_decode(token));
      setIsAuthenticated(true);
    }
  }, []);

  const updateToken = (token: string | null) => {
    setToken(token);

    token
      ? window.localStorage.setItem(TOKEN_KEY, token)
      : window.localStorage.removeItem(TOKEN_KEY);
    setLoading(false);
  };

  const onLoginSuccess = (user: any, token: string) => {
    updateToken(token);
    setUser(user);
    setIsAuthenticated(true);
  };

  const getUser = (Username: string) => {
    return new CognitoUser({
      Username: Username,
      Pool: UserPool,
    });
  };

  const auth = async (Username: string, Password: string) => {
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });
      getUser(Username).authenticateUser(authDetails, {
        onSuccess: (data) => {
          return resolve(data);
        },
        onFailure: (err) => {
          return reject(err);
        },
        newPasswordRequired: (data) => {
          return resolve(data);
        },
      });
    });
  };

  const getSession = () => {
    return new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err: any, session: any) => {
          if (session) {
            if (err) {
              return reject(err);
            } else {
              return resolve(session);
            }
          } else {
            reject(err);
          }
        });
      } else {
        return reject(new Error("No Current User Found"));
      }
    });
  };

  /** Function to get the current valid token - Refreshes when expired / not valid */
  const getToken = (): Promise<string | null> =>
    new Promise((resolve, reject) => {
      // Get Current User Logged In
      const user = UserPool.getCurrentUser();
      if (!user) return reject(new Error("No Current User Found"));

      // Get Session (Refreshes the Token if Expired Underneath)
      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            return reject(err);
          } else {
            if (!session?.isValid())
              return reject(new Error("Invalid User Session!"));

            // Save to Local Storage and Return the New Token
            const newToken = session?.getIdToken().getJwtToken();
            updateToken(newToken);
            return resolve(newToken);
          }
        }
      );
    });

  const changePassword = async (oldpass: string, newpass: string) => {
    return new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err: Error, data: any) => {
          if (err) {
            return reject(err);
          }
        });

        user.changePassword(oldpass, newpass, (err, data) => {
          if (err) {
            return reject(err);
          }
          if (data) {
            return resolve(data);
          }
        });
      }
    });
  };

  const forgotPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      getUser(email).forgotPassword({
        onSuccess: (data) => {
          return resolve(data);
        },
        onFailure: (err) => {
          return reject(err);
        },
      });
    });
  };
  const confirmPassword = (email: string, otp: string, newpass: string) => {
    return new Promise((resolve, reject) => {
      getUser(email).confirmPassword(otp, newpass, {
        onSuccess: () => {
          return resolve({ data: true });
        },
        onFailure: (err) => {
          return reject(err);
        },
      });
    });
  };

  const resendConfirmationLink = (Username: string) => {
    return new Promise((resolve, reject) => {
      getUser(Username).resendConfirmationCode((err: any, result: any) => {
        if (err) {
          return reject(err);
        } else {
          return resolve(result);
        }
      });
    });
  };

  const logout = () => {
    localStorage.removeItem("refreshCount");
    updateToken(null);
    setUser(null);
    setIsAuthenticated(false);
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  const confirmSignUp = (Username: string, code: string) => {
    return new Promise((resolve, reject) => {
      getUser(Username).confirmRegistration(
        code,
        true,
        (err: any, result: any) => {
          if (err) {
            return reject(err);
          } else {
            return resolve(result);
          }
        }
      );
    });
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        isAuthenticated,
        getToken,
        getSession,
        changePassword,
        forgotPassword,
        confirmPassword,
        resendConfirmationLink,
        logout,
        confirmSignUp,
        onLoginSuccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
