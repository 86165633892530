import { Container, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import React from "react";
import { HideIcon, ShowIcon } from "../svgs";
import { PasswordToggleType } from "./PasswordToggle.types";
import * as styles from "./PasswordToggle.styles";

export const PasswordToggle: React.FC<PasswordToggleType> = ({
  isPasswordShown,
  onClick,
}: PasswordToggleType) => {
  return (
    <Container onClick={onClick}>
      <Stack gap={1} align="center" styles={{ cursor: "pointer" }}>
        {isPasswordShown ? <HideIcon /> : <ShowIcon />}
        <Text styles={styles.PasswordToggleStyles}>
          {isPasswordShown ? "Hide" : "Show"}
        </Text>
      </Stack>
    </Container>
  );
};
