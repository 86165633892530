import { RetrieveSubmissionContentResponseDto } from "components/utils/openapi";

export const isQuestionComplete = (
  question: RetrieveSubmissionContentResponseDto
) =>
  (question.content["question"] || "").trim() !== "" &&
  (question.content["correctAnswer"] || "").trim() !== "" &&
  (question.content["incorrectAnswerOne"] || "").trim() !== "" &&
  (question.content["incorrectAnswerTwo"] || "").trim() !== "" &&
  (question.content["incorrectAnswerThree"] || "").trim() !== "";
