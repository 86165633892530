/** @jsxImportSource @emotion/react */
import React from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";

import { Text, Radio, ValidationMessage } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { InputField } from "components/lib/molecules";

import { FormSection } from "../ProfileDetails.types";

const KNOWN_OCCUPATIONS = [
  {
    value: "nceaStudent",
    label: "NCEA student",
  },
  {
    value: "university",
    label: "University student",
  },
  {
    value: "teacher",
    label: "Teacher",
  },
];

const OCCUPATIONS = [
  ...KNOWN_OCCUPATIONS,
  {
    value: "others",
    label: "Others",
  },
];

export const CurrentOccupation: React.FC<FormSection> = ({
  register,
  formState,
  control,
  watch,
}) => {
  const theme = useTheme();
  const occupation = watch("occupation");

  function isOtherInputVisible(value: string, label: string) {
    const unknownOccupation = !KNOWN_OCCUPATIONS.find(
      (occupations) => occupations.value === value
    );
    return unknownOccupation && value !== "" && label === "Others";
  }

  return (
    <Stack direction="vertical" styles={{ paddingTop: theme.space[2] }}>
      <Stack direction="horizontal">
        <Text styles={{ marginRight: "4px" }} variant={3}>
          Current occupation
        </Text>
        <Text styles={{ color: theme.colors.red.default }}>*</Text>
      </Stack>

      <Controller
        name="occupation"
        control={control}
        defaultValue={occupation}
        rules={{
          required: {
            message: "Required",
            value: true,
          },
        }}
        render={({ field }) => (
          <Stack direction="vertical">
            <ValidationMessage
              styles={{ marginTop: "10px" }}
              text={formState?.errors?.occupation?.message}
            />
            {OCCUPATIONS.map(({ value, label }) => {
              const showOtherInput = isOtherInputVisible(field.value, label);
              return (
                <Stack
                  direction="horizontal"
                  gap={3}
                  key={label}
                  styles={{ height: "32px" }}
                >
                  {label === "Others" ? (
                    <Radio
                      value={value}
                      checked={showOtherInput}
                      onChange={() => field.onChange()}
                      name="occupation"
                    >
                      {label}
                    </Radio>
                  ) : (
                    <Radio
                      value={value}
                      checked={value === field.value}
                      onChange={field.onChange}
                      name="occupation"
                    >
                      {label}
                    </Radio>
                  )}

                  {showOtherInput ? (
                    <InputField
                      placeholder="Specify here"
                      defaultValue={field.value}
                      autoFocus
                      style={{
                        width: "300px",
                        height: "16px",
                        marginTop: "12px",
                        border:
                          formState?.errors?.occupation?.message &&
                          `${theme.lineThickness[0]} solid ${theme.colors.red.border}`,
                      }}
                      {...register("occupation", {
                        required: {
                          message: "Required",
                          value: true,
                        },
                      })}
                    />
                  ) : null}
                </Stack>
              );
            })}
          </Stack>
        )}
      />
    </Stack>
  );
};
