import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils/theme";

export const styles: Record<string, CSSObject> = {
  SectionButton: {
    textAlign: "left",
    padding: `${theme.space[3]} ${theme.space[4]}`,
    color: theme.colors.purple.base,
    fontWeight: theme.fontWeights.regular,
    ":hover": {
      background: theme.colors.grey.darkSurface,
      color: theme.colors.purple.default,
    },
  },

  SelectedSectionButton: {
    textAlign: "left",
    padding: `${theme.space[3]} ${theme.space[4]}`,
    background: theme.colors.grey.darkSurface,
    color: theme.colors.purple.default,
    fontWeight: theme.fontWeights.medium,
    ":hover": {
      background: theme.colors.grey.darkSurface,
      color: theme.colors.purple.default,
    },
  },

  SectionTextButton: {
    textAlign: "left",
    padding: `${theme.space[4]} ${theme.space[4]}`,
    color: theme.colors.purple.default,
    ":hover": {
      color: theme.colors.purple.dark,
    },
  },
};
