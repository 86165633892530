import { useTheme } from "@emotion/react";
import { useParams, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { useToast } from "index";
import { Stack } from "components/lib/layouts";
import { Modal, UploadFile } from "components/lib/molecules";
import {
  Heading,
  Card,
  Text,
  Button,
  Container,
  Spinner,
} from "components/lib/atoms";
import {
  EditTemplateProps,
  FormValue,
  TemplateProps,
} from "../../jobTemplates.types";
import { RichEditor, RichPreview } from "components/shared";
import { CompletedIcon, LeftChevronIcon } from "components/lib/icons";
import { PATH } from "components/shared/constants/paths.constants";
import { useJobType } from "components/pages/AdminPage/hooks/useJobType.hook";
import { useJobTypesUpdate } from "components/pages/AdminPage/hooks/useJobTypesUpdate.hook";
import { AttachmentResponseDto } from "components/utils/openapi";
import { useJobTypeUploadAttachment } from "../../../hooks/useJobTypeUploadAttachment.hook";

import * as styles from "./EditTemplate.styles";

type EditTemplateHistoryStateType = {
  hasBeenUpdated?: boolean;
};

export const EditTemplate: React.FC<EditTemplateProps> = ({
  isPreviewMode,
}) => {
  const { push, location } = useHistory<EditTemplateHistoryStateType>();
  const theme = useTheme();
  const toast = useToast();
  const { templateId }: TemplateProps = useParams();
  const { data: template, refetch, isLoading } = useJobType(templateId);
  const { mutateAsync: updateJobType } = useJobTypesUpdate();
  const {
    uploadResourceAttachment,
    deleteResourceAttachment,
    updateResourceAttachment,
  } = useJobTypeUploadAttachment();

  const { control, watch, handleSubmit, reset } = useForm<FormValue>({
    mode: "onChange",
    defaultValues: {
      instructions: template?.instructions ?? "",
      details: template?.details ?? "",
    },
  });
  const [instructions, details] = watch(["instructions", "details"]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState({
    open: false,
    attachmentId: "",
  });

  useEffect(() => {
    reset({
      instructions: template?.instructions ?? "",
      details: template?.details ?? "",
    });
  }, [template, reset]);

  /**
   * Handles the Saving of the Job Type Template on Edit Mode
   * @param formValue data of the to be updated job type template
   */
  const handleSaveJobTypeTemplate = async (formValue: FormValue) => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Saving Job Type Template...",
    });
    try {
      const { details, instructions } = formValue;
      if (!template || !template?.id)
        throw new Error("Job Type Template not found. Please try again later");
      await updateJobType({ ...template, details, instructions });
      await refetch();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Job Type Template saved successfully",
      });

      push(PATH.getAdminJobTemplatesPreview(templateId), {
        hasBeenUpdated: true,
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to save Job Type Template. Please try again later",
      });
    }
  };

  /**
   * Handles the Deletion of Job Type Attachment on Edit Mode
   * @param attachmentId attachment to delete
   */
  const handleDeleteJobTypeAttachment = async (attachmentId: string) => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Deleting Job Type Attachment...",
    });
    try {
      if (!template || !template?.id)
        throw new Error("Job Type Template not found. Please try again later");
      await deleteResourceAttachment(template?.id, attachmentId);
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Job Type Attachment deleted successfully",
      });
      await refetch();

      // Notify Changes as well as close the Modal
      setIsConfirmationModalOpen({
        open: false,
        attachmentId: "",
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to delete Job Type Attachment. Please try again later",
      });
    }
  };

  /**
   * Helper function to upload or update job type attachment
   * @param file to be uploaded
   * @param jobTypeId job type to update
   */
  const uploadJobTypeAttachment = async (
    file: File,
    jobTypeId: string
  ): Promise<AttachmentResponseDto> => {
    if (template && template?.attachments?.length >= 1) {
      if (!template?.attachments[0]?.id) {
        throw new Error("Attachment not found.");
      }
      return await updateResourceAttachment(
        file,
        jobTypeId,
        template?.attachments[0]?.id
      );
    } else {
      return await uploadResourceAttachment(file, jobTypeId);
    }
  };

  /**
   * Handles the Uploading of Job type Attachment
   * @param files files to upload
   */
  const handleUploadSubmissionContentAttachment = (files: File[]) => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Uploading Submission Attachment...",
    });
    try {
      // File and Template Validations
      if (!files || files?.length === 0)
        throw new Error("Please select a file to upload.");
      if (files?.length > 1) throw new Error("You can only upload one file.");
      if (!template || !template?.id) throw new Error("No job type found.");

      // Upload Job Type Attachment
      uploadJobTypeAttachment(files[0], template?.id)
        .then(() => {
          refetch().then(() => {
            toast.removeToast(toastId);
            toast.addToast({
              variant: "success",
              description: "Attachment uploaded successfully",
            });
          });
          push(PATH.getAdminJobTemplatesPreview(templateId), {
            hasBeenUpdated: true,
          });
        })
        .catch((err) => {
          toast.removeToast(toastId);
          toast.addToast({
            variant: "error",
            description:
              err?.message ||
              "Failed to upload Attachment. Please try again later",
          });
        });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message || "Failed to upload Attachment. Please try again later",
      });
    }
  };

  const actionTitle = isPreviewMode ? "Preview" : "Edit";

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Card fullWidth={true}>
        {/* Show this only when data has changed */}
        {isPreviewMode && location?.state?.hasBeenUpdated && (
          <Container styles={styles.successNotification}>
            <CompletedIcon />
            <Text
              variant={3}
              color={theme.colors.mint.dark}
              styles={styles.successNotificationText}
            >
              You successfully saved the template
            </Text>
          </Container>
        )}

        <form onSubmit={handleSubmit(handleSaveJobTypeTemplate)}>
          <Stack direction="vertical" gap={4} fullWidth={true}>
            <Heading variant={1}>{actionTitle} job type template</Heading>
            <Text
              variant={3}
              weight={theme.fontWeights.medium}
              color={theme.colors.purple.dark}
            >
              Template name
            </Text>
            <Text variant={3} styles={styles.templateName}>
              {template?.name}
            </Text>
            <Stack direction="vertical" gap={2}>
              <Text
                variant={3}
                weight={theme.fontWeights.medium}
                color={theme.colors.purple.dark}
              >
                {isPreviewMode ? `Resource file` : `Resource Upload`}
              </Text>
              {template?.attachments && template?.attachments?.length > 0 ? (
                template?.attachments?.map(
                  (attachment: AttachmentResponseDto) => {
                    return (
                      <Stack
                        key={attachment?.id}
                        direction="horizontal"
                        justify="left"
                        align="center"
                        gap={2}
                      >
                        {!isPreviewMode ? (
                          <Text>{attachment?.name}</Text>
                        ) : (
                          <a
                            target="_blank"
                            href={attachment?.url}
                            rel="noreferrer"
                          >
                            <Button type="button" variant="link">
                              {attachment?.name}
                            </Button>
                          </a>
                        )}
                        {!(
                          isPreviewMode || location?.state?.hasBeenUpdated
                        ) && (
                          <Button
                            type="button"
                            style={{ textDecorationLine: "underline" }}
                            variant="warningtext"
                            onClick={() => {
                              setIsConfirmationModalOpen({
                                open: true,
                                attachmentId: attachment?.id,
                              });
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </Stack>
                    );
                  }
                )
              ) : (
                <Text color={theme.colors.grey.text}>No Resource</Text>
              )}
              {!(isPreviewMode || location?.state?.hasBeenUpdated) && (
                <UploadFile
                  maxFiles={1}
                  maxSize={209715200}
                  uploadButtonName={
                    template?.attachments && template?.attachments?.length > 0
                      ? "Replace File"
                      : undefined
                  }
                  uploadFile={handleUploadSubmissionContentAttachment}
                  width={1920}
                  height={1080}
                />
              )}
            </Stack>
            <Text
              variant={3}
              weight={theme.fontWeights.medium}
              styles={styles.richTextTitle}
              color={theme.colors.purple.dark}
            >
              Job instructions
            </Text>
            <Controller
              name="instructions"
              control={control}
              defaultValue={instructions}
              render={({ field }) => {
                return (
                  <>
                    {isPreviewMode ? (
                      <RichPreview styles={styles.richTextPreview}>
                        {field.value}
                      </RichPreview>
                    ) : (
                      <RichEditor
                        variant="text"
                        onChange={field.onChange}
                        data={field.value}
                        styles={styles.richTextEditor}
                      />
                    )}
                  </>
                );
              }}
            />
            <Text
              variant={3}
              weight={theme.fontWeights.medium}
              styles={styles.richTextTitle}
              color={theme.colors.purple.dark}
            >
              Job details
            </Text>
            <Controller
              name="details"
              control={control}
              defaultValue={details}
              render={({ field }) => {
                return (
                  <>
                    {isPreviewMode ? (
                      <RichPreview styles={styles.richTextPreview}>
                        {field.value}
                      </RichPreview>
                    ) : (
                      <RichEditor
                        variant="text"
                        onChange={field.onChange}
                        data={field.value}
                        styles={styles.richTextEditor}
                      />
                    )}
                  </>
                );
              }}
            />
            {isPreviewMode ? (
              <Button
                onClick={() => push(PATH.adminJobTemplates)}
                styles={styles.actionButton}
              >
                Back to job type templates
              </Button>
            ) : (
              <Button type="submit" styles={styles.actionButton}>
                Save & preview
              </Button>
            )}
          </Stack>
        </form>
      </Card>

      <Modal
        isOpen={isConfirmationModalOpen.open}
        continueButtonVariant={"warning"}
        continueLabel="Yes, delete it"
        onContinue={async () => {
          await handleDeleteJobTypeAttachment(
            isConfirmationModalOpen?.attachmentId
          );
        }}
        closeLabel="Cancel"
        onClose={() => {
          setIsConfirmationModalOpen({
            open: false,
            attachmentId: "",
          });
        }}
      >
        <Stack
          direction="vertical"
          align="center"
          gap={2}
          styles={{ padding: theme.space[2], marginBottom: theme.space[3] }}
        >
          <Heading variant={1}>Are you sure?</Heading>
          <Text>You will not be able to recover this attachment</Text>
        </Stack>
      </Modal>

      {!isPreviewMode ? (
        <Button
          variant="link"
          styles={{ textDecoration: "none", marginTop: theme.space[4] }}
          onClick={() => {
            push(PATH.adminJobTemplates);
          }}
        >
          <LeftChevronIcon /> Activity templates
        </Button>
      ) : null}
    </>
  );
};
