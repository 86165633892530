import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { StackPropsType } from "./Stack.types";

import { getStyles } from "../../utils";

export const getStackStyles = (
  theme: Theme,
  {
    styles,
    fullWidth,
    justify,
    inline,
    align,
    direction,
    gap = 0,
    wrap,
  }: Partial<StackPropsType>
) => {
  const base: CSSObject = {
    ...(fullWidth ? { width: "100%" } : {}),
    boxSizing: "border-box",
    display: inline ? "inline-flex" : "flex",
    justifyContent: justify,
    alignItems: align,
    flexWrap: wrap,
    flexDirection: direction === "vertical" ? "column" : "row",
    "> * + *":
      direction === "vertical"
        ? {
            marginTop: theme.space[gap],
            marginInlineStart: 0,
          }
        : {
            marginLeft: theme.space[gap],
          },
  };

  return getStyles({ base, styles });
};
