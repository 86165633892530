import { useLocation } from "react-router";
import { PATH } from "components/shared/constants/paths.constants";

export function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export function getDefaultPageRedirect(user: any) {
 return user && user["custom:role"] === "admin" ? PATH.admin : PATH.browseJobs
}