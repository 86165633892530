/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";

import { HeadingProps } from "./Heading.types";
import { getHeadingStyles } from "./Heading.styles";

export const Heading: React.FC<HeadingProps> = (props) => {
  const { variant = 3, children } = props;
  const theme = useTheme();

  const HX = `h${variant}` as keyof JSX.IntrinsicElements;

  return <HX css={getHeadingStyles(theme, props)}>{children}</HX>;
};
