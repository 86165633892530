/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import { ToastContext } from "index";
import { Button, Text, TextArea } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { theme } from "components/lib/utils";
import { useUpdateUserEndorsements } from "components/shared/hooks/useUpdateUserEndorsements.hook";

import { AccountDetailsFormSection } from "../../common/AccountDetailsFormSection/AccountDetailsFormSection";
import { AccountPageSection } from "../../AccountPage.types";
import * as styles from "./OtherEligibility.styles";

type FormValue = {
  otherEligibility: string;
};

export const OtherEligibility: React.FC<AccountPageSection> = ({
  title,
  user,
  refetchUser,
  onBack,
  onNext,
}) => {
  const toast = useContext(ToastContext);
  const { mutateAsync: updateUserEndorsements } = useUpdateUserEndorsements();
  const { handleSubmit, register } = useForm<FormValue>({
    defaultValues: {
      otherEligibility: user?.endorsements?.otherEligibility ?? "",
    },
  });

  async function handleFormSubmit(values: FormValue) {
    // Save details and on success call next
    const toastId = toast.addToast({
      variant: "info",
      description: "Saving other eligible endorsements...",
    });
    try {
      await updateUserEndorsements({
        otherEligibility: values?.otherEligibility,
      });
      await refetchUser();
      if (onNext) onNext();
      toast.removeToast(toastId);
      toast.addToast({
        variant: "success",
        description: "Other eligible endorsements saved successfully",
      });
    } catch (err: any) {
      toast.removeToast(toastId);
      toast.addToast({
        variant: "error",
        description:
          err?.message ||
          "Failed to save other eligible endorsements. Please try again later",
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <AccountDetailsFormSection
        title={title}
        backButton={onBack && <Button onClick={onBack}>Back</Button>}
        actionButton={
          <Button type="submit">{onNext ? "Next" : "Update"}</Button>
        }
      >
        <Stack fullWidth>
          <Text
            variant={3}
            weight={theme.fontWeights.regular}
            styles={styles.subtitle}
          >
            List down your other eligibility (separate by comma)
          </Text>

          <TextArea rows={10} {...register("otherEligibility")} />

          <Text
            variant={3}
            weight={theme.fontWeights.regular}
            styles={styles.footNote}
          >
            E.g. lorem ipsum, dolor este lepje
          </Text>
        </Stack>
      </AccountDetailsFormSection>
    </form>
  );
};
