import {
  Route,
  Redirect,
  useHistory,
  useLocation,
  Switch,
} from "react-router-dom";
import { useTheme } from "@emotion/react";

import { useAuth } from "components/utils/auth";
import { PATH } from "components/shared/constants/paths.constants";
import { Stack } from "components/lib/layouts";
import { SideMenu, SideMenuItem } from "components/lib/molecules";
import { Container } from "components/lib/atoms";

import { ManageBuildBundles } from "./ManageBuildBundles/ManageBuildBundles";
import { CreateNewJob } from "./CreateNewJob/CreateNewJob";
import { ArchivedJobs } from "./ArchivedJobs/ArchivedJobs";
import { CancelledJobs } from "./CancelledJobs/CancelledJobs";

import * as styles from "./AdminPage.styles";
import { EditTemplate, JobTemplates } from "./JobTemplates";
import { AdminDashboard } from "./AdminDashboard/AdminDashboard";

const menuItems = [
  {
    label: "Dashboard",
    path: PATH.admin,
  },
  {
    label: "Job type templates",
    path: PATH.adminJobTemplates,
  },
  {
    label: "Jobs",
    path: PATH.adminJobs,
  },
  {
    label: "Archived jobs",
    path: PATH.adminArchivedJobs,
  },
  {
    label: "Cancelled jobs",
    path: PATH.adminCancelledJobs,
  },
];

export const AdminPage = () => {
  const { user, isAuthenticated } = useAuth();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();

  // Redirect user to browsejobs of logged in, otherwise to the login page
  if (!isAuthenticated || (user && user["custom:role"] !== "admin")) {
    return (
      <Route
        render={() => {
          return isAuthenticated ? (
            <Redirect to={PATH.browseJobs} />
          ) : (
            <Redirect to={PATH.login} />
          );
        }}
      />
    );
  }
  return (
    <Stack fullWidth={true} direction="horizontal">
      <Container styles={styles.sideMenu()}>
        <SideMenu>
          {menuItems.map(({ label, path }) => (
            <SideMenuItem
              key={label}
              isSelected={
                pathname === path ||
                (path === PATH.adminJobTemplates &&
                  pathname.includes(PATH.adminJobTemplates))
              }
              onClick={() => {
                push(path);
              }}
            >
              {label}
            </SideMenuItem>
          ))}
        </SideMenu>
      </Container>

      <Container styles={styles.container()}>
        <Route path={PATH.admin} exact>
          <AdminDashboard />
        </Route>

        <Route path={PATH.adminJobTemplates}>
          <Switch>
            <Route path={PATH.adminJobTemplatesEdit} exact>
              <EditTemplate />
            </Route>
            <Route path={PATH.adminJobTemplatesPreview} exact>
              <EditTemplate isPreviewMode />
            </Route>

            <JobTemplates />
          </Switch>
        </Route>

        <Route path={PATH.adminJobs} exact>
          <ManageBuildBundles />
        </Route>

        <Route path={PATH.adminCreate} exact>
          <CreateNewJob />
        </Route>

        <Route path={PATH.adminArchivedJobs} exact>
          <ArchivedJobs />
        </Route>

        <Route path={PATH.adminCancelledJobs} exact>
          <CancelledJobs />
        </Route>
      </Container>
    </Stack>
  );
};
