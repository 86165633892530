import { useAuth } from "components/utils/auth";
import {
  AttachmentResponseDto,
  JobManagementApi,
} from "components/utils/openapi";
import { ImageUploadApi } from "components/utils/openapi-custom/imageUploadApi";
import { AppConfig, getImageOptions, getDefaultOptions } from "core/AppConfig";

export const useUploadAttachment = () => {
  const { getToken } = useAuth();
  const { getConfiguration } = AppConfig();

  const uploadAttachment = async (
    file: any,
    submissionId: string,
    submissionContentId: string
  ): Promise<AttachmentResponseDto> => {
    const api = ImageUploadApi(getConfiguration(await getToken()));
    // Swagger Codegen Api
    // const api = new JobManagementApi(getConfiguration(token));
    return api.submissionControllerUploadAttachment(
      file,
      submissionId,
      submissionContentId,
      getImageOptions(await getToken())
    );
  };

  const updateAttachment = async (
    file: any,
    submissionId: string,
    submissionContentId: string,
    attachmentId: string
  ) => {
    const api = ImageUploadApi(getConfiguration(await getToken()));
    return api.submissionControllerUpdateAttachment(
      file,
      submissionId,
      submissionContentId,
      attachmentId,
      getImageOptions(await getToken())
    );
  };

  const deleteAttachment = async (
    submissionId: string,
    submissionContentId: string,
    attachmentId: string
  ) => {
    const api = new JobManagementApi(getConfiguration(await getToken()));
    return api.submissionControllerDeleteAttachment(
      submissionId,
      submissionContentId,
      attachmentId,
      getDefaultOptions(await getToken())
    );
  };

  return {
    uploadAttachment,
    updateAttachment,
    deleteAttachment,
  };
};
