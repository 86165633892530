import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils";

export const container = (): CSSObject => {
  return {
    flex: 1,
    padding: `${theme.space[6]} ${theme.space[9]}`,
    maxWidth: "1320px",
  };
};

export const sideMenu = (): CSSObject => {
  return {
    flexBasis: "280px",
    background: theme.colors.white.default,
    padding: `${theme.space[6]}  ${theme.space[4]} 0`,
    minHeight: "calc(100vh - 150px)",
    zIndex: 1,
  };
};

