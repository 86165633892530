import React, { useState } from "react";
import { useTheme } from "@emotion/react";

import { PasswordToggle } from "components/pages/LoginPage/CommonElements";
import { Button, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { InputField } from "components/lib/molecules";
import {
  ValidationMessageRequired,
  validationPassword,
} from "components/utils/validation";
import {
  AuthCard,
  AuthHeader,
} from "components/pages/LoginPage/CommonElements/CommonElements";
import { FormFieldTitle } from "components/pages/LoginPage/CommonElements/FormFieldTitle";

import { useChangePassword } from "./useChangePassword.hook";
import { PasswordShownHandler } from "./ChangePassword.types";

export const ChangePassword = () => {
  const {
    getValues,
    handleSubmit,
    handleChangePassword,
    register,
    errors,
    isSubmitting,
  } = useChangePassword();
  const [isPasswordShown, setIsPasswordShown] = useState<PasswordShownHandler>({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const isSubmitDisabled = Object.keys(errors).length > 0 || isSubmitting;
  const theme = useTheme();

  return (
    <AuthCard>
      <form onSubmit={handleSubmit(handleChangePassword)}>
        <Stack direction="vertical" gap={4} fullWidth>
          <AuthHeader>Update your password</AuthHeader>
          <Stack direction="vertical" gap={1}>
            <Stack justify="space-between" gap={0}>
              <FormFieldTitle>Current password</FormFieldTitle>
              <PasswordToggle
                isPasswordShown={isPasswordShown.currentPassword}
                onClick={() =>
                  setIsPasswordShown({
                    ...isPasswordShown,
                    currentPassword: !isPasswordShown.currentPassword,
                  })
                }
              />
            </Stack>
            <InputField
              validationMessage={errors.password?.message}
              type={isPasswordShown.currentPassword ? "text" : "password"}
              autoComplete="off"
              autoFocus
              placeholder="Enter new password"
              {...register("password", {
                required: ValidationMessageRequired,
              })}
            />
          </Stack>
          <Stack direction="vertical" gap={1}>
            <Stack justify="space-between" gap={0}>
              <FormFieldTitle>New password</FormFieldTitle>
              <PasswordToggle
                isPasswordShown={isPasswordShown.newPassword}
                onClick={() =>
                  setIsPasswordShown({
                    ...isPasswordShown,
                    newPassword: !isPasswordShown.newPassword,
                  })
                }
              />
            </Stack>
            <InputField
              validationMessage={errors.newPassword?.message}
              type={isPasswordShown.newPassword ? "text" : "password"}
              autoComplete="off"
              placeholder="Enter new password"
              {...register("newPassword", {
                required: ValidationMessageRequired,
                pattern: validationPassword,
              })}
            />
            <Text
              variant={2}
              color={theme.colors.grey.text}
              weight={theme.fontWeights.regular}
            >
              Minimum of at least 8 Characters (with 1 letter and number)
            </Text>
          </Stack>
          <Stack direction="vertical" gap={1}>
            <Stack justify="space-between" gap={0}>
              <FormFieldTitle>Confirm password</FormFieldTitle>
              <PasswordToggle
                isPasswordShown={isPasswordShown.confirmNewPassword}
                onClick={() =>
                  setIsPasswordShown({
                    ...isPasswordShown,
                    confirmNewPassword: !isPasswordShown.confirmNewPassword,
                  })
                }
              />
            </Stack>
            <InputField
              validationMessage={errors.confirmNewPassword?.message}
              type={isPasswordShown.confirmNewPassword ? "text" : "password"}
              autoComplete="off"
              placeholder="Enter new password"
              {...register("confirmNewPassword", {
                required: ValidationMessageRequired,
                pattern: validationPassword,
              })}
            />
            <Text
              variant={2}
              color={theme.colors.grey.text}
              weight={theme.fontWeights.regular}
            >
              Minimum of at least 8 Characters (with 1 letter and number)
            </Text>
          </Stack>
          <Button type="submit" disabled={isSubmitDisabled}>
            Update password
          </Button>
        </Stack>
      </form>
    </AuthCard>
  );
};
