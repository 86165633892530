import { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Link, useLocation } from "react-router-dom";

import { Container, Text, Card, Divider, Button } from "../../lib/atoms";
import { useOnClickOutside } from "../../lib/hooks";
import { Stack } from "../../lib/layouts";
import { AppContainerStyles } from "../styles/layout.styles";

import { NavbarLink, NavbarProps } from "./Navbar.types";
import * as styles from "./Navbar.styles";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  Logo,
  LogoutIcon,
  PaymentsIcon,
  PersonalDetailsIcon,
  Profileicon,
} from "./svgs";
import { AppLink } from "..";
import { PATH } from "components/shared/constants/paths.constants";

export const Navbar = ({
  logout,
  links,
  onClick,
  isAuthenticated,
  user,
}: NavbarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, (e) => {
    setIsProfileMenuOpen(false);
  });

  const activePath = location.pathname.split("/")[1];
  const activeUnauthenticatedPath = location.pathname.substring(1);
  const activeLinkPath = isAuthenticated
    ? activePath
    : activeUnauthenticatedPath;

  return (
    <Stack
      direction="horizontal"
      justify="center"
      align="center"
      fullWidth={true}
      styles={{
        background: theme.colors.purple.dark,
      }}
    >
      <Stack
        direction="horizontal"
        justify="space-between"
        align="center"
        fullWidth={true}
        styles={{
          ...AppContainerStyles,
          minHeight: "60px",
          padding: `0 ${theme.space[3]}`,
        }}
      >
        <Container>
          <Logo />
        </Container>
        <Stack gap={1} styles={{ height: "60px", minHeight: "100%", flexWrap: "nowrap", marginLeft: "auto" }}>
          {links.map((link) => {
            // Only show admin menu if role is admin
            if (
              link.path === "admin" &&
              user &&
              user["custom:role"] !== "admin"
            ) {
              return null;
            }

            const sublinks = link.sublinks?.filter(
              (sublink) => sublink?.path === activeLinkPath
            );

            const isActive =
              link.path === activeLinkPath ||
              (sublinks && sublinks?.length > 0) ||
              false;

            return (
              <NavLink
                key={link.path}
                isActive={isActive}
                link={link}
                onClick={onClick}
              />
            );
          })}
        </Stack>
        {isAuthenticated && user && (
          <Container
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            ref={ref}
            onClick={() => {
              setIsProfileMenuOpen(!isProfileMenuOpen);
            }}
            styles={{
              ...styles.profileMenu,
              marginLeft: "auto",
              ...(isProfileMenuOpen
                ? {
                    borderBottom: `${theme.lineThickness[2]} ${theme.colors.mint.default} solid`,
                  }
                : {
                    borderBottom: `${theme.lineThickness[2]} ${theme.colors.purple.dark} solid`,
                  }),
            }}
          >
            <Container styles={styles.profileIcon}>
              <Profileicon />
            </Container>
            <Text
              color={
                isHovering
                  ? theme.colors.mint.surface
                  : isProfileMenuOpen
                  ? theme.colors.mint.default
                  : theme.colors.white.default
              }
              styles={styles.profileLink}
            >
              {user?.firstName && user?.firstName !== ""
                ? user.firstName
                : user?.email}
            </Text>
            {isProfileMenuOpen ? (
              <ChevronUpIcon
                fillColour={
                  isHovering
                    ? theme.colors.mint.surface
                    : isProfileMenuOpen
                    ? theme.colors.mint.default
                    : undefined
                }
              />
            ) : (
              <ChevronDownIcon
                fillColour={
                  isHovering
                    ? theme.colors.mint.surface
                    : isProfileMenuOpen
                    ? theme.colors.mint.default
                    : undefined
                }
              />
            )}
            {isProfileMenuOpen && (
              <Card styles={styles.profileMenuCard}>
                <Stack direction="vertical" fullWidth={true}>
                  <Stack
                    direction="vertical"
                    gap={2}
                    styles={styles.menuUserName}
                  >
                    <Text color={theme.colors.purple.dark}>
                      {user?.firstName && user?.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : user?.firstName ?? user?.email}
                    </Text>
                    <Text styles={styles.userRole}>Contributor</Text>
                  </Stack>
                  <Divider />

                  <AppLink path={PATH.getAccountSection("profile")}>
                    <Stack gap={2} styles={styles.menuItem}>
                      <PersonalDetailsIcon />
                      <Container>My Account</Container>
                    </Stack>
                  </AppLink>
                  
                  <Divider />

                  <Button
                    variant="textdark"
                    onClick={logout}
                    styles={styles.logoutButton}
                  >
                    <Stack direction="horizontal" gap={2} align="center">
                      <Container>
                        <LogoutIcon />
                      </Container>
                      <Container>Logout</Container>
                    </Stack>
                  </Button>
                </Stack>
              </Card>
            )}
          </Container>
        )}
      </Stack>
    </Stack>
  );
};

const NavLink = ({
  isActive,
  link,
  onClick,
}: {
  isActive: boolean;
  link: NavbarLink;
  onClick: (id: string) => void;
}) => {
  const theme = useTheme();
  const [isSublinksOpen, setIsSublinksOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setIsSublinksOpen(false);
  });

  const LinkContainer = () => {
    const chevronColor = isHovering
      ? theme.colors.mint.surface
      : isActive
      ? theme.colors.mint.default
      : undefined;
    return (
      <Container
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        ref={ref}
        onClick={() => {
          if (link.sublinks) {
            setIsSublinksOpen(!isSublinksOpen);
          } else {
            onClick(link.path);
          }
        }}
        styles={{
          ...styles.linkContainer,
          ...(isActive
            ? {
                borderBottom: `${theme.lineThickness[2]} ${theme.colors.mint.default} solid`,
              }
            : {
                borderBottom: `${theme.lineThickness[2]} ${theme.colors.purple.dark} solid`,
              }),
        }}
      >
        {link.icon && (
          <Container styles={{ marginRight: theme.space[2] }}>
            {link.icon}
          </Container>
        )}

        <Text
          styles={
            link.sublinks
              ? { margin: `0 ${theme.space[1]}` }
              : { width: "100%" }
          }
          color={
            isHovering
              ? theme.colors.mint.surface
              : isActive && !link.icon
              ? theme.colors.mint.default
              : theme.colors.white.default
          }
        >
          {link.label}
        </Text>
        {link.sublinks &&
          (isSublinksOpen ? (
            <ChevronUpIcon fillColour={chevronColor} />
          ) : (
            <ChevronDownIcon fillColour={chevronColor} />
          ))}
        {link.sublinks && isSublinksOpen && (
          <Card styles={styles.sublinkCard}>
            <Stack direction="vertical" fullWidth={true}>
              {link.sublinks.map((sublink, index) => (
                <Link
                  key={sublink.path}
                  to={`/${sublink.path}`}
                  style={styles.linkContainerLink}
                >
                  <Text
                    color={theme.colors.purple.dark}
                    styles={styles.sublinkText}
                  >
                    {sublink.label}
                  </Text>
                  {index < link!.sublinks!.length - 1 && <Divider />}
                </Link>
              ))}
            </Stack>
          </Card>
        )}
      </Container>
    );
  };

  return link.sublinks ? (
    <LinkContainer />
  ) : (
    <Link to={`/${link.path}`} style={styles.linkContainerLink}>
      <LinkContainer />
    </Link>
  );
};
