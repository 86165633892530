/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from "@emotion/react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import {
  Button,
  Card,
  Container,
  Heading,
  Spinner,
} from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { SideMenu, SideMenuItem } from "components/lib/molecules";
import { PATH } from "components/shared/constants/paths.constants";
import { UserInformationResponseDto } from "components/utils/openapi";
import { useUser } from "../../shared/hooks/useUser.hook";
import {
  OtherEligibility,
  PaymentsSection,
  ProfileDetailsSection,
  StandardsEndorsement,
  SubjectsEndorsement,
} from "components/shared/AccountDetails/sections";
import { CompletedIcon, IncompleteIcon } from "components/lib/icons";

import { WizardParams } from "./OnboardWizard.types";
import { WIZARD_STEPS } from "./constants";

function getWizardStepId(index: number) {
  return WIZARD_STEPS[index].id;
}

function getWizardStepLabel(index: number) {
  return WIZARD_STEPS[index].label;
}

/**
 * Helper function to determine if a step is required or not (Used by Checkmarks)
 * @param stepID of the step to be checked if required
 * @returns boolean to determine whether the step is required or not
 */
const isStepRequired = (stepID: string) => {
  switch (stepID) {
    case getWizardStepId(0):
    case getWizardStepId(1):
    case getWizardStepId(2):
    case getWizardStepId(3):
    case getWizardStepId(4):
      return true;

    default:
      return false;
  }
};

const isStepComplete = (
  stepID: string,
  user?: UserInformationResponseDto
): boolean | undefined => {
  switch (stepID) {
    case getWizardStepId(0):
      return user?.detailsProvided;

    case getWizardStepId(1):
      return (
        user?.endorsements?.subjectIds &&
        user?.endorsements?.subjectIds?.length > 0
      );

    case getWizardStepId(2):
      return (
        user?.endorsements?.nceaCodes &&
        user?.endorsements?.nceaCodes?.length > 0
      );

    case getWizardStepId(3):
      return (
        user?.endorsements?.otherEligibility !== undefined &&
        user?.endorsements?.otherEligibility !== null
      );

    case getWizardStepId(4):
      return user?.stripeAccountVerified;

    default:
      return false;
  }
};

interface WizardStepSelectorProps {
  selectedStep: string;
  user?: UserInformationResponseDto;
}
const WizardStepSelector: React.FC<WizardStepSelectorProps> = ({
  selectedStep,
  user,
}) => {
  const { push } = useHistory();

  return (
    <SideMenu>
      {WIZARD_STEPS.map(({ label, id }) => (
        <SideMenuItem
          key={label}
          isSelected={selectedStep === id}
          onClick={() => {
            push(PATH.getOnboardingWizard(id));
          }}
        >
          <Stack
            gap={2}
            align="center"
            justify="space-between"
            fullWidth={true}
          >
            <Container style={{ maxWidth: 170, overflowWrap: "break-word" }}>
              {label}
            </Container>
            {isStepComplete(id, user) ? (
              <CompletedIcon />
            ) : (
              isStepRequired(id) && <IncompleteIcon />
            )}
          </Stack>
        </SideMenuItem>
      ))}
    </SideMenu>
  );
};

const Sections: React.FC<WizardParams> = ({ step }) => {
  const theme = useTheme();
  const { push } = useHistory();
  const { data, isLoading, refetch } = useUser();

  function handleNextStep() {
    const currentStepIndex = WIZARD_STEPS.map(({ id }) => id).indexOf(step);

    const isLastStep = currentStepIndex === WIZARD_STEPS.length - 1;
    if (isLastStep) {
      return push(PATH.browseJobs);
    }

    const nextStep = WIZARD_STEPS[currentStepIndex + 1];
    push(PATH.getOnboardingWizard(nextStep.id));
  }

  function handleBackStep() {
    const currentStepIndex = WIZARD_STEPS.map(({ id }) => id).indexOf(step);
    const previousStep = WIZARD_STEPS[currentStepIndex - 1];

    push(PATH.getOnboardingWizard(previousStep.id));
  }

  const basicWizardComponentProps = {
    user: data,
    refetchUser: refetch,
    onNext: handleNextStep,
  };

  const wizardComponents = [
    <ProfileDetailsSection
      key={getWizardStepId(0)}
      title={getWizardStepLabel(0)}
      onBack={handleBackStep}
      {...basicWizardComponentProps}
    />,
    <SubjectsEndorsement
      key={getWizardStepId(1)}
      title={getWizardStepLabel(1)}
      onBack={handleBackStep}
      {...basicWizardComponentProps}
    />,
    <StandardsEndorsement
      key={getWizardStepId(2)}
      title={getWizardStepLabel(2)}
      onBack={handleBackStep}
      {...basicWizardComponentProps}
    />,
    <OtherEligibility
      key={getWizardStepId(3)}
      title={getWizardStepLabel(3)}
      onBack={handleBackStep}
      {...basicWizardComponentProps}
    />,
    <PaymentsSection
      key={getWizardStepId(4)}
      title={getWizardStepLabel(4)}
      {...basicWizardComponentProps}
    />,
  ];

  const { step: selectedStep } = useParams<WizardParams>();

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Stack direction="horizontal" fullWidth={true} wrap="nowrap" gap={4}>
          <Stack direction="vertical" gap={3}>
            <Heading variant={1}>Onboarding</Heading>
            <WizardStepSelector selectedStep={selectedStep} user={data} />
          </Stack>

          <Stack direction="vertical" fullWidth gap={4}>
            <Card styles={{ padding: theme.space[7], flex: 1 }}>
              {wizardComponents.find(
                (wizardComponent) => wizardComponent.key === step
              )}
            </Card>

            <Stack justify="flex-end">
              <Button
                disabled={
                  !data?.detailsProvided ||
                  !data?.isOnboarded ||
                  !data?.stripeAccountVerified
                }
                onClick={() => {
                  push(PATH.browseJobs);
                }}
                styles={{ width: "fit-content" }}
              >
                Start browsing jobs
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export const OnboardingWizardPage: React.FC = () => {
  const { step }: WizardParams = useParams();

  const isInvalidStep =
    !step || !Boolean(WIZARD_STEPS.find(({ id }) => id === step));

  // Takes care of missing or non supported path param
  if (isInvalidStep) {
    return <Redirect to={PATH.getOnboardingWizard(WIZARD_STEPS[0].id)} />;
  }

  return <Sections step={step} />;
};
