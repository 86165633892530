import { ReactNodeArray } from "react";

import { Stack } from "components/lib/layouts";
import { Heading } from "components/lib/atoms";
import { GetBuildBundlesResponseDto } from "components/utils/openapi";
import { TableData, TableRow } from "components/lib/atoms/Table/Table";
import { useCurriculum } from "components/shared/hooks/useCurriculum";
import { getCurriculumName } from "components/utils/helpers";
import { TableWithPagination } from "components/lib/molecules";
import { getFormattedDateString } from "components/utils/dateUtils";

import { byBundleDate } from "../../ManageBuildBundles/utils";

type BuildBundleDatesPropsType = {
  buildBundleData?: GetBuildBundlesResponseDto[];
  isLoading?: boolean;
};
export const BundleCreationDates = ({
  buildBundleData,
  isLoading,
}: BuildBundleDatesPropsType) => {
  const { data: curriculumData } = useCurriculum();

  const buildBundlesRows: ReactNodeArray | undefined = buildBundleData
    ?.sort(byBundleDate)
    .map((buildBundle) => {
      const name = getCurriculumName(buildBundle?.nceaCode, curriculumData);
      const creationDateString = getFormattedDateString(
        new Date(buildBundle?.creationTime)
      );
      return (
        <TableRow key={buildBundle?.id}>
          <TableData>{creationDateString}</TableData>
          <TableData>{name}</TableData>
        </TableRow>
      );
    });

  return (
    <Stack direction="vertical" gap={2}>
      <Heading variant={2}>Bundle creation dates</Heading>
      <TableWithPagination
        isLoading={isLoading}
        tableHeadings={["Date", "Bundle name"]}
        emptyMessage="No Build Bundles found."
        rowsPerPage={10}
        colgroup={
          <colgroup>
            <col span={1} width="10%" />
            <col span={1} />
          </colgroup>
        }
        children={buildBundlesRows ?? []}
      />
    </Stack>
  );
};
