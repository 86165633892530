import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { getStyles } from "../../utils";

import { TextProps } from "./TextArea.types";

export const getTextAreaStyles = (
  theme: Theme,
  { styles }: Partial<TextProps>
) => {
  const base: CSSObject = {
    boxSizing: "border-box",
    background: theme.colors.white.default,
    border: `${theme.lineThickness[0]} solid ${theme.colors.grey.input}`,
    borderRadius: theme.radii[2],
    fontFamily: theme.fonts.primary,
    width: "100%",
    fontWeight: theme.fontWeights.regular,
    lineHeight: 1.2,
    padding: `${theme.space[2]} ${theme.space[3]}`,
    fontSize: theme.fontSizes[3],
    resize: "none",
    ":disabled": {},
    ":focus": {},
  };
  return getStyles({ base, styles });
};
