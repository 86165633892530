import { useTheme } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";

import { ToastContext } from "index";
import { Spinner } from "components/lib/atoms/Spinner/Spinner";
import {
  RetrieveSubmissionByIdJobResponseDto,
  RetrieveSubmissionContentResponseDto,
} from "components/utils/openapi";
import { AppLink } from "components/shared/AppLink/AppLink";
import { JobTypeMCQEnum } from "components/shared/constants/jobtypes";
import { Container } from "components/lib/atoms/Container/Container";
import { Divider } from "components/lib/atoms/Divider/Divider";
import { getCurriculumName } from "components/utils/helpers";
import { PATH } from "components/shared/constants/paths.constants";
import { Button } from "components/lib/atoms/Button/Button";
import { Card } from "components/lib/atoms/Card/Card";
import { Heading } from "components/lib/atoms/Heading/Heading";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { useCurriculum } from "../../shared/hooks/useCurriculum";

import { useSubmissionById } from "./hooks/useSubmission.hook";
import { useCreateSubmissionContent } from "./hooks/useCreateSubmissionContent.hook";
import { useDeleteSubmissionContent } from "./hooks/useDeleteSubmissionContent.hook";
import { InstructionsSection } from "./sections/InstructionsSection/InstructionsSection";
import { ContactSection } from "./sections/ContactSection/ContactSection";
import { QuestionsSection } from "./sections/QuestionsSection/QuestionsSection";
import { DetailsSection } from "./sections/DetailsSection/DetailsSection";
import { QuestionEditSection } from "./sections/QuestionEditSection/QuestionEditSection";
import { InputsSection } from "./sections/InputsSection/ResourceJobSection";
import { isQuestionComplete } from "./utils";
import { ReactComponent as LeftChevronIcon } from "./svgs/chevronleft.svg";
import { styles } from "./JobEditPage.styles";

export type JobType = "mcq" | "review";
export type InputSubmissionLookup = {
  [key: string]: Partial<RetrieveSubmissionContentResponseDto>;
};

export const JobEditPage = () => {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const curriculumHook = useCurriculum();
  const active = location.pathname;
  const { jobID } = useParams<{ jobID: string }>();
  const { data, isLoading, refetch } = useSubmissionById(jobID);
  const [
    submissionContentLookup,
    setSubmissionContentLookup,
  ] = useState<InputSubmissionLookup>();

  let { url } = useRouteMatch();
  const toast = useContext(ToastContext);

  const { mutateAsync: createSubmissionContent } = useCreateSubmissionContent();
  const { mutateAsync: deleteSubmissionContent } = useDeleteSubmissionContent();

  useEffect(() => {
    // Create Submissions Contents Lookup Object
    let submissionContentLookup: InputSubmissionLookup = {};
    data?.inputSubmissions?.forEach((inputSubmission) => {
      inputSubmission?.submissionContents?.map((submissionContent) => {
        submissionContentLookup[submissionContent?.id] = submissionContent;
      });
    });
    setSubmissionContentLookup(submissionContentLookup);
  }, [data, setSubmissionContentLookup]);

  const createNewQuestion = async () => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Creating...",
    });
    const { id: questionID } = await createSubmissionContent(jobID);
    await refetch();
    toast.removeToast(toastId);
    toast.addToast({
      variant: "success",
      description: "Created question successfully",
    });
    history.push({
      pathname: `${PATH.activeJobs}/${jobID}/resource-job/${questionID}`,
      search: "?new=true",
    });
  };

  const deleteQuestion = async (questionId: string) => {
    const toastId = toast.addToast({
      variant: "info",
      description: "Deleting...",
    });
    await deleteSubmissionContent({ submissionId: jobID, questionId });
    await refetch();
    toast.removeToast(toastId);
    toast.addToast({
      variant: "success",
      description: "Deleted the question successfully",
    });
  };

  const completedQuestions =
    (data &&
      (data?.job?.jobType?.code === JobTypeMCQEnum?.REVIEW_MCQS
        ? data.submissionContents.filter(
            (question) => question.content.approved === true
          ).length
        : data.submissionContents.reduce((agg, question) => {
            return isQuestionComplete(question) ? agg + 1 : agg;
          }, 0))) ||
    0;

  const sections = [
    {
      path: "instructions",
      label: ({ jobType }: { jobType: JobType }) => "Instructions",
      view: ({ jobType }: { jobType: JobType }) => (
        <InstructionsSection
          jobType={jobType}
          activityBuild={data!.activityBuild}
          submissionId={data!.id}
          createNewQuestion={createNewQuestion}
          instructions={data?.job?.jobType?.instructions}
          isReview={data?.job?.jobType.isReview}
        />
      ),
    },
    {
      path: "resource-job",
      label: ({ jobType }: { jobType: JobType }) => "Your job",
      view: ({
        submission,
        jobType,
        refetch,
      }: {
        submission: RetrieveSubmissionByIdJobResponseDto;
        jobType: JobType;
        refetch: () => Promise<any>;
      }) => {
        const jobTypeCode = submission?.job?.jobType?.code;
        return Object.keys(JobTypeMCQEnum).includes(jobTypeCode) ? (
          <QuestionsSection
            jobType={jobType}
            jobID={jobID}
            submission={submission}
            createNewQuestion={createNewQuestion}
            deleteQuestion={deleteQuestion}
            completedQuestions={completedQuestions}
            submissionContentLookup={submissionContentLookup}
          />
        ) : (
          <InputsSection
            jobType={jobType}
            submission={submission}
            refetch={refetch}
          />
        );
      },
    },
    {
      path: "contact-us",
      label: ({ jobType }: { jobType: JobType }) => "Contact Us",
      view: () => <ContactSection />,
    },
    {
      path: "details",
      label: ({ jobType }: { jobType: JobType }) => "Job Details",
      view: () => (
        <DetailsSection
          title={getCurriculumName(
            data!.buildBundle?.nceaCode,
            curriculumHook.data
          )}
          job={{
            id: data!.job?.id,
            jobType: data!.job?.jobType,
            nceaCode: data!.job?.jobType?.code,
            payoutRate: data!.job?.payoutRate,
            // @ts-ignore
            activeSubmissionId: data!.job?.activeSubmissionId,
            activityBuilds: data!.job?.activityBuilds,
            jobDuration: data!.job?.jobDuration,
            completed: data!.job?.completed,
            accepted: true,
          }}
        />
      ),
    },
  ];
  return (
    <Stack direction="horizontal" gap={3} fullWidth={true}>
      <Stack direction="vertical" gap={3} styles={{ width: "300px" }}>
        <Heading variant={1} color={theme.colors.purple.dark}>
          Complete the job
        </Heading>

        {isLoading ? (
          <Spinner />
        ) : (
          <Stack direction="vertical">
            {data &&
              sections?.map((section, index) => (
                <Button
                  key={index}
                  variant="textdark"
                  styles={
                    active && active.includes(section.path)
                      ? styles.SelectedSectionButton
                      : styles.SectionButton
                  }
                  onClick={() => {
                    history.push(`${PATH.activeJobs}/${jobID}/${section.path}`);
                  }}
                >
                  {section.label({
                    jobType:
                      data?.job?.jobType?.code === JobTypeMCQEnum?.CREATE_MCQS
                        ? "mcq"
                        : "review",
                  })}
                </Button>
              ))}

            <Divider
              styles={{
                marginBottom: theme.space[3],
                marginTop: theme.space[3],
              }}
            />
            <AppLink path={PATH.activeJobs}>
              <Stack gap={2} align="center">
                <Container>
                  <LeftChevronIcon />
                </Container>
                <Container styles={{ color: theme.colors.purple.default }}>
                  Active Jobs
                </Container>
              </Stack>
            </AppLink>
          </Stack>
        )}
      </Stack>
      <Card styles={{ flex: 1, height: "fit-content" }}>
        {data &&
          !isLoading &&
          sections.map((section, index) => (
            <Route path={`${url}/${section.path}`} exact key={index}>
              {/*@ts-ignore */}
              <section.view
                submission={data}
                jobType={
                  data?.job?.jobType?.code === JobTypeMCQEnum?.CREATE_MCQS
                    ? "mcq"
                    : "review"
                }
                refetch={refetch}
              />
            </Route>
          ))}

        {data && (
          <Route path={`${url}/resource-job/:questionID`} exact>
            {data && (
              <QuestionEditSection
                completedQuestions={completedQuestions}
                refetch={refetch}
                jobID={jobID}
                submission={data}
                createNewQuestion={createNewQuestion}
                submissionContentLookup={submissionContentLookup}
              />
            )}
          </Route>
        )}
      </Card>
    </Stack>
  );
};
