/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";

import { Button, Container, Heading, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { useAuth } from "components/utils/auth";
import { PATH } from "components/shared/constants/paths.constants";

export const AccountDetails = () => {
  const theme = useTheme();
  const { push } = useHistory();
  const { user } = useAuth();

  return (
    <Stack direction="vertical" gap={4}>
      <Heading variant={2} styles={{ fontSize: theme.fontSizes[6] }}>
        Account details
      </Heading>
      <Stack direction="vertical" gap={1}>
        <Text color={theme.colors.purple.dark}>Email Address</Text>
        <Text color={theme.colors.grey.text}> {user?.email}</Text>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <Button
          onClick={() => {
            push(PATH.changePassword);
          }}
          variant="secondary"
          styles={{ width: "fit-content" }}
        >
          <Stack direction="horizontal" gap={2}>
            <Container>Change Password</Container>
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );
};
