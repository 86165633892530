import { useTheme } from "@emotion/react";
import { Heading } from "components/lib/atoms";

export const AccountDetailsTitle: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <Heading variant={3} styles={{ marginTop: theme.space[3] }}>
      {children}
    </Heading>
  );
};
