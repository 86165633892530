import { useAuth } from "components/utils/auth";
import { Route, Redirect } from "react-router-dom";

export const OnboardRoute = ({ children, ...rest }: any) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        return isAuthenticated ? children : <Redirect to="/login" />;
      }}
    />
  );
};
