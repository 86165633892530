import React from "react";
import { Link } from "react-router-dom";
import pluralize from "pluralize";

import { currencyFormatter, getStandardByCode } from "components/utils/helpers";
import {
  ActivityBuildJobJobResponseDto,
  ActivityBuildJobsResponseDto,
} from "components/utils/openapi";
import { PATH } from "components/shared/constants/paths.constants";
import { getFormattedDateString } from "components/utils/dateUtils";
import { useCurriculum } from "../../../shared/hooks/useCurriculum";

import * as styles from "../BrowseJobsPage.styles";
import { JobCard } from "./JobCard/JobCard";
import { JobCardProps } from "./JobCard/JobCard.types";

interface JobProps {
  activityBuildJob: ActivityBuildJobsResponseDto;
}

export const Job: React.FC<JobProps> = ({ activityBuildJob }) => {
  const { data: curriculumData } = useCurriculum();
  const standardInfo = getStandardByCode(
    activityBuildJob?.buildBundle?.nceaCode,
    curriculumData
  );

  const deadlineString = activityBuildJob?.job?.jobDuration
    ? `${pluralize("Hour", activityBuildJob?.job?.jobDuration, true)}`
    : "";
  const jobToBeDisplayed: JobCardProps = {
    title: activityBuildJob?.activityBuild?.name,
    description: activityBuildJob?.job?.jobType?.description,
    price: `${currencyFormatter.format(
      activityBuildJob?.job?.payoutRate *
        activityBuildJob?.job?.jobType?.contentNumber
    )}`,
    datePosted: getFormattedDateString(activityBuildJob?.createdAt),
    deadLine: deadlineString,
    level: standardInfo?.levelName,
    subject: standardInfo?.subjectName,
    standard: standardInfo?.standardName,
  };

  return (
    <Link
      to={PATH.getJob(activityBuildJob?.id)}
      key={activityBuildJob?.id}
      style={styles.activeJobLink}
    >
      <JobCard {...jobToBeDisplayed} />
    </Link>
  );
};
