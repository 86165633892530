import { useTheme } from "@emotion/react";
import moment from "moment";
import {
  Table,
  TableData,
  TableHeading,
  TableRow,
  TableBody,
} from "components/lib/atoms/Table/Table";
import { Card, Heading, Spinner, Text } from "components/lib/atoms";
import { Stack } from "components/lib/layouts";
import { RetrieveSubmissionsJobResponseDto } from "components/utils/openapi";
import { useExpiredJobsList } from "../../hooks/useExpiredJobsList.hook";

export const ExpiredJobsPanel: React.FC = () => {
  const theme = useTheme();
  const { data, isLoading } = useExpiredJobsList(
    moment().add(-1, "week").toDate(),
    moment().toDate()
  );

  return (
    <Card fullWidth={true}>
      <Stack direction="vertical" gap={4} fullWidth={true}>
        <Heading variant={1}>Expired Jobs</Heading>
        {isLoading ? (
          <Spinner />
        ) : (
          <Stack fullWidth={true} direction="vertical" gap={2}>
            <Table styles={{ width: "100%" }}>
              <TableRow isHeading={true}>
                <TableHeading>Job Name</TableHeading>
                <TableHeading>Job Type</TableHeading>
                <TableHeading>Expired Date</TableHeading>
                <TableHeading>Time</TableHeading>
              </TableRow>
              <TableBody>
                {data &&
                  data
                    ?.sort((submissionA, submissionB) => {
                      return (
                        new Date(submissionB.expiryTime).getTime() -
                        new Date(submissionA.expiryTime).getTime()
                      );
                    })
                    ?.map((submission: RetrieveSubmissionsJobResponseDto) => {
                      const expiry = moment(submission?.expiryTime);

                      return (
                        <TableRow key={submission?.id}>
                          <TableData>
                            {submission?.activityBuild?.name}
                          </TableData>
                          <TableData>
                            {submission?.job.jobType?.description}
                          </TableData>
                          <TableData>{expiry.format("DD MMM YYYY")}</TableData>
                          <TableData>{expiry.format("hh:mm A")}</TableData>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
            {data && data.length === 0 && (
              <Text styles={{ padding: theme.space[2] }}>
                You do not have any expired jobs in the past week
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
