import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "index";

import { AppConfig, getDefaultOptions, getFileOptions } from "core/AppConfig";
import {
  AttachmentResponseDto,
  CreateBuildBundlesDto,
  JobManagementApi,
  UpdateActivityBuildDto,
} from "components/utils/openapi";
import {
  FileUploadApi,
  FileUploadType,
} from "components/utils/openapi-custom/FileUploadApi";
import { useAuth } from "components/utils/auth";
import { PATH } from "components/shared/constants/paths.constants";

import {
  BuildBundleResponse,
  CreateJobStep,
  Resource,
} from "../CreateNewJob/CreateNewJob.types";

export const useCreateNewJob = () => {
  const [formState, setFormState] = useState<CreateJobStep>("enterJobDetails");
  const [jobBundle, setJobBundle] =
    useState<CreateBuildBundlesDto | null>(null);
  const [buildBundleFile, setBuildBundleFile] = useState<any>();
  const { getToken } = useAuth();
  const { getConfiguration } = AppConfig();
  const history = useHistory();
  const toast = useToast();

  async function getApiWithOptions() {
    const token = await getToken();
    const config = getConfiguration(token);
    const defaultOptions = getDefaultOptions(token);
    return { api: new JobManagementApi(config), options: defaultOptions };
  }

  async function handleBuildBundles(): Promise<BuildBundleResponse> {
    if (jobBundle === null) {
      return Promise.reject();
    }

    const { api, options } = await getApiWithOptions();
    return api
      .buildBundlesControllerCreate(jobBundle, options)
      .then((response: any) => {
        return {
          activityBuilds: response?.activityBuilds,
          bundleId: response?.id,
        };
      });
  }

  function createActivityBuildDto(
    resources: Resource[],
    activityBuilds: BuildBundleResponse["activityBuilds"]
  ) {
    return resources.map((resource, index) => {
      const activityBuild: UpdateActivityBuildDto = {
        id: activityBuilds[index]?.id,
        name: resource.name,
        specifiedFileName: resource.fileName,
        published: true,
      };

      return activityBuild;
    });
  }

  async function handleUploadBuildBundleAttachment(
    bundleId: string
  ): Promise<AttachmentResponseDto> {
    // Use File Options Instead
    const token = await getToken();
    const api = FileUploadApi(getConfiguration(token));
    const fileOptions = getFileOptions(token);

    // Swagger Codegen Api
    // const api = new JobManagementApi(getConfiguration(token));
    return api.uploadAttachment(
      buildBundleFile,
      bundleId,
      FileUploadType.BUILD_BUNDLE,
      fileOptions
    );
  }

  async function handlePublishBuilds(
    resources: Resource[],
    buildResponse: BuildBundleResponse
  ) {
    const { api, options } = await getApiWithOptions();
    const activityBuildDto = createActivityBuildDto(
      resources,
      buildResponse.activityBuilds
    );

    return api.buildBundlesControllerUpdate(
      activityBuildDto,
      buildResponse.bundleId,
      options
    );
  }

  function handleJobCreationSuccess() {
    history.push(PATH.browseJobs);
    toast.addToast({
      description: "Jobs created",
      variant: "success",
    });
  }

  function handleJobCreationFailure() {
    toast.addToast({
      description: "Failed to create Jobs",
      variant: "error",
    });
    history.push(PATH.adminCreate);
  }

  async function handleCreateNewJob(resources: Resource[]) {
    try {
      const bundleBuildResponse = await handleBuildBundles();
      if (bundleBuildResponse) {
        await handlePublishBuilds(resources, bundleBuildResponse);
        await handleUploadBuildBundleAttachment(bundleBuildResponse.bundleId);
      }
      handleJobCreationSuccess();
    } catch {
      handleJobCreationFailure();
    }
  }

  return {
    jobBundle,
    formState,
    handleCreateNewJob,
    setJobBundle,
    setFormState,
    setBuildBundleFile,
  };
};
