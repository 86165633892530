import { CSSObject } from "@emotion/serialize";
import { theme } from "components/lib/utils";

export const subjectCheckbox = (): CSSObject => {
  return { minWidth: "325px", marginTop: theme.space[1] };
};

export const subjects = (): CSSObject => {
  return { paddingTop: theme.space[3] };
};
