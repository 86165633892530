import { theme } from "components/lib/utils";
import { CSSObject } from "@emotion/react";
import { CSSProperties } from "react";

export const linkContainer: CSSObject = {
  position: "relative",
  cursor: "pointer",
  height: "100%",
  width: "100px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
};

export const linkContainerLink: CSSProperties = {
  display: "contents",
  textDecoration: "none",
};

export const sublinkText: CSSObject = {
  padding: theme.space[3],
  cursor: "pointer",
};

export const sublinkCard: CSSObject = {
  textAlign: "left",
  right: 0,
  top: `calc(100% - 2px)`,
  position: "absolute",
  width: "160px",
  padding: 0,
  zIndex: theme.zIndices.modal,

  "::after": {
    content: "''",
    position: "absolute",
    bottom: "100%",
    transform: "translateX(-50%)",
    right: theme.space[4],
    width: 0,
    height: 0,
    border: "8px solid transparent",
    borderBottom: `8px solid ${theme.colors.white.default}`,
  },
};

export const profileLink: CSSObject = {
  margin: `0 ${theme.space[1]}`,
  cursor: "pointer",
};

export const profileIcon: CSSObject = { marginTop: "6px", cursor: "pointer" };

export const profileMenu: CSSObject = {
  position: "relative",
  height: "100%",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
};

export const profileMenuCard: CSSObject = {
  textAlign: "left",
  right: 0,
  top: `calc(100% - 2px)`,
  position: "absolute",
  width: "300px",
  padding: 0,
  zIndex: theme.zIndices.modal,

  "::after": {
    content: "''",
    position: "absolute",
    bottom: "100%",
    transform: "translateX(-50%)",
    right: theme.space[4],
    width: 0,
    height: 0,
    border: "8px solid transparent",
    borderBottom: `8px solid ${theme.colors.white.default}`,
  },
};

export const logoutButton: CSSObject = {
  textAlign: "left",
  padding: `${theme.space[2]} ${theme.space[3]}`,
};

export const menuUserName: CSSObject = {
  padding: `${theme.space[3]}`,
};

export const menuItem: CSSObject = {
  padding: `${theme.space[2]} ${theme.space[3]}`,
};

export const userRole: CSSObject = {
  color: theme.colors.grey.text,
  fontSize: theme.fontSizes[2],
  marginTop: "8px",
};
