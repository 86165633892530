import { PATH } from "components/shared/constants/paths.constants";
import { ReactComponent as SignInIcon } from "./svgs/login.svg";
import { ReactComponent as RegisterIcon } from "./svgs/register.svg";

export const unauthenticatedNavlinks = [
  {
    path: "login",
    label: "Sign In",
    icon: <SignInIcon />,
  },
  {
    path: "login/register",
    label: "Register",
    icon: <RegisterIcon />,
  },
];
export const navlinks = [
  {
    path: "browse-jobs",
    label: "Browse Jobs",
  },
  {
    path: "myjobs",
    label: "My Jobs",
    sublinks: [
      {
        path: "active-jobs",
        label: "Active Jobs",
      },
      {
        path: "completed-jobs",
        label: "Completed Jobs",
      },
    ],
  },
  {
    path: "training",
    label: "Training",
  },
  {
    path: "admin",
    label: "Admin",
  },
];
