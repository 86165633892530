import { useQuery } from "react-query";
import { ReportingApi } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

export const useCompletedSubmission = (id: string) => {
  const { getToken } = useAuth();
  return useQuery(["getCompletedSubmissionsId", id], async () =>
    getCompletedSubmissionsId(id, await getToken())
  );
};

const getCompletedSubmissionsId = async (id: string, token: string | null) => {
  const { getConfiguration } = AppConfig();
  const api = new ReportingApi(getConfiguration(token));
  return api.completedSubmissionsControllerFindCompletedSubmissionById(
    id,
    getDefaultOptions(token)
  );
};
