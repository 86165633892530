import { useMutation } from "react-query";
import { JobManagementApi, JobTypesDto } from "components/utils/openapi";
import { AppConfig, getDefaultOptions } from "core/AppConfig";
import { useAuth } from "components/utils/auth";

export const useJobTypesUpdate = () => {
  const { getToken } = useAuth();
  return useMutation(async (jobTypeData: JobTypesDto) => {
    const { getConfiguration } = AppConfig();
    const token = await getToken();
    const api = new JobManagementApi(getConfiguration(token));
    return api.jobTypesControllerUpdateJobTypes(
      jobTypeData,
      jobTypeData?.id,
      getDefaultOptions(token)
    );
  });
};