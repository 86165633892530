import { useTheme } from "@emotion/react";
import { useHistory } from "react-router";

import { Stack } from "components/lib/layouts";
import { Text, Button } from "components/lib/atoms";
import { useQuery } from "components/utils/routing";
import { PATH } from "components/shared/constants/paths.constants";

import { AuthCard, AuthHeader } from "../CommonElements/CommonElements";
import { EmailVerifyIllustrationIcon } from "./svgs";

export const EmailVerification = () => {
  const theme = useTheme();
  const history = useHistory();
  const query = useQuery();

  return (
    <AuthCard>
      <Stack fullWidth align="center" gap={5} direction="vertical">
        <AuthHeader>Verify your email to proceed</AuthHeader>

        <EmailVerifyIllustrationIcon />

        <Stack fullWidth direction="vertical">
          <Text align="left" fullWidth color={theme.colors.purple.dark}>
            We have just sent an email to the address:
          </Text>

          <Text
            align="left"
            color={theme.colors.purple.dark}
            fullWidth
            weight={theme.fontWeights.medium}
          >
            {query.get("email")}
          </Text>
        </Stack>

        <Text align="left" color={theme.colors.purple.dark} fullWidth>
          Please check your email and click on the link provided to verify your
          address.
        </Text>

        <Button
          fullWidth
          onClick={() => {
            history.push(PATH.login);
          }}
        >
          Login
        </Button>
      </Stack>
    </AuthCard>
  );
};
