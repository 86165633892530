import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import pluralize from "pluralize";
import { useDropzone } from "react-dropzone";

import { formatBytes } from "components/lib/utils";
import { Text, Button, ValidationMessage } from "components/lib/atoms";
import { Stack } from "components/lib/layouts/Stack/Stack";
import { getStyles } from "components/lib/utils/theme";

import { UploadFileProps } from "./UploadFile.types";
import { getUploadFileStyles } from "./UploadFile.styles";
import { ReactComponent as UploadIcon } from "./svgs/uploadIcon.svg";
import { fileExtensionByMimeTypeLookup } from "components/shared/constants/mimetypes";

const ImageMimeTypes = ["image/png", "image/jpg", "image/jpeg"];

const resolveFileWithDimension = async (event: any): Promise<any> => {
  const files = event?.target?.files;
  const promises = [];
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    const promise = new Promise((resolve, reject) => {
      if (!ImageMimeTypes.includes(file.type)) resolve(file);
      const image = new Image();
      let url: string;
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      url = URL.createObjectURL(file);
      image.src = url;
    });
    promises.push(promise);
  }
  return Promise.all(promises);
};

const imageValidator = (file: any, width: number, height: number) => {
  let result = null;
  if (width && width > file.width && height && height > file.height) {
    result = null;
  } else {
    result = {
      code: "dimensions-too-large",
      message: `You have selected an image with dimensions that is larger than ${width}px in width or ${height}px in height`,
    };
  }
  return result;
};

export const UploadFile = ({
  maxFiles = 1,
  validator,
  accept,
  maxSize,
  uploadButtonName,
  setFilesToUpload,
  uploadFile,
  height,
  width,
  loading = false,
  styles = {},
}: UploadFileProps): JSX.Element => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } =
    useDropzone({
      getFilesFromEvent: resolveFileWithDimension,
      validator: validator
        ? validator
        : (file: any) => {
            if (ImageMimeTypes.includes(file.type)) {
              width = width ? width : 600;
              height = height ? height : 600;
              const result = imageValidator(file, width, height);
              return result;
            }
            return null;
          },
      maxFiles: maxFiles,
      accept: accept,
      maxSize: maxSize,
      noClick: true,
      noDrag: true,
    });

  useEffect(() => {
    if (setFilesToUpload) setFilesToUpload(acceptedFiles);
  }, [setFilesToUpload, acceptedFiles]);

  const theme = useTheme();
  const { base } = getUploadFileStyles(theme);
  const dropzoneStyles = getStyles({
    base: {
      ...base,
    },
    styles: styles,
  });

  const removeFile = (file: File) => (event: any) => {
    if (acceptedFiles?.length > 0) {
      acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
      event.target.blur();
    } else {
      acceptedFiles.pop();
    }
  };

  const acceptedFileItems = acceptedFiles.map((file) => (
    <Stack key={file.name} direction="horizontal" gap={3} fullWidth={false}>
      <Button disabled={loading} type="button" variant="warning" onClick={removeFile(file)}>
        Remove
      </Button>
    </Stack>
  ));

  const formattedMimeType = () => {
    if (accept) {
      return Array.isArray(accept)
        ? accept
            .map(
              (ele) =>
                fileExtensionByMimeTypeLookup[ele] &&
                `.${fileExtensionByMimeTypeLookup[ele]}`
            )
            .join(", ")
        : fileExtensionByMimeTypeLookup[accept];
    }
  };

  const validationErrors = () => {
    if (fileRejections.length === 0) {
      return null;
    }
    let errorMessage = "";
    switch (fileRejections[0].errors[0].code) {
      case "too-many-files":
        errorMessage = `You have selected more than ${pluralize(
          "file",
          maxFiles,
          true
        )}`;
        break;
      case "file-too-large":
        errorMessage = `File selected is larger than ${formatBytes(maxSize!)}`;
        break;
      case "file-invalid-type":
        errorMessage = accept
          ? `File type must be one of ${formattedMimeType()} `
          : fileRejections[0].errors[0].message;
        break;
      case "dimensions-too-large":
        errorMessage = fileRejections[0].errors[0].message;
        break;
      default:
        errorMessage = "Failed to upload file, please select another";
        break;
    }
    return (
      <ValidationMessage styles={{ marginTop: "10px" }} text={errorMessage} />
    );
  };

  return (
    <Stack direction="vertical" gap={3} fullWidth={false} wrap={"nowrap"}>
      <Stack direction="horizontal" gap={2} justify="left" align="center">
        <div {...getRootProps({ styles: dropzoneStyles })}>
          <input type="button" {...getInputProps()} />
          {acceptedFiles?.length === 0 && (
            <Button
              type="button"
              variant="secondary"
              onClick={open}
              size="medium"
            >
              Choose File
            </Button>
          )}
          {acceptedFiles?.length > 0 && acceptedFileItems}
        </div>
        <Text
          color={
            acceptedFiles?.length === 0
              ? theme.colors.grey.text
              : theme.colors.black.default
          }
        >
          {acceptedFiles?.length === 0
            ? "No File Chosen"
            : acceptedFiles?.map((file) => file?.name).join(", ")}
        </Text>
      </Stack>

      {/* Similar to UploadImage, where states are not used, where it directly uploads from the hook instead */}
      {uploadFile && (
        <Stack direction="horizontal" gap={3}>
          <Button
            variant="primary"
            type="button"
            disabled={acceptedFiles?.length < 1 || loading}
            onClick={() => {
              uploadFile(acceptedFiles);
            }}
            style={{ display: "inline-block", fontWeight: "unset" }}
            size="medium"
          >
            <UploadIcon />
            &nbsp;&nbsp;
            {loading && 'Uploading...'}
            {!loading && (uploadButtonName ?? "Upload selected file")}
          </Button>
        </Stack>
      )}

      {validationErrors()}
    </Stack>
  );
};
