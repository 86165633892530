import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/serialize";
import { ButtonVariant, ButtonProps } from "./Button.types";

import { getStyles } from "../../utils";

export const getButtonStyles = (
  theme: Theme,
  { styles, fullWidth, variant, size = "large" }: Partial<ButtonProps>
) => {
  const base: CSSObject = {
    fontFamily: theme.fonts.primary,
    boxSizing: "border-box",
    border: 0,
    borderRadius: theme.radii[4],
    justifyContent: "center",
    alignItems: "center",
    width: "auto",
    minWidth: "fit-content",
    cursor: "pointer",
    fontWeight: theme.fontWeights.medium,
    padding: `${theme.space[2]} ${theme.space[5]}`,
    fontSize: theme.fontSizes[3],
    transition: "200ms ease-out",
    ":disabled": {
      opacity: 0.5,
      cursor: "default",
      pointerEvents: "none",
    },
    ":focus": {
      outline: 0,
    },
    ...(fullWidth ? { width: "100%" } : {}),
    ...(size === "medium"
      ? {
          fontSize: theme.fontSizes[2],
          lineHeight: "110%",
          height: "40px",
          padding: `0 ${theme.space[4]}`,
        }
      : {}),
  };
  const variants: Record<ButtonVariant, CSSObject> = {
    primary: {
      border: `${theme.lineThickness[1]} solid ${theme.colors.purple.default}`,
      color: theme.colors.white.default,
      background: theme.colors.purple.default,
      ":hover": {
        background: theme.colors.purple.border,
        border: `${theme.lineThickness[1]} solid ${theme.colors.purple.border}`,
      },
    },
    secondary: {
      borderRadius: theme.radii[3],
      border: `${theme.lineThickness[1]} solid ${theme.colors.purple.default}`,
      color: theme.colors.purple.default,
      background: theme.colors.white.default,
      ":hover": {
        color: theme.colors.white.default,
        background: theme.colors.purple.default,
      },
    },
    cancel: {
      borderRadius: theme.radii[3],
      border: `${theme.lineThickness[1]} solid ${theme.colors.mint.default}`,
      color: theme.colors.mint.default,
      background: theme.colors.white.default,
      ":hover": {
        color: theme.colors.white.default,
        background: theme.colors.mint.default,
      },
    },
    warning: {
      border: `${theme.lineThickness[1]} solid ${theme.colors.red.border}`,
      color: theme.colors.red.default,
      background: theme.colors.white.default,
      ":hover": {
        color: theme.colors.white.default,
        background: theme.colors.red.default,
      },
    },
    warningtext: {
      border: 0,
      color: theme.colors.red.default,
      background: theme.colors.white.default,
      ":hover": {
        color: theme.colors.red.default,
        background: theme.colors.white.default,
      },
    },
    textdark: {
      padding: 0,
      fontWeight: theme.fontWeights.regular,
      border: 0,
      color: theme.colors.purple.dark,
      background: "transparent",
      ":hover": {
        color: theme.colors.mint.default,
      },
    },
    textlight: {
      padding: 0,
      border: 0,
      color: theme.colors.white.default,
      background: "transparent",
      ":hover": {
        color: theme.colors.mint.default,
      },
    },
    link: {
      background: "transparent",
      border: 0,
      color: theme.colors.purple.default,
      fontWeight: theme.fontWeights.regular,
      padding: 0,
      textDecoration: "underline",
      ":hover": {
        color: theme.colors.mint.default,
      },
     
    },
  };

  return getStyles({ variants, base, styles, variant });
};
